/*    
<summary>
  This functional component "ClientFilterForm" provides form to get data based on filter
  Developer:Aashish Singh, Created Date:20-March-2024
</summary>
<returns>Returns JSX</returns>
*/
import React, { useState } from "react";
import { Modal, Button, Row, Col } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { Form, Formik, Field, useFormikContext } from "formik";
import { IUser } from "../../../models/IUser";
import FormikFormInput from "../../../shared-components/FormikFormHandlers/FormikFormInput";
import LoaderButton from "../../../shared-components/Button/LoaderButton";
import Label from "../../../shared-components/label/label";
import FormikFormSelectField from "../../../shared-components/FormikFormHandlers/FormikFormSelectField";
import { ErrorMessage } from "../../../constants/error.constant";
import { customerIdList, userTypeList } from "../../../constants/common-constant";
import DraggableModal from "../../../shared-components/Draggable/DraggableModal";

interface IProps {
  submitHandler: () => void;
  onModalClosed: () => void;
  actionInProgress: boolean;
  initialValues: any;

}


const LiveTrackingFilterForm = (props: IProps) => {
  const {
    submitHandler,
    onModalClosed,
    initialValues,
    actionInProgress,
  } = props;

  const FormObserver: React.FC = () => {
    const { values } = useFormikContext<IUser>();
    return null;
  };

  const [isSwitched, setIsSwitched] = useState(false);

  const handleSwitchClick = () => {
    setIsSwitched(!isSwitched);
  };


  return (
    <>
      <Modal className="add-user-modal " show={true} centered dialogAs={DraggableModal}>
        <Modal.Header>
          <Modal.Title>
            <FormattedMessage id='filter_title' />
          </Modal.Title>
          <div onClick={onModalClosed} className="icons8-close">
            <span className="material-symbols-outlined fs-5 text-dark">
              close
            </span>
          </div>
        </Modal.Header>
        <Formik
          initialValues={initialValues}
          enableReinitialize
          onSubmit={async (values) => { }} >
          {({ values }) => (
            <Form className="user-form p-3" noValidate>
              <FormObserver />
              <Modal.Body>
                <FormattedMessage id='filter_message' />
                <br />

                <Row className="mt-3">
                  <Col>
                    <div className="mb-3">
                      <Label
                        className="form-label"
                        required={true}
                        label="user_label_user_type"
                      />
                      <Field
                        name="UserType"
                        type="number"
                        className="form-control"
                        as={FormikFormSelectField}
                        // label='User_Label_Role'
                        typeofoptionid="number"
                        options={userTypeList}
                      />
                    </div>
                  </Col>
                  {values.UserType === 1 &&
                    <Col>
                      <div className="mb-3">
                        <Label
                          className="form-label"
                          required={true}
                          label="user_label_roleid"
                        />
                        <Field
                          name="RoleId"
                          type="number"
                          className="form-control"
                          as={FormikFormSelectField}
                          typeofoptionid="number"
                          options={[{
                            Id: 1,
                            Name: "ClientAdmin"
                          }, {
                            Id: 2,
                            Name: "Client"
                          }, {
                            Id: 3,
                            Name: "User"
                          }]}
                        />
                      </div>
                    </Col>
                  }
                  {values.UserType === 2 &&
                    <Col>
                      <div className="mb-3">
                        <Label
                          className="form-label"
                          required={true}
                          label="customer_id"
                        />
                        <Field
                          className="form-control form-select"
                          aria-label="Default select example"
                          name="CustomerId"
                          type="number"
                          as={FormikFormSelectField}
                          typeofoptionid="string"
                          options={customerIdList}
                        />
                      </div>
                    </Col>
                  }
                </Row>

                <Row>
                  <Col xs={12}>

                    <Label className="form-label"
                      label="user_label_username"
                    />
                    <div className="mb-3">
                      <Field
                        data-testid="testEmail"
                        className="form-control"
                        name="UserName"
                        autoComplete="new-email"
                        type="text"
                        invalidcharacter={[" "]}
                        placeholder="user_label_username"
                        as={FormikFormInput}
                      />
                    </div>
                  </Col>

                </Row>

                <Row>
                  <Col xs={12}>
                    <Label className="form-label"
                      label="Location Filters "
                    />
                  </Col>
                  <hr />
                </Row>


                <Row className="mb-3">

                  <Col xs={12}>
                    <div className="mb-3">
                      <Label className="form-label"
                        label="country"
                      />
                      <Field
                        data-testid="testEmail"
                        className="form-control"
                        name="Country"
                        autoComplete="new-email"
                        type="text"
                        invalidcharacter={[" "]}
                        placeholder="country"
                        as={FormikFormInput}
                      />
                    </div>
                  </Col>

                </Row>

                <Row>
                  <Col xs={5}>
                    <div className="mb-3">
                      <Label className="form-label" htmlFor="starting_point"
                        label="starting_point" />
                      <Field
                        data-testid="testEmail"
                        className="form-control"
                        name={isSwitched ? 'destination_point' : 'starting_point'}
                        autoComplete="off"
                        type="text"
                        placeholder="starting_point"
                        as={FormikFormInput} />
                    </div>
                  </Col>
                  <Col xs={2} className="d-flex align-items-center justify-content-center">
                    <Button
                      variant="btn-secondary arrow-swap btn-light"
                      onClick={handleSwitchClick}
                    >
                      <span className="material-symbols-outlined" style={{ fontSize: '24px' }}>swap_horiz</span>
                    </Button>
                  </Col>
                  <Col xs={5}>
                    <div className="mb-3">
                      <Label className="form-label" htmlFor="destination_point"
                        label="destination_point" />
                      <Field
                        data-testid="testEmail"
                        className="form-control"
                        name={isSwitched ? 'starting_point' : 'destination_point'}
                        autoComplete="off"
                        type="text"
                        placeholder='destination_point'
                        as={FormikFormInput} />
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col xs={12}>  <br />
                    <Label className="form-label"
                      label="active_status"
                    />
                  </Col>
                </Row>

                <Row>
                  <Col xs={3}>
                    <div className="form-check mt-2">
                      <Field
                        data-testid="testIsActive"
                        className="form-check-input"
                        name="isActive"
                        type="radio"
                        id="isActive"
                        value="true"
                      />
                      <label
                        htmlFor="isActive"
                        className="form-check-label mt-1"
                      >
                        <FormattedMessage id="active" />
                      </label>
                    </div>
                  </Col>
                  <Col xs={4}>
                    <div className="form-check mt-2">
                      <Field
                        data-testid="testIsNotActive"
                        className="form-check-input"
                        name="isActive"
                        type="radio"
                        id="isNotActive"
                        value="false"
                      />
                      <label
                        htmlFor="isNotActive"
                        className="form-check-label mt-1"
                      >
                        <FormattedMessage id="not_active" />
                      </label>
                    </div>
                  </Col>
                </Row>

              </Modal.Body>
              <div className="modal-footer  border-0">
                <div className="m-auto">
                  <LoaderButton
                    type="submit"
                    isLoading={actionInProgress}
                    disabled={actionInProgress}
                    text="filter_title"
                  />
                  <Button
                    variant="btn-secondary bg-light btn-light ms-2"
                    onClick={onModalClosed}
                  >
                    <FormattedMessage id="button_cancel" />
                  </Button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};

export default LiveTrackingFilterForm;
