import { observer } from "mobx-react";
import React, { useState, useCallback, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { withSize } from "react-sizeme";
import { toast } from "react-toastify";
import { ColumnType } from "../../constants/enums/gridColumnTypeEnum";
import { useStore } from "../../contexts/StoreProvider";
import useWindowDimensions, {
  getTableMaxHeight,
} from "../../helpers/windowResize.helper";
import { ISorting } from "../../models/ISorting";
import Grid, { getColumns } from "../../shared-components/Grid/Grid";
import { formatMessage } from "../../translations/formatMessage";
import Pagination from "../../shared-components/Grid/Pagination";
import { IGetTransactions, IMissingTicket, ITransaction } from "../../models/ITransaction";
import IPageInfo from "../../models/IPageInfo";
import { IActionFormatter, IFormatter } from "../../models/IFormatter";
import {
  PageLimitOptions,
  initialPageLimit,
} from "../../constants/pageLimit.constant";
import { Col, Container, ListGroup, Nav, Row } from "react-bootstrap";
import SlidingPanel from "react-sliding-side-panel";
import TransactionDetails from "./components/transaction-details";
import SpinnerLoader from "../../shared-components/Loader/SpinnerLoader";
import EditPopupHOC from "../../shared-components/Popup/Hoc/EditPopupHoc";
import UpdateCustomerIdsInTransaction from "./components/edit-customer-ids-in-transaction";
import { showIcons } from "../../helpers/routesFunctions.helper";
import { ModuleName } from "../../constants/moduleName.constant";
import { TransactionsEnum } from "../../constants/enums/productIdTransactionsEnum";
import "./Transaction.scss";
import SubHeader from "../../shared-components/Header/SubHeader";
import { FilterPopupHOC } from "../../shared-components/Filters/FilterPopupHOC";
import TransactionFilterForm from "./forms/transaction-filter-form";
import NoData from "../../assets/Images/no_record.png";
import _ from "lodash";
import UrlConstants from "../../constants/url.constant";
import { transactionInitialFilterState } from "../../core/initialState/initial-filter-states";
import { ITransactionFilter } from "../../models/IFilter";
import Footer from "../../shared-components/Footer/Footer";
import moment from "moment";
import userPageLimitSettingEnum from "../../constants/enums/user-pagination-setting-enum";
import UpdateTransaction from "./components/update-transaction";
import DeletePopupHOC from "../../shared-components/Popup/Hoc/DeletePopupHoc";
import DeletePopup from "../../shared-components/Popup/DeletePopup";
import { getAmountDecimal, getClientId, getUserType } from "../../helpers/localStorages.helper";
import UserType from "../../constants/userType.constant";
import { emptyValue, getCustomerLabelPrefix, getLabelOfTransactionReport } from "../../constants/common-constant";
import AddPopupHOC from "../../shared-components/Popup/Hoc/AddPopupHoc";
import MissingTransactionFilterForm from "./forms/missing-transaction-filter-form";
import { addReportInitialData } from "../../core/initialState/add-report-state";
import MissingTransactionPreview from "./missing-transaction-preview";
import RoutesConstants from "../../constants/routes.constant";
import ViewDetailsPopupHOC from "../../shared-components/Popup/Hoc/ViewDetailsPopupHoc";

interface IProps {
  showEditPopup: boolean;
  editToggleHandler: () => void;
  size: {
    height: number;
  };
  showFilterPopup: boolean;
  filterToggleHandler: () => void;
  showDeletePopup: boolean;
  deleteToggleHandler: () => void;
  showAddPopup: boolean;
  addToggleHandler: () => void;
  showViewDetailsPopup: boolean;
  viewDetailsToggleHandler: () => void;
}

const Transaction: React.FC<IProps> = (props: IProps) => {
  // const [transactionsTabKey, setTransactionsTabKey]= useState<string>("transaction");
  const [productId, setProductId] = useState<string>(
    TransactionsEnum.Deliveries
  );
  const { transactionsStore, preferencesStore, customerStore } = useStore();
  const {

    editToggleHandler,
    showEditPopup,
    showFilterPopup,
    filterToggleHandler,
    showDeletePopup,
    deleteToggleHandler,
    showAddPopup,
    addToggleHandler,
    showViewDetailsPopup,
    viewDetailsToggleHandler
  } = props;
  const [selectedRow, setSelectedRow] = useState<ITransaction | undefined>(
    undefined
  );
  const [openPanel, setOpenPanel] = useState(false);
  const {
    inProgress,
    missingTransactionState,
    transactionData,
    getAllTransactionService,
    allTransaction,
    reset,
    addUpdateTransactionState,
    exportTransactionReportByIdService,
    filter,
    setFilterDetail,
    transactionCustomerIdsUpdateState,
    deleteTransactionState,
    deleteTransactionService,
    resetDeleteTransactionState,
    resetGetTransactionDetailState,
    GetAllTransactionOptionIdsService,
    optionIdsListState,
    resetOptionIdsListState,
    allAvailableOptionIds,
    getAllMissingTransactionService,
    GetAllMissingTransactionViewService,
    resetTransactionViewDataState
  } = transactionsStore;

  const {
    CSVFileDownloadPostService,
    GetCustomerSummaryService,
    resetSummaryState,
    getCustomerOptions,
    getTruckOptions,
    GetTruckSummaryService,
    userPageLimitSetting,
    setUserPageLimitSetting,
    selectedClientId,
    setSelectedClientId,
    setAmountDecimal,
    setEnableLimitSettings,
    allAvailableClientOptions, resetClientListState, clientList
  } = preferencesStore;

  const {
    GetPDFSettingByIdService,
    resetGetSettingDetailState,
    settingDetailState,
    getSettingDetail
  } = customerStore;

  const [selectedId, setSelectedId] = useState<number>(-1);
  const [changeDetails, setChangeDetails] = useState<boolean>(false);
  const [disablePagination, setDisablePagination] = useState<boolean>(false);
  const allTransactionsData: Array<IGetTransactions> = allTransaction;
  const [pageLimitForTransactions, setPageLimitForTransactions] =
    useState<number>(userPageLimitSetting.trasnactionLimitConfig);
  const [searchTextForTransactions, setSearchTextForTransactions] =
    useState("");
  const [sortingForTransactions, setSortingForTransactions] =
    useState<ISorting>({
      column: "ts_start",
      orderBy: "desc",
    });
  const [isApiCalled, setIsApiCalled] = useState<boolean>(false);

  const [pageInfo, setPageInfo] = useState<IPageInfo>({
    currentPage: 1,
    totalPages: 1,
  });

  let decimalValue = getAmountDecimal();

  const { height, width } = useWindowDimensions();

  const closePanelHandler = () => {
    setSelectedRow(undefined);
    setOpenPanel(false);
    resetGetTransactionDetailState();
  };

  const onPageChanged = useCallback((pageNumber: number) => {
    setPageInfo((pageInfo) => ({ ...pageInfo, currentPage: pageNumber }));
  }, []);

  const onPageLimitChangedForTransactions = (
    evt: React.ChangeEvent<HTMLSelectElement>
  ) => {
    let value = parseInt(evt.target.value);
    setPageLimitForTransactions(value);
    setPageInfo({ ...pageInfo, currentPage: 1 });
    setUserPageLimitSetting(userPageLimitSettingEnum.TrasnactionLimitConfig, value);
  };

  const onSearchTextChangedForTransactions = (text: string) => {
    setSearchTextForTransactions(text);
  };

  const callGetAllTransactionsService = useCallback(() => {
    setDisablePagination(true);
    if (getUserType() === UserType.SuperAdmin) {
      if (selectedClientId > 0 && clientList.length > 0) {
        if (productId == TransactionsEnum.Deliveries) {
          GetPDFSettingByIdService()
        }
        getAllTransactionService(
          productId,
          pageInfo.currentPage,
          pageLimitForTransactions,
          sortingForTransactions.orderBy,
          sortingForTransactions.column,
          _.omit(filter, ["isFilterActive"])
        );
      }
    }
    else {
      if (productId == TransactionsEnum.Deliveries) {
        GetPDFSettingByIdService()
      }
      getAllTransactionService(
        productId,
        pageInfo.currentPage,
        pageLimitForTransactions,
        sortingForTransactions.orderBy,
        sortingForTransactions.column,
        _.omit(filter, ["isFilterActive"])
      );
    }
    setIsApiCalled(true);
  }, [
    getAllTransactionService,
    productId,
    pageInfo.currentPage,
    pageLimitForTransactions,
    sortingForTransactions.orderBy,
    sortingForTransactions.column,
    selectedClientId,
    filter,
    clientList.length
  ]);



  const refreshClickHandler = () => {
    callGetAllTransactionsService();
  };

  // useEffect(() => {
  //   callGetAllTransactionsService();
  // }, [callGetAllTransactionsService]);

  // useEffect(() => {
  //   if (isApiCalled) {
  //     // showTransactionsDetailToggleHandler();
  //     setIsApiCalled(false);
  //   }
  // }, [isApiCalled]);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "filter" of the useEffect changed.
   */
  useEffect(() => {
    if (selectedClientId > 0)
      callGetAllTransactionsService();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter, selectedClientId, callGetAllTransactionsService]);
  // useEffect(() => {
  //   if (filter.isFilterActive) {
  //     callGetAllTransactionsService();
  //   } else callGetAllTransactionsService();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [filter, productId, callGetAllTransactionsService]);

  useEffect(() => {
    setPageInfo((pageInfo) => ({
      ...pageInfo,
      totalPages: transactionData?.TotalPages,
    }));
    setDisablePagination(false);
  }, [allTransaction, transactionData.TotalPages]);

  useEffect(() => {
    if (addUpdateTransactionState.success) {
      callGetAllTransactionsService();
    }
  }, [addUpdateTransactionState.success, callGetAllTransactionsService]);

  useEffect(() => {
    if (transactionCustomerIdsUpdateState.success) {
      callGetAllTransactionsService();
    }
  }, [transactionCustomerIdsUpdateState.success, callGetAllTransactionsService]);

  useEffect(() => {
    if (deleteTransactionState.success) {
      toast.success(formatMessage("message_transactions_delete_success"));
      deleteToggleHandler();
      resetDeleteTransactionState();
      callGetAllTransactionsService();
    }
  }, [deleteTransactionState.success, resetDeleteTransactionState, callGetAllTransactionsService]);

  useEffect(() => {
    return () => {
      resetFilterHandler();
      resetClientListState();
      resetOptionIdsListState();
    }
  }, []);

  // useEffect(() => {
  //   if (transactionDownloadError) {
  //     toast.error(formatMessage("message_transactions_download_failed"));
  //     resetError();
  //   }
  // }, [transactionDownloadError, resetError]);

  const openEditTransactionsHandler = (row: { Id: number }) => {
    setSelectedId(row.Id);
    setChangeDetails(false);
    closePanelHandler();
    editToggleHandler();
  };

  const showDetailsClickHandler = (row: ITransaction) => {
    setSelectedRow(row);
    // setChangeDetails(true);
    setOpenPanel(true);
    // !showTransactionsDetail && showTransactionsDetailToggleHandler();
    // setIsApiCalled(false);
  };

  const downloadTransactionsHandler = (row: ITransaction) => {
    exportTransactionReportByIdService(row.Id, productId, row.TicketNumber, row.CustomerId1);
  };

  const [selectedGenerateViewData, setSelectedGenerateViewData] = useState<ITransactionFilter>({
    ...transactionInitialFilterState,
  })

  const exportFilteredLogsCSV = () => {
    let tempFilterDetails = { ..._.omit(filter, ["isFilterActive"]) };
    let startDateData = moment(tempFilterDetails.StartDate).format(
      "YYYY-MM-DD"
    );
    let endDateData = moment(tempFilterDetails.EndDate).format("YYYY-MM-DD");
    const tempStartDate = startDateData + " 00:00:00";
    const tempEndDate = endDateData + " 23:59:59";

    let filterBody = {
      StartDate: tempStartDate,
      EndDate: tempEndDate,
      TicketNumber: tempFilterDetails.TicketNumber,
      TruckId: tempFilterDetails.TruckId,
      CustomerID1: tempFilterDetails.CustomerID1
    };
    let fileNameSegmentArray = productId.split("_");
    const clientIdFromLocalStorage = getClientId() != null ? getClientId() : -1;
    let fileName = `Transaction${fileNameSegmentArray.length === 2
      ? `_${fileNameSegmentArray[1] === "_" ? "" : fileNameSegmentArray[1]}`
      : ""
      }`;
    CSVFileDownloadPostService(
      `${UrlConstants.TransactionCSVDownload}?productId=${productId}&clientId=${clientIdFromLocalStorage}&tz=${moment().utcOffset()}`,
      fileName,
      filterBody
    );
  };

  const clientSelectHandler = (e: { label: string; value: number; key: number }) => {
    let value = e.value;
    if (value < 0) toast.error(formatMessage("please_select_any_client_admin"));
    else {
      resetFilterHandler();
      setSelectedClientId(value);
      setPageInfo({ ...pageInfo, currentPage: 1 });
      setAmountDecimal(value)
      setEnableLimitSettings(value)
    }
  };

  const deleteClickHandler = (row: { Id: number }) => {
    setSelectedId(row.Id);
    setChangeDetails(false);
    deleteToggleHandler();
  };

  const deleteHandler = () => {
    deleteTransactionService(selectedId);
  };

  const handleSortingChange = (accessor: string) => {
    const sortOrder = sortingForTransactions.orderBy === "asc" ? "desc" : "asc";
    setSortingForTransactions({ column: accessor, orderBy: sortOrder });
  };

  const openFilterHandler = () => {
    closePanelHandler();
    // GetCustomerSummaryService();
    GetTruckSummaryService();
    GetAllTransactionOptionIdsService(productId);
    filterToggleHandler();
  };

  const openMissingTicketHandler = () => {
    closePanelHandler();
    // GetCustomerSummaryService();
    GetTruckSummaryService();
    GetAllTransactionOptionIdsService(productId);
    addToggleHandler();
  };

  const filterSubmitHandler = (values: ITransactionFilter) => {
    closePanelHandler();
    setPageInfo({ ...pageInfo, currentPage: 1 });
    setFilterDetail({ ...values, isFilterActive: true });
    props.filterToggleHandler();
  };
  // const missingSubmitHandler = (values: IMissingTicket) => {
  //   closePanelHandler();
  //   getAllMissingTransactionService(`${values.TruckId}_missing_tickets`, values);
  //   addToggleHandler();
  // };

  const missingSubmitHandler = (values: any) => {
    if (values.ActionType === 1) {
      const state = {
        SearchId: values.SearchId === "Select" ? "" : values.SearchId,
        StartDate: values.StartDate,
        EndDate: values.EndDate,
        FileName: values.FileName,
        CustomerID1: "cust admin"
      }
      setSelectedGenerateViewData(values);
      // filterToggleHandler();
      viewDetailsToggleHandler();
      // GetAllMissingTransactionViewService("", values);
      // localStorage.setItem("report_values", JSON.stringify(state))
      // window.open(RoutesConstants.TransactionPreview, '_blank')
    }
    else {
      closePanelHandler();
      getAllMissingTransactionService(`${values.TruckId}_missing_tickets`, values);
      addToggleHandler();
    }
  };

  const resetFilterHandler = () => {
    setPageInfo({ ...pageInfo, currentPage: 1 });
    setFilterDetail({ ...transactionInitialFilterState });
  };

  const TransactionsRefreshHandler = () => {
    setChangeDetails(false);
    setDisablePagination(true);
    getAllTransactionService(
      productId,
      pageInfo.currentPage,
      pageLimitForTransactions,
      sortingForTransactions.orderBy,
      sortingForTransactions.column,
      _.omit(filter, ["isFilterActive"])
    );
    setIsApiCalled(true);
  };

  const reportPreviewCloseHandler = () => {
    // setAutoRefresh(true);
    resetTransactionViewDataState();
    viewDetailsToggleHandler();
  }

  const addIcons: Array<IActionFormatter<string>> = [
    {
      handler: showDetailsClickHandler,
      icon: (
        <svg
          enableBackground="new 0 0 519.643 519.643"
          width="13"
          height="13"
          fill="white"
          viewBox="0 0 519.643 519.643"
        >
          <circle cx="259.823" cy="259.866" r="80" />
          <path d="m511.673 237.706c-61.494-74.31-154.579-145.84-251.85-145.84-97.29 0-190.397 71.58-251.85 145.84-10.63 12.84-10.63 31.48 0 44.32 15.45 18.67 47.84 54.71 91.1 86.2 108.949 79.312 212.311 79.487 321.5 0 43.26-31.49 75.65-67.53 91.1-86.2 10.599-12.815 10.654-31.438 0-44.32zm-251.85-89.84c61.76 0 112 50.24 112 112s-50.24 112-112 112-112-50.24-112-112 50.24-112 112-112z" />
        </svg>
      ),
      key: "",
      title: "label_showdetails",
      className: "btn-secondary",
    },
    {
      handler: openEditTransactionsHandler,
      icon: (
        <svg height="14" viewBox="0 0 492.49284 492" width="14">
          <path d="m304.140625 82.472656-270.976563 270.996094c-1.363281 1.367188-2.347656 3.09375-2.816406 4.949219l-30.035156 120.554687c-.898438 3.628906.167969 7.488282 2.816406 10.136719 2.003906 2.003906 4.734375 3.113281 7.527344 3.113281.855469 0 1.730469-.105468 2.582031-.320312l120.554688-30.039063c1.878906-.46875 3.585937-1.449219 4.949219-2.8125l271-270.976562zm0 0" />
          <path d="m476.875 45.523438-30.164062-30.164063c-20.160157-20.160156-55.296876-20.140625-75.433594 0l-36.949219 36.949219 105.597656 105.597656 36.949219-36.949219c10.070312-10.066406 15.617188-23.464843 15.617188-37.714843s-5.546876-27.648438-15.617188-37.71875zm0 0" />
        </svg>
      ),
      // key: "IsEditable",
      key: "",
      title: "label_edit",
      className: "btn-warning",
    },
    {
      handler: downloadTransactionsHandler,
      icon: (
        <svg
          version="1.1"
          x="0px"
          y="0px"
          fill="#fff"
          height="13"
          width="13"
          viewBox="0 0 512 512"
        >
          <g>
            <g>
              <path
                d="M382.56,233.376C379.968,227.648,374.272,224,368,224h-64V16c0-8.832-7.168-16-16-16h-64c-8.832,0-16,7.168-16,16v208h-64
c-6.272,0-11.968,3.68-14.56,9.376c-2.624,5.728-1.6,12.416,2.528,17.152l112,128c3.04,3.488,7.424,5.472,12.032,5.472
c4.608,0,8.992-2.016,12.032-5.472l112-128C384.192,245.824,385.152,239.104,382.56,233.376z"
              />
            </g>
          </g>
          <g>
            <g>
              <path d="M432,352v96H80v-96H16v128c0,17.696,14.336,32,32,32h416c17.696,0,32-14.304,32-32V352H432z" />
            </g>
          </g>
        </svg>
      ),
      key: "",
      title: "label_download_pdf",
      className: "btn-success",
    },
    {
      handler: deleteClickHandler,
      icon: <svg
        version="1.1"
        x="0px"
        y="0px"
        width="13"
        height="13"
        viewBox="0 0 512 512"
      >
        <g>
          <g>
            <path
              d="M62.205,150l26.569,320.735C90.678,493.865,110.38,512,133.598,512h244.805c23.218,0,42.92-18.135,44.824-41.265
L449.795,150H62.205z M180.986,452c-7.852,0-14.458-6.108-14.956-14.063l-15-242c-0.513-8.276,5.771-15.395,14.033-15.908
c8.569-0.601,15.381,5.757,15.908,14.033l15,242C196.502,444.632,189.721,452,180.986,452z M271,437c0,8.291-6.709,15-15,15
c-8.291,0-15-6.709-15-15V195c0-8.291,6.709-15,15-15s15,6.709,15,15V437z M360.97,195.938l-15,242
c-0.493,7.874-7.056,14.436-15.908,14.033c-8.262-0.513-14.546-7.632-14.033-15.908l15-242
c0.513-8.276,7.764-14.297,15.908-14.033C355.199,180.543,361.483,187.662,360.97,195.938z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              d="M451,60h-90V45c0-24.814-20.186-45-45-45H196c-24.814,0-45,20.186-45,45v15H61c-16.569,0-30,13.431-30,30
c0,16.567,13.431,30,30,30c137.966,0,252.039,0,390,0c16.569,0,30-13.433,30-30C481,73.431,467.569,60,451,60z M331,60H181V45
c0-8.276,6.724-15,15-15h120c8.276,0,15,6.724,15,15V60z"
            />
          </g>
        </g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
      </svg>,
      // key: "IsDeletable",
      key: "",
      title: "label_delete",
    },
  ];



  const columns = getColumns<ITransaction, number>(
    [
      {
        columnType: ColumnType.Hidden,
        accessorKey: "Id",
      },
      {
        columnType: ColumnType.Sorting,
        accessorKey: "TicketNumber",
        sortingName: "ticket_number",
        displayName: "transactions_label_ticketnumber",
      },
      {
        columnType: ColumnType.ActionsFormatter,
        icons: showIcons(addIcons, ModuleName.TicketManagement),
        displayName: "label_action",
      },
      {
        columnType: ColumnType.Sorting,
        accessorKey: "TsStart",
        sortingName: "ts_start",
        displayName: "transactions_label_starttime",
      },
      {
        columnType: ColumnType.Sorting,
        accessorKey: "TruckId",
        sortingName: "truck_id",
        displayName: "transactions_label_truckid",
      },
      {
        columnType: ColumnType.Sorting,
        accessorKey: "CustomerId1",
        sortingName: "customer_id1",
        displayName: productId !== TransactionsEnum.Deliveries ? getCustomerLabelPrefix(productId, 1) : getSettingDetail.CustomerId1Title,
      },
      {
        columnType: ColumnType.Sorting,
        accessorKey: "CustomerId2",
        sortingName: "customer_id2",
        displayName: productId !== TransactionsEnum.Deliveries ? getCustomerLabelPrefix(productId, 2) : getSettingDetail.CustomerId2Title,//"transactions_label_customer_id_2",
      },
      {
        columnType: ColumnType.Sorting,
        accessorKey: "CustomerId3",
        sortingName: "customer_id3",
        displayName: productId !== TransactionsEnum.Deliveries ? getCustomerLabelPrefix(productId, 3) : getSettingDetail.CustomerId3Title,
      },
      {
        columnType: ColumnType.Sorting,
        accessorKey: "CustomerId4",
        sortingName: "customer_id4",
        displayName: productId !== TransactionsEnum.Deliveries ? getCustomerLabelPrefix(productId, 4) : getSettingDetail.CustomerId4Title,
      },
      {
        columnType: ColumnType.Sorting,
        accessorKey: "GrossDelivered",
        sortingName: "gross_delivered",
        displayName: "transactions_label_grossdelivered",
      },
      {
        columnType: ColumnType.Sorting,
        accessorKey: "Unit",
        sortingName: "unit",
        displayName: "transactions_label_order_unit",
      },
      {
        columnType: ColumnType.Sorting,
        accessorKey: "PricePerL",
        sortingName: "price_per_l",
        displayName: "unitprice",
      },
      {
        columnType: ColumnType.Sorting,
        accessorKey: "MeterId",
        sortingName: "mid",
        displayName: "meter_id",
      },
    ],
    sortingForTransactions,
    handleSortingChange
  );
  return (
    <>
      {showViewDetailsPopup && (
        <MissingTransactionPreview
          data={selectedGenerateViewData}
          onModalClosed={reportPreviewCloseHandler}
        />
      )}
      {showFilterPopup && (
        <TransactionFilterForm
          initialValues={_.omit(filter, ["isFilterActive"])}
          submitHandler={filterSubmitHandler}
          onModalClosed={filterToggleHandler}
          actionInProgress={inProgress || optionIdsListState.inProgress}
          productId={productId}
          CustomerIdOptions={allAvailableOptionIds}
          TruckIdOptions={getTruckOptions}
        />
      )}
      {showAddPopup && (
        <MissingTransactionFilterForm
          initialValues={_.omit(filter, ["isFilterActive"])}
          submitHandler={missingSubmitHandler}
          onModalClosed={addToggleHandler}
          actionInProgress={missingTransactionState.inProgress || optionIdsListState.inProgress}
          productId={productId}
          // CustomerIdOptions={allAvailableOptionIds}
          TruckIdOptions={getTruckOptions}
        />
      )}
      {showDeletePopup && (
        <DeletePopup
          modalSubmit={deleteHandler}
          modalClosed={deleteToggleHandler}
          isLoading={deleteTransactionState.inProgress}
          message="delete_transaction_confirm"
          submitBtnText="submit_button"
          cancelBtnText="button_cancel"
          modalTitle="delete_transaction"
        />
      )}
      {showEditPopup && (
        <>
          {
            getUserType() == UserType.SuperAdmin ?
              <UpdateTransaction id={selectedId} productId={productId} modalClosed={editToggleHandler} /> :
              <UpdateCustomerIdsInTransaction id={selectedId} modalClosed={editToggleHandler} />
          }
        </>
      )}
      <section className="transactions">
        <React.Fragment>
          <div className={`main-content`}>
            <SubHeader
              moduleNameString={"transactions_title"}
              moduleName={ModuleName.TicketManagement}
              filterHandler={openFilterHandler}
              isFilterActive={filter.isFilterActive}
              exportCSVHandler={exportFilteredLogsCSV}
              resetFilterHandler={resetFilterHandler}
              isAdd={false}
              isFullView={false}
              isDownload={true}
              isRefresh={true}
              showClientSelectHandler={true}
              clientSelectHandler={clientSelectHandler}
              refreshClickHandler={refreshClickHandler}
              missingTicketHandler={openMissingTicketHandler}
              isFilter={true}
              isDateFilter={false}
              isImport={false}
              isMissing={true} />
          </div>
          <div className="tabVIew">
            <div className="transactionTabs p-3 pb-0">
              <Nav
                className="m-auto"
                activeKey={productId}
                onSelect={(k: any) => {
                  closePanelHandler()
                  setProductId(k);
                  resetFilterHandler();
                  resetOptionIdsListState();
                  setPageLimitForTransactions(userPageLimitSetting.trasnactionLimitConfig);
                  setPageInfo({
                    currentPage: 1,
                    totalPages: 1,
                  });
                }}
                as="ul"
              >
                <Nav.Item as="li">
                  <Nav.Link
                    eventKey={TransactionsEnum.Transaction}
                    aria-current="page"
                  >
                    <svg
                      version="1.1"
                      height="20"
                      width="20"
                      fill="#000"
                      x="0px"
                      y="0px"
                      viewBox="0 0 512.18 512.18"
                    >
                      <g>
                        <g>
                          <path d="M448.18,80h-320c-17.673,0-32,14.327-32,32s14.327,32,32,32h320c17.673,0,32-14.327,32-32S465.853,80,448.18,80z" />
                        </g>
                      </g>
                      <g>
                        <g>
                          <path
                            d="M64.18,112c-0.036-8.473-3.431-16.586-9.44-22.56c-12.481-12.407-32.639-12.407-45.12,0
			C3.61,95.414,0.215,103.527,0.18,112c-0.239,2.073-0.239,4.167,0,6.24c0.362,2.085,0.952,4.124,1.76,6.08
			c0.859,1.895,1.876,3.715,3.04,5.44c1.149,1.794,2.49,3.457,4,4.96c1.456,1.45,3.065,2.738,4.8,3.84
			c1.685,1.227,3.512,2.248,5.44,3.04c2.121,1.1,4.382,1.908,6.72,2.4c2.073,0.232,4.167,0.232,6.24,0
			c8.45,0.007,16.56-3.329,22.56-9.28c1.51-1.503,2.851-3.166,4-4.96c1.164-1.725,2.181-3.545,3.04-5.44
			c1.021-1.932,1.826-3.971,2.4-6.08C64.419,116.167,64.419,114.073,64.18,112z"
                          />
                        </g>
                      </g>
                      <g>
                        <g>
                          <path
                            d="M64.18,256c0.238-2.073,0.238-4.167,0-6.24c-0.553-2.065-1.359-4.053-2.4-5.92c-0.824-1.963-1.843-3.839-3.04-5.6
			c-1.109-1.774-2.455-3.389-4-4.8c-12.481-12.407-32.639-12.407-45.12,0C3.61,239.414,0.215,247.527,0.18,256
			c0.062,4.217,0.875,8.388,2.4,12.32c0.802,1.893,1.766,3.713,2.88,5.44c1.217,1.739,2.611,3.348,4.16,4.8
			c1.414,1.542,3.028,2.888,4.8,4c1.685,1.228,3.511,2.249,5.44,3.04c1.951,0.821,3.992,1.412,6.08,1.76
			c2.047,0.459,4.142,0.674,6.24,0.64c2.073,0.239,4.167,0.239,6.24,0c2.036-0.349,4.024-0.94,5.92-1.76
			c1.981-0.786,3.861-1.807,5.6-3.04c1.772-1.112,3.386-2.458,4.8-4c1.542-1.414,2.888-3.028,4-4.8c1.23-1.684,2.251-3.51,3.04-5.44
			c1.093-2.124,1.9-4.384,2.4-6.72C64.426,260.167,64.426,258.073,64.18,256z"
                          />
                        </g>
                      </g>
                      <g>
                        <g>
                          <path
                            d="M64.18,400c0.237-2.073,0.237-4.167,0-6.24c-0.553-2.116-1.359-4.157-2.4-6.08c-0.859-1.895-1.876-3.715-3.04-5.44
			c-1.112-1.772-2.458-3.386-4-4.8c-12.481-12.407-32.639-12.407-45.12,0c-1.542,1.414-2.888,3.028-4,4.8
			c-1.164,1.725-2.181,3.545-3.04,5.44c-0.83,1.948-1.421,3.99-1.76,6.08c-0.451,2.049-0.665,4.142-0.64,6.24
			c0.036,8.473,3.431,16.586,9.44,22.56c1.414,1.542,3.028,2.888,4.8,4c1.685,1.228,3.511,2.249,5.44,3.04
			c1.951,0.821,3.992,1.412,6.08,1.76c2.047,0.459,4.142,0.674,6.24,0.64c2.073,0.239,4.167,0.239,6.24,0
			c2.036-0.349,4.024-0.94,5.92-1.76c1.981-0.786,3.861-1.807,5.6-3.04c1.772-1.112,3.386-2.458,4.8-4
			c1.542-1.414,2.888-3.028,4-4.8c1.231-1.683,2.252-3.51,3.04-5.44c1.092-2.125,1.899-4.384,2.4-6.72
			C64.426,404.167,64.426,402.073,64.18,400z"
                          />
                        </g>
                      </g>
                      <g>
                        <g>
                          <path d="M480.18,224h-352c-17.673,0-32,14.327-32,32s14.327,32,32,32h352c17.673,0,32-14.327,32-32S497.853,224,480.18,224z" />
                        </g>
                      </g>
                      <g>
                        <g>
                          <path
                            d="M336.18,368h-208c-17.673,0-32,14.327-32,32c0,17.673,14.327,32,32,32h208c17.673,0,32-14.327,32-32
			C368.18,382.327,353.853,368,336.18,368z"
                          />
                        </g>
                      </g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                    </svg>
                    <span className="ps-1">
                      {" "}
                      <FormattedMessage id="in_flow" />{" "}
                    </span>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item as="li">
                  <Nav.Link eventKey={TransactionsEnum.Transfer}>
                    <svg
                      height="20"
                      width="20"
                      fill="#000"
                      viewBox="0 0 24 24"
                    >
                      <g>
                        <path d="m21 11h-14c-.6 0-1-.4-1-1s.4-1 1-1h11.6l-2.3-2.3c-.4-.4-.4-1 0-1.4s1-.4 1.4 0l4 4c.3.3.4.7.2 1.1-.1.4-.5.6-.9.6z" />
                        <path d="m7 19c-.3 0-.5-.1-.7-.3l-4-4c-.3-.3-.4-.7-.2-1.1s.5-.6.9-.6h14c.6 0 1 .4 1 1s-.4 1-1 1h-11.6l2.3 2.3c.4.4.4 1 0 1.4-.2.2-.4.3-.7.3z" />
                      </g>
                    </svg>
                    <span className="ps-1">
                      {" "}
                      <FormattedMessage id="transfer" />
                    </span>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item as="li">
                  <Nav.Link
                    eventKey={TransactionsEnum.Deliveries}
                    className="text-black pe-4"
                  >
                    <svg
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                      x="0px"
                      y="0px"
                      height="20"
                      width="20"
                      fill="#000"
                      viewBox="0 0 512 512"
                    >
                      <g>
                        <g>
                          <path
                            d="M386.689,304.403c-35.587,0-64.538,28.951-64.538,64.538s28.951,64.538,64.538,64.538
			c35.593,0,64.538-28.951,64.538-64.538S422.276,304.403,386.689,304.403z M386.689,401.21c-17.796,0-32.269-14.473-32.269-32.269
			c0-17.796,14.473-32.269,32.269-32.269c17.796,0,32.269,14.473,32.269,32.269C418.958,386.738,404.485,401.21,386.689,401.21z"
                          />
                        </g>
                      </g>
                      <g>
                        <g>
                          <path
                            d="M166.185,304.403c-35.587,0-64.538,28.951-64.538,64.538s28.951,64.538,64.538,64.538s64.538-28.951,64.538-64.538
			S201.772,304.403,166.185,304.403z M166.185,401.21c-17.796,0-32.269-14.473-32.269-32.269c0-17.796,14.473-32.269,32.269-32.269
			c17.791,0,32.269,14.473,32.269,32.269C198.454,386.738,183.981,401.21,166.185,401.21z"
                          />
                        </g>
                      </g>
                      <g>
                        <g>
                          <path d="M430.15,119.675c-2.743-5.448-8.32-8.885-14.419-8.885h-84.975v32.269h75.025l43.934,87.384l28.838-14.5L430.15,119.675z" />
                        </g>
                      </g>
                      <g>
                        <g>
                          <rect
                            x="216.202"
                            y="353.345"
                            width="122.084"
                            height="32.269"
                          />
                        </g>
                      </g>
                      <g>
                        <g>
                          <path
                            d="M117.781,353.345H61.849c-8.912,0-16.134,7.223-16.134,16.134c0,8.912,7.223,16.134,16.134,16.134h55.933
			c8.912,0,16.134-7.223,16.134-16.134C133.916,360.567,126.693,353.345,117.781,353.345z"
                          />
                        </g>
                      </g>
                      <g>
                        <g>
                          <path
                            d="M508.612,254.709l-31.736-40.874c-3.049-3.937-7.755-6.239-12.741-6.239H346.891V94.655
			c0-8.912-7.223-16.134-16.134-16.134H61.849c-8.912,0-16.134,7.223-16.134,16.134s7.223,16.134,16.134,16.134h252.773v112.941
			c0,8.912,7.223,16.134,16.134,16.134h125.478l23.497,30.268v83.211h-44.639c-8.912,0-16.134,7.223-16.134,16.134
			c0,8.912,7.223,16.134,16.134,16.134h60.773c8.912,0,16.134-7.223,16.135-16.134V264.605
			C512,261.023,510.806,257.538,508.612,254.709z"
                          />
                        </g>
                      </g>
                      <g>
                        <g>
                          <path
                            d="M116.706,271.597H42.487c-8.912,0-16.134,7.223-16.134,16.134c0,8.912,7.223,16.134,16.134,16.134h74.218
			c8.912,0,16.134-7.223,16.134-16.134C132.84,278.82,125.617,271.597,116.706,271.597z"
                          />
                        </g>
                      </g>
                      <g>
                        <g>
                          <path
                            d="M153.815,208.134H16.134C7.223,208.134,0,215.357,0,224.269s7.223,16.134,16.134,16.134h137.681
			c8.912,0,16.134-7.223,16.134-16.134S162.727,208.134,153.815,208.134z"
                          />
                        </g>
                      </g>
                      <g>
                        <g>
                          <path
                            d="M180.168,144.672H42.487c-8.912,0-16.134,7.223-16.134,16.134c0,8.912,7.223,16.134,16.134,16.134h137.681
			c8.912,0,16.134-7.223,16.134-16.134C196.303,151.895,189.08,144.672,180.168,144.672z"
                          />
                        </g>
                      </g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                    </svg>
                    <span className="ps-1">
                      {" "}
                      <FormattedMessage id="Deliveries" />
                    </span>
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </div>
            {/* <div className={`main-content Transactions ${showTransactionsDetail ? 'table-container' : ''} `}> */}
            <div className={`main-content`}>
              {inProgress && <SpinnerLoader />}
              {/* P<Header headerText={formatMessage("SecureFuelFill_Title")} transactionsTabKey={transactionsTabKey} setTransactionsTabKey={setTransactionsTabKey} moduleNameString={"Dashboard_Title"} /> */}
              {/* <Row className="py-3">
              <Col
                className="align-items-center d-flex justify-content-start"
                xs={3}
              >

              </Col>
              <Col className="d-flex justify-content-end">
              <SearchField
                  onSearchTextChanged={onSearchTextChangedForTransactions}
                />  
              </Col>
            </Row> */}
              <div id="moveContent" className="transactionWrapper">

                {allTransactionsData.length !== 0 ?
                  <><div className={`${openPanel ? "paneIsOpen" : ""}`}>
                    <div className="px-3">
                      <div className=" rounded shadow">
                        <div className="Fuel-table">
                          <div
                            className="table-responsive"
                            style={getTableMaxHeight(height, width)}
                            data-testid="table"
                          >
                            {allTransactionsData && (
                              <Grid
                                data={allTransactionsData}
                                columns={columns}
                                isInProgress={inProgress}
                                hiddenColumns={["Id"]}
                              />
                            )}
                          </div>
                        </div>
                        <Container fluid>
                          <Row className="tableFooter py-1">
                            <Col xs="2" className="d-flex">
                              <b className="text-white text-hidden-m mt-1 pt-1">
                                <FormattedMessage id="show" />:{" "}
                              </b>

                              <select
                                data-testid="pageSelect"
                                className="ms-2 from-control"
                                onChange={onPageLimitChangedForTransactions}
                                value={pageLimitForTransactions}
                              >
                                {PageLimitOptions.map((op) => (
                                  <option key={op} value={op}>
                                    {op}
                                  </option>
                                ))}
                              </select>
                            </Col>

                            <Col className="d-flex justify-content-end text-white" xs="10">

                              <Pagination
                                totalPages={pageInfo.totalPages}
                                pageLimit={pageLimitForTransactions}
                                onPageChanged={onPageChanged}
                                disablePagination={disablePagination}
                              />
                            </Col>
                            <Col className="d-flex justify-content-center text-white total-transaction" xs="12">
                              <ul className="list-group list-group-horizontal total-transaction">
                                <li className="list-group-item bg-transparent border-0 text-white d-flex">
                                  <FormattedMessage id="Total Transactions" />:{" "} {transactionData.TotalTransactionCount}
                                </li>
                                <li className="list-group-item bg-transparent border-0 text-white">             <FormattedMessage id="Total Vat" />:{" "} {transactionData.TotalVat !== null ? Number(transactionData.TotalVat).toFixed(Number(decimalValue)) : emptyValue}
                                </li>
                                <li className="list-group-item bg-transparent border-0 text-white">
                                  <FormattedMessage id="Total Amount Due" />:{" "} {transactionData.TotalAmountDue !== null ? Number(transactionData.TotalAmountDue).toFixed(Number(decimalValue)) : emptyValue}
                                </li>
                                <li className="list-group-item bg-transparent border-0 text-white"> <FormattedMessage id="Total Volume Delivered" />:{" "} {Number(transactionData.TotalVolumeDelivered).toFixed(2)}
                                </li>
                              </ul>
                            </Col>
                          </Row>
                        </Container>
                      </div>
                    </div>
                  </div>
                    <SlidingPanel
                      type={"right"}
                      isOpen={openPanel}
                      noBackdrop={false}
                      size={100}
                    >
                      <div>
                        <div className="row g-0 border-bottom pt-3 pb-1 ps-3">
                          <Col lg={10} md={6} xs={6}>
                            <h2 className="fs-4 fw-bolder mb-0">
                              <FormattedMessage id="transaction" />
                            </h2>
                          </Col>
                          <Col className="text-end" lg={2} md={6} xs={6}>
                            <svg
                              onClick={closePanelHandler}
                              role="button"
                              xlinkTitle="close"
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              fill="currentColor"
                              className="bi bi-x"
                              viewBox="0 0 16 16"
                            >
                              <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                            </svg>
                          </Col>
                        </div>
                        <div className="group_detailsWrapper">
                          <TransactionDetails selectedData={selectedRow} productId={productId} />
                        </div>
                      </div>
                    </SlidingPanel></>
                  :
                  (
                    <div className="no_data_table background-image ">
                      <div className="no_data w-100 text-capitalize text-center">
                        <img src={NoData} alt="NOData" className="NOData" />
                        <h1 className="text-capitalize fs-2">
                          <FormattedMessage id="message_no_datafound" />
                        </h1>
                      </div>
                    </div>
                  )
                }
              </div>
            </div>
          </div>
          <Footer />
        </React.Fragment>
      </section>
    </>
  );
};

export default ViewDetailsPopupHOC(AddPopupHOC(DeletePopupHOC(FilterPopupHOC(
  EditPopupHOC(withSize({ monitorHeight: true })(observer(Transaction)))
))));
