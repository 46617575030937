import { ModalDialog, ModalDialogProps } from 'react-bootstrap';
import Draggable, { DraggableData, DraggableEvent } from 'react-draggable';
import './DraggableModal.scss';
import { useRef, useState } from 'react';

const DraggableModal = (props: ModalDialogProps & React.RefAttributes<HTMLDivElement>) => {
    const [bounds, setBounds] = useState({
        left: 0,
        right: 0,
        top: 0,
        bottom: 0
    });

    const draggleRef = useRef<HTMLDivElement>(null);

    const onStart = (event: DraggableEvent, uiData: DraggableData) => {
        const { clientWidth, clientHeight } = window?.document?.documentElement;
        const targetRect = draggleRef?.current?.getBoundingClientRect();
        if (targetRect) {
            setBounds({
                left: -clientWidth / 2, // Ensure it can't be dragged past the left boundary
                right: clientWidth / 2, // Ensure it can't be dragged past the right boundary
                top: -targetRect.top + uiData.y, // Allow vertical dragging
                bottom: clientHeight - targetRect.bottom + uiData.y // Restrict vertical dragging within window height
            });
        }
    };

    return (
        <Draggable
            handle=".modal-header"
            bounds={bounds}
            onStart={onStart}
            nodeRef={draggleRef}
            scale={1}
        >
            <div ref={draggleRef}>
                <ModalDialog {...props} />
            </div>
        </Draggable>
    );
};

export default DraggableModal;
