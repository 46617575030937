/*    
<summary>
  This functional component "AddUpdateRole" defines the props like initial values and functions for the child form component.
  Developer:Aashish Singh, Created Date:04-March-2024
</summary>
<returns>Returns JSX</returns>
*/

import { observer } from "mobx-react";
import React, { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useStore } from "../../contexts/StoreProvider";
import { IOption } from "../../models/ICommon";
import { IAddTransactions, ITransaction, IUpdateTransactions, } from "../../models/ITransaction";
import { formatMessage } from "../../translations/formatMessage";
import { Field, Form, Formik } from "formik";
import { Modal, Row, Col, Button, Container, Card } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import LoaderButton from "../../shared-components/Button/LoaderButton";
import FormikFormInput from "../../shared-components/FormikFormHandlers/FormikFormInput";
import Label from "../../shared-components/label/label";
import { cloneDeep, filter } from "lodash";
import { ModuleName } from "../../constants/moduleName.constant";
import Footer from "../../shared-components/Footer/Footer";
import SubHeader from "../../shared-components/Header/SubHeader";
import SpinnerLoader from "../../shared-components/Loader/SpinnerLoader";
import "./superadmin-dashboard.scss";
import TotalDrivers from "../../assets/Images/total-drivers.svg";
import TotalVehicles from "../../assets/Images/Total-vehicles.svg";
import clients from "../../assets/Images/clients.png";
import TotalCustomers from "../../assets/Images/total-customers.svg";
import TotalTransaction from "../../assets/Images/total-transaction.svg";
import Filter from "../../assets/Images/filterdashboard.svg";
import Avatar from "../../assets/Images/defaultImages2.png";
import FuelTank from "../../assets/Images/water-tank.svg";
import CPU from "../../assets/Images/cpu-usege.png";
import Memory from "../../assets/Images/memory.jpg";
import Disk from "../../assets/Images/disk-free.png";
import BlueWave from "../../assets/Images/blueWave.svg";
import purlplewave from "../../assets/Images/purlplewave.svg";
import {
  DATE_TIME_FORMAT_FILENAME, DATE_TIME_FORMAT_TO_BACKEND, emptyValue,
  roundWithPrecision,
} from "../../constants/common-constant";
import { initOptions, lineChartOptions, pieChartConfig, } from "../../shared-components/LineChart/line-chart-type";
import moment from "moment";
import LineChart from "../../shared-components/LineChart/line-chart";
import FilterPopupHOC from "../../shared-components/Popup/Hoc/FilterPopupHoc";
import SuperAdminDashboardFilterForm from "./form/superadmin-dashboard-filter-form";
import { NavLink } from "react-router-dom";
import RoutesConstants from "../../constants/routes.constant";
import SelectFilter from "../../assets/Images/selectFilter.png";

interface IProps {
  showFilterPopup: boolean;
  filterToggleHandler: () => void;
}

const SuperAdminDashboard = (props: IProps) => {
  // const filterDetails = tempFilterDetails ? JSON.parse(tempFilterDetails) : { ClientId: 'please_select' };
  const { showFilterPopup, filterToggleHandler } = props;
  const { superAdminDashboardStore, preferencesStore } = useStore();
  const {
    GetClientSummaryDetail,
    clientSummaryDetail,
    getClientData,
    getDashboardDataSuccessState,
    resetDashboardDataSuccessState,
    clientInfoSummaryDetail,
    GetClientInfoSummaryDetail,
    getClientInfoData,
    GetAllMonitoringDataService,
    getMonitoringData,
    monitoringData,
    monitoringDataState,
  } = superAdminDashboardStore;
  const {
    selectedSuperAdminDashboardClientName,
    setSelectedSuperAdminDashboardClientName,
    GetAllClientsService,
    allAvailableAccountOptions,
    clientList
  } = preferencesStore;
  // const [filterState, setFilterState] = useState<{ ClientId: string }>(filterDetails);
  const [charts, setCharts] = useState<any>([]);
  let chartCollection: any = [];

  useEffect(() => {
    GetAllClientsService(true);
    GetClientSummaryDetail();
    GetAllMonitoringDataService();
  }, [
    GetClientSummaryDetail,
    GetClientInfoSummaryDetail,
    GetAllMonitoringDataService,
    GetAllClientsService,
  ]);

  const GetClientInfoSummary = useCallback(() => {
    if (selectedSuperAdminDashboardClientName !== "please_select") {
      if (clientList.map(e => e.Name).includes(selectedSuperAdminDashboardClientName)) {
        GetClientInfoSummaryDetail(selectedSuperAdminDashboardClientName);
      }
      else {
        GetClientInfoSummaryDetail("All");
      }
    }
  }, [GetClientInfoSummaryDetail, selectedSuperAdminDashboardClientName]);

  useEffect(() => {
    GetClientInfoSummary();
  }, [GetClientInfoSummary]);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "monitoringData" of the useEffect changed.
   */
  useEffect(() => {
    if (monitoringData) {
      createMonitoringDataLineChart(
        getMonitoringData.chartPoints,
        false,
        getMonitoringData.maxValue
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [monitoringData]);

  /**
   * This function returns the xAxisInterval based on the range.t.
   */
  const getXAxisLabelInterval = (range: number) => {
    let xAxisInterval: number = 2;
    if (!isNaN(range)) {
      let interval = Math.trunc(range / 12);
      xAxisInterval = interval > 0 ? interval : 1;
    }
    return xAxisInterval;
  };

  /**
   * This function maps the charts points for sim Usage to the data suitable for line chart component.
   */
  const createMonitoringDataLineChart = (
    allChartData: any,
    showlabel: boolean,
    maxValue: number
  ) => {
    var xAxisInterval = getXAxisLabelInterval(
      allChartData[0]?.dataPoints?.length
    );
    let pieConfig = pieChartConfig;
    if (allChartData !== undefined) {
      allChartData.forEach((element: any, index: number) => {
        if (index !== 2) {
          initOptions.data[0].dataPoints = [...element.dataPoints];
          initOptions.axisY.title = formatMessage(element.KPIUnit);
          initOptions.axisX.interval = getXAxisLabelInterval(
            element.dataPoints.length
          );
          if (index === 1 && element.KPIName === "memory_usage") {
            initOptions.axisY.valueFormatString = "#0.## GB";
            initOptions.data[0].color = "#1fe8b2";
            initOptions.data[0].yValueFormatString = "#0.## GB";
            initOptions.axisY.maximum = null;
          } else {
            initOptions.axisY.valueFormatString = "#0.## %";
            initOptions.axisY.maximum = 1;
            initOptions.data[0].color = "#ff651a";
            initOptions.data[0].yValueFormatString = "#0.## %";
          }
          chartCollection.push(cloneDeep(initOptions));
          initOptions.data[0].dataPoints = [];
        } else {
          let totalMemory = element.dataPoints[0].y + element.dataPoints[1].y;
          pieChartConfig.subtitles[0].text =
            totalMemory > 0
              ? roundWithPrecision(
                (element.dataPoints[0].y * 100) / totalMemory,
                2
              ) + "%  Free"
              : "100 % Free";
          pieConfig.data[0].dataPoints = element?.dataPoints.map(
            (point: { label: string; y: number }) => {
              return {
                ...point,
                y: point.y,
              };
            }
          );
          chartCollection.push(cloneDeep(pieConfig));
          pieConfig.data[0].dataPoints = [];
        }
      });
      setCharts(chartCollection);
    }
  };

  const filterHandler = (values: any) => {
    // setFilterState(() => { return { ClientId: values.ClientId } })
    setSelectedSuperAdminDashboardClientName(values.ClientId);
    localStorage.setItem(
      "secure_fuel_superadmin_dashboard_filter",
      values.ClientId
    );
    filterToggleHandler();
  };

  return (
    <React.Fragment>
      {showFilterPopup && (
        <SuperAdminDashboardFilterForm
          title={"client_info_filter_title"}
          submitHandler={filterHandler}
          initialValues={{ ClientId: selectedSuperAdminDashboardClientName }}
          onModalClosed={filterToggleHandler}
        />
      )}

      <div className={`main-content user`}>
        <SubHeader
          moduleNameString={"dashboard_title"}
          moduleName={ModuleName.AddTransaction}
          // addHandler={openAddCSVHandler}
          // filterHandler={openFilterHandler}
          // isFilterActive={filter.isFilterActive}
          // exportCSVHandler={exportFilteredLogsCSV}
          // resetFilterHandler={resetFilterHandler}
          isAdd={false}
          addButtonTitle="add_csv"
          isFullView={false}
          isDownload={false}
          isRefresh={false}
          isFilter={false}
          isDateFilter={false}
          isImport={false} isMissing={false} />
      </div>

      {/* {transactionAddInProgress && <SpinnerLoader />} */}
      <div
        className="AdminWrapper background-image py-2 px-5"
      >  <div className="super-admin-container">
          <div className="row">
            {/* Client Info Card Start */}
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 mt-3">
              <Card className="shadow-sm rounded h-100 fuel-header">
                <Card.Header>
                  <Card.Title className="m-0">
                    <div className="d-flex justify-content-between">
                      <span className="fw-bolder fs-5"><FormattedMessage id="account" /></span>
                    </div>
                  </Card.Title>
                </Card.Header>
                {clientSummaryDetail.inProgress && <SpinnerLoader />}
                <div className="clients client-bg d-flex justify-content-between align-items-start p-3 ">
                  <div className="p-3">
                    {/* <p className="fs-4 text-white fw-semi-bold mb-0">
                          <FormattedMessage id="Clients" />
                        </p> */}
                    <p className="fs-1 fw-semi-bold m-0 text-white fw-bold mt-0">
                      {getClientData.ClientCount}
                    </p>
                  </div>

                  {/* <div className="client-dashboard">
                        <img
                          src={clients}
                          alt="clients"
                          className="total-clients"
                        />
                      </div>  */}
                </div>
                <Card.Body className="">
                  <div className="d-flex d-flex justify-content-between">
                    <span className="fw-bolder fs-5 mb-1">
                      <FormattedMessage id="latest_accounts" />
                    </span>
                    <div>
                      <NavLink
                        to={RoutesConstants.Clients}
                        className="view-all-client text-end text-white text-decoration-none fw-semi-bold"
                      >
                        <FormattedMessage id="see_all" />
                      </NavLink>
                    </div>
                  </div>
                  <Card.Text>

                    <ul className="list-group h-100 p-0 mt-1">
                      {getClientData.RecentClientList.map((client) => (
                        <li className="list-group-item d-flex px-0 justify-content-between align-items-start border-bottom border-0 border-light bg-transparent row">
                          <div className="col-lg-6">
                            <div className="d-flex">
                              <div className="avatar avatar-50px me-3">
                                <img
                                  src={
                                    client.ProfileImage
                                      ? client.ProfileImage
                                      : Avatar
                                  }
                                  alt="Avatar"
                                  className="h-20px w-20px rounded"
                                />
                              </div>
                              <div>
                                <small className="fw-bold lh-1">
                                  {client.ClientName}
                                </small>
                                <br />
                                <small>{client.ClientAdmin}</small>
                              </div>
                            </div>
                          </div>
                          <small className="mt-1 fs-11 col-lg-6">
                            <div className="d-flex">
                              <span className="material-symbols-outlined fs-5 pe-2 purple-color">
                                schedule
                              </span>
                              {moment(client.CreationDate).format(
                                DATE_TIME_FORMAT_TO_BACKEND
                              )}
                            </div>
                          </small>
                        </li>
                      ))}
                    </ul>
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
            {/* Client Info Card End */}
            <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-12 mt-3">
              <Card className="shadow-sm rounded h-100 fuel-header">
                <Card.Header>
                  <Card.Title className="m-0">
                    <div className="d-flex justify-content-between">
                      <span className="fw-bolder fs-5">
                        <FormattedMessage id="account_info" />
                        <small className="text-white client-filter ms-2">
                          {selectedSuperAdminDashboardClientName ===
                            "please_select"
                            ? "Please select an account"
                            : selectedSuperAdminDashboardClientName}
                        </small>
                      </span>
                      <img title="Filter"
                        src={Filter}
                        onClick={() => {
                          filterToggleHandler();
                        }}
                        alt="blackRectangle"
                        className="filter"
                      />
                    </div>
                  </Card.Title>
                </Card.Header>
                <Card.Body className="">
                  {clientInfoSummaryDetail.inProgress && <SpinnerLoader />}
                  {selectedSuperAdminDashboardClientName !== "please_select" ? (
                    <Card.Text>
                      <div className="vehicles row">
                        <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12 mt-3">
                          <div className="rounded h-100 bg-Total-vehicles-brown  p-3">
                            {/* {dashboardMetricsDetailState.inProgress && <SpinnerLoader />} */}
                            <div className="d-flex justify-content-between">
                              <div>
                                <p className="fs-2 fw-bolder mt-2 text-white">
                                  {/* {getClientInfoData.TotalVehicles ? getClientInfoData.TotalVehicles : emptyValue} */}
                                  {getClientInfoData.TotalVehicles}
                                </p>
                                <p className="text-white fw-semi-bold">
                                  <FormattedMessage id="total_vehicles" />
                                </p>
                              </div>
                              <div>
                                <div className="circle-total">
                                  <img
                                    src={TotalVehicles}
                                    alt="vehicles"
                                    className="total-vehicles"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12 mt-3">
                          <div className="rounded h-100 bg-Total-drivers-blue  p-3">
                            {/* {dashboardMetricsDetailState.inProgress && <SpinnerLoader />} */}
                            <div className="d-flex justify-content-between">
                              <div>
                                <p className="fs-2 fw-bolder mt-2 text-white">
                                  {/* {getClientInfoData.TotalDrivers ? getClientInfoData.TotalDrivers : emptyValue} */}
                                  {getClientInfoData.TotalDrivers}
                                </p>
                                <p className="text-white fw-semi-bold">
                                  <FormattedMessage id="total_drivers" />
                                </p>
                              </div>
                              <div>
                                <div className="circle-total">
                                  <img
                                    src={TotalDrivers}
                                    alt="vehicles"
                                    className="total-vehicles"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12 mt-3">
                          <div className="rounded h-100 bg-Total-customer-blue p-3">
                            <div className="d-flex justify-content-between">
                              <div>
                                <p className="fs-2 fw-bolder mt-2 text-white">
                                  {getClientInfoData.TotalCustomer}
                                </p>
                                <p className="text-white fw-semi-bold">
                                  <FormattedMessage id="total_customers" />
                                </p>
                              </div>
                              <div>
                                <div className="circle-total">
                                  <img
                                    src={TotalCustomers}
                                    alt="vehicles"
                                    className="total-vehicles"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xxl-6 mt-4">
                          <div className="card rounded">
                            <div className="card-body card-purple">
                              <div className="">
                                <div className="client-transaction ms-3">
                                  <span className="circle-client bg-gradient-green">
                                    <img
                                      src={TotalTransaction}
                                      alt="fuelTruck"
                                      className=""
                                    />
                                  </span>
                                </div>
                                <div className="ps-3">
                                  <p className="fs-3 fw-bold mb-0 text-purple mt-3">
                                    {getClientInfoData.TotalTransactions}
                                  </p>
                                  <p className="text-mute5 fw-semi-bold">
                                    {" "}
                                    <FormattedMessage id="total_transaction" />{" "}
                                  </p>
                                </div>
                              </div>
                              <img
                                src={purlplewave}
                                alt="purlplewave"
                                className="blue-wave"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xxl-6 mt-4">
                          <div className="card rounded">
                            <div className="card-body card-green">
                              <div className="">
                                <div className="client-transaction ms-3">
                                  <span className="circle-client bg-gradient-info">
                                    <img
                                      src={FuelTank}
                                      alt="fuelTruck"
                                      className=""
                                    />
                                  </span>
                                </div>
                                <div className="ps-3">
                                  <p className="fs-3 fw-bold text-blue mb-0 mt-3">
                                    {getClientInfoData.TotalTanks}
                                  </p>
                                  <p className="text-mute fw-semi-bold">
                                    {" "}
                                    <FormattedMessage id="total_tanks" />{" "}
                                  </p>
                                </div>
                              </div>
                              <img
                                src={BlueWave}
                                alt="BlueWave"
                                className="blue-wave"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </Card.Text>
                  ) : (
                    <Card.Text>
                      <div className="no_data text-center py-2">
                        <img
                          src={SelectFilter}
                          alt="selectFilter"
                          className="selectFilter"
                        />
                        <p className="fs-4 mt-3 pt-2 ">
                          {" "}
                          Please select an account from filter
                        </p>
                      </div>
                    </Card.Text>
                  )}
                </Card.Body>
              </Card>
            </div>
          </div>
          <div className="row cpu-monitoring">
            {charts.map((option: any, index: number) => {
              return (
                <div
                  className={`${getMonitoringData.chartPoints[index].KPIName ==
                    "disk_free_space"
                    ? "col-xxl-5 col-xl-5 col-lg-12 mt-5 mb-3"
                    : "col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-5"
                    } `}
                >
                  <Card className="shadow-sm rounded h-100 fuel-header">
                    <Card.Header>
                      <Card.Title className="m-0">
                        <div className="d-flex justify-content-between">
                          <span className="fw-bolder fs-5">
                            <FormattedMessage
                              id={getMonitoringData.chartPoints[index].KPIName}
                            />
                          </span>
                        </div>
                      </Card.Title>
                    </Card.Header>
                    <Card.Body className="">
                      <Card.Text>
                        <LineChart index={index} options={option}></LineChart>
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </div>
                // <Card.Body>
                //   <Card.Text className="text-center">
                //     <div key={index} className={"my-3 ipadchart"}>
                //       <LineChart index={index} options={option}></LineChart>
                //     </div>
                //   </Card.Text>
                // </Card.Body>
              );
            })}
            {/* <div className="col-lg-4 col-md-12 col-sm-6 col-xs-12">
              <Card className="shadow-sm rounded h-100 fuel-header">
                <Card.Header>
                  <Card.Title className="m-0">
                    <div className="d-flex justify-content-between">
                      <span className="fw-bolder fs-5">CPU</span>
                    </div>
                  </Card.Title>
                </Card.Header>
                <Card.Body className="">
                  <Card.Text>
                    <img src={CPU} alt="fuelTruck" className="cpu" />
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
            <div className="col-lg-4 col-md-12 col-sm-6 col-xs-12">
              <Card className="shadow-sm rounded h-100 fuel-header">
                <Card.Header>
                  <Card.Title className="m-0">
                    <div className="d-flex justify-content-between">
                      <span className="fw-bolder fs-5">Memory</span>
                    </div>
                  </Card.Title>
                </Card.Header>
                <Card.Body className="">
                  <Card.Text>
                    <img src={Memory} alt="fuelTruck" className="" />
                  </Card.Text>
                </Card.Body>
              </Card>
            </div> */}
            {/* <div className="col-lg-4 col-md-12 col-sm-6 col-xs-12">
              <Card className="shadow-sm rounded h-100 fuel-header">
                <Card.Header>
                  <Card.Title className="m-0">
                    <div className="d-flex justify-content-between">
                      <span className="fw-bolder fs-5">Disk Free Space</span>
                    </div>
                  </Card.Title>
                </Card.Header>
                <Card.Body>
                  <Card.Text className="text-center">
                    <img src={Disk} alt="fuelTruck" className="disk" />
                  </Card.Text>
                </Card.Body>
              </Card>
            </div> */}
          </div>
        </div>
      </div>
      <Footer />
    </React.Fragment>
  );
};

export default FilterPopupHOC(observer(SuperAdminDashboard));
