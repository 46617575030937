import { observer } from "mobx-react";
import { useEffect, useState } from "react";
import { Card, CardBody, Col, Row } from "react-bootstrap";
import { withSize } from "react-sizeme";
import blackRectangle from "../../assets/Images/blackRectangle.png";
import Plants from "../../assets/Images/customer.png";
import Drivers from "../../assets/Images/drivers.svg";
import Filter from "../../assets/Images/filterdashboard.svg";
import fuelConsumed from "../../assets/Images/fuelConsumed.png";
import fuelTruck from "../../assets/Images/fuelTruck.png";
import inflow from "../../assets/Images/inflow.png";
import NoChart from "../../assets/Images/noChart.png";
import oragneRecatngle from "../../assets/Images/oragneRecatngle.png";
import yellowDots from "../../assets/Images/yellowDots.png";
import yellowRectangle from "../../assets/Images/yellowRectangle.png";
import redBox from "../../assets/Images/redBox.png";
import transaction from "../../assets/Images/transaction.png";
import TruckOffline from "../../assets/Images/truck-offline.svg";
import TruckStatus from "../../assets/Images/truck-status.svg";
import Trucks from "../../assets/Images/truck.png";
import vehicles from "../../assets/Images/vehicles.svg";
import { ModuleName } from "../../constants/moduleName.constant";
import { useStore } from "../../contexts/StoreProvider";
import Chart from "../../shared-components/Charts/Chart";
import Footer from "../../shared-components/Footer/Footer";
import SubHeader from "../../shared-components/Header/SubHeader";
import { formatMessage } from "../../translations/formatMessage";
import "./Dashboard.scss";
import NoData from "../../assets/Images/no_record.png";
import moment from "moment";
import { FormattedMessage } from "react-intl";
import NoDataAlarm from "../../assets/Images/no-alarm.png";
import NoTankLevel from "../../assets/Images/emptyfuel.png";
import { DATE_TIME_FORMAT, DATE_TIME_FORMAT_TO_BACKEND, DashboardFilterCase } from "../../constants/common-constant";
import { ColumnType } from "../../constants/enums/gridColumnTypeEnum";
import { ITruckOption } from "../../models/ICommon";
import { getColumns } from "../../shared-components/Grid/Grid";
import SpinnerLoader from "../../shared-components/Loader/SpinnerLoader";
import FilterPopupHOC from "../../shared-components/Popup/Hoc/FilterPopupHoc";
import DashboardFilterForm from "./forms/dashboard-filter-form";
import RoutesConstants from "../../constants/routes.constant";
import { NavLink, useNavigate } from "react-router-dom";
import { getDaysBetweenDateTimes } from "../../helpers/dateTime.helper";
interface IProps {
  size: {
    height: number;
  };
  showFilterPopup: boolean;
  filterToggleHandler: () => void;
}


const Dashboard = (props: IProps) => {
  let t: NodeJS.Timeout;
  const [isFullScreen, setIsFullScreen] = useState<boolean>(false);
  const volumePercentage = 60;
  const navigate = useNavigate();
  const { dashboardStore, truckStore, preferencesStore } = useStore();
  const { showFilterPopup, filterToggleHandler } = props;
  const [isLocalFilterChanged, setIsLocalFilterChanged] = useState<boolean>(false);
  const [isGlobalFilterChanged, setIsGlobalFilterChanged] = useState<boolean>(false);
  const {
    GetDailyFuelUsageService,
    GetTankLevelService,
    GetDashboardMetricsService,
    GetFuelStatusService,
    getFuelStatusDetail,
    dailyFuelUsageDetail,
    dashboardMetricsDetail,
    GetVolumeDeliveredService,
    GetVolumeConsumedService,
    getVolumeConsumedDetail,
    getVolumeDeliveredDetail,
    getDailyFuelUsageChartData,
    fuelStatusDetailState,
    dailyFuelUsageDetailState,
    dashboardMetricsDetailState,
    VolumeConsumedDetailState,
    VolumeDeliveredDetailState,
    getDashboardDataSuccessState,
    resetDashboardDataSuccessState,
    filterCase,
    filterSetting,
    setFilterCase,
    setFilterSetting,
    setFilterDateSetting,
    getFilterSettingInfo,
    GetRecentAlarmsService,
    dashboardRecentAlarmsState,
    getDashboardRecentAlarmsDetail,
    GetTruckStatusService,
    getTruckStatusData,
    truckStatusDetailState,
    tankLevelDetail,
    tankLevelDetailState,
    getTankLevelDetail
  } = dashboardStore;
  const {
    GetAllTruckService,
    availableTruckOptions,
    availableTruckOptionsWithAll,
  } = truckStore;
  const { GetDepartmentSummaryService, GetAllDeptAndVehiclesSummaryService, GetVehicleSummaryService, GetCustomerSummaryService, getCustomerOptionsAll, getAllVehicleOptions, getAllDepartmentOptions } = preferencesStore;
  const truckCount = availableTruckOptions.length;
  const defaultFilterValue: string | string[] = availableTruckOptions.length > 0 ? availableTruckOptions[0].Id : "";
  // const [filterCase, setFilterCase] = useState<string>("");
  // const defaultFilterValue: { TruckIds: string | string[] } = { TruckIds: availableTruckOptions.length > 0 ? availableTruckOptions[0].Id : "" };
  const [dateFilter, setDateFilter] = useState<{ startDate: Date, endDate: Date }>({ startDate: getFilterSettingInfo.StartDate, endDate: getFilterSettingInfo.EndDate });
  // const [initialFuelStatusFilterValue, setInitialFuelStatusFilterValue] = useState<{ TruckIds: string | string[] }>(defaultFilterValue);
  // const [initialDailyFuelUsageFilterValue, setInitialDailyFuelUsageFilterValue] = useState<{ TruckIds: string | string[] }>(defaultFilterValue);
  // const [initialTankLevelFilterValue, setInitialTankLevelFilterValue] = useState<{ TruckIds: string | string[] }>(defaultFilterValue);

  const Columns = getColumns([
    {
      columnType: ColumnType.Hidden,
      accessorKey: 'Id',
    },
    {
      columnType: ColumnType.TextHeader,
      accessorKey: 'Name',
      displayName: 'label_all'
    },
  ])

  const [autoRefresh, setAutoRefresh] = useState<boolean>(false);

  const dateFilterHandler = (values: { startDate: Date; endDate: Date }) => {
    setFilterDateSetting(values.startDate, values.endDate);
    setDateFilter({ ...values });
  }

  const getXAxisLabelInterval = (range: number) => {
    let xAxisInterval: number = 2;
    if (!isNaN(range)) {
      let interval = range > 12 ? Math.trunc(range / 6) : 1;
      xAxisInterval = interval > 0 ? interval : 1;
    }
    return xAxisInterval;
  };

  const callDashboardDataApi = () => {
    setAutoRefresh(false);
    GetFuelStatusService(getFilterSettingInfo.FuelStatus.Id, dateFilter.startDate, dateFilter.endDate);
    GetDailyFuelUsageService(getFilterSettingInfo.DailyFuelUsage, dateFilter.startDate, dateFilter.endDate);
    GetTankLevelService(getFilterSettingInfo.TankLevel.Id, dateFilter.startDate, dateFilter.endDate);
    GetDashboardMetricsService();
    GetRecentAlarmsService();
    GetVolumeDeliveredService(dateFilter.startDate, dateFilter.endDate);
    GetVolumeConsumedService(dateFilter.startDate, dateFilter.endDate);
    GetTruckStatusService();
  }

  useEffect(() => {
    GetAllTruckService();
    // GetDepartmentSummaryService();
    // GetVehicleSummaryService();
    GetAllDeptAndVehiclesSummaryService(-1);
    GetCustomerSummaryService()
  }, []);

  useEffect(() => {
    let tempValue: ITruckOption | string = availableTruckOptions.length > 0 ? availableTruckOptions[0] : "";
    let tempFilterDetails = localStorage.getItem("secure_fuel_dashboard_filter");
    if (tempFilterDetails == null) {
      setFilterSetting(DashboardFilterCase.DailyFuelUsage, tempValue, true);
    }
    else {
      setFilterSetting(DashboardFilterCase.DailyFuelUsage, tempValue, false, true);
    }
    if (truckCount > 0) {
      if (tempFilterDetails == null)
        setFilterSetting(DashboardFilterCase.TankLevel, tempValue, true);
      else
        setFilterSetting(DashboardFilterCase.TankLevel, tempValue, false, true);
      GetTankLevelService(getFilterSettingInfo.TankLevel, dateFilter.startDate, dateFilter.endDate);
      GetTruckStatusService();
    }

    GetFuelStatusService(getFilterSettingInfo.FuelStatus.Id, dateFilter.startDate, dateFilter.endDate);
    GetDashboardMetricsService();
    GetRecentAlarmsService();
    GetVolumeDeliveredService(dateFilter.startDate, dateFilter.endDate);
    GetVolumeConsumedService(dateFilter.startDate, dateFilter.endDate);
    if (getCustomerOptionsAll.length > 0 || getAllVehicleOptions.length > 0 || getAllDepartmentOptions.length > 0) {
      GetDailyFuelUsageService(getFilterSettingInfo.DailyFuelUsage, dateFilter.startDate, dateFilter.endDate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [truckCount]);

  useEffect(() => {
    if (isGlobalFilterChanged) {
      resetDashboardDataSuccessState();
      callDashboardDataApi();
    }
    setIsGlobalFilterChanged(() => false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isGlobalFilterChanged]);


  useEffect(() => {
    if (isLocalFilterChanged) {
      resetDashboardDataSuccessState();
      callDashboardDataApi();
      setIsLocalFilterChanged(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLocalFilterChanged]);

  useEffect(() => {
    if (getDashboardDataSuccessState) {
      setAutoRefresh(true);
      resetDashboardDataSuccessState();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getDashboardDataSuccessState]);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "autoRefresh" of the useEffect changed.
   */
  useEffect(() => {
    if (autoRefresh) {
      t = setInterval(() =>
        callDashboardDataApi(), 30000);
    }
    else clearInterval(t);
    return () => {
      clearInterval(t);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoRefresh]);


  const filterHandler = (values: any) => {
    if (values) {
      setIsLocalFilterChanged(true);
      setInitialValuesByCase(values, filterCase);
    }
    filterToggleHandler();
  };

  const getInitialValuesByCase = (fCase: string) => {
    switch (fCase) {
      case "FuelStatus":
        return getFilterSettingInfo.FuelStatus.Id;
      case "DailyFuelUsage":
        return getFilterSettingInfo.DailyFuelUsage;
      case "TankLevel":
        return getFilterSettingInfo.TankLevel.Id;
    }
    return defaultFilterValue;
  }


  const callServiceByFilterCase = (fCase: string) => {
    switch (fCase) {
      case "FuelStatus":
        return GetFuelStatusService(getFilterSettingInfo.FuelStatus.Id, dateFilter.startDate, dateFilter.endDate);
      case "DailyFuelUsage":
        return GetDailyFuelUsageService(getFilterSettingInfo.DailyFuelUsage, dateFilter.startDate, dateFilter.endDate);
      case "TankLevel":
        return GetTankLevelService(getFilterSettingInfo.TankLevel.Id, dateFilter.startDate, dateFilter.endDate);
    }
  }

  const getTruckOtionsListByCase = (fCase: string) => {
    switch (fCase) {
      case "FuelStatus":
        return availableTruckOptionsWithAll;
      case "DailyFuelUsage":
        return availableTruckOptionsWithAll;
      case "TankLevel":
        return availableTruckOptions;
    }
    return availableTruckOptionsWithAll;
  }

  const getTitleByCase = (fCase: string) => {
    switch (fCase) {
      case "FuelStatus":
        return "fuel_status";
      case "DailyFuelUsage":
        return "daily_fuel_usage";
      case "TankLevel":
        return "tank_level";
    }
    return "filter_title";
  }
  const setInitialValuesByCase = (values: any, fCase: string) => {
    switch (fCase) {
      case "FuelStatus":
        setFilterSetting(DashboardFilterCase.FuelStatus, values);
        return;
      case "DailyFuelUsage":
        setFilterSetting(DashboardFilterCase.DailyFuelUsage, values);
        return;
      case "TankLevel":
        setFilterSetting(DashboardFilterCase.TankLevel, values);
        return;
    }
  }
  const openFilterHandler = (fCase: string) => {
    setFilterCase(fCase);
    filterToggleHandler();
  }
  const redirectToTruckHandler = () => {
    navigate(RoutesConstants.Trucks)
  }

  return (
    <>
      {showFilterPopup && (
        <DashboardFilterForm
          title={formatMessage(getTitleByCase(filterCase))}
          truckOptions={getTruckOtionsListByCase(filterCase)}
          initialValues={filterCase === "DailyFuelUsage" ? { CustomerId2: getInitialValuesByCase(filterCase).CustomerId2.Id, CustomerId3: getInitialValuesByCase(filterCase).CustomerId3.Id, CustomerId: getInitialValuesByCase(filterCase).CustomerId.Id } : { TruckIds: getInitialValuesByCase(filterCase) }}
          // singleSelector={filterCase == "FuelStatus" ? false : true}
          // disabled={isDisabled}
          singleSelector={true}
          submitHandler={filterHandler}
          onModalClosed={filterToggleHandler}
          actionInProgress={false}
          selectAllColumns={Columns}
        />
      )}
      <div className={`main-content`}>
        <SubHeader
          moduleNameString={"dashboard_title"}
          moduleName={ModuleName.Dashboard}
          setIsFullScreen={setIsFullScreen}
          isAdd={false}
          isFullView={true}
          isDownload={false}
          isRefresh={false}
          isFilter={false}
          isDateFilter={true}
          isGlobalFilterChanged={isGlobalFilterChanged}
          setIsGlobalFilterChanged={setIsGlobalFilterChanged}
          dateFilterHandler={dateFilterHandler}
          dateFilter={dateFilter} isImport={false} isMissing={false} />
      </div>

      <div className={`${isFullScreen && "dashboard-full-screen"}`}>
        {isFullScreen && (
          <div className={"circle-icon blue miniMizeIcon"}>
            <svg
              onClick={() => {
                setIsFullScreen((isFullScreen) => !isFullScreen);
              }}
              height="22"
              fill="#fff"
              width="22"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g>
                <path d="m21 10c0-.69033-.5596-1.24997-1.25-1.24997h-2.7322l3.9822-3.98224c.4882-.48815.4882-1.27961 0-1.76776-.4882-.48816-1.2796-.48816-1.7678 0l-3.9822 3.98223v-2.73223c0-.69036-.5596-1.25-1.25-1.25-.6903 0-1.25.55964-1.25 1.25v5.74997c0 .6904.5597 1.25 1.25 1.25h5.75c.6904 0 1.25-.5596 1.25-1.25z" />
                <path d="m3 14c0 .6904.55964 1.25 1.25 1.25h2.73222l-3.98222 3.9823c-.48816.4881-.48816 1.2796 0 1.7677.48815.4882 1.27961.4882 1.76777 0l3.98221-3.9822v2.7322c0 .6904.55965 1.25 1.25 1.25.69032 0 1.25002-.5596 1.25002-1.25v-5.75c0-.6903-.5597-1.25-1.25002-1.25h-5.74998c-.69036 0-1.25.5597-1.25 1.25z" />
              </g>
            </svg>
          </div>
        )}
        <div className="dashboard py-2 px-5">
          <div className="container">
            {/* {fuelStatusDetailState.inProgress && dailyFuelUsageDetailState.inProgress && dashboardMetricsDetailState.inProgress && VolumeDeliveredDetailState.inProgress && VolumeConsumedDetailState.inProgress && <SpinnerLoader />} */}
            <Row>
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-5">
                {/* Fuel Status Card Start */}
                <Card className="shadow-sm rounded h-100 fuel-header">
                  <Card.Header>
                    <Card.Title className="m-0">
                      <div className="d-flex justify-content-between">
                        <span className="fw-bolder fs-5">
                          <FormattedMessage id="fuel_status" />
                          {
                            getFilterSettingInfo?.FuelStatus?.Name &&
                            <small className="text-white client-filter ms-2"> {getFilterSettingInfo?.FuelStatus?.Name}</small>
                          }
                        </span>
                        <img title="Filter"
                          src={Filter}
                          onClick={() => { openFilterHandler("FuelStatus") }}
                          alt="blackRectangle"
                          className="filter"
                        />
                      </div>
                    </Card.Title>
                  </Card.Header>
                  <Card.Body className="py-5 d-flex">
                    {fuelStatusDetailState.inProgress && <SpinnerLoader />}
                    <Card.Text className="w-100">
                      <Row className="mt-2">
                        <Col className="position-relative">
                          <div className="circle bg-gradient-danger">
                            <img
                              src={transaction}
                              alt="transaction"
                              className=""
                            />

                            <p className="text-white m-0">
                              <FormattedMessage id="total_transaction" /></p>
                            <p className="fs-3 fw-bolder m-0 text-white">{getFuelStatusDetail?.TotalTransaction}</p>
                            <img src={redBox} alt="redBox" className="" />
                          </div>
                        </Col>
                        <Col className="position-relative">
                          <div className="circle bg-gradient-info">
                            <img src={fuelTruck} alt="fuelTruck" className="" />

                            <p className="m-0 text-white">
                              <FormattedMessage id="total_fuel_delivered" />
                            </p>
                            <p className="fs-3 fw-bolder m-0 text-white">
                              {getFuelStatusDetail?.TotalFuelDelivered} L
                            </p>
                            <img
                              src={oragneRecatngle}
                              alt="oragneRecatngle"
                              className=""
                            />
                          </div>
                        </Col>
                        <Col className="position-relative">
                          <div className="circle bg-gradient-success">

                            <img
                              src={fuelConsumed}
                              alt="fuelConsumed"
                              className=""
                            />
                            <p className="m-0 text-white"><FormattedMessage id="total_inflow" /> </p>
                            <p className="fs-3 fw-bolder m-0  text-white">
                              {getFuelStatusDetail?.TotalFuelInflow} L
                            </p>
                            <img
                              src={blackRectangle}
                              alt="blackRectangle"
                              className=""
                            />
                          </div>
                        </Col>
                        <Col className="position-relative">
                          <div className="circle bg-gradient-light">
                            <img
                              src={inflow}
                              alt="inflow"
                              className=""
                            />
                            <p className="text-white m-0">
                              <FormattedMessage id="fuel_consumed" /></p>
                            <p className="fs-3 fw-bolder m-0 text-white">{getFuelStatusDetail?.TotalFuelConsumed} L</p>
                            <img src={yellowDots} alt="yellowDots" className="" />
                          </div>
                        </Col>
                      </Row>
                    </Card.Text>
                  </Card.Body>
                </Card>
                {/* Fuel Status Card End */}
              </div>


            </Row>

            <Row className="Fuel-background-blue">
              {/* Tank Level Card Start */}
              <div className="Tank-background-brown col-lg-4 col-md-12 col-sm-12 col-xs-12 mt-5 ">
                <Card className="shadow-sm rounded h-100 fuel-header">
                  <Card.Header>
                    <Card.Title className="m-0">
                      <div className="d-flex justify-content-between">
                        <span className="fw-bolder fs-5">
                          <FormattedMessage id="tank_level" />
                          {

                            getFilterSettingInfo?.TankLevel?.Name &&
                            <small className="text-white client-filter ms-2"> {getFilterSettingInfo?.TankLevel?.Name}</small>
                          }
                        </span>
                        <img title="Filter"
                          src={Filter}
                          onClick={() => { openFilterHandler("TankLevel") }}
                          alt="blackRectangle"
                          className="filter"
                        />
                      </div>
                    </Card.Title>
                  </Card.Header>
                  {
                    (truckCount > 0) ?
                      <Card.Body className="py-5 d-flex">
                        {tankLevelDetailState.inProgress && <SpinnerLoader />}
                        <Card.Text>

                          <div className="row d-flex align-items-center">
                            <div className="col-sm-6">
                              <div className="vertical_tank_progress">
                                <div className="progress progress-bar-vertical mx-auto">
                                  <div
                                    role="progressbar"
                                    className="progress-bar bg-primary"
                                    style={{
                                      height: `${volumePercentage}%`,
                                    }}
                                  ></div>
                                </div>
                                <ul className="term-list">
                                  <li className="list-group-item"></li>
                                  <li className="list-group-item"></li>
                                  <li className="list-group-item"></li>
                                  <li className="list-group-item"></li>
                                  <li className="list-group-item"></li>
                                  <li className="list-group-item"></li>
                                </ul>
                              </div>
                            </div>
                            {
                              getTankLevelDetail.Compartment === 2 &&
                              <div className="col-sm-6">
                                <div className="vertical_tank_progress">
                                  <div className="progress progress-bar-vertical mx-auto">
                                    <div
                                      role="progressbar"
                                      className="progress-bar bg-primary"
                                      style={{
                                        height: `${volumePercentage}%`,
                                      }}
                                    ></div>
                                  </div>
                                  <ul className="term-list">
                                    <li className="list-group-item"></li>
                                    <li className="list-group-item"></li>
                                    <li className="list-group-item"></li>
                                    <li className="list-group-item"></li>
                                    <li className="list-group-item"></li>
                                    <li className="list-group-item"></li>
                                  </ul>
                                </div>
                              </div>
                            }
                            <div className="col-sm-6 p-0">
                              <div className="row">
                                <p className="mt-3 fs-6 fw-semi-bold">
                                  Compartment 1
                                </p>
                                <div className="col-sm-12">
                                  <div className="filledCircle">
                                    <div className="d-flex align-items-center mb-2">
                                      <div className="">
                                        <span className="p-1 d-block bg-primary rounded-circle"></span>
                                      </div>
                                      <div className="ms-2">
                                        <p className="mb-0">
                                          Filled Fuel{" "}
                                          <small className="text-muted"> {getTankLevelDetail.Fuel1} %</small>
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-sm-12">
                                  <div className="filledCircle emptyCircle">
                                    <div className="d-flex align-items-center mb-2">
                                      <div className="">
                                        <span className="p-1 d-block bg-primary rounded-circle"></span>
                                      </div>
                                      <div className="flex-grow-1 ms-2">
                                        <p className="mb-0">
                                          Empty Fuel{" "}
                                          <small className="text-muted"> {100 - getTankLevelDetail.Fuel1} %</small>
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {
                              getTankLevelDetail.Compartment === 2 &&
                              <div className="col-sm-6 p-0">
                                <div className="row">
                                  <p className="mt-3   fs-6 fw-semi-bold">
                                    Compartment 2
                                  </p>
                                  <div className="col-sm-12">
                                    <div className="filledCircle">
                                      <div className="d-flex align-items-center mb-2">
                                        <div className="">
                                          <span className="p-1 d-block bg-primary rounded-circle"></span>
                                        </div>
                                        <div className="flex-grow-1 ms-2">
                                          <p className="mb-0">
                                            Filled Fuel
                                            <small className="text-muted"> {getTankLevelDetail.Fuel2} %</small>
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-sm-12">
                                    <div className="filledCircle emptyCircle">
                                      <div className="d-flex align-items-center mb-2">
                                        <div className="">
                                          <span className="p-1 d-block bg-primary rounded-circle"></span>
                                        </div>
                                        <div className="flex-grow-1 ms-2">
                                          <p className="mb-0">
                                            Empty Fuel
                                            <small className="text-muted"> {100 - getTankLevelDetail.Fuel2} %</small>
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            }
                          </div>

                        </Card.Text>
                      </Card.Body>
                      :
                      <Card.Body>
                        <Card.Text>
                          <div className="no_data_table no-tanks">
                            <div className="no_data w-100 text-center">
                              <img src={NoData} alt="NOData" className="NOData" />
                              <h1 className="fs-4">
                                <FormattedMessage id="message_no_datafound" />
                              </h1>
                            </div>
                          </div>
                        </Card.Text>
                      </Card.Body>
                  }
                </Card>
              </div>
              {/* Tank Level Card End */}
              {/* Daily Fuel Usage Card Start */}
              <Col className="col-lg-8 col-md-12 col-sm-12 col-xs-12 mt-5 ">
                <Card className="shadow-sm rounded h-100 fuel-header">
                  <Card.Header>
                    <Card.Title className="m-0">
                      <div className="d-flex justify-content-between">
                        <span className="fw-bolder fs-5">
                          {" "}
                          <FormattedMessage id="daily_fuel_usage" />
                          <span className="header-box">

                            <small className="text-white ms-2">
                              <FormattedMessage id="customer" /></small>
                            {
                              getFilterSettingInfo?.DailyFuelUsage?.CustomerId &&
                              <small className="text-white client-filter ms-2">{getFilterSettingInfo?.DailyFuelUsage?.CustomerId.Name}</small>
                            }

                            <small className="text-white ms-3">    <FormattedMessage id="department" /></small>
                            {
                              getFilterSettingInfo?.DailyFuelUsage?.CustomerId2 &&
                              <small className="text-white client-filter ms-2">{getFilterSettingInfo?.DailyFuelUsage?.CustomerId2.Name}</small>
                            }


                            <small className="text-white ms-2">
                              <FormattedMessage id="vehicle" /></small>
                            {
                              getFilterSettingInfo?.DailyFuelUsage?.CustomerId3 &&
                              <small className="text-white client-filter ms-2">{getFilterSettingInfo?.DailyFuelUsage?.CustomerId3.Name}</small>
                            }

                          </span>
                        </span>
                        <img title="Filter"
                          src={Filter}
                          onClick={() => { openFilterHandler("DailyFuelUsage") }}
                          alt="blackRectangle"
                          className="filter"
                        />
                      </div>
                    </Card.Title>
                  </Card.Header>
                  <Card.Body>
                    <Card.Text>
                      {dailyFuelUsageDetailState.inProgress && <SpinnerLoader />}

                      {
                        getDailyFuelUsageChartData.length > 0 ?
                          <Chart
                            data={[
                              {
                                type: "column",
                                color: "#AF9763",
                                yValueFormatString: "#0.###### L",
                                // indexLabelFontSize: 16,
                                dataPoints: [...getDailyFuelUsageChartData],
                              },
                            ]}
                            xAxisInterval={getXAxisLabelInterval(getDaysBetweenDateTimes(moment(dateFilter.startDate).toISOString(), moment(dateFilter.endDate).toISOString()))}
                          /> :
                          <>
                            <div className="no-data-chart text-center ">
                              <div className="no-chart-content">

                                <img
                                  src={NoChart}
                                  alt="noChart"
                                  className="noChart"
                                />
                                <p className="pt-1 fs-4">
                                  <FormattedMessage id="no_chart_data_available_for_the_selected_date_range" />
                                </p>
                              </div>
                            </div>
                          </>
                      }
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              {/* Daily Fuel Usage Card End */}
            </Row>

            {/* Total Vehicle Driver Customer Tanks Card Start */}
            <div className="vehicles row">
              <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12 mt-5">
                <div className="rounded h-100 bg-vehicles-pink  py-3">
                  {/* {dashboardMetricsDetailState.inProgress && <SpinnerLoader />} */}
                  <div className="text-center">
                    <p className="fs-3 fw-bolder mb-0 mt-2">{dashboardMetricsDetail.Vehicles}</p>
                    <p className="fs-6"><FormattedMessage id="total_vehicles" /></p>
                    <img src={vehicles} alt="vehicles" />
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12 mt-5">
                <div className="rounded h-100 bg-drivers-blue text-center py-3">
                  {/* {dashboardMetricsDetailState.inProgress && <SpinnerLoader />} */}
                  <div className="text-end"></div>
                  <div className="text-center">
                    <p className="fs-3 fw-bolder mb-0 mt-2">{dashboardMetricsDetail.Drivers}</p>
                    <p className="fs-6"><FormattedMessage id="total_drivers" /></p>
                    <img src={Drivers} alt="Drivers" />
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12 mt-5">
                <div className="rounded h-100 bg-plants-blue text-center py-3">
                  <div className="text-center">
                    <p className="fs-3 fw-bolder mb-0 mt-2">{dashboardMetricsDetail.Customers}</p>
                    <p className="fs-6"><FormattedMessage id="total_customers" /> </p>
                    <img src={Plants} alt="Plants" />
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12 mt-5">
                <div className="rounded h-100  bg-tanks-brown  text-center py-3">
                  <div className="text-center">
                    <p className="fs-3 fw-bolder mb-0 mt-2">{dashboardMetricsDetail.Tanks}</p>
                    <p className="fs-6"><FormattedMessage id="total_tanks" /></p>
                    <img src={Trucks} alt="Trucks" />
                  </div>
                </div>
              </div>
            </div>
            {/* Total Vehicle Driver Customer Tanks Card End */}

            {/* Trucks Status Card Start */}
            {/*truck online/offline*/}
            <Row className="mt-5 mb-3">
              <Col xs={12}>
                <Card className="shadow-sm rounded h-100 fuel-header">
                  <Card.Header>
                    <Card.Title className="m-0">
                      <div className="d-flex justify-content-between">
                        <span className="fw-bolder fs-5"><FormattedMessage id="truck_status" /></span>
                        <div className="d-flex onOff-truck">
                          <div className="me-3 d-flex align-items-center">
                            <div className="truck_circle">
                              <img
                                src={TruckStatus}
                                alt="TruckStatus"
                                className="TruckStatus"
                              />
                            </div>
                            <small className="text-white ps-2"> Online</small>
                          </div>
                          <div className="d-flex align-items-center">
                            <div className="truck_circle">
                              <img
                                src={TruckOffline}
                                alt="TruckOffline"
                                className="TruckStatus"
                              />
                            </div>
                            <small className="text-white ps-2">Offline</small>
                          </div>
                        </div>
                      </div>
                    </Card.Title>
                  </Card.Header>
                  <Card.Body>
                    <Card.Text className="position-relative truck-status">
                      {truckStatusDetailState.inProgress && <SpinnerLoader />}

                      <div className="row">
                        {getTruckStatusData.length > 0 ?
                          getTruckStatusData.map((truck) => truck.TruckStatus === "Online" ?
                            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-xs-12 mb-3">
                              <div className="truck-status-card online-truck h-100">
                                <div className="row g-0">
                                  <div className="col-2 col-xs-2">
                                    <div className="d-flex align-items-center">
                                      <span>
                                        <img
                                          src={TruckStatus}
                                          alt="TruckStatus"
                                          className="TruckStatus"
                                        />
                                      </span>
                                    </div>
                                  </div>
                                  <div className="col-10 col-xs-10 text-end">
                                    <small className="m-0">{truck.TruckId}</small>
                                    <p className="mb-5"><FormattedMessage id="truck_id" /></p>
                                  </div>
                                </div>
                                <h6 className="mt-2">{truck.TruckStatusUpdatedTime}</h6>
                              </div>
                            </div> :
                            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-xs-12 mb-3">
                              <div className="truck-status-card offline-truck h-100">
                                <div className="row g-0">
                                  <div className="col-2 col-xs-2">
                                    <div className="d-flex align-items-center">
                                      <span>
                                        <img
                                          src={TruckOffline}
                                          alt="TruckOffline"
                                          className="TruckStatus"
                                        />
                                      </span>
                                    </div>
                                  </div>
                                  <div className="col-10 col-xs-10 text-end">
                                    <small className="m-0">{truck.TruckId}</small>
                                    <p>Truck ID</p>
                                  </div>
                                </div>
                                <h6 className="mt-2">{moment(truck.TruckStatusUpdatedTime).format(DATE_TIME_FORMAT_TO_BACKEND)}</h6>
                              </div>
                            </div>
                          ) :
                          <div className="no_data w-100 text-center">
                            <img src={NoData} alt="NOData" className="NOData" />
                            <h1 className="fs-2">
                              <FormattedMessage id="message_no_datafound" />
                            </h1>
                          </div>
                        }
                      </div>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            {/* Tanks Status Card End */}

            <div className="mb-3 row">
              {/* Volume Delivered Card Start */}
              <div className="col-lg-4 col-md-12 col-sm-12 col-xs-12 mt-5 mt-5">
                <Card className="shadow-sm rounded h-100 fuel-header">
                  <Card.Header>
                    <Card.Title className="m-0">
                      <div className="d-flex justify-content-between">
                        <span className="fw-bolder fs-5"> Volume Delivered (L) </span>
                      </div>
                    </Card.Title>
                  </Card.Header>
                  <Card.Body className="py-5 ">
                    {VolumeDeliveredDetailState.inProgress && <SpinnerLoader />}
                    <Card.Text>
                      <Row className="mt-2">
                        <Col>
                          <div className="transaction-block">
                            <a className="transaction-circle text-decoration-none">
                              <div className="w-100 h-100 total-Transaction d-flex justify-content-center">
                                <p className="mb-1 text-black fs-5">
                                  Minimum
                                </p>
                                <p className="fs-4 fw-bolder mb-0 mt-2 text-black">
                                  {getVolumeDeliveredDetail.MinDelivered}
                                </p>
                              </div>
                            </a>
                            <a className="transaction-circle text-decoration-none">
                              <div className="w-100 h-100 total-Transaction d-flex justify-content-center">
                                <p className="mb-1 text-black fs-5">  Average</p>
                                <p className="fs-4 fw-bolder mb-0 mt-2 text-black">
                                  {getVolumeDeliveredDetail.AvgDelivered}
                                </p>
                              </div>
                            </a>
                            <a className="transaction-circle text-decoration-none">
                              <div className="w-100 h-100 total-Transaction d-flex justify-content-center">
                                <p className="mb-1 text-black fs-5">
                                  Maximum
                                </p>
                                <p className="fs-4 fw-bolder mb-0 mt-2 text-black">
                                  {getVolumeDeliveredDetail.MaxDelivered}
                                </p>
                              </div>
                            </a>
                          </div>
                        </Col>
                      </Row>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </div>
              {/* Volume Delivered Card End */}

              {/* Volume Consumed Card Start */}
              <div className="col-lg-4 col-md-12 col-sm-12 col-xs-12 mt-5">
                <Card className="shadow-sm rounded h-100 fuel-header">
                  <Card.Header>
                    <Card.Title className="m-0">
                      <div className="d-flex justify-content-between">
                        <span className="fw-bolder fs-5">Volume InFlow (L)</span>
                      </div>
                    </Card.Title>
                  </Card.Header>
                  <Card.Body className="py-5 ">
                    {VolumeConsumedDetailState.inProgress && <SpinnerLoader />}
                    <Card.Text>
                      <Row className="mt-2 d-flex justify-content-between">
                        <Col>
                          <div className="transaction-block transaction-count">
                            <a className="transaction-circle text-decoration-none circle-red">
                              <div className="w-100 h-100 total-Transaction d-flex justify-content-center">
                                <p className="mb-1 text-white fs-5">
                                  Minimum
                                </p>
                                <p className="fs-4 fw-bolder mb-0 mt-2 text-white">
                                  {getVolumeConsumedDetail.MinConsumed}
                                </p>
                              </div>
                            </a>
                            <a className="transaction-circle text-decoration-none circle-green">
                              <div className="w-100 h-100 total-Transaction d-flex justify-content-center">
                                <p className="mb-1 text-white fs-5">  Average</p>
                                <p className="fs-4 fw-bolder mb-0 mt-2 text-white">
                                  {getVolumeConsumedDetail.AvgConsumed}
                                </p>
                              </div>
                            </a>
                            <a className="transaction-circle text-decoration-none circle-purple">
                              <div className="w-100 h-100 total-Transaction d-flex justify-content-center">
                                <p className="mb-1 text-white fs-5">
                                  Maximum
                                </p>
                                <p className="fs-4 fw-bolder mb-0 mt-2 text-white">
                                  {getVolumeConsumedDetail.MaxConsumed}
                                </p>
                              </div>
                            </a>
                          </div>
                        </Col>
                      </Row>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </div>
              {/* Volume Consumed Card End */}

              {/* Recent Alarms Card Start */}
              <div className="col-lg-4 col-md-12 col-sm-12 col-xs-12 mt-5">
                <Card className="shadow-sm rounded h-100 fuel-header">
                  <Card.Header>
                    <Card.Title className="m-0">
                      <div className="d-flex justify-content-between">
                        <span className="fw-bolder fs-5">Recent Alarms</span>
                      </div>
                    </Card.Title>
                  </Card.Header>
                  <Card.Body className="p-0">
                    {dashboardRecentAlarmsState.inProgress && <SpinnerLoader />}
                    <Card.Text>
                      <div className="list-group timeline-list">
                        <div className="list-group-item border-0 bg-transparent">
                          {getDashboardRecentAlarmsDetail.length > 0 ? getDashboardRecentAlarmsDetail.map((alarm) => <div className="row d-flex align-items-center p-2 circle-repeat">
                            <div className="col-lg-2 col-sm-2 col-xs-2 timeline-width">
                              <div className="timeline-circle-wrap">
                                <div className="timeline-circle border-warning">
                                  <div className="timeline-circle blue">
                                    <span className="material-symbols-outlined text-white">
                                      autostop
                                    </span>{" "}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-10 col-sm-10 col-xs-10 timeline-width">
                              <small>{moment(alarm.AlarmTimestamp).format(DATE_TIME_FORMAT_TO_BACKEND)}</small>

                              <p className="mb-0">Alarm Updated</p>
                              <small className="small text-muted mb-0">
                                {alarm.AlarmDetail}
                              </small>
                            </div>
                          </div>) : <div className="No-data-alarm text-center py-2">
                            <img src={NoDataAlarm} alt="NOData" className="NoAlarm" />
                            <p className="fs-3 mt-3 pt-2 ">     <FormattedMessage id="message_no_datafound" /></p>
                          </div>}
                        </div>
                      </div>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </div>
              {/* Recent Alarms Card End */}

            </div>
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
};

export default withSize({ monitorHeight: true })(
  FilterPopupHOC(observer(Dashboard))
);
