import React, { useEffect } from "react";
import { Modal, Button, Row, Col } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { Form, Formik, Field, useFormikContext } from "formik";
import LoaderButton from "../../../shared-components/Button/LoaderButton";
import Label from "../../../shared-components/label/label";
import FormikFormSelectField from "../../../shared-components/FormikFormHandlers/FormikFormSelectField";
import { useStore } from "../../../contexts/StoreProvider";
import { SuperAdminDashboardFilterValidateSchema } from "../../../shared-components/FormikFormHandlers/FormikValidationSchema";
import DraggableModal from "../../../shared-components/Draggable/DraggableModal";

interface IProps {
    submitHandler: (values: any) => void;
    onModalClosed: () => void;
    initialValues: { ClientId: string };
    title: string;
}

const SuperAdminDashboardFilterForm = (props: IProps) => {

    const { preferencesStore } = useStore();

    const { submitHandler, onModalClosed, title, initialValues } = props;

    const { allAvailableAccountOptions, selectedClientId, GetAllClientsService } = preferencesStore;


    // useEffect(()=>{
    //     GetAllClientsService(true);
    // }, []);

    const FormObserver: React.FC = () => {
        const { values } = useFormikContext<{ ClientId: string }>();
        return null;
    };

    return (
        <>
            <Modal className="add-user-modal admin-filter" show={true} centered dialogAs={DraggableModal}>
                <Modal.Header>
                    <Modal.Title>
                        <FormattedMessage id={title} />
                    </Modal.Title>
                    <div onClick={onModalClosed} className="icons8-close">
                        <span className="material-symbols-outlined fs-5 text-dark">
                            close
                        </span>
                    </div>
                </Modal.Header>
                <Formik
                    initialValues={initialValues}
                    validationSchema={SuperAdminDashboardFilterValidateSchema}
                    enableReinitialize
                    onSubmit={async (values) => {
                        let isValueSelected = allAvailableAccountOptions.filter((client) => client.Name !== values.ClientId)
                        submitHandler(values);
                    }}
                >
                    {({ values }) => (
                        <Form className="user-form p-3" noValidate>
                            <FormObserver />
                            <Modal.Body>
                                <Row className="mb-3 form-group d-flex align-items-center">
                                    <Col lg={4} md={4} sm={4} xs={6}>
                                        <Label
                                            className="form-label"
                                            label="label_select_client"
                                        />
                                    </Col>
                                    <Col lg={8} md={8} sm={8} xs={6}>
                                        <Field
                                            name="ClientId"
                                            className="form-control"
                                            as={FormikFormSelectField}
                                            options={allAvailableAccountOptions}
                                            isName={+true}
                                            isaccount={+true}
                                        />
                                    </Col>
                                </Row>

                            </Modal.Body>
                            <div className="modal-footer  border-0">
                                <div className="m-auto">
                                    <LoaderButton
                                        type="submit"
                                        isLoading={false}
                                        text="filter_title"
                                    />
                                    <Button
                                        variant="btn-secondary bg-light btn-light ms-2"
                                        onClick={onModalClosed}
                                    >
                                        <FormattedMessage id="button_cancel" />
                                    </Button>
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
            </Modal>
        </>
    );
};

export default SuperAdminDashboardFilterForm;
