/*    
<summary>
   This file is used to provide initial values or state to the truck Data observables.
   Developer:Ashish Singh, Created Date:04-April-2024
</summary>
*/
import { IAddEditTruck } from "../../models/response/ITruckResponse";

let date = new Date();
date.setDate(date.getDate() + 7)

export const addEditTruckState: IAddEditTruck = {
    DeviceId: "",
    InventorySource: 0,
    SimCard: "",
    IpAddress: "",
    InstallationDate: "",
    AccountId: "",
    TruckId: "",
    AssetType: "",
    Equipment: "",
    Compartment: 0,
    ExpiryDate: "",
}