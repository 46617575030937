import { observer } from "mobx-react";
import React, { ReactElement, useCallback, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import NoData from "../../../assets/Images/no_record.png";
import { ColumnType } from "../../../constants/enums/gridColumnTypeEnum";
import { useStore } from "../../../contexts/StoreProvider";
import useWindowDimensions, { getTableMaxHeight } from "../../../helpers/windowResize.helper";
import { IActionFormatter } from "../../../models/IFormatter";
import { ISorting } from "../../../models/ISorting";
import { IClient } from "../../../models/response/IClientResponse";
import { IAddCustomerRate } from "../../../models/response/ICustomerResponse";
import Grid, { getColumns } from "../../../shared-components/Grid/Grid";
import SpinnerLoader from "../../../shared-components/Loader/SpinnerLoader";
import DeletePopup from "../../../shared-components/Popup/DeletePopup";
import { IPayment } from "../../../models/response/IPaymentResponse";
import { formatNumberWithCommas } from "../../../constants/common-constant";
import Pagination from "../../../shared-components/Grid/Pagination";
import IPageInfo from "../../../models/IPageInfo";
import { initialPageLimit, PageLimitOptions } from "../../../constants/pageLimit.constant";
import moment from "moment";
import { formatMessage } from "../../../translations/formatMessage";

interface IProps {
    id: number;
    showDeletePopup: boolean;
    deleteToggleHandler: () => void;
    onPageLimitChanged: (evt: React.ChangeEvent<HTMLSelectElement>) => void;
    pageLimit: number;
    pageInfo: IPageInfo;
    onPageChanged: (pageNumber: number) => void;
    disablePagination: boolean;
    sorting: ISorting
    handleSortingChange: (accessor: string) => void
}

const PaymentDetails: React.FC<IProps> = (props: IProps) => {
    const { paymentStore, preferencesStore } = useStore();
    const {
        id, showDeletePopup, deleteToggleHandler, onPageLimitChanged,
        pageLimit, pageInfo, onPageChanged, disablePagination
    } = props;


    const { getPaymentListData, paymentListState, DeletePaymentService, deletePaymentDataState, filter } = paymentStore;
    const [data, setData] = useState<IPayment[]>(getPaymentListData.PaymentList);
    const [selectedId, setSelectedId] = useState<number>(-1);
    const [sorting, setSorting] = useState<ISorting>({
        column: "userName",
        orderBy: "asc",
    });

    useEffect(() => {
        setData(() => getPaymentListData.PaymentList)
    }, [getPaymentListData.PaymentList, setData])
    // const [filterData, setFilterData] = useState<any>({...filter});
    const { height, width } = useWindowDimensions();

    const deleteClickHandler = (row: { Id: number }) => {
        setSelectedId(row.Id);
        deleteToggleHandler();
    };
    const addIcons: Array<IActionFormatter<ReactElement>> = [
        {
            handler: deleteClickHandler,
            icon: (
                <svg
                    version="1.1"
                    x="0px"
                    y="0px"
                    width="13"
                    height="13"
                    viewBox="0 0 512 512"
                >
                    <g>
                        <g>
                            <path
                                d="M62.205,150l26.569,320.735C90.678,493.865,110.38,512,133.598,512h244.805c23.218,0,42.92-18.135,44.824-41.265
    L449.795,150H62.205z M180.986,452c-7.852,0-14.458-6.108-14.956-14.063l-15-242c-0.513-8.276,5.771-15.395,14.033-15.908
    c8.569-0.601,15.381,5.757,15.908,14.033l15,242C196.502,444.632,189.721,452,180.986,452z M271,437c0,8.291-6.709,15-15,15
    c-8.291,0-15-6.709-15-15V195c0-8.291,6.709-15,15-15s15,6.709,15,15V437z M360.97,195.938l-15,242
    c-0.493,7.874-7.056,14.436-15.908,14.033c-8.262-0.513-14.546-7.632-14.033-15.908l15-242
    c0.513-8.276,7.764-14.297,15.908-14.033C355.199,180.543,361.483,187.662,360.97,195.938z"
                            />
                        </g>
                    </g>
                    <g>
                        <g>
                            <path
                                d="M451,60h-90V45c0-24.814-20.186-45-45-45H196c-24.814,0-45,20.186-45,45v15H61c-16.569,0-30,13.431-30,30
    c0,16.567,13.431,30,30,30c137.966,0,252.039,0,390,0c16.569,0,30-13.433,30-30C481,73.431,467.569,60,451,60z M331,60H181V45
    c0-8.276,6.724-15,15-15h120c8.276,0,15,6.724,15,15V60z"
                            />
                        </g>
                    </g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                </svg>
            ),
            // key: "IsEditable",
            key: "",
            title: "label_delete",
        },
    ];

    const columns = getColumns<IClient, number>(
        [
            {
                columnType: ColumnType.Hidden,
                accessorKey: "Id",
            },
            {
                columnType: ColumnType.TextHeader,
                accessorKey: "PaymentDate",
                displayName: "payment_date",
            },
            {
                columnType: ColumnType.ActionsFormatter,
                icons: addIcons,
                displayName: "label_action",
            },
            {
                columnType: ColumnType.TextHeader,
                accessorKey: "AmountCr",
                displayName: "payment_amount_credited",
            },
            {
                columnType: ColumnType.TextHeader,
                accessorKey: "ModeOfPayment",
                displayName: "payment_mode",
            },
            {
                columnType: ColumnType.TextHeader,
                accessorKey: "Description",
                displayName: "payment_description",
            },
        ]
    );

    const deleteHandler = () => {
        DeletePaymentService(selectedId);
    };

    return (
        <React.Fragment>
            {paymentListState.inProgress && <SpinnerLoader />}
            {showDeletePopup && (
                <DeletePopup
                    modalTitle="payment_delete"
                    modalSubmit={deleteHandler}
                    modalClosed={deleteToggleHandler}
                    isLoading={deletePaymentDataState.inProgress}
                    message="delete_customer_rate_confirm"
                    submitBtnText={"label_delete"}
                    cancelBtnText={"button_cancel"} />
            )}
            <Container fluid className="p-0 mb-3">
                {data.length !== 0 ? (
                    <div>
                        <div className="pt-3 px-3">
                            <div className="rounded border">
                                <div className="Fuel-table invoice-table">
                                    <div
                                        className="table-responsive"
                                        style={getTableMaxHeight(height, width)}
                                        data-testid="table"
                                    >
                                        {data && (
                                            <Grid
                                                data={data}
                                                columns={columns}
                                                isInProgress={paymentListState.inProgress}
                                                hiddenColumns={['Id']}
                                            />
                                        )}
                                    </div>
                                </div>
                                <div className="d-flex justify-content-end amount-label">
                                    <FormattedMessage id="total_amount" />:
                                    <span className="ms-2">{formatNumberWithCommas(getPaymentListData.TotalAmount)}</span>
                                </div>
                                <Container fluid>
                                    <Row className="tableFooter py-1 mt-0">
                                        <Col xs="3" className="d-flex">
                                            <b className="text-white text-hidden-m mt-1 pt-1">
                                                <FormattedMessage id="show" />:{" "}
                                            </b>
                                            <select
                                                data-testid="pageSelect"
                                                className="ms-2 from-control"
                                                onChange={onPageLimitChanged}
                                                value={pageLimit}
                                            >
                                                {PageLimitOptions.map((op) => (
                                                    <option key={op} value={op}>
                                                        {op}
                                                    </option>
                                                ))}
                                            </select>
                                        </Col>

                                        <Col className="d-flex justify-content-end" xs="9">
                                            <Pagination
                                                totalPages={pageInfo.totalPages}
                                                pageLimit={pageLimit}
                                                onPageChanged={onPageChanged}
                                                disablePagination={disablePagination}
                                            />
                                        </Col>
                                    </Row>
                                </Container>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="no_data_table">
                        <div className="no_data w-100 text-capitalize text-center">
                            <img src={NoData} alt="NOData" className="NOData" />
                            <h1 className="fs-4 fw-semi-bold">
                                <FormattedMessage id="message_no_datafound" />
                            </h1>
                            {(filter.StartDate || filter.EndDate) &&
                                <>

                                    <h5 className="fs-5">
                                    Applied Filter Dates:- {`${formatMessage("start_date")}: ${moment(filter.StartDate).format("YYYY-MM-DD")}`} - {`${formatMessage("end_date")}: ${moment(filter.EndDate).format("YYYY-MM-DD")}`}
                                    </h5></>
                            }
                        </div>
                    </div>
                )}
            </Container>

        </React.Fragment>
    );
};

export default observer(PaymentDetails);
