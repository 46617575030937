import moment from "moment";
import { IOption, ITruckOption } from "../models/ICommon";
import { formatMessage } from "../translations/formatMessage";
import { TransactionsEnum } from "./enums/productIdTransactionsEnum";
import { TransactionReportTypeEnum } from "./enums/transaction-report-type-enum";

/*    
<summary>
   This typescript file contains common predefined constant.
   Developer: Aashish Singh, Created Date:04-March-2024
</summary>
*/
export const DATE_FORMAT = "MM-DD-YYYY";
export const DATE_FORMAT_BACKEND = "YYYY-MM-DD";
export const DATE_TIME_FORMAT_WITH_MILLSECONDS = "DD-MM-YYYY HH:mm:ss.SS";
export const DATE_TIME_FORMAT = "DD-MM-YYYY HH:mm:ss";
export const DATE_TIME_FORMAT_FOR_XAXIS = "DD-MMM HH:mm";
export const DATE_TIME_FORMAT_TO_BACKEND = "YYYY-MM-DD HH:mm:ss";
export const DATE_TIME_FORMAT_FILENAME = "DD-MM-YYYY HH-mm-ss";
export const DATE_TIME_FORMAT_UTC_WITH_MILLSECONDS = "yyyy-MM-DD'T'HH:mm:ss.SSS'Z'";
export const DATE_TIME_FORMAT_UTC = "yyyy-MM-DD'T'HH:mm:ss'Z'";

export const integerRegex = /^(?:\d+|)$/;
export const floatRegex = /^(?:\d+(\.\d*)?|)$/;
export const negativeFloatRegex = /^-?(?:\d+(\.\d*)?|)$/;
export const ipv4Regex = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
export const ipv6Regex = /^(?:[A-Fa-f0-9]{1,4}:){7}[A-Fa-f0-9]{1,4}$/;

export const alarmTypes: IOption[] = [
  { Id: -1, Name: "please_select" },
  { Id: 0, Name: "LOWER_CONSUMPTION_ALARM" },
  { Id: 1, Name: "HIGHER_CONSUMPTION_ALARM" },
  { Id: 2, Name: "ALLOCATION_PER_FILL_ALARM_FOR_DRIVER" },
  { Id: 3, Name: "ALLOCATION_VOLUME_ALARM_FOR_DRIVER" },
  { Id: 4, Name: "ALLOCATION_PER_FILL_ALARM_FOR_VEHICLE" },
  { Id: 5, Name: "ALLOCATION_VOLUME_ALARM_FOR_VEHICLE" },
  { Id: 6, Name: "CUSTOMER_THRESHOLD_LIMIT_ALARM" },
  { Id: 7, Name: "CUSTOMER_VOLUME_LIMIT_ALARM" },
];

export const registerExpiryDuration: IOption[] = [
  { Id: -1, Name: "please_select" },
  { Id: 15, Name: "15_days" },
  { Id: 30, Name: "1_month" },
  { Id: 90, Name: "3_month" },
  { Id: 180, Name: "6_month" },
  { Id: 365, Name: "1_year" },
];

export const allocationPeriodTypes: IOption[] = [
  { Id: -1, Name: "please_select" },
  { Id: 0, Name: "daily" },
  { Id: 1, Name: "weekly" },
  { Id: 2, Name: "monthly" },
];

export const userTypeList: IOption[] = [
  { Id: -1, Name: "please_select" },
  //  { Id: 0, Name: "client_admin" },
  { Id: 1, Name: "client_user" },
  { Id: 2, Name: "customer_user" },
];

export const UserTypeSuperAdmin = "ROLE_SUPER_ADMIN";

export const customerIdList: IOption[] = [
  { Id: -1, Name: "please_select" },
  { Id: 2355, Name: "2355" },
  { Id: 56456, Name: "56456" },
  { Id: 7587, Name: "7587" },
];

export const productTypeList = [
  { Id: 0, Name: "DIESEL" },
  { Id: 1, Name: "DIESEL_IN" },
  { Id: 2, Name: "DIESEL_TRF" },
]

export const unitTypeList = [
  { Id: 0, Name: "liter_unit" },
  { Id: 1, Name: "gallon_unit" },
]

export const compartmentTypes: IOption[] = [
  { Id: 0, Name: "compartment_0" },
  { Id: 1, Name: "compartment_1" },
];

export const inventorySourceTypes: IOption[] = [
  { Id: 0, Name: "ticket" },
  { Id: 1, Name: "level_sensor" },
];

export const ticketTitleTypes: IOption[] = [
  { Id: -1, Name: "please_select" },
  { Id: 0, Name: "TAX_INVOICE" },
  { Id: 1, Name: "DELIVERY_TICKET" },
  { Id: 2, Name: "DELIVERY_NOTE" },
];

export const reportFileTypes: IOption[] = [
  { Id: 1, Name: "csv" },
  { Id: 3, Name: "excel" },
  { Id: 0, Name: "pdf" },
  { Id: 2, Name: "view" },
];

export const missingTransactionFileTypes: IOption[] = [
  { Id: 0, Name: "csv" },
  { Id: 1, Name: "view" },
  // { Id: 3, Name: "excel" },
];

export const allOption: ITruckOption = { Id: "All", Name: "All" }
export const allOptionDailyFuelUsage = { CustomerId2: { Id: "All", Name: "All" }, CustomerId3: { Id: "All", Name: "All" }, CustomerId: { Id: "All", Name: "All" } };
export const allOptionCustomerDashboard = {
  CustomerId2: "All",
  CustomerId3: "All"
}

export const getMonthName = (monthNumber: number) => {
  var mL = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];
  return mL[monthNumber]
}
export const getCurrentDateTime = () => {
  let dateTime = moment(new Date()).format(DATE_TIME_FORMAT_TO_BACKEND);
  return dateTime;
}

export const allocationPeriodColorSchemeDriver = (allocationPeriod: string) => {
  switch (allocationPeriod) {
    case "Daily":
      return "text-success";
    case "Weekly":
      return "text-info";
    case "Monthly":
      return "text-warning";
  }
}

export const allocationPeriodColorSchemeVehicle = (allocationPeriod: string) => {
  switch (allocationPeriod) {
    case "Daily":
      return "bg-success text-white";
    case "Weekly":
      return "bg-info text-white";
    case "Monthly":
      return "bg-warning text-dark";
  }
}

export const getUserTypeStyleClass = (UserType: string) => {
  switch (UserType) {
    case UserTypeEnumStringConstant.ROLE_SUPER_ADMIN:
      return "badge-super-admin";
    case UserTypeEnumStringConstant.ROLE_CUSTOMER_ADMIN:
      return "badge-customer-admin";
    case UserTypeEnumStringConstant.ROLE_CUSTOMER_USER:
      return "badge-customer-user";
    case UserTypeEnumStringConstant.ROLE_CLIENT_ADMIN:
      return "badge-client-admin";
    case UserTypeEnumStringConstant.ROLE_CLIENT_USER:
      return "badge-client-user";
    default:
      return "badge-primary";
  }
}

export enum AllocationPeriodEnumStringConstant {
  Daily = "Daily",
  Weekly = "Weekly",
  Monthly = "Monthly"
}
export enum UserTypeEnumStringConstant {
  ROLE_CUSTOMER_USER = "ROLE_CUSTOMER_USER",
  ROLE_CUSTOMER_ADMIN = "ROLE_CUSTOMER_ADMIN",
  ROLE_CLIENT_USER = "ROLE_CLIENT_USER",
  ROLE_CLIENT_ADMIN = "ROLE_CLIENT_ADMIN",
  ROLE_SUPER_ADMIN = "ROLE_SUPER_ADMIN",
}

export const emptyValue = "-";
export const notAvailable = "not_available";

export const DashboardFilterCase = {
  FuelStatus: "FuelStatus",
  DailyFuelUsage: "DailyFuelUsage",
  TankLevel: "TankLevel"
}

export const roundWithPrecision = (num: number, precision: number) => {
  var multiplier = Math.pow(10, precision);
  return Math.round(num * multiplier) / multiplier;
}

export const selectOption: IOption[] = [{ Id: -1, Name: "Select" }];


export const getLabelOfTransactionReport = (transactionReportType: TransactionReportTypeEnum) => {
  switch (transactionReportType) {
    case TransactionReportTypeEnum.TRUCK_WISE:
      return "asset";
    // return "truck";
    case TransactionReportTypeEnum.CUSTOMER_WISE:
      return "customer";
    case TransactionReportTypeEnum.VEHICLE_WISE:
      return "vehicle";
    case TransactionReportTypeEnum.DEPARTMENT_WISE:
      return "department";
    case TransactionReportTypeEnum.PAYMENT_WISE:
      return "payment";
  };
}

export const getCustomerLabelPrefix = (productId: string, labelSuffix: number) => {
  if (productId == TransactionsEnum.Transfer)
    return formatMessage("transfer_id" + labelSuffix)
  else
    return formatMessage("supplier_id" + labelSuffix)
}

export const allocationPeriodCustomerTypes: IOption[] = [
  { Id: 0, Name: "daily" },
  { Id: 1, Name: "weekly" },
  { Id: 2, Name: "monthly" },
];

export const amountDecimalTypes: IOption[] = [
  { Id: 0, Name: "two_decimal" },
  { Id: 1, Name: "three_decimal" },
];

export function formatNumberWithCommas(num: number) {
  return new Intl.NumberFormat().format(num); // You can use the custom function here instead if desired
}

export const calculateEffectivePrice = (unitPricePerLiter: number, vatPercentage: number) => {
  return unitPricePerLiter * (1 + (vatPercentage / 100));
}