/*    
<summary>
   This file is used to provide initial values or state to the customer Data observables.
   Developer:Ashish Singh, Created Date:20-March-2024
</summary>
*/
import moment from "moment";
import { IAddCustomerRate, IAddEditCustomer } from "../../models/response/ICustomerResponse";
import { DATE_FORMAT_BACKEND } from "../../constants/common-constant";

export const addEditCustomerState: IAddEditCustomer = {
    // CustomerId: "",
    Name: "",
    PhoneNo: "",
    ContactPersonName: "",
    ContactPersonPhoneNo: "",
    ContactPersonEmail: "",
    City: "",
    State: "",
    Country: "",
    Address: "",
    IsActive: true,
    CustomerAdminName: "",
    Email: "",
    MaxAmountLimit: "",
    MaxVolumeLimit: "",
    PricePerL: "",
    PricePerGallon: "",
    Vat: "",
    CurrencyName: "",
    ThresholdVolumeLimit: "",
    TaxRegistrationNo: "",
    // AllocationPeriod: 2,
    RemainingVolume: 0,
    EnableLimitSettings: false
}

export const addCustomerState: IAddCustomerRate = {
    CustomerId: "",
    CustomerIdLong: "",
    EffectiveDate: moment(new Date()).format(DATE_FORMAT_BACKEND),
    RateUkg: "",
    RateLtr: "",
    Vat: ""
}