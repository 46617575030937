/*    
<summary>
  This functional component "UpdateTransaction" defines the props like initial values and functions for the child form component.
  Developer:Aashish Singh, Created Date:26-June-2024
</summary>
<returns>Returns JSX</returns>
*/

import { observer } from 'mobx-react';
import React, { useCallback, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useStore } from '../../../contexts/StoreProvider';
import { formatMessage } from '../../../translations/formatMessage';
import { IOption } from '../../../models/ICommon';
import { IAddEditRole } from '../../../models/response/IRoleResponse';
import UpdateCustomerIdsInTransactionForm from '../forms/edit-customer-ids-in-transaction-form';
import { IAddTransactions } from '../../../models/ITransaction';
import UpdateTransactionForm from '../forms/update-transaction-form';
import { Modal } from 'react-bootstrap';
import SpinnerLoader from '../../../shared-components/Loader/SpinnerLoader';
import { FormattedMessage } from 'react-intl';
import preferencesStore from '../../../core/stores/preferences-store';
import { getClientId } from '../../../helpers/localStorages.helper';
import DraggableModal from '../../../shared-components/Draggable/DraggableModal';
interface IProps {
    id: number;
    productId: string;
    modalClosed: () => void;
}

const UpdateTransaction = (props: IProps) => {

    const { transactionsStore, preferencesStore } = useStore();
    const { getTransactionByIdService, transactionDetailState, getAddTransactionDetail,
        updateTransactionService, addUpdateTransactionState,
        resetAddUpdateTransactionState, resetGetTransactionDetailState } = transactionsStore;
    const { GetAllClientsService, allAvailableClientOptions } = preferencesStore;

    const { id, modalClosed } = props

    const onModalClosed = () => {
        modalClosed();
        resetAddUpdateTransactionState();
        resetGetTransactionDetailState();
    }

    useEffect(() => {
        if (id > 0) {
            getTransactionByIdService(id);
        }
        GetAllClientsService()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    useEffect(() => {
        if (addUpdateTransactionState.success) {
            toast.success(formatMessage("updated_success"));
            onModalClosed();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [addUpdateTransactionState.success]);

    // useEffect(() => {
    //   if (roleDetailState.error) {
    //     toast.error(formatMessage(roleDetailState.error));
    //     // resetGetRoleDetail();
    //   }
    //   // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [roleDetailState.error]);

    const onSubmitForm = (values: IAddTransactions) => {
        let clientIdFromLocalStorage = getClientId();
        const clientId = clientIdFromLocalStorage != null ? clientIdFromLocalStorage : -1;
        let clientArray = allAvailableClientOptions.filter((client: IOption) => client.Id == clientId);
        if (clientArray.length === 1)
            updateTransactionService(id, { ...values, ClientId: clientArray[0].Name });
    }

    return (
        <React.Fragment>
            <Modal className="update-transaction-modal add-user-modal" show={true} centered dialogAs={DraggableModal}>
                <Modal.Header>
                    <Modal.Title className="fs-5 ps-3">
                        <FormattedMessage id="update_transaction" />
                    </Modal.Title>
                    <div onClick={onModalClosed} className="icons8-close">
                        <span className="material-symbols-outlined fs-5 text-dark">
                            close
                        </span>
                    </div>
                    {transactionDetailState.inProgress || addUpdateTransactionState.inProgress ? <SpinnerLoader /> : null}
                </Modal.Header>
                <UpdateTransactionForm
                    initialValues={{ ...getAddTransactionDetail }}
                    id={id}
                    submitHandler={onSubmitForm}
                    onModalClosed={onModalClosed}
                    addUpdateInProgress={addUpdateTransactionState.inProgress}
                />
            </Modal>
        </React.Fragment>
    );
};

export default observer(UpdateTransaction);
