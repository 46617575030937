/*    
<summary>
  This functional component "AddUserForm" provides form to add new user or update existing user.
  Developer:Aashish Singh, Created Date:04-March-2024
</summary>
<returns>Returns JSX</returns>
*/
import React from "react";
import { Modal, Button, Row, Col } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { Form, Formik, Field, useFormikContext } from "formik";
import { IUser } from "../../../models/IUser";
import SpinnerLoader from "../../../shared-components/Loader/SpinnerLoader";
import FormikFormInput from "../../../shared-components/FormikFormHandlers/FormikFormInput";
import LoaderButton from "../../../shared-components/Button/LoaderButton";
import Label from "../../../shared-components/label/label";
import FormikFormSelectField from "../../../shared-components/FormikFormHandlers/FormikFormSelectField";
import { IOption } from "../../../models/ICommon";
import {
  getVehicleValidateSchema,
  truckValidateSchema,
} from "../../../shared-components/FormikFormHandlers/FormikValidationSchema";
import { IAddEditTruck } from "../../../models/response/ITruckResponse";
import DraggableModal from "../../../shared-components/Draggable/DraggableModal";
import FormikDateFieldMUI from "../../../shared-components/FormikFormHandlers/FormikFormDatePickerMUI";
import moment from "moment";
import { DATE_FORMAT_BACKEND } from "../../../constants/common-constant";

interface IProps {
  id: number;
  submitHandler: (data: IAddEditTruck) => void;
  onModalClosed: () => void;
  getDataInProgress: boolean;
  addUpdateInProgress: boolean;
  initialValues: IAddEditTruck;
  compartmentTypes: IOption[];
  inventorySourceTypes: IOption[];
}

const AddUpdateTruckForm = (props: IProps) => {
  const {
    id,
    submitHandler,
    onModalClosed,
    getDataInProgress,
    addUpdateInProgress,
    initialValues,
    compartmentTypes,
    inventorySourceTypes
  } = props;

  const FormObserver: React.FC = () => {
    const { values } = useFormikContext<IUser>();
    return null;
  };

  return (
    <>
      <Modal className="add-user-modal " show={true} centered dialogAs={DraggableModal}>
        <Modal.Header>
          <Modal.Title className="fs-5 ps-3">
            {id === -1 ? (
              <FormattedMessage id="add_truck" />
            ) : (
              <FormattedMessage id="update_truck" />
            )}
          </Modal.Title>
          <div onClick={onModalClosed} className="icons8-close">
            <span className="material-symbols-outlined fs-5 text-dark">
              close
            </span>
          </div>
          {getDataInProgress ? <SpinnerLoader /> : null}
        </Modal.Header>
        <Formik
          initialValues={initialValues}
          enableReinitialize
          validationSchema={truckValidateSchema}
          onSubmit={async (values: any) => {
            let installdate =
              values.InstallationDate ? (values.InstallationDate?.$d
                ? values.InstallationDate.format(DATE_FORMAT_BACKEND)
                : moment(values.InstallationDate.$d).format(DATE_FORMAT_BACKEND)) : "";
            let expirydate =
              values.ExpiryDate ? (values.ExpiryDate?.$d
                ? values.ExpiryDate.format(DATE_FORMAT_BACKEND)
                : moment(values.ExpiryDate.$d).format(DATE_FORMAT_BACKEND)) : "";
            let tempValues = {
              DeviceId: values.DeviceId ? values.DeviceId.trim() : values.DeviceId,
              AccountId: values.AccountId,
              TruckId: values.TruckId ? values.TruckId.trim() : values.TruckId,
              Compartment: values.Compartment,
              InventorySource: values.InventorySource,
              SimCard: values.SimCard,
              IpAddress: values.IpAddress,
              InstallationDate: installdate,
              AssetType: values.AssetType,
              Equipment: values.Equipment,
              ExpiryDate: expirydate
            }
            submitHandler({ ...tempValues });
          }}
        >
          {({ values }) => (
            <Form className="user-form p-3" noValidate>
              <FormObserver />
              <Modal.Body>
                <Row>
                  <Col lg={6}>
                    <div className="mb-3 form-group">
                      <Label
                        className="form-label"
                        required={true}
                        label="truck_id"
                      />
                      <Field
                        data-testid="testTruckID"
                        className="form-control"
                        type="text"
                        name="TruckId"
                        validcharacter={["@", "#", "$", "-", "_", "."]}
                        invalidcharacter={[" "]}
                        placeholder="truck_id"
                        as={FormikFormInput}
                      // disabled={props.id > 0 ? true : false}
                      />
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div className="mb-3 form-group">
                      <Label
                        className="form-label"
                        label="inventory_source"
                      />
                      <Field
                        data-testid="testTruckID"
                        className="form-control"
                        name="InventorySource"
                        type="number"
                        as={FormikFormSelectField}
                        typeofoptionid="number"
                        options={inventorySourceTypes}
                      />
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div className="form-group date-picker-error">
                      <Label
                        className="form-label"
                        // required={true}
                        label="installation_date"
                      />
                      <Field name="InstallationDate"
                        as={FormikDateFieldMUI}
                      // min={id > 0 ? initialValues.StartDate : moment(new Date()).format(DATE_FORMAT_BACKEND)}
                      />
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div className="form-group date-picker-error">
                      <Label
                        className="form-label"
                        // required={true}
                        label="expiry_date"
                      />
                      <Field name="ExpiryDate"
                        as={FormikDateFieldMUI}
                      // min={id > 0 ? initialValues.StartDate : moment(new Date()).format(DATE_FORMAT_BACKEND)}
                      />
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div className="mb-3 form-group">
                      <Label
                        className="form-label"
                        label="sim_card"
                      />
                      <Field
                        data-testid="testTruckID"
                        className="form-control"
                        type="text"
                        name="SimCard"
                        validcharacter={["@", "#", "$", "-", "_", "."]}
                        // invalidcharacter={[" "]}
                        placeholder="sim_card"
                        as={FormikFormInput}
                      />
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div className="mb-3 form-group">
                      <Label
                        className="form-label"
                        label="ip_address"
                      />
                      <Field
                        data-testid="testTruckID"
                        className="form-control"
                        type="text"
                        name="IpAddress"
                        validcharacter={["@", "#", "$", "-", "_", "."]}
                        invalidcharacter={[" "]}
                        placeholder="ip_address"
                        as={FormikFormInput}
                      />
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div className="mb-3 form-group">
                      <Label
                        className="form-label"
                        label="asset_type"
                      />
                      <Field
                        data-testid="testTruckID"
                        className="form-control"
                        type="text"
                        name="AssetType"
                        validcharacter={["@", "#", "$", "-", "_", "."]}
                        // invalidcharacter={[" "]}
                        placeholder="asset_type"
                        as={FormikFormInput}
                      />
                    </div>
                  </Col>
                  {/* <Col lg={6}>
                    <div className="mb-3 form-group">
                      <Label
                        className="form-label"
                        required={true}
                        label="client_id"
                      />
                      <Field
                        data-testid="testTruckID"
                        className="form-control"
                        type="text"
                        name="AccountId"
                        validcharacter={["@", "#", "$", "-", "_", "."]}
                        invalidcharacter={[" "]}
                        placeholder="client_id"
                        as={FormikFormInput}
                      />
                    </div>
                  </Col> */}
                  <Col lg={6}>
                    <div className="mb-3 form-group">
                      <Label
                        className="form-label"
                        required={true}
                        label="equipment"
                      />
                      <Field
                        data-testid="testTruckID"
                        className="form-control"
                        type="text"
                        name="Equipment"
                        validcharacter={["@", "#", "$", "-", "_", "."]}
                        // invalidcharacter={[" "]}
                        placeholder="equipment"
                        as={FormikFormInput}
                      />
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div className="mb-3 form-group">
                      <Label
                        className="form-label"
                        label="compartment"
                      />
                      <Field
                        name="Compartment"
                        className="form-control"
                        type="number"
                        as={FormikFormSelectField}
                        typeofoptionid="number"
                        options={compartmentTypes}
                      />
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div className="mb-3 form-group">
                      <Label
                        className="form-label"
                        required={true}
                        label="transactions_label_deviceid"
                      />
                      <Field
                        name="DeviceId"
                        data-testid="testCustId2"
                        className="form-control"
                        type="text"
                        invalidcharacter={[" "]}
                        validcharacter={['@', '#', '$', '-', '_', '.']}
                        placeholder="transactions_label_deviceid"
                        as={FormikFormInput}
                      />
                    </div>
                  </Col>
                </Row>
              </Modal.Body>
              <div className="modal-footer  border-0">
                <div className="m-auto">
                  <LoaderButton
                    type="submit"
                    isLoading={addUpdateInProgress}
                    disabled={addUpdateInProgress}
                    text={id === -1 ? "button_save" : "button_edit"}
                  />
                  <Button
                    variant="btn-secondary bg-light btn-light ms-2"
                    onClick={onModalClosed}
                  >
                    <FormattedMessage id="button_cancel" />
                  </Button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};

export default AddUpdateTruckForm;
