import * as Yup from "yup";
import { getAmountDecimal } from "../../helpers/localStorages.helper";
import { ipv4Regex, ipv6Regex } from "../../constants/common-constant";

export const getDriverValidateSchema = (date: Date) => {
  date.setDate(date.getDate() - 1);
  return (
    Yup.object({
      RFID: Yup.string()
        .max(40, "rfid_limit_reached"),
      DriverName: Yup.string()
        .required("field_required")
        .min(3, 'driver_name_length_required')
        .max(20, 'driver_name_length_required'),
      DLNo: Yup.string()
        .required("field_required")
        .matches(
          /^[A-Za-z0-9]{5,10}$/,
          "dlnumber_limit_reached")
        .min(5, 'dlnumber_limit_reached')
        .max(10, 'dlnumber_limit_reached'),
      DLExpiryDate: Yup.date()
        .required("field_required")
        .min(date, "add_latest_expiry_date"),
      // TruckAllocation: Yup.string()
      //   .min(3, 'name_length_required')
      //   .max(30, 'name_length_required'),
      TruckAllocation1: Yup.string()
        .max(100, 'char_length_required'),
      FuelDeliveryEnabled: Yup.boolean()
        .required("field_required"),
      AllocationPerFill: Yup.number()
        .required("field_required")
        .min(0, "field_required")
        .max(2147483647, 'integer_max_limit_reached'),
      AllocationPeriod: Yup.number()
        .required("field_required")
        .min(0, "field_required"),
      AllocationVolume: Yup.number()
        .required("field_required")
        .min(0, "field_required")
        .max(2147483647, 'integer_max_limit_reached'),
      // RemainingVolume: Yup.number()
      //   // .required("field_required")
      //   // .min(0, "field_required")
      //   .test(
      //     'RemainingVolume',
      //     'remaining_volume_limit_must_be_less',
      //     (RemainingVolume, context) => {
      //       return (RemainingVolume !== undefined && context.parent.AllocationVolume < RemainingVolume) ? false : true;
      //     }
      //   )
      //   .max(2147483647, 'integer_max_limit_reached'),
      BelongToCustomer: Yup.boolean(),
      EmployeeId: Yup.string()
        .max(40, "limit_reached_40"),
      CustomerId: Yup.string()
        .test(
          'CustomerId',
          'field_required',
          (CustomerId, context) => {
            return ((CustomerId === undefined || CustomerId === "-1") && context.parent.BelongToCustomer) ? false : true;
          }
        )
    })
  )
}

export const getVehicleValidateSchema = (date: Date) => {
  date.setDate(date.getDate() - 1);
  return (
    Yup.object({
      RFID: Yup.string()
        .max(40, "rfid_limit_reached"),
      Description: Yup.string()
        .max(150, 'desc_length_required'),
      RegistrationNo: Yup.string()
        .required("field_required")
        .min(3, 'reg_no_length_required')
        .max(30, 'reg_no_length_required'),
      RegistrationExpiryDate: Yup.date()
        .required("field_required")
        .min(date, "add_latest_expiry_date"),
      // TruckAllocation: Yup.string()
      //   .min(3, 'name_length_required')
      //   .max(30, 'name_length_required'),
      TruckAllocation1: Yup.string()
        .max(100, 'char_length_required'),
      FuelDeliveryEnabled: Yup.boolean()
        .required("field_required"),
      AllocationPerFill: Yup.number()
        .required("field_required")
        .min(0, "field_required")
        .max(2147483647, 'integer_max_limit_reached'),
      AllocationPeriod: Yup.number()
        .required("field_required")
        .min(0, "field_required"),
      AllocationVolume: Yup.number()
        .required("field_required")
        .min(0, "field_required")
        .max(2147483647, 'integer_max_limit_reached'),
      // RemainingVolume: Yup.number()
      //   // .required("field_required")
      //   // .min(0, "field_required")
      //   .test(
      //     'RemainingVolume',
      //     'remaining_volume_limit_must_be_less',
      //     (RemainingVolume, context) => {
      //       return (RemainingVolume !== undefined && context.parent.AllocationVolume < RemainingVolume) ? false : true;
      //     }
      //   )
      //   .max(2147483647, 'integer_max_limit_reached'),
      LowerConsumptionLimit: Yup.number()
        .required("field_required")
        .min(0, "field_required")
        .max(2147483647, 'integer_max_limit_reached'),
      HigherConsumptionLimit: Yup.number()
        .required("field_required")
        .min(0, "field_required")
        .test(
          'HigherConsumptionLimit',
          'higher_consumption_limit_must_be_greater',
          (HigherConsumptionLimit, context) => {
            return (HigherConsumptionLimit !== undefined && context.parent.LowerConsumptionLimit >= HigherConsumptionLimit) ? false : true;
          }
        )
        .max(2147483647, 'integer_max_limit_reached'),
      CustId2: Yup.string()
        .required("field_required"),
      // CustId4: Yup.string()
      //   .required("field_required"),
      // CustId5: Yup.string()
      //   .required("field_required"),
      // CustId6: Yup.string()
      //   .required("field_required"),
      BelongToCustomer: Yup.boolean(),
      CustomerId: Yup.string()
        .test(
          'CustomerId',
          'field_required',
          (CustomerId, context) => {
            return ((CustomerId === undefined || CustomerId === "-1") && context.parent.BelongToCustomer) ? false : true;
          }
        )
    })
  )
}

export const addClientValidateSchema = Yup.object({
  Name: Yup.string()
    .required("field_required")
    .test(
      "no-all",
      "not_all",
      value => !/^(ALL|All|all|aLL|aLl|alL|AlL|ALl)$/.test(value))
    .matches(/^(?!pg_|PG_|pG_|Pg_)([a-zA-Z][\w]*)(?!.*_)$/, "invalid_input")
    // .matches(/^(?!pg_|PG_)(?![0-9_])[a-zA-Z][a-zA-Z0-9]*[^_]$/, "invalid_input")
    // .matches(/^(?!pg_|PG_|pg$|PG$|pg|PG)(?![_\d])(?!.*_$)[A-Za-z0-9_]*$/, "invalid_input")
    .min(3, 'name_length_required')
    .max(30, 'name_length_required'),
  ContactPersonPhoneNo: Yup.string()
    .max(30, 'phone_length_required')
    .min(1, "phone_length_required"),
  // .matches(
  //   /^[- +()]*[- +()0-9]*$/,
  //   "invalid_phone_no"
  // ),
  ContactPersonName: Yup.string()
    .max(30, 'max_name_length_required'),
  ContactPersonEmail: Yup.string()
    .matches(
      /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
      "email_validation_invalid"
    ),
  Address: Yup.string()
    // .required("field_required")
    .max(200, "address_max_limit_reached"),
  // Address: Yup.string()
  //   .required("field_required")
  //   .min(10, 'address_length_required')
  //   .max(100, 'address_length_required'),
  City: Yup.string(),
  // .required("field_required"),
  State: Yup.string(),
  Country: Yup.string(),
  // .required("field_required"),
  PhoneNo: Yup.string()
    // .required("field_required")
    .max(30, 'phone_length_required')
    .min(1, "phone_length_required"),
  // .matches(
  //   /^[- +()]*[- +()0-9]*$/,
  //   "invalid_phone_no"
  // ),
  StartDate: Yup.date(),
  // .required("field_required"),
  EndDate: Yup.date()
    // .required("field_required")
    .min(Yup.ref('StartDate'), 'end_date_must_be_greater'),
  IsActive: Yup.boolean(),
  // required("field_required"),
  ClientAdminName: Yup.string()
    .required("field_required")
    .min(3, 'name_length_required')
    .max(30, 'name_length_required'),
  Email: Yup.string()
    .required("field_required")
    .min(5, 'email_length_required')
    .max(50, 'email_length_required')
    .matches(
      /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
      "email_validation_invalid"
    ),
});

export const updateClientValidateSchema = Yup.object({
  Name: Yup.string()
    .required("field_required")
    .min(3, 'name_length_required')
    .max(30, 'name_length_required')
    .test(
      "no-all",
      "not_all",
      value => !/^(ALL|All|all)$/.test(value)),
  ContactPersonPhoneNo: Yup.string()
    .max(30, 'phone_length_required')
    .min(1, "phone_length_required"),
  // .matches(
  //   /^[- +()]*[- +()0-9]*$/,
  //   "invalid_phone_no"
  // ),
  ContactPersonName: Yup.string()
    .max(30, 'max_name_length_required'),
  ContactPersonEmail: Yup.string()
    .matches(
      /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
      "email_validation_invalid"
    ),
  Address: Yup.string()
    // .required("field_required")
    .max(200, "address_max_limit_reached"),
  City: Yup.string(),
  // .required("field_required"),
  State: Yup.string(),
  Country: Yup.string(),
  // .required("field_required"),
  PhoneNo: Yup.string()
    // .required("field_required")
    .max(30, 'phone_length_required')
    .min(1, "phone_length_required"),
  // .matches(
  //   /^[- +()]*[- +()0-9]*$/,
  //   "invalid_phone_no"
  // ),
  StartDate: Yup.date()
    .required("field_required"),
  EndDate: Yup.date()
    .required("field_required")
    .min(Yup.ref('StartDate'), 'end_date_must_be_greater'),
  IsActive: Yup.boolean(),
  // required("field_required"),
  // Email: Yup.string()
  //   .required("field_required")
  //   .min(5, 'email_length_required')
  //   .max(50, 'email_length_required')
  //   .matches(
  //     /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
  //     "email_validation_invalid"
  //   ),
});

export const FilterValidateSchema = Yup.object({
  EndDate: Yup.date().min(
    Yup.ref('StartDate'),
    "DeviceLog_Validation_StartEndDateTime"
  ),
  // EndTime: Yup.mixed()
  //   .test('endTimeValidation', "DeviceLog_Validation_StartEndDateTime", (value, ctx) => {
  //     const { EndDate, StartDate, StartTime } = ctx.parent;
  //     if (moment(StartDate).format(DateFormat) === moment(EndDate).format(DateFormat)) {
  //       return moment(value, TimeFormat).format(TimeFormat) > moment(StartTime, TimeFormat).format(TimeFormat)
  //     } else {
  //       return true
  //     }
  //   })
});

export const UserChangePasswordValidateSchema = Yup.object({
  NewPassword: Yup.string()
    .min(8, "min_length_required")
    .max(20, "min_length_required")
    // .matches(/^(?=.*[A-Z])(?=.*\d)(?=.*[^\da-zA-Z]).{8,}$/, "user_validation_password_matches")
    .required("field_required"),
  ConfirmPassword: Yup.string()
    .min(8, "min_length_required")
    .max(20, "min_length_required")
    .oneOf([Yup.ref('NewPassword')], "validation_new_confirm_password_mismatch")
    .required("field_required"),
});

export const forgetPasswordOTPSchema = Yup.object({
  Digit1: Yup.number(),
  Digit2: Yup.number(),
  Digit3: Yup.number(),
  Digit4: Yup.number()
})

export const forgetPasswordAuthSchema = Yup.object({
  Email: Yup.string()
    .matches(/^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([一-龠ぁ-ゔァ-ヴー々〆〤a-zA-Z\-0-9]+\.)+[一-龠ぁ-ゔァ-ヴー々〆〤a-zA-Z]{2,}))$/, "invalid_login_id")
    .min(5, "lodin_id_length_required")
    .max(50, "lodin_id_length_required")
    .required("field_required"),
})

export const LoginValidateSchema = Yup.object({
  Email: Yup.string()
    .required("field_required"),
  Password: Yup.string()
    .required("field_required")
});

export const addUserValidateSchema = Yup.object({
  UserName: Yup.string()
    .required("field_required")
    .min(3, 'name_length_required')
    .max(30, 'name_length_required'),

  UserType: Yup.number()
    .min(0, "field_required"),
  RoleId: Yup.string()
    .test(
      'RoleId',
      'field_required',
      (RoleId, context) => {
        return ((RoleId == undefined || RoleId == "-1") && parseInt(context.parent.UserType) == 2) ? false : true;
      }
    ),
  CustomerId: Yup.string()
    .test(
      'CustomerId',
      'field_required',
      (CustomerId, context) => {
        return ((CustomerId == undefined || CustomerId == "-1") && (context.parent.UserType == "0" || context.parent.UserType == "1")) ? false : true;
      }
    ),
  Email: Yup.string()
    .required("field_required")
    .min(5, 'email_length_required')
    .max(50, 'email_length_required')
    .matches(
      /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
      "email_validation_invalid"
    ),
  Address: Yup.string()
    .max(200, "address_max_limit_reached"),
  City: Yup.string(),
  State: Yup.string(),
  Country: Yup.string(),
  PhoneNo: Yup.string()
    // .required("field_required")
    .max(30, 'phone_length_required')
    .min(1, "phone_length_required"),
  // .matches(
  //   /^[- +()]*[- +()0-9]*$/,
  //   "invalid_phone_no"
  // ),
  WhatsAppNo: Yup.string()
    // .required("field_required")
    .max(30, 'phone_length_required')
    .min(1, "phone_length_required"),
  // .matches(
  //   /^[- +()]*[- +()0-9]*$/,
  //   "invalid_phone_no"
  // ),
  IsActive: Yup.boolean()
    .required("field_required"),
});


export const editUserValidateSchema = Yup.object({
  UserName: Yup.string()
    .required("field_required")
    .min(3, 'name_length_required')
    .max(30, 'name_length_required'),
  Email: Yup.string()
    .required("field_required")
    .min(5, 'email_length_required')
    .max(50, 'email_length_required')
    .matches(
      /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
      "email_validation_invalid"
    ),
  UserType: Yup.number()
    .min(0, "field_required"),
  RoleId: Yup.string()
    .test(
      'RoleId',
      'field_required',
      (RoleId, context) => {
        return ((RoleId == undefined || RoleId == "-1") && context.parent.UserType == "2") ? false : true;
      }
    ),
  CustomerId: Yup.string()
    .test(
      'CustomerId',
      'field_required',
      (CustomerId, context) => {
        return ((CustomerId == undefined || CustomerId == "-1") && (context.parent.UserType == "0" || context.parent.UserType == "1")) ? false : true;
      }
    ),
  Address: Yup.string()
    .max(200, "address_max_limit_reached"),
  City: Yup.string(),
  State: Yup.string(),
  Country: Yup.string(),
  PhoneNo: Yup.string()
    // .required("field_required")
    .max(30, 'phone_length_required')
    .min(1, "phone_length_required"),
  // .matches(
  //   /^[- +()]*[- +()0-9]*$/,
  //   "invalid_phone_no"
  // ),
  WhatsAppNo: Yup.string()
    // .required("field_required")
    .max(30, 'phone_length_required')
    .min(1, "phone_length_required"),
  // .matches(
  //   /^[- +()]*[- +()0-9]*$/,
  //   "invalid_phone_no"
  // ),
  IsActive: Yup.boolean()
    .required("field_required"),
});

export const updateLoggedInUserPasswordSchema = Yup.object({
  OldPassword: Yup.string()
    .required("field_required")
    .min(8, "min_length_required")
    .max(20, "min_length_required"),
  NewPassword: Yup.string()
    .required("field_required")
    .min(8, "min_length_required")
    .max(20, "min_length_required"),
  ConfirmPassword: Yup.string()
    .required("field_required")
    .min(8, "min_length_required")
    .max(20, "min_length_required")
    .oneOf([Yup.ref('NewPassword')], "validation_new_confirm_password_mismatch"),
});

export const addUpdateRoleValidateSchema = Yup.object({
  RoleName: Yup.string()
    .required("field_required")
    .min(3, 'name_length_required')
    .max(30, 'name_length_required'),
});


export const addCustomerValidateSchema = (decimalValue: number) => {
  return (
    Yup.object({
      Name: Yup.string()
        .required("field_required")
        .min(3, 'name_length_required')
        .max(30, 'name_length_required'),
      ContactPersonPhoneNo: Yup.string()
        .max(30, 'phone_length_required')
        .min(1, "phone_length_required"),
      // .matches(
      //   /^[- +()]*[- +()0-9]*$/,
      //   "invalid_phone_no"
      // ),
      ContactPersonName: Yup.string()
        .max(30, 'max_name_length_required'),
      ContactPersonEmail: Yup.string()
        .matches(
          /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
          "email_validation_invalid"
        ),
      Address: Yup.string()
        // .required("field_required")
        .max(200, "address_max_limit_reached"),
      City: Yup.string(),
      // .required("field_required"),
      State: Yup.string(),
      Country: Yup.string(),
      // .required("field_required"),
      PhoneNo: Yup.string()
        // .required("field_required")
        .max(30, 'phone_length_required')
        .min(1, "phone_length_required"),
      // .matches(
      //   /^[- +()]*[- +()0-9]*$/,
      //   "invalid_phone_no"
      // ),
      CustomerAdminName: Yup.string()
        .required("field_required")
        .min(3, 'name_length_required')
        .max(30, 'name_length_required'),
      Email: Yup.string()
        .required("field_required")
        .min(5, 'email_length_required')
        .max(50, 'email_length_required')
        .matches(
          /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
          "email_validation_invalid"
        ),
      IsActive: Yup.boolean().
        required("field_required"),
      PricePerL: Yup.number()
        .required("field_required")
        .max(2147483647, 'integer_max_limit_reached')
        .test(
          "is-decimal",
          decimalValue === 2 ? "two_digits_allowed" : "three_digits_allowed",
          (val) => {
            if (val !== undefined && val !== null) {
              const pattern = new RegExp(`^\\d+(\\.\\d{0,${decimalValue}})?$`);
              return pattern.test(String(val));
            }
            return true;
          }
        ),
      PricePerGallon: Yup.number()
        .required("field_required")
        .max(2147483647, 'integer_max_limit_reached')
        .test(
          "is-decimal",
          decimalValue === 2 ? "two_digits_allowed" : "three_digits_allowed",
          (val) => {
            if (val !== undefined && val !== null) {
              const pattern = new RegExp(`^\\d+(\\.\\d{0,${decimalValue}})?$`);
              return pattern.test(String(val));
            }
            return true;
          }
        ),
      CurrencyName: Yup.string()
        .required("field_required"),
      // MaxAmountLimit: Yup.string()
      //   .required("field_required"),
      // MaxVolumeLimit: Yup.string()
      //   .required("field_required"),
      Vat: Yup.number()
        .required("field_required")
        .max(100, "invalid_percent_range")
        .test(
          "is-decimal",
          "two_digits_allowed",
          (val) => {
            if (val !== undefined && val !== null) {
              const pattern = new RegExp(`^\\d+(\\.\\d{0,2})?$`);
              return pattern.test(String(val));
            }
            return true;
          }
        ),
      TaxRegistrationNo: Yup.string()
        .max(15, "max_limit_reached_15"),
      MaxAmountLimit: Yup.string().test(
        "is-decimal",
        decimalValue === 2 ? "two_digits_allowed" : "three_digits_allowed",
        (val) => {
          if (val !== undefined && val !== null && val !== '') {
            const pattern = new RegExp(`^\\d+(\\.\\d{0,${decimalValue}})?$`);
            return pattern.test(String(val));
          }
          return true;
        }
      ),
      ThresholdVolumeLimit: Yup.string().test(
        'ThresholdVolumeLimit',
        'threshold_volume_limit_must_be_smaller',
        (ThresholdVolumeLimit, context) => {
          return (ThresholdVolumeLimit !== undefined && Number(context.parent.MaxVolumeLimit) < Number(ThresholdVolumeLimit)) ? false : true;
        }
      )
    })
  )
}

export const addCustomerRateSchema = (decimalValue: number) => {
  const stripTime = (date: string | number | Date) => {
    const newDate = new Date(date);
    newDate.setHours(0, 0, 0, 0);
    return newDate;
  };

  const today = stripTime(new Date());
  return (
    Yup.object({
      RateLtr: Yup.number()
        .required("field_required")
        .max(2147483647, 'integer_max_limit_reached')
        .test(
          "is-decimal",
          decimalValue === 2 ? "two_digits_allowed" : "three_digits_allowed",
          (val) => {
            if (val !== undefined && val !== null) {
              const pattern = new RegExp(`^\\d+(\\.\\d{0,${decimalValue}})?$`);
              return pattern.test(String(val));
            }
            return true;
          }
        ),
      RateUkg: Yup.number()
        .required("field_required")
        .max(2147483647, 'integer_max_limit_reached')
        .test(
          "is-decimal",
          decimalValue === 2 ? "two_digits_allowed" : "three_digits_allowed",
          (val) => {
            if (val !== undefined && val !== null) {
              const pattern = new RegExp(`^\\d+(\\.\\d{0,${decimalValue}})?$`);
              return pattern.test(String(val));
            }
            return true;
          }
        ),
      Vat: Yup.number()
        .required("field_required")
        .max(100, "invalid_percent_range")
        .test(
          "is-decimal",
          "two_digits_allowed",
          (val) => {
            if (val !== undefined && val !== null) {
              const pattern = new RegExp(`^\\d+(\\.\\d{0,2})?$`);
              return pattern.test(String(val));
            }
            return true;
          }
        ),
      EffectiveDate: Yup.date()
        .required("field_required")
        .transform((value) => stripTime(value))
        .min(today, 'Effective Date cannot be in the past')
    }))
}

export const editCustomerValidateSchema = (decimalValue: number) => {
  return (
    Yup.object({
      Name: Yup.string()
        .required("field_required")
        .min(3, 'name_length_required')
        .max(30, 'name_length_required'),

      ContactPersonPhoneNo: Yup.string()
        .max(30, 'phone_length_required')
        .min(1, "phone_length_required"),
      // .matches(
      //   /^(?:\+\d{1,3}-)?\d{10}$/,
      //   "invalid_phone_no"
      // ),
      ContactPersonName: Yup.string()
        .max(30, 'max_name_length_required'),
      ContactPersonEmail: Yup.string()
        .matches(
          /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
          "email_validation_invalid"
        ),
      Address: Yup.string()
        // .required("field_required")
        .max(200, "address_max_limit_reached"),
      City: Yup.string(),
      // .required("field_required"),
      State: Yup.string(),
      Country: Yup.string(),
      // .required("field_required"),
      PhoneNo: Yup.string()
        // .required("field_required")
        .max(30, 'phone_length_required')
        .min(1, "phone_length_required"),
      // .matches(
      //   /^(?:\+\d{1,3}-)?\d{10}$/,
      //   "invalid_phone_no"
      // ),
      IsActive: Yup.boolean().
        required("field_required"),
      // PricePerL: Yup.number()
      //   .required("field_required")
      //   .max(2147483647, 'integer_max_limit_reached')
      //   .test(
      //     "is-decimal",
      //     "two_digits_allowed",
      //     (val: any) => {
      //       if (val != undefined) {
      //         return /^\d+(\.\d{0,2})?$/.test(val);
      //       }
      //       return true;
      //     }
      //   ),
      // PricePerGallon: Yup.number()
      //   .required("field_required")
      //   .max(2147483647, 'integer_max_limit_reached')
      //   .test(
      //     "is-decimal",
      //     "two_digits_allowed",
      //     (val: any) => {
      //       if (val != undefined) {
      //         return /^\d+(\.\d{0,2})?$/.test(val);
      //       }
      //       return true;
      //     }
      //   ),
      CurrencyName: Yup.string()
        .required("field_required"),
      // MaxAmountLimit: Yup.string()
      //   .required("field_required"),
      // MaxVolumeLimit: Yup.string()
      //   .required("field_required"),
      // Vat: Yup.number()
      //   .required("field_required")
      //   .max(100, "invalid_percent_range")
      //   .test(
      //     "is-decimal",
      //     "two_digits_allowed",
      //     (val: any) => {
      //       if (val != undefined) {
      //         return /^\d+(\.\d{0,2})?$/.test(val);
      //       }
      //       return true;
      //     }
      //   ),
      MaxAmountLimit: Yup.string().test(
        "is-decimal",
        decimalValue === 2 ? "two_digits_allowed" : "three_digits_allowed",
        (val) => {
          if (val !== undefined && val !== null && val !== '') {
            const pattern = new RegExp(`^\\d+(\\.\\d{0,${decimalValue}})?$`);
            return pattern.test(String(val));
          }
          return true;
        }
      ),
      TaxRegistrationNo: Yup.string()
        .max(15, "max_limit_reached_15"),
      ThresholdVolumeLimit: Yup.string().test(
        'ThresholdVolumeLimit',
        'threshold_volume_limit_must_be_smaller',
        (ThresholdVolumeLimit, context) => {
          return (ThresholdVolumeLimit !== undefined && Number(context.parent.MaxVolumeLimit) < Number(ThresholdVolumeLimit)) ? false : true;
        }
      )
    }))
}

export const addPaymentSchema = (decimalValue: number) => {
  return (
    Yup.object({
      PaymentDate: Yup.date()
        .required("field_required"),
      AmountCr: Yup.number()
        .required("field_required")
        .max(2147483647, 'integer_max_limit_reached')
        .test(
          "is-decimal",
          decimalValue === 2 ? "two_digits_allowed" : "three_digits_allowed",
          (val) => {
            if (val !== undefined && val !== null) {
              const pattern = new RegExp(`^\\d+(\\.\\d{0,${decimalValue}})?$`);
              return pattern.test(String(val));
            }
            return true;
          }
        ),
      ModeOfPayment: Yup.string()
        .max(150, 'max_limit_reached_150'),
      Description: Yup.string()
        .max(250, 'max_limit_reached_250')
    }))
}

export const filterTransactionValidateSchema = Yup.object({
  StartDate: Yup.date()
    .required("field_required"),
  EndDate: Yup.date()
    .required("field_required")
    .min(Yup.ref("StartDate"), "ts_finish_must_be_greater"),
});

export const missingTransactionValidateSchema = Yup.object({
  StartDate: Yup.date()
    .required("field_required"),
  EndDate: Yup.date()
    .required("field_required")
    .min(Yup.ref("StartDate"), "ts_finish_must_be_greater"),
  TruckId: Yup.string()
    .required("field_required"),
  // TruckId: Yup.string()
  // .notOneOf(["please_select"] as const, "field_required")
});

export const addTransactionValidateSchema = (decimalValue: number) => {
  return (
    Yup.object({
      TsStart: Yup.date()
        .required("field_required"),
      TsFinish: Yup.date()
        .required("field_required")
        .min(Yup.ref("TsStart"), "ts_finish_must_be_greater"),
      // TsGen: Yup.date()
      //   .required("field_required"),
      // TsSend: Yup.date()
      //   .required("field_required")
      //   .min(Yup.ref("TsGen"), "ts_send_must_be_greater"),
      ClientId: Yup.string()
        .required("field_required"),
      DeviceId: Yup.string()
        .required("field_required"),
      TruckId: Yup.string()
        .required("field_required"),
      // OrderUuid:Yup.string()
      // .required("field_required"),
      TicketNumber: Yup.string()
        .required("field_required"),
      CustomerId1: Yup.string()
        // .required("field_required")
        .max(40, "limit_reached_40"),
      CustomerId2: Yup.string()
        .max(40, "limit_reached_40"),
      CustomerId3: Yup.string()
        .max(40, "limit_reached_40"),
      CustomerId4: Yup.string()
        .max(40, "limit_reached_40"),
      CustomerId5: Yup.string()
        .max(40, "limit_reached_40"),
      CustomerId6: Yup.string()
        .max(40, "limit_reached_40"),
      ProductId: Yup.string()
        .required("field_required"),
      Lat: Yup.number()
        .max(9999.99999999, 'lat_lng_max_limit_reached')
        .test(
          "is-decimal",
          "eight_digits_allowed",
          (val: any) => {
            if (val != undefined) {
              return /^\d+(\.\d{0,8})?$/.test(val);
            }
            return true;
          }
        ),
      Lon: Yup.number()
        .max(9999.99999999, 'lat_lng_max_limit_reached')
        .test(
          "is-decimal",
          "eight_digits_allowed",
          (val: any) => {
            if (val != undefined) {
              return /^\d+(\.\d{0,8})?$/.test(val);
            }
            return true;
          }
        ),
      EndGross: Yup.number()
        .required("field_required")
        .max(2147483647, 'integer_max_limit_reached'),
      // .test(
      //   "is-decimal",
      //   "two_digits_allowed",
      //   (val: any) => {
      //     if (val != undefined) {
      //       return /^\d+(\.\d{0,2})?$/.test(val);
      //     }
      //     return true;
      //   }
      // ),
      FlowAvg: Yup.number()
        .max(2147483647, 'integer_max_limit_reached'),
      // .test(
      //   "is-decimal",
      //   "two_digits_allowed",
      //   (val: any) => {
      //     if (val != undefined) {
      //       return /^\d+(\.\d{0,2})?$/.test(val);
      //     }
      //     return true;
      //   }
      // ),
      FlowMax: Yup.number()
        .max(2147483647, 'integer_max_limit_reached'),
      // .test(
      //   "is-decimal",
      //   "two_digits_allowed",
      //   (val: any) => {
      //     if (val != undefined) {
      //       return /^\d+(\.\d{0,2})?$/.test(val);
      //     }
      //     return true;
      //   }
      // ),
      GrossDelivered: Yup.number()
        .required("field_required")
        .max(2147483647, 'integer_max_limit_reached'),
      // .test(
      //   "is-decimal",
      //   "two_digits_allowed",
      //   (val: any) => {
      //     if (val != undefined) {
      //       return /^\d+(\.\d{0,2})?$/.test(val);
      //     }
      //     return true;
      //   }
      // ),
      PricePerL: Yup.number()
        .max(2147483647, 'integer_max_limit_reached')
        .test(
          "is-decimal",
          decimalValue === 2 ? "two_digits_allowed" : "three_digits_allowed",
          (val) => {
            if (val !== undefined && val !== null) {
              const pattern = new RegExp(`^\\d+(\\.\\d{0,${decimalValue}})?$`);
              return pattern.test(String(val));
            }
            return true;
          }
        ),
      Vat: Yup.number()
        .max(100, "invalid_percent_range")
        .test(
          "is-decimal",
          "two_digits_allowed",
          (val) => {
            if (val !== undefined && val !== null) {
              const pattern = new RegExp(`^\\d+(\\.\\d{0,2})?$`);
              return pattern.test(String(val));
            }
            return true;
          }
        ),
      AmountDue: Yup.number()
        .max(2147483647, 'integer_max_limit_reached')
        .test(
          "is-decimal",
          decimalValue === 2 ? "two_digits_allowed" : "three_digits_allowed",
          (val) => {
            if (val !== undefined && val !== null) {
              const pattern = new RegExp(`^\\d+(\\.\\d{0,${decimalValue}})?$`);
              return pattern.test(String(val));
            }
            return true;
          }
        ),
      VehicleRfid: Yup.string()
        .max(40, "rfid_limit_reached"),
      DriverRfid: Yup.string()
        .max(40, "rfid_limit_reached"),
      OperatorRfid: Yup.string()
        .max(40, "rfid_limit_reached"),
      MeterId: Yup.number()
        .max(2147483647, 'integer_max_limit_reached'),
      NetDelivered: Yup.number()
        .max(2147483647, 'integer_max_limit_reached'),
      // .test(
      //   "is-decimal",
      //   "two_digits_allowed",
      //   (val: any) => {
      //     if (val != undefined) {
      //       return /^\d+(\.\d{0,2})?$/.test(val);
      //     }
      //     return true;
      //   }
      // ),
      InvName1: Yup.string()
        .max(40, "limit_reached_40"),
      InvName2: Yup.string()
        .max(40, "limit_reached_40"),
      InvValue1: Yup.number()
        .max(2147483647, 'integer_max_limit_reached'),
      // .test(
      //   "is-decimal",
      //   "two_digits_allowed",
      //   (val: any) => {
      //     if (val != undefined) {
      //       return /^\d+(\.\d{0,2})?$/.test(val);
      //     }
      //     return true;
      //   }
      // ),
      InvValue2: Yup.number()
        .max(2147483647, 'integer_max_limit_reached'),
      // .test(
      //   "is-decimal",
      //   "two_digits_allowed",
      //   (val: any) => {
      //     if (val != undefined) {
      //       return /^\d+(\.\d{0,2})?$/.test(val);
      //     }
      //     return true;
      //   }
      // ),
      // Tank: Yup.object({
      //   Value1: Yup.number()
      //     .required("field_required"),
      //   Type1: Yup.string()
      //     .required("field_required"),
      //   Value2: Yup.number()
      //     .required("field_required"),
      //   Type2: Yup.string()
      //     .required("field_required"),
      // })
    }))
}

export const editTransactionValidateSchema = (decimalValue: number) => {
  return (
    Yup.object({
      TsStart: Yup.date()
        .required("field_required"),
      TsFinish: Yup.date()
        .required("field_required")
        .min(Yup.ref("TsStart"), "ts_finish_must_be_greater"),
      // TsGen: Yup.date()
      //   .required("field_required"),
      // TsSend: Yup.date()
      //   .required("field_required")
      //   .min(Yup.ref("TsGen"), "ts_send_must_be_greater"),
      // ClientId: Yup.string()
      //   .required("field_required"),
      ProductId: Yup.string()
        .required("field_required"),
      DeviceId: Yup.string()
        .required("field_required"),
      TruckId: Yup.string()
        .required("field_required"),
      // OrderUuid:Yup.string()
      // .required("field_required"),
      TicketNumber: Yup.string()
        .required("field_required"),
      CustomerId1: Yup.string()
        .max(40, "limit_reached_40"),
      CustomerId2: Yup.string()
        .max(40, "limit_reached_40"),
      CustomerId3: Yup.string()
        .max(40, "limit_reached_40"),
      CustomerId4: Yup.string()
        .max(40, "limit_reached_40"),
      CustomerId5: Yup.string()
        .max(40, "limit_reached_40"),
      CustomerId6: Yup.string()
        .max(40, "limit_reached_40"),
      // ProductId:Yup.string()
      // .required("field_required"),
      Lat: Yup.number()
        .max(9999.99999999, 'lat_lng_max_limit_reached')
        .test(
          "is-decimal",
          "eight_digits_allowed",
          (val: any) => {
            if (val != undefined) {
              return /^\d+(\.\d{0,8})?$/.test(val);
            }
            return true;
          }
        ),
      Lon: Yup.number()
        .max(9999.99999999, 'lat_lng_max_limit_reached')
        .test(
          "is-decimal",
          "eight_digits_allowed",
          (val: any) => {
            if (val != undefined) {
              return /^\d+(\.\d{0,8})?$/.test(val);
            }
            return true;
          }
        ),
      EndGross: Yup.number()
        .max(2147483647, 'integer_max_limit_reached'),
      // .test(
      //   "is-decimal",
      //   "two_digits_allowed",
      //   (val: any) => {
      //     if (val != undefined) {
      //       return /^\d+(\.\d{0,2})?$/.test(val);
      //     }
      //     return true;
      //   }
      // ),
      FlowAvg: Yup.number()
        .max(2147483647, 'integer_max_limit_reached'),
      // .test(
      //   "is-decimal",
      //   "two_digits_allowed",
      //   (val: any) => {
      //     if (val != undefined) {
      //       return /^\d+(\.\d{0,2})?$/.test(val);
      //     }
      //     return true;
      //   }
      // ),
      FlowMax: Yup.number()
        .max(2147483647, 'integer_max_limit_reached'),
      // .test(
      //   "is-decimal",
      //   "two_digits_allowed",
      //   (val: any) => {
      //     if (val != undefined) {
      //       return /^\d+(\.\d{0,2})?$/.test(val);
      //     }
      //     return true;
      //   }
      // ),
      GrossDelivered: Yup.number()
        .required("field_required")
        .max(2147483647, 'integer_max_limit_reached'),
      // .test(
      //   "is-decimal",
      //   "two_digits_allowed",
      //   (val: any) => {
      //     if (val != undefined) {
      //       return /^\d+(\.\d{0,2})?$/.test(val);
      //     }
      //     return true;
      //   }
      // ),
      // PricePerL: Yup.number()
      //   .max(2147483647, 'integer_max_limit_reached')
      //   .test(
      //     "is-decimal",
      //     decimalValue === 2 ? "two_digits_allowed" : "three_digits_allowed",
      //     (val) => {
      //       if (val !== undefined && val !== null) {
      //         const pattern = new RegExp(`^\\d+(\\.\\d{0,${decimalValue}})?$`);
      //         return pattern.test(String(val));
      //       }
      //       return true;
      //     }
      //   ),
      // Vat: Yup.number()
      //   .max(100, "invalid_percent_range")
      //   .test(
      //     "is-decimal",
      //     "two_digits_allowed",
      //     (val) => {
      //       if (val !== undefined && val !== null) {
      //         const pattern = new RegExp(`^\\d+(\\.\\d{0,2})?$`);
      //         return pattern.test(String(val));
      //       }
      //       return true;
      //     }
      //   ),
      // AmountDue: Yup.number()
      //   .max(2147483647, 'integer_max_limit_reached')
      //   .test(
      //     "is-decimal",
      //     decimalValue === 2 ? "two_digits_allowed" : "three_digits_allowed",
      //     (val) => {
      //       if (val !== undefined && val !== null) {
      //         const pattern = new RegExp(`^\\d+(\\.\\d{0,${decimalValue}})?$`);
      //         return pattern.test(String(val));
      //       }
      //       return true;
      //     }
      //   ),
      VehicleRfid: Yup.string()
        .max(40, "rfid_limit_reached"),
      DriverRfid: Yup.string()
        .max(40, "rfid_limit_reached"),
      OperatorRfid: Yup.string()
        .max(40, "rfid_limit_reached"),
      MeterId: Yup.number()
        .max(2147483647, 'integer_max_limit_reached'),
      NetDelivered: Yup.number()
        .max(2147483647, 'integer_max_limit_reached'),
      // .test(
      //   "is-decimal",
      //   "two_digits_allowed",
      //   (val: any) => {
      //     if (val != undefined) {
      //       return /^\d+(\.\d{0,2})?$/.test(val);
      //     }
      //     return true;
      //   }
      // ),
      InvName1: Yup.string()
        .max(40, "limit_reached_40"),
      InvName2: Yup.string()
        .max(40, "limit_reached_40"),
      InvValue1: Yup.number()
        .max(2147483647, 'integer_max_limit_reached'),
      // .test(
      //   "is-decimal",
      //   "two_digits_allowed",
      //   (val: any) => {
      //     if (val != undefined) {
      //       return /^\d+(\.\d{0,2})?$/.test(val);
      //     }
      //     return true;
      //   }
      // ),
      InvValue2: Yup.number()
        .max(2147483647, 'integer_max_limit_reached'),
      // .test(
      //   "is-decimal",
      //   "two_digits_allowed",
      //   (val: any) => {
      //     if (val != undefined) {
      //       return /^\d+(\.\d{0,2})?$/.test(val);
      //     }
      //     return true;
      //   }
      // ),
      // Tank: Yup.object({
      //   Value1: Yup.number()
      //     .required("field_required"),
      //   Type1: Yup.string()
      //     .required("field_required"),
      //   Value2: Yup.number()
      //     .required("field_required"),
      //   Type2: Yup.string()
      //     .required("field_required"),

      // })
    }))
}
export const editTransactionCustomerIdsValidateSchema = Yup.object({
  CustomerId1: Yup.string()
    .max(40, "limit_reached_40"),
  CustomerId2: Yup.string()
    .max(40, "limit_reached_40"),
  CustomerId3: Yup.string()
    .max(40, "limit_reached_40"),
  CustomerId4: Yup.string()
    .max(40, "limit_reached_40"),
  CustomerId5: Yup.string()
    .max(40, "limit_reached_40"),
  CustomerId6: Yup.string()
    .max(40, "limit_reached_40"),
});

export const importCsvValidateSchema = Yup.object({
  ClientId: Yup.number()
    .min(1, "field_required"),
  CSVFile: Yup.mixed().nullable()
    .required('field_required')
    .test('csv', 'only_csv', (value: any) => {
      let val;
      if (value) {
        val = value.split('.');
        return val[1].toLowerCase() === "csv";
      }
      else return false
    }),
});

export const importVehicleCsvValidateSchema = Yup.object({
  // ClientId: Yup.number()
  //   .min(1, "field_required"),
  CSVFile: Yup.mixed().nullable()
    .required('field_required')
    .test('csv', 'only_csv', (value: any) => {
      let val;
      if (value) {
        val = value.split('.');
        return val[1].toLowerCase() === "csv";
      }
      else return false
    }),
});

export const SuperAdminDashboardFilterValidateSchema = Yup.object({
  ClientId: Yup.string()
    .notOneOf(["please_select"] as const, "field_required")
});

export const transactionReportValidateSchema = Yup.object({
  SearchId: Yup.string()
    .required("field_required").notOneOf(["plese_select"] as const, "field_required"),
  FileName: Yup.string()
    .required("field_required").max(50, 'max_limit_reached_50'),
  StartDate: Yup.date().required('Start date and time is required'),
  EndDate: Yup.date()
    .required('End date and time is required')
    .min(Yup.ref('StartDate'), 'End date and time must be later than start date and time'),

});

export const transactionPaymentReportValidateSchema = Yup.object({
  FileName: Yup.string()
    .required("field_required").max(30, 'max_limit_reached_30'),
  StartDate: Yup.date().required('Start date and time is required'),
  EndDate: Yup.date()
    .required('End date and time is required')
    .min(Yup.ref('StartDate'), 'End date and time must be later than start date and time'),

});

export const truckValidateSchema = Yup.object({
  // Name: Yup.string()
  //   .required("field_required"),
  DeviceId: Yup.string()
    .required("field_required")
    .max(50, 'max_limit_reached_50'),
  // ClientId: Yup.string()
  //   .required("field_required")
  TruckId: Yup.string()
    .required("field_required")
    .max(50, 'max_limit_reached_50'),
  // AccountId: Yup.string()
  //   .required("field_required")
  //   .max(50, 'max_limit_reached_50'),
  SimCard: Yup.string()
    .max(50, 'max_limit_reached_50'),
  Equipment: Yup.string()
    .required("field_required")
    .max(50, 'max_limit_reached_50'),
  AssetType: Yup.string()
    .max(50, 'max_limit_reached_50'),
  InstallationDate: Yup.date()
    .nullable(), // Allows null or empty string,
  ExpiryDate: Yup.date()
    // .required("field_required")
    .nullable() // Allows null or empty string
    .min(Yup.ref('InstallationDate'), 'install_date_must_be_greater'),
  IpAddress: Yup.string()
    .max(50, 'max_limit_reached_50')
    .test('is-valid-ip', 'Invalid IP address', (value) => {
      // Allow empty string
      if (!value || value === '') return true;
      // Validate IPv4 or IPv6
      return ipv4Regex.test(value) || ipv6Regex.test(value);
    })
});

export const truckFilterValidateSchema = Yup.object({
  TruckId: Yup.string()
    .required("field_required"),
});

export const dashboardFilterValidateSchema = Yup.object({
  TruckIds: Yup.string()
    .required("field_required"),
});

export const customerDashboardFilterValidateSchema = Yup.object({
  TruckIds: Yup.string()
    .notOneOf(["please_select"] as const, "field_required"),
});

export const pdfSettingValidateSchema = Yup.object({
  TicketTitle: Yup.string()
    .notOneOf(["please_select"] as const, "field_required"),
  Header1: Yup.string().max(40, 'max_limit_reached_40'),
  Header2: Yup.string().max(40, 'max_limit_reached_40'),
  Header3: Yup.string().max(40, 'max_limit_reached_40'),
  Header4: Yup.string().max(40, 'max_limit_reached_40'),
  Header5: Yup.string().max(40, 'max_limit_reached_40'),
  Header6: Yup.string().max(40, 'max_limit_reached_40'),
  CustomerId1Title: Yup.string().max(40, 'max_limit_reached_40'),
  CustomerId2Title: Yup.string().max(40, 'max_limit_reached_40'),
  CustomerId3Title: Yup.string().max(40, 'max_limit_reached_40'),
  CustomerId4Title: Yup.string().max(40, 'max_limit_reached_40'),
  CustomerId5Title: Yup.string().max(40, 'max_limit_reached_40'),
  CustomerId6Title: Yup.string().max(40, 'max_limit_reached_40'),
  VatInfo: Yup.string().max(40, 'max_limit_reached_40'),
  Footer1: Yup.string().max(40, 'max_limit_reached_40'),
  Footer2: Yup.string().max(40, 'max_limit_reached_40'),
});

export const paymentFilterValidateSchema = Yup.object({
  StartDate: Yup.date().required('start_date_error'),
  EndDate: Yup.date()
    .required("end_date_error")
    .min(Yup.ref('StartDate'), 'end_date_later_error'),
});

export const addEditSupplierValidateSchema = (decimalValue: number) => {
  return (
    Yup.object({
      SupplierName: Yup.string()
        .required("field_required")
        .min(3, 'name_length_required')
        .max(30, 'name_length_required'),
      ContactPersonPhoneNo: Yup.string()
        .max(30, 'phone_length_required')
        .min(1, "phone_length_required"),
      // .matches(
      //   /^[- +()]*[- +()0-9]*$/,
      //   "invalid_phone_no"
      // ),
      ContactPersonName: Yup.string()
        .max(30, 'max_name_length_required'),
      ContactPersonEmail: Yup.string()
        .matches(
          /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
          "email_validation_invalid"
        ),
      Address: Yup.string()
        // .required("field_required")
        .max(200, "address_max_limit_reached"),
      PhoneNo: Yup.string()
        // .required("field_required")
        .max(30, 'phone_length_required')
        .min(1, "phone_length_required"),
      // .matches(
      //   /^[- +()]*[- +()0-9]*$/,
      //   "invalid_phone_no"
      // ),
      PricePerL: Yup.number()
        .required("field_required")
        .max(2147483647, 'integer_max_limit_reached')
        .test(
          "is-decimal",
          decimalValue === 2 ? "two_digits_allowed" : "three_digits_allowed",
          (val) => {
            if (val !== undefined && val !== null) {
              const pattern = new RegExp(`^\\d+(\\.\\d{0,${decimalValue}})?$`);
              return pattern.test(String(val));
            }
            return true;
          }
        ),
      PricePerGallon: Yup.number()
        .required("field_required")
        .max(2147483647, 'integer_max_limit_reached')
        .test(
          "is-decimal",
          decimalValue === 2 ? "two_digits_allowed" : "three_digits_allowed",
          (val) => {
            if (val !== undefined && val !== null) {
              const pattern = new RegExp(`^\\d+(\\.\\d{0,${decimalValue}})?$`);
              return pattern.test(String(val));
            }
            return true;
          }
        ),
      TaxRegistrationNo: Yup.string()
        .max(15, "max_limit_reached_15"),
    })
  )
}