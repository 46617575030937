/*    
<summary>
  This functional component "AddUpdateDriver" defines the props like initial values and functions for the child form component.
  Developer:Aashish Singh, Created Date:04-March-2024
</summary>
<returns>Returns JSX</returns>
*/

import { observer } from 'mobx-react';
import React, { useCallback, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useStore } from '../../../contexts/StoreProvider';
import { IAddEditDriver } from '../../../models/response/IDriverResponse';
import { formatMessage } from '../../../translations/formatMessage';
import AddUpdateDriverForm from '../forms/add-edit-driver-form';
import { allocationPeriodTypes } from '../../../constants/common-constant';
import { getClientLimitSetting, getEnableLimitSetting } from '../../../helpers/localStorages.helper';

interface IProps {
  id: number;
  modalClosed: () => void;
}

const AddUpdateDriver = (props: IProps) => {

  const { driverStore, customerStore } = useStore();
  const { GetDriverByIdService, resetAddUpdateDriverState, resetGetDriverDetail, driverDetailState, getDriverDetail, addUpdateDriverState, AddDriverService, UpdateDriverService } = driverStore;
  const { allAvailableCustomerOptionsWithLimit } = customerStore;
  const initialValues: IAddEditDriver = getDriverDetail;
  const { id, modalClosed } = props
  let clientSetting = getEnableLimitSetting();

  const onModalClosed = useCallback(() => {
    resetGetDriverDetail();
    resetAddUpdateDriverState();
    modalClosed();
  }, [resetGetDriverDetail, resetAddUpdateDriverState, modalClosed])

  useEffect(() => {
    if (id > 0) {
      GetDriverByIdService(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (addUpdateDriverState.success) {
      if (id === -1)
        toast.success(formatMessage("added_success"));
      else
        toast.success(formatMessage("updated_success"));
      onModalClosed();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addUpdateDriverState.success]);

  // useEffect(() => {
  //   if (driverDetailState.error) {
  //     toast.error(formatMessage(driverDetailState.error));
  //     resetGetDriverDetail();
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [driverDetailState.error]);

  useEffect(() => {
    if (addUpdateDriverState.error) {
      toast.error(formatMessage(addUpdateDriverState.error));
      resetAddUpdateDriverState();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addUpdateDriverState.error]);

  const onSubmitForm = (values: IAddEditDriver) => {
    if (id > 0)
      UpdateDriverService(id, values);
    else
      AddDriverService(id, values);
  }

  return (
    <React.Fragment>
      <AddUpdateDriverForm
        initialValues={initialValues}
        isLimitSettingEnabled={clientSetting}
        id={id}
        submitHandler={onSubmitForm}
        onModalClosed={onModalClosed}
        getDataInProgress={driverDetailState.inProgress}
        addUpdateInProgress={addUpdateDriverState.inProgress}
        allocationPeriodTypes={allocationPeriodTypes}
        customerIdList={allAvailableCustomerOptionsWithLimit}
      />
    </React.Fragment>
  );
};

export default observer(AddUpdateDriver);
