/*    
<summary>
   This class component is all about Managing customer data functionality.
   Developer:Aashish Singh, Created Date:20-March-2024
</summary>
<param>No Parameter Passed</param>
<returns>Returns class instance</returns>
*/

import { makeObservable, observable, action, computed } from "mobx";
import IApiResponse, { IApiSuccessResponse } from "../../models/response/IApiResponse";
import { getCustomerListState } from "../initialState/get-customer-list-state";
import { addCustomerState, addEditCustomerState as initialState } from "../initialState/add-edit-customer-state";
import UrlConstants from "../../constants/url.constant";
import * as services from '../service/base-service';
import { IObservableInitialState, IOption, IOptionWithLimit } from "../../models/ICommon";
import { demoCustomer } from "./data";
import moment from "moment";
import { DATE_FORMAT_BACKEND, DATE_TIME_FORMAT_FILENAME, DATE_TIME_FORMAT_TO_BACKEND, emptyValue, roundWithPrecision } from "../../constants/common-constant";
import { IGetCustomerList, ICustomer, ICustomerList, IAddEditCustomer, IPDFSetting, IAddCustomerRate, IGetCustomerRate } from "../../models/response/ICustomerResponse";
import { formatMessage } from "../../translations/formatMessage";
import { toast } from "react-toastify";
import { getAmountDecimal, getClientId } from "../../helpers/localStorages.helper";
import { ICustomerState } from "../../models/state/ICustomerState";
import { ICustomerInitialFilterState, ICustomerFilter, IInvoiceDetailFilter, IInvoiceDetailInitialFilterState } from "../../models/IFilter";
import { customerInitialFilterState, invoiceDetailInitialFilterState } from "../initialState/initial-filter-states";
import { addEditPDFSettingState } from "../initialState/add-edit-setting-state";
import { getInvoiceDetailtListState } from "../initialState/get-invoice-detail-list-state";


export class CustomerStore implements ICustomerState {
    inProgress = false;
    error = '';
    customerList: IGetCustomerList = getCustomerListState;
    customerData: ICustomer[] = [];

    initialStateValue: IObservableInitialState = {
        success: false,
        error: '',
        inProgress: false
    }
    customerRateList: IGetCustomerRate = { ...getInvoiceDetailtListState };
    customerRateState: IAddCustomerRate = addCustomerState;
    addUpdateCustomerState: IObservableInitialState = { ...this.initialStateValue };
    addCustomerRateState: IObservableInitialState = { ...this.initialStateValue };
    addCustomerRateStateSuccessMessage: string = "";
    getCustomerRateListState: IObservableInitialState = { ...this.initialStateValue };
    DeleteCustomerState: IObservableInitialState = { ...this.initialStateValue };
    deleteCustomerRateState = { ...this.initialStateValue }

    customerDetail: ICustomer | undefined = undefined;
    totalCustomer: number = 0;
    customerDetailState: IObservableInitialState = { ...this.initialStateValue };

    settingDetail: IPDFSetting | undefined = undefined;
    settingDetailState: IObservableInitialState = { ...this.initialStateValue };

    addUpdateSettingState: IObservableInitialState = { ...this.initialStateValue };

    filter: ICustomerFilter = {
        ...customerInitialFilterState
    }

    pricingFilter: IInvoiceDetailFilter = {
        ...invoiceDetailInitialFilterState
    }

    constructor() {
        makeObservable(this, {
            inProgress: observable,
            error: observable,
            customerList: observable,
            addUpdateCustomerState: observable,
            addUpdateSettingState: observable,
            DeleteCustomerState: observable,
            customerDetail: observable,
            customerDetailState: observable,
            settingDetail: observable,
            settingDetailState: observable,
            filter: observable,
            customerData: observable,
            addCustomerRateState: observable,
            addCustomerRateStateSuccessMessage: observable,
            getCustomerRateListState: observable,
            deleteCustomerRateState: observable,
            customerRateList: observable,
            customerRateState: observable,
            pricingFilter: observable,
            totalCustomer: observable,
            GetAllCustomerService: action,
            AddCustomerRateService: action,
            DeleteCustomerRateService: action,
            GetCustomerRateListService: action,
            resetDeleteCustomerRateState: action,
            resetGetCustomerRateListState: action,
            resetAddCustomerRateState: action,
            GetCustomerListService: action,
            GetCustomerByIdService: action,
            GetPDFSettingByIdService: action,
            AddCustomerService: action,
            UpdateCustomerService: action,
            UpdatePDFSettingService: action,
            DeleteCustomerService: action,
            reset: action,
            resetStore: action,
            resetGetCustomerDetail: action,
            resetGetSettingDetailState: action,
            resetAddUpdateSettingState: action,
            setFilterDetail: action,
            setPricingFilterDetail: action,
            resetAddUpdateCustomerState: action,
            resetDeleteCustomerState: action,
            getCustomerDetail: computed,
            getSettingDetail: computed,
            getCustomerList: computed,
            allAvailableCustomerOptions: computed,
            getCustomerRateListData: computed,
            getcustomerRateState: computed,
            allAvailableCustomerOptionsWithLimit: computed,
        });
    }

    GetAllCustomerService = () => {
        this.inProgress = true;
        const clientIdFromLocalStorage = getClientId() != null ? getClientId() : -1;
        const url = `${UrlConstants.GetAllCustomer}?clientId=${clientIdFromLocalStorage}`;
        return services.get(url)
            .then((response: IApiResponse<IApiSuccessResponse<any>>) => {
                this.customerData = response.data.Data.CustomerAdminList;
                this.totalCustomer = response.data.Data.TotalCustomers;
            })
            .catch((err: string) => {
                this.error = err;
            })
            .finally(action(() => { this.inProgress = false; }));
    }

    get allAvailableCustomerOptions(): IOption[] {
        const options: IOption[] = [
            {
                Id: -1,
                Name: "please_select",
            }
        ];
        if (this.customerData && this.customerData?.length > 0)
            this.customerData.map((customer) => {
                options.push({
                    Id: customer.CustomerId,
                    Name: customer.Name,
                })
            })
        return options;
    }
    get allAvailableCustomerOptionsWithLimit(): IOptionWithLimit[] {
        const options: IOptionWithLimit[] = [
            {
                Id: -1,
                Name: "please_select",
                EnableLimitSettings: true
            }
        ];
        if (this.customerData && this.customerData?.length > 0)
            this.customerData.map((customer) => {
                options.push({
                    Id: customer.CustomerId,
                    Name: customer.Name,
                    EnableLimitSettings: customer.EnableLimitSettings
                })
            })
        return options;
    }

    GetCustomerListService = (currentPage: number, pagerSize: number, orderBy: string, column: string, clientId: number, filter: ICustomerInitialFilterState) => {
        this.inProgress = true;
        let requestBody = {
            ...filter, IsActive: filter.IsActive == "2" ? null : (filter.IsActive == "1" ? false : true)
        }
        const clientIdFromLocalStorage = getClientId() != null ? getClientId() : -1;
        // const url = UrlConstants.User + '?pageNo=' + currentPage + '&pageSize=' + pagerSize + '&orderBy=' + orderBy + '&column=' + column + '&searchText=' + searchText + '&clientId=' + clientId;
        const url = `${UrlConstants.GetCustomerList}?clientId=${clientIdFromLocalStorage}`;
        // this.customerList = demoCustomer;
        // this.inProgress = false;
        return services.post(url, { ...requestBody })
            .then((response: IApiResponse<IApiSuccessResponse<any>>) => {
                this.customerList = { ...getCustomerListState, Customers: response.data.Data.CustomerAdminList };
                this.totalCustomer = response.data.Data.TotalCustomers;
            })
            .catch((err: string) => {
                this.error = err;
            })
            .finally(action(() => { this.inProgress = false; }));
    }

    get getCustomerList(): ICustomerList[] {
        let decimalValue = getAmountDecimal();
        if (this.customerList?.Customers && this.customerList.Customers?.length > 0) {
            return this.customerList.Customers.map((customer: ICustomer) => {
                let IsDelete: boolean = true;
                let IsEdit: boolean = true;
                return {
                    ...customer,
                    // MaxVolumeLimit: String(customer.MaxVolumeLimit !== null ? Number(customer.MaxVolumeLimit).toFixed(4) : ""),
                    MaxVolumeLimit: String(customer.MaxVolumeLimit !== null ? Number(customer.MaxVolumeLimit).toFixed(4) : ""),
                    RemainingVolume: customer.RemainingVolume !== null ? Number(customer.RemainingVolume.toFixed(4)) : 0,
                    PricePerL: customer.PricePerL !== null ? Number(customer.PricePerL).toFixed(decimalValue) : emptyValue,
                    PricePerGallon: customer.PricePerGallon !== null ? Number(customer.PricePerGallon).toFixed(decimalValue) : emptyValue,
                    EnableLimitSettings: customer.EnableLimitSettings,
                    Id: customer.CustomerId,
                    IsDeletable: IsDelete,
                    IsEditable: IsEdit,
                }
            })
        }
        return [];
    }

    GetCustomerByIdService = (id: number) => {
        this.customerDetailState.inProgress = true;
        const clientIdFromLocalStorage = getClientId() != null ? getClientId() : -1;
        const url = `${UrlConstants.GetCustomerById}?id=${id}&clientId=${clientIdFromLocalStorage}`;
        // const tempDetail = this.customerList.Customers.filter((d:ICustomer)=>d.CustomerId === id);
        // this.customerDetail = tempDetail.length > 0 ? {...tempDetail[0]} : undefined;
        return services.get(url)
            .then((response: IApiResponse<IApiSuccessResponse<any>>) => {
                this.customerDetail = response.data.Data.CustomerAdmin;
            })
            .catch((err: string) => {
                toast.error(formatMessage(err));
                // this.customerDetailState.error = err;
            }).finally(action(() => { this.customerDetailState.inProgress = false; }));
    }

    get getCustomerDetail(): IAddEditCustomer {
        if (this.customerDetail) {
            return {
                ...this.customerDetail,
                Name: this.customerDetail.Name !== null ? this.customerDetail.Name : "",
                PhoneNo: this.customerDetail.PhoneNo !== null ? this.customerDetail.PhoneNo : "",
                ContactPersonName: this.customerDetail.ContactPersonName !== null ? this.customerDetail.ContactPersonName : "",
                ContactPersonPhoneNo: this.customerDetail.ContactPersonPhoneNo !== null ? this.customerDetail.ContactPersonPhoneNo : "",
                ContactPersonEmail: this.customerDetail.ContactPersonEmail !== null ? this.customerDetail.ContactPersonEmail : "",
                City: this.customerDetail.City !== null ? this.customerDetail.City : "",
                State: this.customerDetail.State !== null ? this.customerDetail.State : "",
                Country: this.customerDetail.Country !== null ? this.customerDetail.Country : "",
                Address: this.customerDetail.Address !== null ? this.customerDetail.Address : "",
                CustomerAdminName: this.customerDetail.CustomerAdminName !== null ? this.customerDetail.CustomerAdminName : "",
                Email: this.customerDetail.Email !== null ? this.customerDetail.Email : "",
                CurrencyName: this.customerDetail.CurrencyName !== null ? this.customerDetail.CurrencyName : "",
                MaxAmountLimit: String(this.customerDetail.MaxAmountLimit !== null ? this.customerDetail.MaxAmountLimit : ""),
                MaxVolumeLimit: String(this.customerDetail.MaxVolumeLimit !== null ? Number(this.customerDetail.MaxVolumeLimit).toFixed(4) : ""),
                ThresholdVolumeLimit: String(this.customerDetail.ThresholdVolumeLimit !== null ? this.customerDetail.ThresholdVolumeLimit : ""),
                // AllocationPeriod: this.customerDetail.AllocationPeriod !== null ? this.customerDetail.AllocationPeriod : 2,
                RemainingVolume: this.customerDetail.RemainingVolume !== null ? Number(this.customerDetail.RemainingVolume.toFixed(4)) : 0,
                UpdatedAt: this.customerDetail?.UpdatedAt ? moment(this.customerDetail?.UpdatedAt).format(DATE_TIME_FORMAT_TO_BACKEND) : "",
                TaxRegistrationNo: this.customerDetail.TaxRegistrationNo !== null ? this.customerDetail.TaxRegistrationNo : "",
                EnableLimitSettings: this.customerDetail.EnableLimitSettings
                // PricePerL: this.customerDetail.PricePerL !== null ? this.customerDetail.PricePerL : "",
                // PricePerGallon: this.customerDetail.PricePerGallon !== null ? this.customerDetail.PricePerGallon : "",
                // Vat: this.customerDetail.Vat !== null ? this.customerDetail.Vat : "",
            };
        }
        return initialState;
    }

    resetGetCustomerDetail = () => {
        this.customerDetail = undefined;
        this.customerDetailState = { ...this.initialStateValue };
    }

    GetPDFSettingByIdService = () => {
        this.settingDetailState.inProgress = true;
        const clientIdFromLocalStorage = getClientId() != null ? getClientId() : -1;
        const url = `${UrlConstants.GetPdfSettingById}?clientId=${clientIdFromLocalStorage}`;
        return services.get(url)
            .then((response: IApiResponse<IApiSuccessResponse<any>>) => {
                this.settingDetail = response.data.Data;
            })
            .catch((err: string) => {
                toast.error(formatMessage(err));
                // this.settingDetailState.error = err;
            }).finally(action(() => { this.settingDetailState.inProgress = false; }));
    }

    get getSettingDetail(): IPDFSetting {
        if (this.settingDetail) {
            return {
                ...this.settingDetail,
                CustomerId1Title: this.settingDetail.CustomerId1Title ? this.settingDetail.CustomerId1Title : "Customer ID1",
                CustomerId2Title: this.settingDetail.CustomerId2Title ? this.settingDetail.CustomerId2Title : "Customer ID2",
                CustomerId3Title: this.settingDetail.CustomerId3Title ? this.settingDetail.CustomerId3Title : "Customer ID3",
                CustomerId4Title: this.settingDetail.CustomerId4Title ? this.settingDetail.CustomerId4Title : "Customer ID4",
                CustomerId5Title: this.settingDetail.CustomerId5Title ? this.settingDetail.CustomerId5Title : "Customer ID5",
                CustomerId6Title: this.settingDetail.CustomerId6Title ? this.settingDetail.CustomerId6Title : "Customer ID6",
                TicketTitle: this.settingDetail.TicketTitle?.replace(" ", "_"),
            };
        }
        return addEditPDFSettingState;
    }

    resetGetSettingDetailState = () => {
        this.settingDetail = undefined;
        this.settingDetailState = { ...this.initialStateValue };
    }

    AddCustomerService = (id: number, data: IAddEditCustomer) => {
        let requestBody = {
            Name: data.Name,
            PhoneNo: data.PhoneNo,
            ContactPersonName: data.ContactPersonName,
            ContactPersonPhoneNo: data.ContactPersonPhoneNo,
            ContactPersonEmail: data.ContactPersonEmail,
            City: data.City,
            State: data.State,
            Country: data.Country,
            Address: data.Address,
            IsActive: data.IsActive,
            CustomerAdminName: data.CustomerAdminName,
            Email: data.Email,
            CurrencyName: data.CurrencyName,
            TaxRegistrationNo: data.TaxRegistrationNo,
            EnableLimitSettings: data.EnableLimitSettings,
            MaxAmountLimit: Number(data.MaxAmountLimit ? data.MaxAmountLimit : 0),
            MaxVolumeLimit: Number(data.MaxVolumeLimit ? data.MaxVolumeLimit : 0),
            ThresholdVolumeLimit: Number(data.ThresholdVolumeLimit ? data.ThresholdVolumeLimit : 0),
            // AllocationPeriod: data.AllocationPeriod,
            PricePerL: Number(data.PricePerL),
            PricePerGallon: Number(data.PricePerGallon),
            Vat: Number(data.Vat),
            EffectiveDate: moment(new Date).format(DATE_FORMAT_BACKEND) + "T00:00:00.000Z",
        }
        this.addUpdateCustomerState.inProgress = true;
        const clientIdFromLocalStorage = getClientId() != null ? getClientId() : -1;
        let url = `${UrlConstants.AddCustomer}?clientId=${clientIdFromLocalStorage}`;
        return services.post(url, { ...requestBody })
            .then(() => {
                this.addUpdateCustomerState.success = true;
            })
            .catch((err: string) => {
                this.addUpdateCustomerState.error = err;
            })
            .finally(action(() => { this.addUpdateCustomerState.inProgress = false; }));
    }

    UpdateCustomerService = (id: number, data: IAddEditCustomer) => {
        if (!data.PricePerL || !data.PricePerGallon) {
            toast.warning(formatMessage("Please add price per liter or price per gallon"));
        }
        let requestBody = {
            Name: data.Name,
            PhoneNo: data.PhoneNo,
            ContactPersonName: data.ContactPersonName,
            ContactPersonPhoneNo: data.ContactPersonPhoneNo,
            ContactPersonEmail: data.ContactPersonEmail,
            City: data.City,
            State: data.State,
            Country: data.Country,
            Address: data.Address,
            IsActive: data.IsActive,
            CustomerAdminName: data.CustomerAdminName,
            Email: data.Email,
            CurrencyName: data.CurrencyName,
            EnableLimitSettings: data.EnableLimitSettings,
            MaxAmountLimit: Number(data.MaxAmountLimit ? data.MaxAmountLimit : 0),
            MaxVolumeLimit: Number(data.MaxVolumeLimit ? data.MaxVolumeLimit : 0),
            ThresholdVolumeLimit: Number(data.ThresholdVolumeLimit ? data.ThresholdVolumeLimit : 0),
            RemainingVolume: Number(data.RemainingVolume),
            // AllocationPeriod: data.AllocationPeriod,
            TaxRegistrationNo: data.TaxRegistrationNo,
            // PricePerL: Number(data.PricePerL),
            // PricePerGallon: Number(data.PricePerGallon),
            // Vat: Number(data.Vat),
        }
        this.addUpdateCustomerState.inProgress = true;
        const clientIdFromLocalStorage = getClientId() != null ? getClientId() : -1;
        let url = `${UrlConstants.UpdateCustomer}?id=${id}&clientId=${clientIdFromLocalStorage}`;
        // let url = `${UrlConstants.UpdateCustomer}?clientId=${clientIdFromLocalStorage}`;
        return services.put(url, { ...requestBody })
            .then(() => {
                this.addUpdateCustomerState.success = true;
            })
            .catch((err: string) => {
                this.addUpdateCustomerState.error = err;
            })
            .finally(action(() => { this.addUpdateCustomerState.inProgress = false; }));
    }

    resetAddUpdateCustomerState = () => {
        this.addUpdateCustomerState = { ...this.initialStateValue }
    }

    UpdatePDFSettingService = (data: IPDFSetting) => {
        this.addUpdateSettingState.inProgress = true;
        const clientIdFromLocalStorage = getClientId() != null ? getClientId() : -1;
        let url = `${UrlConstants.AddPdfSettings}?clientId=${clientIdFromLocalStorage}`;
        return services.put(url, { ...data })
            .then(() => {
                this.addUpdateSettingState.success = true;
            })
            .catch((err: string) => {
                this.addUpdateSettingState.error = err;
            })
            .finally(action(() => { this.addUpdateSettingState.inProgress = false; }));
    }

    resetAddUpdateSettingState = () => {
        this.addUpdateSettingState = { ...this.initialStateValue }
    }

    DeleteCustomerService = (id: number) => {
        this.DeleteCustomerState.inProgress = true;
        const clientIdFromLocalStorage = getClientId() != null ? getClientId() : -1;
        const url = `${UrlConstants.DeleteCustomer}?id=${id}&clientId=${clientIdFromLocalStorage}`;
        return services.deleteapi(url)
            .then(() => {
                this.DeleteCustomerState.success = true;
            })
            .catch((err: string) => {
                this.DeleteCustomerState.error = err;
            })
            .finally(action(() => { this.DeleteCustomerState.inProgress = false; }));
    }

    resetDeleteCustomerState = () => {
        this.DeleteCustomerState = { ...this.initialStateValue }
    }

    reset = () => {
        this.error = '';
        this.inProgress = false;
    }

    resetError = () => {
        this.error = '';
    }

    /**
     * This function is used to reset all store observables to their initial values.
     * @returns
     */
    resetStore = () => {
        this.error = '';
        this.inProgress = false;
        this.customerList = getCustomerListState;

        this.customerDetail = undefined;
        this.customerDetailState = { ...this.initialStateValue }

        this.addUpdateCustomerState = { ...this.initialStateValue }

        this.DeleteCustomerState = { ...this.initialStateValue }
    }

    /**
     * This function is used to convert customer list data to the corresponding CSV format
     * @param data : Customer List data
     * @returns csvData suitable for creating blob object
     */
    convertToCSV = (data: any[]): string => {
        const header = Object.keys(data[0]).join(",");
        const rows = data.map((obj) => Object.values(obj).join(","));
        return `${header}\n${rows.join("\n")}`;
    };

    /**
     * This function downloads the CSV by dynamically making an <a> tag. 
     * @param data : Customer List data
     */
    downloadCustomerCSV = (data: ICustomerList[]) => {
        const currentDate = new Date();
        if (data.length > 0) {
            const csvData = this.convertToCSV(this.exportCustomerCSV(data));
            const blob = new Blob([csvData], { type: "text/csv" });
            const url = URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            a.download = moment(currentDate).format(DATE_TIME_FORMAT_FILENAME) + " Customers.csv";
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        }
        else
            toast.warn(formatMessage("no_data_to_export"));
    };

    /**
     * This function is used to convert the customerDetailsList to the required data suitable for convertToCSV function.
     * @param customerDataList : Customer List
     * @returns CSV data suitable for convertToCSV function
     */
    exportCustomerCSV(customerDataList: any): ICustomerList[] {
        if (customerDataList && customerDataList?.length > 0)
            return customerDataList?.map((customer: ICustomer) => {
                return {
                    CustomerId: customer.CustomerId,
                    Name: customer.Name,
                    PhoneNo: customer.PhoneNo,
                    ContactPersonName: customer.ContactPersonName,
                    ContactPersonPhoneNo: customer.ContactPersonPhoneNo,
                    ContactPersonEmail: customer.ContactPersonEmail,
                    City: customer.City,
                    State: customer.State,
                    Country: customer.Country,
                    Address: customer.Address,
                    IsActive: customer.IsActive,
                    CustomerAdminName: customer.CustomerAdminName,
                    Email: customer.Email,
                }
            })
        return [];
    }

    AddCustomerRateService = (id: number, data: IAddCustomerRate) => {
        this.addCustomerRateState.inProgress = true;
        const clientIdFromLocalStorage = getClientId() != null ? getClientId() : -1;
        let url = `${UrlConstants.AddCustomerRate}?clientId=${clientIdFromLocalStorage}`;
        return services.post(url, { ...data })
            .then((response) => {
                this.addCustomerRateState.success = true;
                this.addCustomerRateStateSuccessMessage = response.data.Data;
            })
            .catch((err: string) => {
                this.addCustomerRateState.error = err;
            })
            .finally(action(() => { this.addCustomerRateState.inProgress = false; }));
    }

    get getcustomerRateState() {
        return { ...this.customerRateState }
    }

    resetAddCustomerRateState = () => {
        this.addCustomerRateState = { ...this.initialStateValue }
        this.addCustomerRateStateSuccessMessage = "";
        this.customerRateState = addCustomerState;
    }

    GetCustomerRateListService = (customerIdLong: number, customerId: string, currentPage: number, pagerSize: number, orderBy: string, column: string, filter: IInvoiceDetailInitialFilterState) => {
        this.getCustomerRateListState.inProgress = true;
        const clientIdFromLocalStorage = getClientId() != null ? getClientId() : -1;
        // const url = UrlConstants.User + '?pageNo=' + currentPage + '&pageSize=' + pagerSize + '&orderBy=' + orderBy + '&column=' + column + '&searchText=' + searchText + '&clientId=' + clientId;
        const url = `${UrlConstants.GetAllCustomerRate}?clientId=${clientIdFromLocalStorage}&pageNo=${currentPage}&pageSize=${pagerSize}&customerIdLong=${customerIdLong}&customerId=${customerId}`;
        let startDateData = filter.StartDate ? moment(filter.StartDate).format("YYYY-MM-DD") : null;
        let endDateData = filter.EndDate ? moment(filter.EndDate).format("YYYY-MM-DD") : null;
        const tempStartDate = filter.StartDate ? startDateData + "T00:00:00Z" : null;
        const tempEndDate = filter.EndDate ? endDateData + "T23:59:59Z" : null;
        let requestBody = { ...filter, StartDate: tempStartDate, EndDate: tempEndDate };
        return services.post(url, { ...requestBody })
            .then((response: IApiResponse<IApiSuccessResponse<any>>) => {
                this.customerRateList = response.data.Data;
            })
            .catch((err: string) => {
                this.getCustomerRateListState.error = err;
            })
            .finally(action(() => { this.getCustomerRateListState.inProgress = false; }));
    }

    resetGetCustomerRateListState = () => {
        this.getCustomerRateListState = { ...this.initialStateValue }
    }

    get getCustomerRateListData() {
        let decimalValue = getAmountDecimal();
        return this.customerRateList.CustomerRate.map((value) => {
            return {
                ...value,
                RateUkg: Number(value.RateUkg).toFixed(decimalValue),
                RateLtr: Number(value.RateLtr).toFixed(decimalValue),
                Vat: value.Vat,
                EffectiveDate: moment(value.EffectiveDate).format(DATE_FORMAT_BACKEND),
            }
        })
    }

    DeleteCustomerRateService = (id: number) => {
        this.deleteCustomerRateState.inProgress = true;
        const clientIdFromLocalStorage = getClientId() != null ? getClientId() : -1;
        // const url = UrlConstants.User + '?pageNo=' + currentPage + '&pageSize=' + pagerSize + '&orderBy=' + orderBy + '&column=' + column + '&searchText=' + searchText + '&clientId=' + clientId;
        const url = `${UrlConstants.DeleteCustomerRate}?clientId=${clientIdFromLocalStorage}&id=${id}`;
        return services.deleteapi(url)
            .then((response: IApiResponse<IApiSuccessResponse<any>>) => {
                this.deleteCustomerRateState.success = true;
            })
            .catch((err: string) => {
                this.deleteCustomerRateState.error = err;
            })
            .finally(action(() => { this.deleteCustomerRateState.inProgress = false; }));
    }

    resetDeleteCustomerRateState = () => {
        this.deleteCustomerRateState = { ...this.initialStateValue }
    }
    /*
    This function is used to set the filter details to the billingFilter observable.  
    */
    setFilterDetail = (filter: ICustomerFilter) => {
        this.filter = { ...filter };
    }

    setPricingFilterDetail = (pricingfilter: IInvoiceDetailFilter) => {
        this.pricingFilter = { ...pricingfilter };
    }
    /*
    This function is used to download CSVFile.  
    we can use this code when we get the csv data from API
    */
    // CSVFileDownloadService = () => {
    //     const url = URLConstants.DownloadCustomer;
    //     const token = new AuthStore().getToken;
    //     this.csvDownloadState.inProgress = true;
    //     axiosDownload({
    //         url: baseURL + url,
    //         method: 'GET',
    //         responseType: 'blob',
    //         headers: {
    //             'authorization': 'Bearer ' + token
    //         }
    //     }).then((response: any) => {
    //         const url = window.URL.createObjectURL(new Blob([response.data]));
    //         const link = document.createElement('a');
    //         link.href = url;
    //         link.setAttribute('download', 'Customer_' + moment(Date.now()).format(DATE_TIME_FORMAT_FILENAME) + '.csv');
    //         document.body.appendChild(link);
    //         link.click();
    //         toast.success(formatMessage("downloaded_started_successfully"));
    //     }).catch((err: any) => {
    //         toast.error(formatMessage(errorMessage.ErrorOccured));
    //     })
    //         .finally(action(() => { this.csvDownloadState.inProgress = false; }));
    // }
}

export default new CustomerStore();