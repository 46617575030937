/*    
<summary>
  This functional component "AboutSupport" provides About/Support info.
  Developer:Aashish Singh, Created Date:05-April-2024
</summary>
<returns>Returns JSX</returns>
*/
import React from "react";
import { Modal } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { formatMessage } from "../../../translations/formatMessage";
import { getVersion } from "../../../helpers/localStorages.helper";
import "./about-support.scss";
import { Container, Row, Col, } from "react-bootstrap";
import logo from "../../../assets/Images/secureFuelLogo_black.png";
import circleAbout from "../../../assets/Images/circle-about.png";
import DraggableModal from "../../Draggable/DraggableModal";
interface IProps {
  modalClosed: () => void;
}

const AboutSupport = (props: IProps) => {
  const { modalClosed } = props;

  return (
    <>
      <Modal className="add-user-modal modal-about" show={true} centered dialogAs={DraggableModal}>
        <Modal.Header>
          <Modal.Title>
            <FormattedMessage id="about_support_title" />
          </Modal.Title>
          <div onClick={modalClosed} className="icons8-close">
            <span className="material-symbols-outlined fs-5 text-dark">
              close
            </span>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="supportWrapper">
            <Row>
              <Col lg="6" md="6" sm="6" >
                <div className="pb-5 logo-support mt-5">
                  <img src={logo} alt="logo" /> <br />
                  <div className="about-content">
                    <h5 className="version mt-3"> Version: {getVersion()}</h5>
                    {/* <div className="d-flex align-items-center pb-3">
                      <div>
                        <div className="green circle">
                          <span className="material-symbols-outlined text-white">
                            call
                          </span>
                        </div>
                      </div>

                      <div className="ps-3">
                        <h6 className="text-muted mb-0">Support Contact No</h6>
                        <p className="mb-0 text-break">78367895134</p>
                      </div>
                    </div>
                    <div className="d-flex align-items-center">
                      <div>
                      <div className="purple circle">
                          <span className="material-symbols-outlined text-white">
                            mail
                          </span>
                        </div>
                      </div>

                      <div className="ps-3">
                        <h6 className="text-muted mb-0">Support Email Id</h6>
                        <p className="mb-0 text-break">securefuel@gmail.com</p>
                      </div>
                    </div> */}
                  </div>
                </div>
              </Col>
              <Col lg="6">
                <div className="about-image pe-5">
                  <img
                    src={circleAbout}
                    alt="circleAbout"
                    className="circleLogo"
                  />
                </div>
              </Col>
            </Row>
          </div>
          {/* <img src={About} alt="AboutSupport" /> */}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AboutSupport;
