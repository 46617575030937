import moment from "moment"
import { IAlarmFilter, IClientFilter, ICustomerFilter, IDriverFilter, IInvoiceDetailFilter, IPaymentFilter, ISupplierFilter, ITransactionFilter, ITruckFilter, IUserFilter, IVehicleFilter } from "../../models/IFilter"

export const clientInitialFilterState: IClientFilter = {
    City: null,
    ContactPersonName: null,
    IsActive: "2",
    ContactPersonEmail: null,
    UserName: null,
    isFilterActive: false,
}

export const customerInitialFilterState: ICustomerFilter = {
    City: null,
    ContactPersonName: null,
    IsActive: "2",
    ContactPersonEmail: null,
    UserName: null,
    isFilterActive: false,
}

export const userInitialFilterState: IUserFilter = {
    UserName: null,
    Email: null,
    IsActive: "2",
    UserType: -1,
    isFilterActive: false,
}

export const transactionInitialFilterState: ITransactionFilter = {
    TicketNumber: null,
    StartDate: new Date(new Date().setHours(0, 0, 0, 0) - 7 * 24 * 60 * 60 * 1000),
    EndDate: new Date(new Date().setHours(23, 59, 59, 999)),
    TruckId: null,
    CustomerID1: null,
    isFilterActive: false,
}

export const driverInitialFilterState: IDriverFilter = {
    RFID: null,
    DriverName: null,
    DLNo: null,
    DLExpiryInDays: -1,
    isFilterActive: false,
}

export const vehicleInitialFilterState: IVehicleFilter = {
    RFID: null,
    RegistrationNo: null,
    RegistrationExpiryDateInDays: -1,
    isFilterActive: false,
}

export const truckInitialFilterState: ITruckFilter = {
    TruckId: null,
    isFilterActive: false,
}

export const alarmInitialFilterState: IAlarmFilter = {
    isFilterActive: false,
    StartDate: new Date(new Date().setHours(0, 0, 0, 0) - 7 * 24 * 60 * 60 * 1000),
    EndDate: new Date(new Date().setHours(23, 59, 59, 999)),
    AlarmType: -1,
    AlarmTimestamp: new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000)
}

const date = new Date();
date.setDate(date.getDate() - 7)

export const initialDashboardFilter: any = {
    FuelStatus: "All",
    DailyFuelUsage: { CustomerId2: 'All', CustomerId3: 'All', CustomerId: 'All' },
    TankLevel: "",
    StartDate: date,
    EndDate: new Date()
};

export const initialCustomerDashboardFilter: any = {
    FuelStatus: { CustomerId2: 'All', CustomerId3: 'All' },
    DailyFuelUsage: { CustomerId2: 'All', CustomerId3: 'All' },
    TankLevel: { TruckIds: '' },
    StartDate: date,
    EndDate: new Date()
};

export const paymentInitialFilterState: IPaymentFilter = {
    isFilterActive: true,
    ModeOfPayment: null,
    StartDate: moment().subtract(3, 'months').toDate(),
    EndDate: new Date(),
}

export const invoiceDetailInitialFilterState: IInvoiceDetailFilter = {
    isFilterActive: false,
    StartDate: null,
    EndDate: null,
}
export const supplierInitialFilterState: ISupplierFilter = {
    SupplierName: null,
    isFilterActive: false,
}

export interface IOption {
    Id: number;
    Name: string;
}