/*    
<summary>
   This class component is all about Managing report data functionality.
   Developer:Aashish Singh, Created Date:14-May-2024
</summary>
<param>No Parameter Passed</param>
<returns>Returns class instance</returns>
*/

import { AxiosResponse } from "axios";
import { action, computed, makeObservable, observable } from "mobx";
import moment from "moment";
import { toast } from "react-toastify";
import { DATE_FORMAT_BACKEND, DATE_TIME_FORMAT_TO_BACKEND, emptyValue, roundWithPrecision } from "../../constants/common-constant";
import { TransactionReportTypeEnum } from "../../constants/enums/transaction-report-type-enum";
import UrlConstants from "../../constants/url.constant";
import { downloadPdfFile } from "../../helpers/fileManager.helper";
import { getAmountDecimal, getClientId, getCustomerId, getUserType } from "../../helpers/localStorages.helper";
import { IObservableInitialState } from "../../models/ICommon";
import { ITransaction } from "../../models/ITransaction";
import IApiResponse, { IApiSuccessResponse } from "../../models/response/IApiResponse";
import { IPaymentViewReport } from "../../models/response/IPaymentResponse";
import { IAddReport, IGetPaymentReport, IGetReport, IReport, IReportCount, IReportInfo } from "../../models/response/IReportResponse";
import { formatMessage } from "../../translations/formatMessage";
import { getPaymentReportListState, getReportListState, getReportViewListState } from "../initialState/get-report-list-state";
import * as services from '../service/base-service';
import UserType from "../../constants/userType.constant";

export class ReportStore {
    inProgress = false;
    error = '';
    reportList: Array<IReport> = [];

    initialStateValue: IObservableInitialState = {
        success: false,
        error: '',
        inProgress: false
    }

    reportCountState: IObservableInitialState = {
        success: false,
        error: '',
        inProgress: false
    }

    deleteReportState: IObservableInitialState = {
        success: false,
        error: '',
        inProgress: false
    }

    reportCount: IReportCount = {
        TruckWiseReportCount: 0,
        CustomerWiseReportCount: 0,
        DepartmentWiseReportCount: 0,
        VehicleWiseReportCount: 0,
        PaymentWiseReportCount: 0,
    }

    addReportState: IObservableInitialState = { ...this.initialStateValue };
    downloadReportState: IObservableInitialState = { ...this.initialStateValue };
    DeleteReportState: IObservableInitialState = { ...this.initialStateValue };

    reportViewDataState: IObservableInitialState = { ...this.initialStateValue };
    paymentReportViewData: IGetPaymentReport = { ...getPaymentReportListState };
    reportViewData: IGetReport = { ...getReportViewListState };

    constructor() {
        makeObservable(this, {
            inProgress: observable,
            error: observable,
            reportList: observable,
            addReportState: observable,
            DeleteReportState: observable,
            downloadReportState: observable,
            reportCountState: observable,
            reportViewData: observable,
            reportViewDataState: observable,
            reportCount: observable,
            deleteReportState: observable,
            paymentReportViewData: observable,
            GetReportListService: action,
            GetAllReportViewDataService: action,
            AddReportService: action,
            reset: action,
            resetStore: action,
            GetReportCountService: action,
            resetAddReportState: action,
            resetReportCountState: action,
            resetReportViewDataState: action,
            ExportReportById: action,
            DeleteReportByIdService: action,
            resetDeleteReportState: action,
            getReportList: computed,
            getReportCount: computed,
            getAllReportViewData: computed,
            getAllPaymentReportViewData: computed,
        });
    }

    GetReportListService = () => {
        const clientIdFromLocalStorage = getClientId() != null ? getClientId() : -1;
        this.inProgress = true;
        const url = `${UrlConstants.GetReportList}?clientId=${clientIdFromLocalStorage}`;//.GetReportList + '?pageNo=' + currentPage + '&pageSize=' + pagerSize + '&column='+ column + '&orderBy=' + orderBy;
        return services.get(url)
            .then((response: IApiResponse<IApiSuccessResponse<Array<IReport>>>) => {
                this.reportList = response.data.Data;
            })
            .catch((err: string) => {
                toast.error(formatMessage(err));
                this.error = err;
            })
            .finally(action(() => { this.inProgress = false; }));
    }

    get getReportList(): IReport[] {
        if (this.reportList && this.reportList?.length > 0) {
            return this.reportList?.map((report: IReport) => {
                let IsDelete: boolean = true;
                let IsEdit: boolean = true;
                return {
                    ...report,
                    CreatedAt: moment(report.CreatedAt).format(DATE_TIME_FORMAT_TO_BACKEND),
                    Id: report.Id,
                    IsDeletable: IsDelete,
                    IsEditable: IsEdit,
                }
            })
        }
        return [];
    }

    GetAllReportViewDataService = (data: any, currentPage: number, pageLimit: any, orderBy: any, column: any) => {
        const clientIdFromLocalStorage = getClientId() != null ? getClientId() : -1;
        this.reportViewDataState.inProgress = true;
        let requestBody = data.ReportType === TransactionReportTypeEnum.PAYMENT_WISE ? {
            ReportType: data.ReportType,
            StartDate: data.StartDate,
            EndDate: data.EndDate,
            FileName: data.FileName,
            SearchId: data.SearchId,
            CustomerIdLong: (getUserType() === UserType.CleintAdmin || getUserType() === UserType.ClientUser) ? data.CustomerId : (getCustomerId() != null ? Number(getCustomerId()) : -1),
            CustomerId: data.SearchId
        } : {
            ReportType: data.ReportType,
            SearchId: data.SearchId,
            StartDate: data.StartDate,
            EndDate: data.EndDate,
            FileName: data.FileName,
            CustomerIdLong: getCustomerId() != null ? Number(getCustomerId()) : -1,
        }
        const url = data.ReportType === TransactionReportTypeEnum.PAYMENT_WISE ?
            `${UrlConstants.ViewPaymentReport}?clientId=${clientIdFromLocalStorage}&pageNo=${currentPage}&pageSize=${pageLimit}`
            : `${UrlConstants.GenerateReportViewData}?clientId=${clientIdFromLocalStorage}&pageNo=${currentPage}&pageSize=${pageLimit}`;//.GetReportList + '?pageNo=' + currentPage + '&pageSize=' + pagerSize + '&column='+ column + '&orderBy=' + orderBy;
        return services.post(url, { ...requestBody })
            .then((response: IApiResponse<IApiSuccessResponse<any>>) => {
                if (data.ReportType === TransactionReportTypeEnum.PAYMENT_WISE) {
                    this.paymentReportViewData = response.data.Data;
                } else {
                    this.reportViewData = response.data.Data;
                }
                this.reportViewDataState.success = true;
            })
            .catch((err: string) => {
                // toast.error(formatMessage(err));
                this.reportViewDataState.error = err;
            })
            .finally(action(() => { this.reportViewDataState.inProgress = false; }));
    }

    get getAllReportViewData(): any[] {
        let decimalValue = getAmountDecimal();
        if (this.reportViewData && this.reportViewData?.Ticket.length > 0) {
            return this.reportViewData?.Ticket.map((transaction: ITransaction) => {
                return {
                    ...transaction,
                    Id: transaction.Id,
                    DeviceId: transaction.DeviceId !== null ? transaction.DeviceId : "",
                    ClientId: transaction.ClientId !== null ? transaction.ClientId : "",
                    TruckId: transaction.TruckId !== null ? transaction.TruckId : "",
                    Unit: transaction.Unit !== null ? (transaction.Unit === "L" ? "Litre" : transaction.Unit) : transaction.Unit,
                    TicketNumber: transaction.TicketNumber !== null ? transaction.TicketNumber : "",
                    ProductId: transaction.ProductId !== null ? transaction.ProductId : "",
                    TsStart: transaction.TsStart !== "1970-01-01T00:00:00.000Z" ? moment(transaction.TsStart).format(DATE_TIME_FORMAT_TO_BACKEND) : "",
                    TsFinish: transaction.TsFinish !== "1970-01-01T00:00:00.000Z" ? moment(transaction.TsFinish).format(DATE_TIME_FORMAT_TO_BACKEND) : "",
                    MeterId: transaction.MeterId !== null ? roundWithPrecision(transaction.MeterId, 2) : "",
                    NetDelivered: transaction.NetDelivered !== null ? roundWithPrecision(transaction.NetDelivered, 2) : "",
                    EndGross: transaction.EndGross !== null ? roundWithPrecision(transaction.EndGross, 2) : "",
                    FlowMax: transaction.FlowMax !== null ? roundWithPrecision(transaction.FlowMax, 2) : "",
                    FlowAvg: transaction.FlowAvg !== null ? roundWithPrecision(transaction.FlowAvg, 2) : "",
                    GrossDelivered: transaction.GrossDelivered !== null ? roundWithPrecision(transaction.GrossDelivered, 2) : "",
                    TsGen: transaction.TsGen ? moment(transaction.TsGen).format(DATE_TIME_FORMAT_TO_BACKEND) : "",
                    TsSend: transaction.TsSend ? moment(transaction.TsSend).format(DATE_TIME_FORMAT_TO_BACKEND) : "",
                    VehicleRfid: transaction.VehicleRfid !== null ? transaction.VehicleRfid : "",
                    DriverRfid: transaction.DriverRfid !== null ? transaction.DriverRfid : "",
                    OperatorRfid: transaction.OperatorRfid !== null ? transaction.OperatorRfid : "",
                    CustomerId1: transaction.CustomerId1 !== null ? transaction.CustomerId1 : "",
                    CustomerId2: transaction.CustomerId2 !== null ? transaction.CustomerId2 : "",
                    CustomerId3: transaction.CustomerId3 !== null ? transaction.CustomerId3 : "",
                    CustomerId4: transaction.CustomerId4 !== null ? transaction.CustomerId4 : "",
                    CustomerId5: transaction.CustomerId5 !== null ? transaction.CustomerId5 : "",
                    CustomerId6: transaction.CustomerId6 !== null ? transaction.CustomerId6 : "",
                    InvName1: transaction.InvName1 !== null ? transaction.InvName1 : "",
                    InvUnit1: transaction.InvUnit1 !== null ? (transaction.InvUnit1 === "L" ? "Litre" : transaction.InvUnit1) : transaction.Unit,
                    InvValue1: transaction.InvValue1 !== null ? roundWithPrecision(transaction.InvValue1, 2) : "",
                    InvName2: transaction.InvName2 !== null ? transaction.InvName2 : "",
                    InvUnit2: transaction.InvUnit2 !== null ? (transaction.InvUnit2 === "L" ? "Litre" : transaction.InvUnit2) : transaction.Unit,
                    InvValue2: transaction.InvValue2 !== null ? roundWithPrecision(transaction.InvValue2, 2) : "",
                    PricePerL: transaction.PricePerL !== null ? Number(transaction.PricePerL).toFixed(decimalValue) : "",
                    VatAmount: transaction.VatAmount !== null ? Number(transaction.VatAmount).toFixed(decimalValue) : "",
                    AmountDue: transaction.AmountDue !== null ? Number(transaction.AmountDue).toFixed(decimalValue) : "",
                }
            })
        }
        return [];
    }

    get getAllPaymentReportViewData() {
        let decimalValue = getAmountDecimal();
        if (this.paymentReportViewData && this.paymentReportViewData?.Payments.length > 0) {
            return this.paymentReportViewData?.Payments.map((payment: IPaymentViewReport) => {
                return {
                    ...payment,
                    Id: payment.Id !== null ? payment.Id : 0,
                    PaymentDate: payment.PaymentDate ? moment(payment.PaymentDate).format(DATE_FORMAT_BACKEND) : emptyValue,
                    Description: payment.Description ? payment.Description : emptyValue,
                    AmountCr: payment.AmountCr !== null ? Number(payment.AmountCr).toFixed(decimalValue) : emptyValue,
                    ModeOfPayment: payment.ModeOfPayment ? payment.ModeOfPayment : emptyValue,
                }
            })
        }
        return [];
    }

    resetReportViewDataState = () => {
        this.reportViewDataState = { ...this.initialStateValue };
        this.reportViewData = { ...getReportViewListState };
        this.paymentReportViewData = { ...getPaymentReportListState };
    }

    AddReportService = (data: IAddReport, transactionReportType: number) => {
        this.addReportState.inProgress = true;
        let reportData = data.ReportType === TransactionReportTypeEnum.PAYMENT_WISE ? {
            ...data,
            ReportType: transactionReportType,
            CustomerIdLong: (getUserType() === UserType.CleintAdmin || getUserType() === UserType.ClientUser) ? data.CustomerId : (getCustomerId() != null ? Number(getCustomerId()) : -1),
            CustomerId: data.SearchId
        } : {
            ...data,
            ReportType: transactionReportType,
            CustomerIdLong: getCustomerId() != null ? Number(getCustomerId()) : -1,
        }
        const clientIdFromLocalStorage = getClientId() != null ? getClientId() : -1;
        let url = transactionReportType === TransactionReportTypeEnum.PAYMENT_WISE ? `${UrlConstants.GeneratePaymentReport}?clientId=${clientIdFromLocalStorage}&tz=${moment().utcOffset()}` : `${UrlConstants.GenerateTransactionReport}?clientId=${clientIdFromLocalStorage}&tz=${moment().utcOffset()}`;
        return services.post(url, { ...reportData })
            .then(() => {
                this.addReportState.success = true;
            })
            .catch((err: string) => {
                // this.addReportState.error = err;
                toast.error(formatMessage(err));
            })
            .finally(action(() => { this.addReportState.inProgress = false; }));
    }

    ExportReportById = (id: number, reportName: string) => {
        const clientIdFromLocalStorage = getClientId() != null ? getClientId() : -1;
        this.downloadReportState.inProgress = true;
        const url = `${UrlConstants.DownloadTransactionReportPdf}?clientId=${clientIdFromLocalStorage}&reportId=${id}`;
        return services.downloadFile(url)
            .then((response: AxiosResponse<any>) => {
                this.downloadReportState.success = true;
                downloadPdfFile(response, reportName, reportName.split(".")[reportName.split(".").length - 1]);
            })
            .catch((err: string) => {
                this.downloadReportState.error = err;
            }).finally(action(() => { this.downloadReportState.inProgress = false; }));
    }

    DeleteReportByIdService = (id: number) => {
        const clientIdFromLocalStorage = getClientId() != null ? getClientId() : -1;
        this.deleteReportState.inProgress = true;
        const url = `${UrlConstants.DeleteReportPdf}?reportId=${id}&clientId=${clientIdFromLocalStorage}`;
        return services.deleteapi(url)
            .then((response: AxiosResponse<any>) => {
                this.deleteReportState.success = true;
            })
            .catch((err: string) => {
                this.deleteReportState.error = err;
            }).finally(action(() => { this.deleteReportState.inProgress = false; }));
    }

    GetReportCountService = () => {
        const clientIdFromLocalStorage = getClientId() != null ? getClientId() : -1;
        this.reportCountState.inProgress = true;
        const url = `${UrlConstants.GetReportCount}?clientId=${clientIdFromLocalStorage}`;//.GetReportList + '?pageNo=' + currentPage + '&pageSize=' + pagerSize + '&column='+ column + '&orderBy=' + orderBy;
        return services.get(url)
            .then((response: IApiResponse<IApiSuccessResponse<any>>) => {
                this.reportCount = response.data.Data;
                this.reportCountState.success = true;
            })
            .catch((err: string) => {
                toast.error(formatMessage(err));
                this.reportCountState.error = err;
            })
            .finally(action(() => { this.reportCountState.inProgress = false; }));
    }

    get getReportCount() {
        return this.reportCount;
    }

    resetReportCountState = () => {
        this.reportCountState = { ...this.initialStateValue }
    }

    resetDeleteReportState = () => {
        this.deleteReportState = { ...this.initialStateValue }
    }

    resetAddReportState = () => {
        this.addReportState = { ...this.initialStateValue }
    }

    resetDownloadReportState = () => {
        this.downloadReportState = { ...this.initialStateValue }
    }

    reset = () => {
        this.error = '';
        this.inProgress = false;
    }

    resetError = () => {
        this.error = '';
    }

    /**
     * This function is used to reset all store observables to their initial values.
     * @returns
     */
    resetStore = () => {
        this.error = '';
        this.inProgress = false;
        this.reportList = getReportListState;

        this.addReportState = { ...this.initialStateValue }

        this.DeleteReportState = { ...this.initialStateValue }
    }
}

export default new ReportStore();

// CreatedAt: moment(report.CreatedAt).format(DATE_TIME_FORMAT_TO_BACKEND),
