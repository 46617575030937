/*    
<summary>
  This functional component "AddUpdateSupplierForm" provides form to add new customer or update existing customer.
  Developer:Aashish Singh, Created Date:20-March-2024
</summary>
<returns>Returns JSX</returns>
*/
import { Field, Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { floatRegex } from "../../../constants/common-constant";
import { getAmountDecimal } from "../../../helpers/localStorages.helper";
import { IOption } from "../../../models/ICommon";
import { ISupplier } from "../../../models/response/ISupplierResponse";
import LoaderButton from "../../../shared-components/Button/LoaderButton";
import FormikFormInputForPhone from "../../../shared-components/FormikFormHandlers/formik-form-input-for-number";
import FormikFormInputForRegexCheck from "../../../shared-components/FormikFormHandlers/formik-form-input-for-regex-check";
import FormikFormInput from "../../../shared-components/FormikFormHandlers/FormikFormInput";
import FormikFormTextArea from "../../../shared-components/FormikFormHandlers/FormikFormTextArea";
import Label from "../../../shared-components/label/label";
import SpinnerLoader from "../../../shared-components/Loader/SpinnerLoader";
import { addEditSupplierValidateSchema } from "../../../shared-components/FormikFormHandlers/FormikValidationSchema";
import DraggableModal from "../../../shared-components/Draggable/DraggableModal";


interface IProps {
  id: number;
  submitHandler: (data: ISupplier) => void;
  onModalClosed: () => void;
  getDataInProgress: boolean;
  addUpdateInProgress: boolean;
  initialValues: ISupplier;
}

const AddUpdateSupplierForm = (props: IProps) => {
  const {
    id,
    submitHandler,
    onModalClosed,
    getDataInProgress,
    addUpdateInProgress,
    initialValues,
  } = props;
  const [oldValues, setOldValues] = useState<ISupplier>();

  let decimalValue: number = getAmountDecimal();
  useEffect(() => {
    setOldValues({ ...props.initialValues })
  }, [props.initialValues])

  // const FormObserver: React.FC = () => {
  //   const { values, setFieldValue } = useFormikContext<IAddEditSupplier>();

  //   useEffect(() => {
  //     if (id == -1) {
  //       if (oldValues?.PricePerL != values.PricePerL || oldValues?.PricePerGallon != values.PricePerGallon
  //         || oldValues?.MaxAmountLimit != values.MaxAmountLimit) {
  //         if (values.PricePerL && Number(values.PricePerL) > 0) {
  //           let tempVolume = Number(values.MaxAmountLimit) / Number(values.PricePerL);
  //           setFieldValue("MaxVolumeLimit", Number(tempVolume).toFixed(3));
  //           setOldValues({ ...values });
  //         }
  //         else if (values.PricePerGallon && Number(values.PricePerGallon) > 0) {
  //           let tempVolume = Number(values.MaxAmountLimit) / (Number(values.PricePerGallon) / 4.54609);
  //           setFieldValue("MaxVolumeLimit", Number(tempVolume).toFixed(3));
  //           setOldValues({ ...values });
  //         }
  //         else if ((values.PricePerGallon == "" || Number(values.PricePerGallon) == 0) && values.PricePerL == "" || Number(values.PricePerL) == 0) {
  //           setFieldValue("MaxVolumeLimit", 0);
  //         }
  //       }
  //     }
  //     else {
  //       if (props.initialValues?.MaxAmountLimit != values.MaxAmountLimit || props.initialValues?.MaxAmountLimit != values.MaxAmountLimit) {
  //         if ((values.MaxAmountLimit != "" && Number(values.MaxAmountLimit) != 0)) {
  //           let tempVolume = Number(values.PricePerL) > 0 ? Number(values.MaxAmountLimit) / Number(values.PricePerL) : (Number(values.PricePerGallon) > 0 ? Number(values.MaxAmountLimit) / (Number(values.PricePerGallon) / 4.54609) : 0);
  //           setFieldValue("MaxVolumeLimit", Number(tempVolume).toFixed(3));
  //         }
  //         else {
  //           setFieldValue("MaxVolumeLimit", 0);
  //         }
  //       }
  //     }
  //   }, [values.MaxAmountLimit, values.PricePerL, values.PricePerGallon]);
  //   return null;
  // };

  return (
    <>
      <Modal
        className="add-customer-modal add-user-modal supplier-modal"
        show={true}
        centered
        dialogAs={DraggableModal}
      >
        <Modal.Header>
          <Modal.Title className="fs-5 ps-3">
            {id === -1 ? (
              <FormattedMessage id="add_supplier" />
            ) : (
              <FormattedMessage id="update_supplier" />
            )}
          </Modal.Title>
          <div onClick={onModalClosed} className="icons8-close">
            <span className="material-symbols-outlined fs-5 text-dark">
              close
            </span>
          </div>
          {getDataInProgress ? <SpinnerLoader /> : null}
        </Modal.Header>
        <Formik
          initialValues={initialValues}
          enableReinitialize
          validationSchema={addEditSupplierValidateSchema}
          onSubmit={async (values) => {
            let tempValues = {
              SupplierName: values.SupplierName ? values.SupplierName.trim() : values.SupplierName,
              PhoneNo: values.PhoneNo ? values.PhoneNo.trim() : values.PhoneNo,
              ContactPersonName: values.ContactPersonName ? values.ContactPersonName.trim() : values.ContactPersonName,
              ContactPersonPhoneNo: values.ContactPersonPhoneNo ? values.ContactPersonPhoneNo.trim() : values.ContactPersonPhoneNo,
              ContactPersonEmail: values.ContactPersonEmail ? values.ContactPersonEmail.trim() : values.ContactPersonEmail,
              Address: values.Address ? values.Address.trim() : values.Address,
              PricePerL: values.PricePerL,
              PricePerGallon: values.PricePerGallon,
              TaxRegistrationNo: values.TaxRegistrationNo ? values.TaxRegistrationNo.trim() : values.TaxRegistrationNo,
            }

            submitHandler({
              ...tempValues
            });
          }}
        >
          {({ values, setFieldValue }) => (
            <Form className="customer-form p-3 user-form" noValidate>
              {/* <FormObserver /> */}
              <Modal.Body>
                <Row className="position-relative">
                  <Col lg={6} xs={6}>
                    <div className="form-group">
                      <Label
                        className="form-label mt-2"
                        required={true}
                        label="supplier_name"
                      />

                      <Field
                        data-testid="testEmail"
                        className="form-control"
                        name="SupplierName"
                        type="text"
                        // invalidcharacter={[" "]}
                        validcharacter={['@', '#', '$', '-', '_', '.']}
                        placeholder="supplier_name"
                        as={FormikFormInput}
                      />
                    </div>
                  </Col>
                  <Col lg={6} xs={6}>
                    <div className="form-group">
                      <Label
                        className="form-label mt-2"
                        label="label_customer_trn"
                      />
                      <Field
                        data-testid="testCurrencyName"
                        className="form-control"
                        name="TaxRegistrationNo"
                        type="text"
                        placeholder="label_customer_trn"
                        as={FormikFormInput}
                      />
                    </div>
                  </Col>

                  {/* { (getUserType() !== UserType.SupplierAdmin)  &&
                    <Col xs={12}>
                    <div className="mb-3">
                      <Label
                        className="form-label"
                        required={true}
                        label="customer_id"
                      />
                      <Field
                        className="form-control form-select"
                        aria-label="Default select example"
                        name="SupplierId"
                        type="number"
                        as={FormikFormSelectField}
                        typeofoptionid="string"
                        options={customerIdList}
                      />
                    </div>
                  </Col>
                  } */}
                </Row>

                <Row className="border-box position-relative border mt-3">
                  <Col xs={12} className="pb-3">
                    <p className="h6 mt-3 fw-semi-bold heading-title">
                      <FormattedMessage id="contact_person_details" />
                    </p>
                  </Col>

                  <Col lg={4} md={4} xs={6}>
                    <div className="form-group">
                      <Label className="form-label" label="contact_person" />
                      <Field
                        data-testid="testContactPersonName"
                        className="form-control"
                        name="ContactPersonName"
                        validcharacter={['@', '#', '$', '-', '_', '.']}
                        type="text"
                        placeholder="contact_person"
                        as={FormikFormInput}
                      />
                    </div>
                  </Col>
                  <Col lg={4} md={4} xs={6}>
                    <div className="form-group">
                      <Label
                        className="form-label"
                        label="contact_person_phone_no"
                      />
                      {/* <InfoTooltip Info="phone_format_info" /> */}
                      <Field
                        data-testid="testContactPersonPhoneNo"
                        className="form-control"
                        name="ContactPersonPhoneNo"
                        type="tel"
                        validcharacter={["+", "-"]}
                        placeholder="contact_person_phone_no"
                        as={FormikFormInputForPhone}
                      />
                    </div>
                    {/* <div className="mb-3">
                      <Label
                        className="form-label"
                        label="contact_person_phone_no"
                      />
                      <Field
                        data-testid="testContactPersonPhoneNo"
                        className="form-control"
                        name="ContactPersonPhoneNo"
                        type="text"
                        placeholder="contact_person_phone_no"
                        regex={/^(?:\d+(\.\d*)?|)$/}
                        as={FormikFormInputForRegexCheck}
                      />
                    </div> */}
                  </Col>
                  <Col lg={4} md={4} xs={12}>
                    <div className="form-group">
                      <Label className="form-label" label="email" />
                      <Field
                        data-testid="testContactPersonEmail"
                        className="form-control"
                        name="ContactPersonEmail"
                        type="text"
                        placeholder="email"
                        invalidcharacter={[" "]}
                        validcharacter={[
                          "-",
                          "_",
                          "[",
                          "]",
                          "(",
                          ")",
                          ":",
                          "#",
                          "@",
                          ".",
                          "$"
                        ]}
                        tolowercase={+true}
                        as={FormikFormInput}
                      />
                    </div>
                  </Col>
                </Row>

                <Row className="border-box position-relative border">
                  <Col xs={12} className="pb-3">
                    <p className="h6 mt-3 fw-semi-bold heading-title">
                      <FormattedMessage id="supplier_address_info" />
                    </p>
                  </Col>

                  <Col lg={12} xs={12}>
                    <div className="form-group">
                      <Label className="form-label"
                        label="phone_no"
                        required={false}
                      />
                      {/* <InfoTooltip Info="phone_format_info" /> */}
                      <Field
                        data-testid="testPhoneNo"
                        className="form-control"
                        name="PhoneNo"
                        type="tel"
                        validcharacter={["+", "-"]}
                        placeholder="phone_no"
                        as={FormikFormInputForPhone}
                      />
                    </div>

                  </Col>
                  <Col lg={12} xs={12}>
                    <div className="form-group">
                      <Label
                        className="form-label"
                        required={false}
                        label="address"
                      />
                      <Field
                        data-testid="testAddress"
                        className="form-control"
                        name="Address"
                        type="text"
                        validcharacter={['@', '#', '$', '-', '_', '.']}
                        placeholder="address"
                        as={FormikFormTextArea}
                      />
                    </div>
                  </Col>
                </Row>
                <Row className="border-box position-relative border">
                  <Col xs={12} className="pb-3 ">
                    <p className="h6 mt-3 fw-semi-bold heading-title">
                      <FormattedMessage id="pricing_details" />
                    </p>
                  </Col>
                  <Col lg={6} md={6} sm={6} xs={12}>
                    <div className="form-group">
                      <Label
                        className="form-label"
                        required={true}
                        label="transactions_label_perunitprice"
                      />
                      <Field
                        className="form-control"
                        aria-label="Default select example"
                        name="PricePerL"
                        placeholder="transactions_label_perunitprice"
                        regex={floatRegex}
                        as={FormikFormInputForRegexCheck}
                      // onChange={(event: any) => {
                      //   let value = event.target.value;
                      //   if (floatRegex.test(value)) {
                      //     setFieldValue('PricePerL', value);
                      //     if (values.MaxAmountLimit) {
                      //       setFieldValue('MaxVolumeLimit', value ? (Number(values.MaxAmountLimit) / Number(value)).toFixed(4) : "");
                      //     }
                      //   }
                      //   else {
                      //     event?.preventDefault()
                      //   }
                      // }}
                      // onBlur={(event: any) => {
                      //   let value = event.target.value;
                      //   if (floatRegex.test(value)) {
                      //     setFieldValue('PricePerL', value);
                      //     if (values.MaxAmountLimit) {
                      //       setFieldValue('MaxVolumeLimit', value ? (Number(values.MaxAmountLimit) / Number(value)).toFixed(4) : "");
                      //     }
                      //   }
                      //   else {
                      //     event?.preventDefault()
                      //   }
                      // }}
                      // isvolumelimit={+true}
                      />
                    </div>
                  </Col>
                  <Col lg={6} md={6} sm={6} xs={12}>
                    <div className="form-group">
                      <Label
                        className="form-label"
                        required={true}
                        label="transactions_label_perunitgallon"
                      />
                      <Field
                        className="form-control"
                        aria-label="Default select example"
                        name="PricePerGallon"
                        placeholder="transactions_label_perunitgallon"
                        regex={floatRegex}
                        as={FormikFormInputForRegexCheck}
                      />
                    </div>
                  </Col>
                </Row>
              </Modal.Body>
              <div className="modal-footer  border-0">
                <div className="m-auto">
                  <LoaderButton
                    type="submit"
                    isLoading={addUpdateInProgress}
                    disabled={addUpdateInProgress}
                    text={id === -1 ? "button_save" : "button_edit"}
                  />
                  <Button
                    variant="btn-secondary bg-light btn-light ms-2"
                    onClick={onModalClosed}
                  >
                    <FormattedMessage id="button_cancel" />
                  </Button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};

export default AddUpdateSupplierForm;
