import React from 'react';
import './Chart.scss';
import CanvasJSReact from '../../assets/canvasjs.react.js';
import { IChartData } from '../../models/IChartData';
const CanvasJSChart = CanvasJSReact.CanvasJSChart;

interface IProps {
    data: Array<IChartData>;
    xAxisInterval?: number;
}

const Chart = (props: IProps) => {
    const { data } = props;

    const options = {
        title: {
            text: "",
            fontSize: 15,
            fontFamily: "sans-serif"
        },
        toolTip: {
            content: "{x} <br/>{y}"
        },
        axisX: {
            labelTextAlign: "center",
            labelAutoFit: false,
            title: "",
            valueFormatString: "DD-MMM",
            interval: props.xAxisInterval ?? 1,
            intervalType: "day",
            labelFontSize: 13,
            labelFontFamily: "sans-serif",
            labelFontColor: "#7d7a7a",
            titleFontSize: 13,
            titleFontFamily: "sans-serif",
            lineThickness: 2,
            lineColor: "#ccc",
            tickColor: "#ccc",
            tickThickness: 1
        },
        axisY: {
            // title: "Data",
            titleFontColor: "#7d7a7a",
            labelFontSize: 13,
            labelFontFamily: "sans-serif",
            labelFontColor: "#7d7a7a",
            titleFontSize: 13,
            titleFontFamily: "sans-serif",
            gridColor: "#ccc",
            gridThickness: 1,
            lineThickness: 2,
            lineColor: "#ccc",
            tickColor: "#ccc",
            tickThickness: 1,
            minimum: 0,
        },
        data: data,
        theme: "light2",
        backgroundColor: "transparent",
        interactivityEnabled: true,
        animationEnabled: true,
        exportEnabled: false,
        zoomEnabled: true,
        culture: "en",
        shared: true
    };

    return <div>
        <CanvasJSChart options={options} />
    </div>
}

export default Chart;