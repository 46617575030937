/*    
<summary>
  This functional component "ClientFilterForm" provides form to get data based on filter
  Developer:Aashish Singh, Created Date:20-March-2024
</summary>
<returns>Returns JSX</returns>
*/
import { Field, Form, Formik, useFormikContext } from "formik";
import moment from "moment";
import React from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { DATE_FORMAT_BACKEND } from "../../../constants/common-constant";
import { IPaymentInitialFilterState } from "../../../models/IFilter";
import { IPayment } from "../../../models/response/IPaymentResponse";
import LoaderButton from "../../../shared-components/Button/LoaderButton";
import FormikDateFieldMUI from "../../../shared-components/FormikFormHandlers/FormikFormDatePickerMUI";
import FormikFormInput from "../../../shared-components/FormikFormHandlers/FormikFormInput";
import Label from "../../../shared-components/label/label";
import { paymentFilterValidateSchema } from "../../../shared-components/FormikFormHandlers/FormikValidationSchema";
import DraggableModal from "../../../shared-components/Draggable/DraggableModal";

interface IProps {
    submitHandler: (values: any) => void;
    onModalClosed: () => void;
    actionInProgress: boolean;
    initialValues: IPaymentInitialFilterState;
}

const PaymentFilterForm = (props: IProps) => {
    const { submitHandler, onModalClosed, initialValues, actionInProgress } =
        props;
    // const [err, setErr] = useState<boolean>(false)

    const FormObserver: React.FC = () => {
        const { values } = useFormikContext<IPayment>();
        return null;
    };


    return (
        <>
            <Modal className="add-user-modal " show={true} centered dialogAs={DraggableModal}>
                <Modal.Header>
                    <Modal.Title>
                        <FormattedMessage id="filter_title" />
                    </Modal.Title>
                    <div onClick={onModalClosed} className="icons8-close">
                        <span className="material-symbols-outlined fs-5 text-dark">
                            close
                        </span>
                    </div>
                </Modal.Header>
                <Formik
                    initialValues={initialValues}
                    enableReinitialize
                    validationSchema={paymentFilterValidateSchema}
                    onSubmit={async (values: any) => {
                        // let tempValues: IClientInitialFilterState = {
                        //   ...values,
                        //   City: values.City ? values.City.trim() : values.City,
                        //   UserName: values.UserName ? values.UserName.trim() : values.UserName,
                        //   ContactPersonName: values.ContactPersonName ? values.ContactPersonName.trim() : values.ContactPersonName,
                        //   ContactPersonEmail: values.ContactPersonEmail ? values.ContactPersonEmail.trim() : values.ContactPersonEmail,
                        // }
                        // if(JSON.stringify(values) === JSON.stringify(_.omit(clientInitialFilterState, ["isFilterActive"])))
                        // {
                        //   // toast.warn(formatMessage("please_select_at_least_1_field"));
                        //   setErr(true);
                        //   setTimeout(()=>{setErr(false)}, 3000);
                        // }
                        // else{
                        //   setErr(false);
                        let tempValues = {
                            StartDate: new Date(values.StartDate?.$d).toString() !== "Invalid Date" ? moment(values.StartDate.$d).format(DATE_FORMAT_BACKEND) : values.StartDate,
                            EndDate: new Date(values.EndDate?.$d).toString() !== "Invalid Date" ? moment(values.EndDate.$d).format(DATE_FORMAT_BACKEND) : values.EndDate,
                            ModeOfPayment: values.ModeOfPayment
                        }
                        props.submitHandler(tempValues);
                        // }
                    }}
                >
                    {({ values }) => (
                        <Form className="user-form p-3" noValidate>
                            <FormObserver />
                            <Modal.Body>
                                <Row className="date-range-transaction d-flex">
                                    <Col lg={6} md={6} xs={12} className="mb-2">
                                        <div className="form-group date-picker-error">
                                            <Label
                                                className="form-label"
                                                required={true}
                                                label="start_date"
                                            />
                                            <Field name="StartDate"
                                                as={FormikDateFieldMUI}
                                            />
                                            {/* <Field
                        data-testid="testEmail"
                        className="form-control"
                        name="StartDate"
                        // type="text"
                        placeholder="start_date"
                        minDate={new Date().setDate(new Date().getDate())}
                        as={FormikFormDatePickerMUI}
                      /> */}
                                        </div>
                                    </Col>
                                    <Col lg={6} md={6} xs={12} className="mb-2">
                                        <div className="form-group date-picker-error">
                                            <Label
                                                className="form-label"
                                                required={true}
                                                label="end_date"
                                            />
                                            <Field name="EndDate"
                                                as={FormikDateFieldMUI}
                                            />
                                            {/* <Field
                        data-testid="testEmail"
                        className="form-control"
                        name="EndDate"
                        // type="text"
                        placeholder="end_date"
                        minDate={new Date().setDate(new Date().getDate() + 1)}
                        as={FormikFormDatePickerMUI}
                      /> */}
                                        </div>
                                    </Col>
                                    <Col xs={12}>
                                        <Label
                                            className="form-label"
                                            label="payment_mode"
                                        />
                                        <Field
                                            data-testid="testTicketNumber"
                                            className="form-control"
                                            name="ModeOfPayment"
                                            type="text"
                                            placeholder="payment_mode"
                                            as={FormikFormInput}
                                        />
                                    </Col>
                                </Row>
                            </Modal.Body>
                            <div className="modal-footer  border-0">
                                <div className="m-auto">
                                    <LoaderButton
                                        type="submit"
                                        isLoading={actionInProgress}
                                        disabled={actionInProgress}
                                        text="filter_title"
                                    />
                                    <Button
                                        variant="btn-secondary bg-light btn-light ms-2"
                                        onClick={onModalClosed}
                                    >
                                        <FormattedMessage id="button_cancel" />
                                    </Button>
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
            </Modal >
        </>
    );
};

export default PaymentFilterForm;
