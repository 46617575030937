import React, { useState } from 'react';

const ImportPopUpHoc = (Component: any) =>
    function Comp(props: any) {
        const [showImportPopup, setShowImportPopup] = useState<boolean>(false);

        const importToggleHandler = () => {
            setShowImportPopup(!showImportPopup);
        }

        return <Component showImportPopup={showImportPopup} importToggleHandler={importToggleHandler} {...props} />;
    };

export default ImportPopUpHoc;