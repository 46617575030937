/*    
<summary>
  This functional component "DriverFilterForm" provides form to get data based on filter
  Developer:Aashish Singh, Created Date:20-March-2024
</summary>
<returns>Returns JSX</returns>
*/
import React, { useState } from "react";
import { Modal, Button, Row, Col } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { Form, Formik, Field, useFormikContext } from "formik";
import { IUser } from "../../../models/IUser";
import FormikFormInput from "../../../shared-components/FormikFormHandlers/FormikFormInput";
import LoaderButton from "../../../shared-components/Button/LoaderButton";
import Label from "../../../shared-components/label/label";
import FormikFormSelectField from "../../../shared-components/FormikFormHandlers/FormikFormSelectField";
import { ErrorMessage } from "../../../constants/error.constant";
import {
  allocationPeriodTypes,
  customerIdList,
  registerExpiryDuration,
  userTypeList,
} from "../../../constants/common-constant";
import DateRangeFilter from "../../Filter/Filter";
import FormikFormDatePicker from "../../../shared-components/FormikFormHandlers/FormikFormDatePicker";
import { IDriverInitialFilterState } from "../../../models/IFilter";
import { driverInitialFilterState } from "../../../core/initialState/initial-filter-states";
import _ from "lodash";
import DraggableModal from "../../../shared-components/Draggable/DraggableModal";

interface IProps {
  submitHandler: (values: any) => void;
  onModalClosed: () => void;
  actionInProgress: boolean;
  initialValues: IDriverInitialFilterState;
}

const DriverFilterForm = (props: IProps) => {
  const { submitHandler, onModalClosed, initialValues, actionInProgress } =
    props;

  const [err, setErr] = useState<boolean>(false);

  const FormObserver: React.FC = () => {
    const { values } = useFormikContext<IUser>();
    return null;
  };


  return (
    <>
      <Modal className="add-user-modal filter-from filter-vehicle" show={true} centered dialogAs={DraggableModal}>
        <Modal.Header>
          <Modal.Title>
            <FormattedMessage id="filter_title" />
          </Modal.Title>
          <div onClick={onModalClosed} className="icons8-close">
            <span className="material-symbols-outlined fs-5 text-dark">
              close
            </span>
          </div>
        </Modal.Header>
        <Formik
          initialValues={initialValues}
          enableReinitialize
          onSubmit={async (values) => {
            if (JSON.stringify(values) === JSON.stringify(_.omit(driverInitialFilterState, ["isFilterActive"]))) {
              // toast.warn(formatMessage("please_select_at_least_1_field"));
              setErr(true);
              setTimeout(() => { setErr(false) }, 3000);
            }
            else {
              setErr(false);
              let tempValues = {
                RFID: values.RFID ? values.RFID.trim() : null,
                DriverName: values.DriverName ? values.DriverName.trim() : null,
                DLNo: values.DLNo ? values.DLNo.trim() : null,
                DLExpiryInDays: values.DLExpiryInDays,
              }
              props.submitHandler({ ...tempValues });
            }
          }}
        >
          {({ values }) => (
            <Form className="user-form p-3" noValidate>
              <FormObserver />
              <Modal.Body>

                <Row>
                  <Col lg={6} md={6} xs={12}>
                    <div className="mb-3">
                      <Label className="form-label" label="rfid" />
                      <Field
                        data-testid="testRFID"
                        className="form-control"
                        name="RFID"
                        type="text"
                        invalidcharacter={[" "]}
                        validcharacter={['@', '#', '$', '-', '_', '.']}
                        placeholder="rfid"
                        as={FormikFormInput}
                      />
                    </div>
                  </Col>

                  <Col lg={6} md={6} xs={12}>
                    <div className="mb-3">
                      <Label className="form-label" label="driver_name" />
                      <Field
                        data-testid="testDriverName"
                        className="form-control"
                        name="DriverName"
                        type="text"
                        placeholder="driver_name"
                        as={FormikFormInput}
                      />
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col lg={6} md={6} xs={12}>
                    <div className="mb-3">
                      <Label className="form-label" label="dl_no" />
                      <Field
                        data-testid="testDLNo"
                        className="form-control"
                        name="DLNo"
                        type="text"
                        invalidcharacter={[" "]}
                        placeholder="dl_no"
                        as={FormikFormInput}
                      />
                    </div>
                  </Col>

                  <Col lg={6} md={6} xs={12}>
                    <div className="mb-3 ">
                      <Label className="form-label" label="dl_expiry_in_days" />
                      {/* from now to given date - aaj se 30 days baad , 6 months- future  */}
                      <Field
                        name="DLExpiryInDays"
                        className="form-control"
                        type="text"
                        as={FormikFormSelectField}
                        placeholder="dl_expiry_in_days"
                        typeofoptionid="number"
                        options={registerExpiryDuration}
                      />
                    </div>
                  </Col>
                </Row>


                {
                  err &&
                  <div className="error-text text-center w-100">
                    <span data-testid="testError" className="text-danger errorMessage">
                      <FormattedMessage id="please_select_at_least_1_field" />
                    </span>
                  </div>
                }


              </Modal.Body>
              <div className="modal-footer  border-0">
                <div className="m-auto">
                  <LoaderButton
                    type="submit"
                    isLoading={actionInProgress}
                    disabled={actionInProgress}
                    text="filter_title"
                  />
                  <Button
                    variant="btn-secondary bg-light btn-light ms-2"
                    onClick={onModalClosed}
                  >
                    <FormattedMessage id="button_cancel" />
                  </Button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};

export default DriverFilterForm;
