import axiosDownload, { AxiosResponse } from "axios";
import { makeObservable, observable, action, computed } from "mobx";
import IApiResponse, { IApiSuccessResponse } from "../../models/response/IApiResponse";
import { IAddTransactions, ICSVImportForm, IMissingTicket, ITransaction, IUpdateTransactions } from "../../models/ITransaction";
import UrlConstants from "../../constants/url.constant";
import * as services from '../service/base-service';
import { ITransactionsState } from "../../models/state/ITransactionsState";
import { getAllMissingTransactionState, getAllTransactionState } from "../initialState/getAllTransactions.state";
import { addTransactionInitialState, transactionState } from "../initialState/transactionDetail.state";
import { downloadPdfFile } from "../../helpers/fileManager.helper";
import { ITransactionFilter, ITransactionInitialFilterState } from "../../models/IFilter";
import { transactionInitialFilterState } from "../initialState/initial-filter-states";
import moment from "moment";
import { DATE_TIME_FORMAT_FILENAME, DATE_TIME_FORMAT_TO_BACKEND, emptyValue, getCurrentDateTime, roundWithPrecision } from "../../constants/common-constant";
import { formatMessage } from "../../translations/formatMessage";
import { toast } from "react-toastify";
import { IObservableInitialState, IOption } from "../../models/ICommon";
import { getAmountDecimal, getClientId, getToken } from "../../helpers/localStorages.helper";
import { ErrorMessage } from "../../constants/error.constant";


export class TransactionsStore implements ITransactionsState {
    inProgress = false;
    errors = '';
    transactionData = getAllTransactionState;

    initialStateValue: IObservableInitialState = {
        success: false,
        error: '',
        inProgress: false
    }


    transactionDetail: ITransaction | undefined = undefined;
    transactionDetailState = { ...this.initialStateValue }

    addUpdateTransactionState = { ...this.initialStateValue }
    transactionCustomerIdsUpdateState = { ...this.initialStateValue }

    transactionDownloadState = { ...this.initialStateValue }
    addTransactionCSVState = { ...this.initialStateValue }
    deleteTransactionState = { ...this.initialStateValue }
    missingTransactionState = { ...this.initialStateValue }

    filter: ITransactionFilter = {
        ...transactionInitialFilterState
    }

    optionIdsList: Array<{ Id: number, Name: string }> = [];
    optionIdsListState = { ...this.initialStateValue }
    missingTransactionViewData = getAllMissingTransactionState;
    missingTransactionViewState = { ...this.initialStateValue }


    constructor() {
        makeObservable(this, {
            inProgress: observable,
            errors: observable,
            transactionData: observable,
            transactionDetail: observable,
            transactionDetailState: observable,
            addUpdateTransactionState: observable,
            transactionCustomerIdsUpdateState: observable,
            transactionDownloadState: observable,
            addTransactionCSVState: observable,
            deleteTransactionState: observable,
            filter: observable,
            optionIdsList: observable,
            optionIdsListState: observable,
            missingTransactionViewState: observable,
            missingTransactionViewData: observable,
            reset: action,
            getAllTransactionService: action,
            addTransactionService: action,
            updateTransactionService: action,
            getTransactionByIdService: action,
            updateCustomerIdsInTransactionByIdService: action,
            addTransactionCSVService: action,
            exportTransactionReportByIdService: action,
            deleteTransactionService: action,
            GetAllTransactionOptionIdsService: action,
            resetGetTransactionDetailState: action,
            resetAddUpdateTransactionState: action,
            resetTransactionCustomerIdsUpdateState: action,
            resetExportTransactionState: action,
            resetAddTransactionCSVState: action,
            resetDeleteTransactionState: action,
            resetOptionIdsListState: action,
            GetAllMissingTransactionViewService: action,
            setFilterDetail: action,
            allTransaction: computed,
            getTransactionDetail: computed,
            getAddTransactionDetail: computed,
            allAvailableOptionIds: computed,
            getMissingTransactionViewData: computed,
        });
    }

    getAllTransactionService = (productId: string, currentPage: number, pagerSize: number, orderBy: string, column: string, filter: ITransactionInitialFilterState) => {
        let startDateData = moment(filter.StartDate).utc().format(DATE_TIME_FORMAT_TO_BACKEND);
        let endDateData = moment(filter.EndDate).utc().format(DATE_TIME_FORMAT_TO_BACKEND);
        // const tempStartDate = startDateData + " 00:00:00";
        // const tempEndDate = endDateData + " 23:59:59";

        let requestBody = {
            StartDate: startDateData,
            EndDate: endDateData,
            TicketNumber: filter.TicketNumber,
            TruckId: filter.TruckId,
            CustomerID1: filter.CustomerID1,
        }

        this.inProgress = true;
        const clientIdFromLocalStorage = getClientId() != null ? getClientId() : -1;
        const url = UrlConstants.GetAllTransactions + '?productId=' + productId + '&pageNo=' + currentPage + '&pageSize=' + pagerSize + '&column=' + column + '&orderBy=' + orderBy + '&clientId=' + `${clientIdFromLocalStorage}`; return services.post(url, { ...requestBody })
            // return services.get(url)
            .then((response: IApiResponse<any>) => {
                this.transactionData = response.data.Data;
            })
            .catch((err: string) => {
                // this.errors = err;
                toast.error(formatMessage(err));
            })
            .finally(action(() => { this.inProgress = false; }));
    }


    get allTransaction() {
        if (this.transactionData?.Tickets && this.transactionData.Tickets?.length > 0) {
            const TransactionName = localStorage.getItem("secure_fuel_TransactionName");
            let decimalValue = getAmountDecimal();
            return this.transactionData.Tickets.map((transaction) => {
                return {
                    ...transaction,
                    TsStart: moment(transaction.TsStart).format(DATE_TIME_FORMAT_TO_BACKEND),
                    TsFinish: moment(transaction.TsFinish).format(DATE_TIME_FORMAT_TO_BACKEND),
                    Unit: transaction.Unit !== null ? (transaction.Unit === "L" ? "Litre" : transaction.Unit) : transaction.Unit,
                    GrossDelivered: roundWithPrecision(transaction.GrossDelivered, 2),
                    PricePerL: Number(transaction.PricePerL).toFixed(decimalValue),
                };
            })
        }
        return [];
    }

    getTransactionByIdService = (id: number) => {
        this.transactionDetailState.inProgress = true;
        const clientIdFromLocalStorage = getClientId() != null ? getClientId() : -1;
        const url = `${UrlConstants.GetTransactionById}?id=${id}&clientId=${clientIdFromLocalStorage}`;
        return services.get(url)
            .then((response: IApiResponse<any>) => {
                this.transactionDetail = response.data.Data;
            })
            .catch((err: string) => {
                // this.transactionDetailState.error = err;
                toast.error(formatMessage(err));
            }).finally(action(() => { this.transactionDetailState.inProgress = false; }));
    }


    resetGetTransactionDetailState = () => {
        this.transactionDetail = undefined;
        this.transactionDetailState = { ...this.initialStateValue };
    }

    get getTransactionDetail() {
        let decimalValue = getAmountDecimal();
        if (this.transactionDetail) {
            return {
                ...this.transactionDetail,
                TsStart: moment(this.transactionDetail.TsStart).format(DATE_TIME_FORMAT_TO_BACKEND),
                TsFinish: moment(this.transactionDetail.TsFinish).format(DATE_TIME_FORMAT_TO_BACKEND),
                TsGen: this.transactionDetail.TsGen ? moment(this.transactionDetail.TsGen).format(DATE_TIME_FORMAT_TO_BACKEND) : emptyValue,
                TsSend: this.transactionDetail.TsSend ? moment(this.transactionDetail.TsSend).format(DATE_TIME_FORMAT_TO_BACKEND) : emptyValue,
                EndGross: roundWithPrecision(this.transactionDetail.EndGross, 2),
                FlowMax: roundWithPrecision(this.transactionDetail.FlowMax, 2),
                FlowAvg: roundWithPrecision(this.transactionDetail.FlowAvg, 2),
                GrossDelivered: roundWithPrecision(this.transactionDetail.GrossDelivered, 2),
                PricePerL: roundWithPrecision(this.transactionDetail.PricePerL, decimalValue),
                MeterId: roundWithPrecision(this.transactionDetail.MeterId, 2),
                NetDelivered: roundWithPrecision(this.transactionDetail.NetDelivered, 2),
                Vat: roundWithPrecision(this.transactionDetail.Vat, 2),
                AmountDue: roundWithPrecision(this.transactionDetail.AmountDue, decimalValue),
                VatAmount: roundWithPrecision(this.transactionDetail.VatAmount, decimalValue),
                // InvValue1: roundWithPrecision(this.transactionDetail.InvValue1, 2),
                // InvValue2: roundWithPrecision(this.transactionDetail.InvValue2, 2),
                // Unit: this.transactionDetail.Unit === "L" ? formatMessage("liter_unit") : formatMessage("gallon_unit")
            };
        }
        return transactionState;
    }

    get getAddTransactionDetail(): IAddTransactions {
        let currentDateTime = getCurrentDateTime();
        if (this.transactionDetail) {
            return {
                ...this.transactionDetail,
                DeviceId: this.transactionDetail.DeviceId !== null ? this.transactionDetail.DeviceId : "",
                ClientId: this.transactionDetail.ClientId !== null ? this.transactionDetail.ClientId : "",
                TruckId: this.transactionDetail.TruckId !== null ? this.transactionDetail.TruckId : "",
                // Unit: this.transactionDetail.Unit === "L" ? "liter_unit" : "gallon_unit",
                Unit: this.transactionDetail.Unit !== null ? (this.transactionDetail.Unit === "L" ? "liter_unit" : "gallon_unit") : "liter_unit",
                TicketNumber: this.transactionDetail.TicketNumber !== null ? this.transactionDetail.TicketNumber : "",
                ProductId: this.transactionDetail.ProductId !== null ? this.transactionDetail.ProductId : "",
                TsStart: this.transactionDetail.TsStart !== "1970-01-01T00:00:00.000Z" ? new Date(moment(this.transactionDetail.TsStart).format(DATE_TIME_FORMAT_TO_BACKEND)) : "",
                TsFinish: this.transactionDetail.TsFinish !== "1970-01-01T00:00:00.000Z" ? new Date(moment(this.transactionDetail.TsFinish).format(DATE_TIME_FORMAT_TO_BACKEND)) : "",
                MeterId: this.transactionDetail.MeterId !== null ? roundWithPrecision(this.transactionDetail.MeterId, 2) : "",
                NetDelivered: this.transactionDetail.NetDelivered !== null ? roundWithPrecision(this.transactionDetail.NetDelivered, 2) : "",
                Lat: this.transactionDetail.Lat !== null ? this.transactionDetail.Lat : "",
                Lon: this.transactionDetail.Lon !== null ? this.transactionDetail.Lon : "",
                EndGross: this.transactionDetail.EndGross !== null ? roundWithPrecision(this.transactionDetail.EndGross, 2) : "",
                FlowMax: this.transactionDetail.FlowMax !== null ? roundWithPrecision(this.transactionDetail.FlowMax, 2) : "",
                FlowAvg: this.transactionDetail.FlowAvg !== null ? roundWithPrecision(this.transactionDetail.FlowAvg, 2) : "",
                GrossDelivered: this.transactionDetail.GrossDelivered !== null ? roundWithPrecision(this.transactionDetail.GrossDelivered, 2) : "",
                TsGen: this.transactionDetail.TsGen ? new Date(moment(this.transactionDetail.TsGen).format(DATE_TIME_FORMAT_TO_BACKEND)) : "",
                TsSend: this.transactionDetail.TsSend ? new Date(moment(this.transactionDetail.TsSend).format(DATE_TIME_FORMAT_TO_BACKEND)) : "",
                VehicleRfid: this.transactionDetail.VehicleRfid !== null ? this.transactionDetail.VehicleRfid : "",
                DriverRfid: this.transactionDetail.DriverRfid !== null ? this.transactionDetail.DriverRfid : "",
                OperatorRfid: this.transactionDetail.OperatorRfid !== null ? this.transactionDetail.OperatorRfid : "",
                CustomerId1: this.transactionDetail.CustomerId1 !== null ? this.transactionDetail.CustomerId1 : "",
                CustomerId2: this.transactionDetail.CustomerId2 !== null ? this.transactionDetail.CustomerId2 : "",
                CustomerId3: this.transactionDetail.CustomerId3 !== null ? this.transactionDetail.CustomerId3 : "",
                CustomerId4: this.transactionDetail.CustomerId4 !== null ? this.transactionDetail.CustomerId4 : "",
                CustomerId5: this.transactionDetail.CustomerId5 !== null ? this.transactionDetail.CustomerId5 : "",
                CustomerId6: this.transactionDetail.CustomerId6 !== null ? this.transactionDetail.CustomerId6 : "",
                InvName1: this.transactionDetail.InvName1 !== null ? this.transactionDetail.InvName1 : "",
                InvUnit1: this.transactionDetail.InvUnit1 !== null ? (this.transactionDetail.InvUnit1 === "L" ? "liter_unit" : "gallon_unit") : "liter_unit",
                InvValue1: this.transactionDetail.InvValue1 !== null ? roundWithPrecision(this.transactionDetail.InvValue1, 2) : "",
                InvName2: this.transactionDetail.InvName2 !== null ? this.transactionDetail.InvName2 : "",
                InvUnit2: this.transactionDetail.InvUnit2 !== null ? (this.transactionDetail.InvUnit2 === "L" ? "liter_unit" : "gallon_unit") : "liter_unit",
                // InvUnit2: this.transactionDetail.InvUnit2 === "L" ? "liter_unit" : "gallon_unit",
                InvValue2: this.transactionDetail.InvValue2 !== null ? roundWithPrecision(this.transactionDetail.InvValue2, 2) : "",
                // Vat: this.transactionDetail.Vat !== null ? roundWithPrecision(this.transactionDetail.Vat, 2) : "",
                // PricePerL: this.transactionDetail.PricePerL !== null ? roundWithPrecision(this.transactionDetail.PricePerL, 2) : "",
                // AmountDue: this.transactionDetail.AmountDue !== null ? roundWithPrecision(this.transactionDetail.AmountDue, 2) : "",
                // VatAmount: roundWithPrecision(this.transactionDetail.VatAmount, 2),
            }
        }
        return { ...addTransactionInitialState, TsStart: currentDateTime, TsFinish: currentDateTime, TsGen: currentDateTime, TsSend: currentDateTime };
    }

    GetAllTransactionOptionIdsService = (productId: string) => {
        this.optionIdsListState.inProgress = true;
        const clientIdFromLocalStorage = getClientId() != null ? getClientId() : -1;
        const url = `${UrlConstants.GetAllCustomerAndSupplierAdminsNames}?clientId=${clientIdFromLocalStorage}&productId=${productId}`;
        return services.get(url)
            .then((response: IApiResponse<IApiSuccessResponse<Array<IOption>>>) => {
                this.optionIdsList = response.data.Data;
                this.optionIdsListState.success = true;
            })
            .catch((err: string) => {
                this.optionIdsListState.error = err;
            })
            .finally(action(() => { this.optionIdsListState.inProgress = false; }));
    }

    get allAvailableOptionIds(): IOption[] {
        const options: IOption[] = [
            {
                Id: -1,
                Name: "please_select",
            }
        ];
        if (this.optionIdsList && this.optionIdsList?.length > 0)
            this.optionIdsList.map((item) => {
                options.push({
                    Id: item.Id,
                    Name: item.Name,
                })
            })
        return options;
    }

    resetOptionIdsListState = () => {
        this.optionIdsListState = { ...this.initialStateValue }
        this.optionIdsList = [];
    }

    addTransactionService = (data: IAddTransactions) => {
        this.addUpdateTransactionState.inProgress = true;
        const url = `${UrlConstants.AddTransaction}`;
        return services.post(url, data)
            .then((response: IApiResponse<any>) => {
                this.addUpdateTransactionState.success = true;
            })
            .catch((err: string) => {
                toast.error(formatMessage(err));
                // this.transactionAddError = err;
            }).finally(action(() => { this.addUpdateTransactionState.inProgress = false; }));
    }

    updateTransactionService = (id: number, data: IAddTransactions) => {
        this.addUpdateTransactionState.inProgress = true;
        const url = `${UrlConstants.UpdateTransaction}`;
        return services.put(url, { ...data })
            .then((response: IApiResponse<any>) => {
                this.addUpdateTransactionState.success = true;
            })
            .catch((err: string) => {
                toast.error(formatMessage(err));
                // this.transactionAddError = err;
            }).finally(action(() => { this.addUpdateTransactionState.inProgress = false; }));
    }

    /**
     * This function is used to reset addUpdateTransactionState observables to their initial values.
     * @returns
     */
    resetAddUpdateTransactionState = () => {
        this.addUpdateTransactionState = { ...this.initialStateValue }
    }

    updateCustomerIdsInTransactionByIdService = (id: number, data: IUpdateTransactions) => {
        this.transactionCustomerIdsUpdateState.inProgress = true;

        let putData = {
            ...data,
            ticketId: id
        }
        const clientIdFromLocalStorage = getClientId() != null ? getClientId() : -1;
        const url = `${UrlConstants.UpdateCustomerIdsInTransaction}?clientId=${clientIdFromLocalStorage}`;
        return services.put(url, putData)
            .then((response: IApiResponse<any>) => {
                this.transactionCustomerIdsUpdateState.success = true;
            })
            .catch((err: string) => {
                // this.transactionCustomerIdsUpdateState.error = err;
                toast.error(formatMessage(err));
            }).finally(action(() => { this.transactionCustomerIdsUpdateState.inProgress = false; }));
    }

    /**
     * This function is used to reset transactionCustomerIdsUpdateState observables to their initial values.
     * @returns
     */
    resetTransactionCustomerIdsUpdateState = () => {
        this.transactionCustomerIdsUpdateState = { ...this.initialStateValue }
    }

    /**
    * This function is used to Add New transactions using CSV Service. 
    * @param transactions : Add transactions CSV 
    * @returns
    */
    addTransactionCSVService = (data: ICSVImportForm) => {
        const clientIdFromLocalStorage = getClientId() != null ? getClientId() : -1;
        this.addTransactionCSVState.inProgress = true;
        const formData = new FormData();
        if (data.CSVFile)
            formData.append('file', data.CSVFile); // Append the file to FormData
        return services.postMultipart(UrlConstants.ImportTicketCsv + "?clientName=" + data.ClientName, formData)
            .then((response: IApiResponse<IApiSuccessResponse<boolean>>) => {
                this.addTransactionCSVState.success = true;
                toast.success(formatMessage("added_success"));
                this.downloadLog(response.data);
            })
            .catch((err: any) => {
                if (err.message && err.data) {
                    switch (err.message) {
                        case ErrorMessage.AllRecordsDuplicate:
                            toast.error(formatMessage(err.message ?? err));
                            this.downloadLog({ Data: err.data });
                            break
                        case ErrorMessage.SomeRecordsDuplicate:
                            toast.info(formatMessage(err.message ?? err));
                            this.downloadLog({ Data: err.data });
                            this.addTransactionCSVState.success = true;
                            break
                        case ErrorMessage.ErrorLog:
                            toast.error(formatMessage(err.message ?? err));
                            this.downloadErrorLog(err.data);
                            break
                        default:
                            break
                    }
                }
                else {
                    toast.error(formatMessage(err));
                }
            })
            .finally(action(() => { this.addTransactionCSVState.inProgress = false; }));
    }

    downloadErrorLog = (data: any) => {
        const currentDate = new Date();
        const splittedData = data.split("Row");
        const logContent = `${splittedData.map((item: any, index: number) => `${index > 0 ? `Row ${item}\n` : `${item}\n`}`).join("")}`;
        const blob = new Blob([logContent], { type: "text/plain" });
        const a = document.createElement("a");
        a.href = URL.createObjectURL(blob);
        a.download = moment(currentDate).format(DATE_TIME_FORMAT_FILENAME) + " log.txt";
        a.click();
        URL.revokeObjectURL(a.href);
    };

    /**
    * This function is used to download Log. 
    * @param deviceRegistereds : DeviceRegister CSV 
    * @returns
    */
    downloadLog = (data: any) => {
        const currentDate = new Date();
        const logContent = this.generateLogContent(data.Data);
        const blob = new Blob([logContent], { type: "text/plain" });
        const a = document.createElement("a");
        a.href = URL.createObjectURL(blob);
        a.download = moment(currentDate).format(DATE_TIME_FORMAT_FILENAME) + " log.txt";
        a.click();
        URL.revokeObjectURL(a.href);
    };

    generateLogContent = (data: any) => {
        const currentDate = new Date();

        const generateErrorList = (values: string[] | number, itemsPerLine: number = 5) => {
            if (!Array.isArray(values) || values.length === 0) {
                return 'None';
            }

            const formattedList = values.map((item, index) => {
                const separator = (index + 1) % itemsPerLine === 0 ? '\n' : ', ';
                return `${item}${separator}`;
            });

            return formattedList.join('').trim();
        };

        const logContent = `
Log generated on: ${moment(currentDate).format(DATE_TIME_FORMAT_TO_BACKEND)}
Total Transactions Count (To be added): ${data.TotalTransactionsCount}
Transactions Added: ${data.TransactionsAdded}
Transactions Failed: ${data.TransactionsFailed}

**Errors**
**Duplicate Transactions: Ticket Numbers Unavailable (Count: ${data.DuplicateTicketNumbers?.length}) ** :
 ${data?.DuplicateTicketNumbers?.length > 0 ? generateErrorList(data.DuplicateTicketNumbers) : 0}
`;

        return logContent;
    };

    /**
     * This function is used to reset Export CSV observables to their initial values.
     * @returns
     */
    resetAddTransactionCSVState = () => {
        this.addTransactionCSVState = { ...this.initialStateValue }
    }

    exportTransactionReportByIdService = (id: number, productId: string, ticketNumber: string, CustomerId1: string) => {
        this.transactionDownloadState.inProgress = true;
        const clientIdFromLocalStorage = getClientId() != null ? getClientId() : -1;
        const url = `${UrlConstants.ExportTransactionReport}?reportFormat=pdf&id=${id}&clientId=${clientIdFromLocalStorage}&tz=${moment().utcOffset()}`;
        return services.downloadFile(url)
            .then((response: AxiosResponse<any>) => {
                this.transactionDownloadState.success = true;
                downloadPdfFile(response, `transaction_${CustomerId1}_${ticketNumber}`, 'pdf');
            })
            .catch((err: string) => {
                this.transactionDownloadState.error = err;
                toast.error(formatMessage(err));
            }).
            finally(action(() => { this.transactionDownloadState.inProgress = false; }));
    }

    getAllMissingTransactionService = (title: string, filter: IMissingTicket) => {
        let startDateData = moment(filter.StartDate).utc().format(DATE_TIME_FORMAT_TO_BACKEND);
        let endDateData = moment(filter.EndDate).utc().format(DATE_TIME_FORMAT_TO_BACKEND);
        // const tempStartDate = startDateData + " 00:00:00";
        // const tempEndDate = endDateData + " 23:59:59";

        let requestBody = {
            StartDate: startDateData,
            EndDate: endDateData,
            TruckId: filter.TruckId,
        }

        const clientIdFromLocalStorage = getClientId() != null ? getClientId() : -1;
        const url = UrlConstants.GetAllMissingTransactions + '?clientId=' + `${clientIdFromLocalStorage}&tz=${moment().utcOffset()}`;
        const token = getToken();
        this.missingTransactionState.inProgress = true;
        axiosDownload({
            url: url,
            method: 'POST',
            responseType: 'blob',
            headers: {
                'authorization': 'Bearer ' + token
            },
            data: requestBody
        }).then((response: any) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', title + '_' + moment(Date.now()).format(DATE_TIME_FORMAT_FILENAME) + '.csv');
            document.body.appendChild(link);
            link.click();
            toast.success(formatMessage("downloaded_started_successfully"));
        }).catch((err: any) => {
            if (err.response.status === 402) {
                toast.error(formatMessage(ErrorMessage.NoMissingTicketsFound));
            } else {
                toast.error(formatMessage(ErrorMessage.ErrorOccured));
            }
        })
            .finally(action(() => { this.missingTransactionState.inProgress = false; }));
    }

    GetAllMissingTransactionViewService = (title: string, filter: IMissingTicket, pageNo: number, pageSize: number) => {
        let startDateData = moment(filter.StartDate).utc().format(DATE_TIME_FORMAT_TO_BACKEND);
        let endDateData = moment(filter.EndDate).utc().format(DATE_TIME_FORMAT_TO_BACKEND);
        // const tempStartDate = startDateData + " 00:00:00";
        // const tempEndDate = endDateData + " 23:59:59";

        let requestBody = {
            StartDate: startDateData,
            EndDate: endDateData,
            TruckId: filter.TruckId,
        }

        const clientIdFromLocalStorage = getClientId() != null ? getClientId() : -1;
        const url = UrlConstants.GetAllMissingViewTransactions + `?clientId=${clientIdFromLocalStorage}&tz=${moment().utcOffset()}&pageNo=${pageNo}&pageSize=${pageSize}`;
        this.missingTransactionViewState.inProgress = true;
        return services.post(url, requestBody)
            .then((response: IApiResponse<IApiSuccessResponse<any>>) => {
                this.missingTransactionViewData = response.data.Data;
                this.missingTransactionViewState.success = true;
            })
            .catch((err: string) => {
                this.missingTransactionViewState.error = err;
                toast.error(formatMessage(err));
            })
            .finally(action(() => { this.missingTransactionViewState.inProgress = false; }));
    }

    resetTransactionViewDataState = () => {
        this.missingTransactionViewState = { ...this.initialStateValue }
        this.missingTransactionViewData = getAllMissingTransactionState
    }

    get getMissingTransactionViewData() {
        if (this.missingTransactionViewData) {
            return { ...this.missingTransactionViewData, MissingTickets: this.missingTransactionViewData.MissingTickets.map((item: any) => { return { TicketNumber: item } }) }
        }
        else {
            return this.missingTransactionViewData
        }
    }

    deleteTransactionService = (id: number) => {
        this.deleteTransactionState.inProgress = true;
        const clientIdFromLocalStorage = getClientId() != null ? getClientId() : -1;
        const url = `${UrlConstants.DeleteTransaction}?id=${id}&clientId=${clientIdFromLocalStorage}`;
        return services.deleteapi(url)
            .then(() => {
                this.deleteTransactionState.success = true;
            })
            .catch((err: string) => {
                // this.deleteTransactionState.error = err;
                toast.error(formatMessage(err));
            })
            .finally(action(() => { this.deleteTransactionState.inProgress = false; }));
    }

    resetDeleteTransactionState = () => {
        this.deleteTransactionState = { ...this.initialStateValue }
    }

    resetExportTransactionState = () => {
        this.transactionDownloadState = { ...this.initialStateValue }
    }

    reset = () => {
        this.errors = '';
        this.inProgress = false;
        this.transactionDetail = undefined;
        this.addUpdateTransactionState = { ...this.initialStateValue };
        this.transactionCustomerIdsUpdateState = { ...this.initialStateValue };
        this.transactionDetailState = { ...this.initialStateValue };
        this.transactionDownloadState = { ...this.initialStateValue };
    }

    /*
    This function is used to set the filter details to the Filter observable.  
    */
    setFilterDetail = (filter: ITransactionFilter) => {
        this.filter = { ...filter };
    }

}

export default new TransactionsStore();