/*    
<summary>
   This class component is all about Managing truck data functionality.
   Developer:Aashish Singh, Created Date:04-April-2024
</summary>
<param>No Parameter Passed</param>
<returns>Returns class instance</returns>
*/

import { makeObservable, observable, action, computed } from "mobx";
import IApiResponse, { IApiSuccessResponse } from "../../models/response/IApiResponse";
import UrlConstants from "../../constants/url.constant";
import * as services from '../service/base-service';
import { IObservableInitialState, IOption, ITruckOption } from "../../models/ICommon";
import moment from "moment";
import { DATE_TIME_FORMAT_FILENAME } from "../../constants/common-constant";
import { formatMessage } from "../../translations/formatMessage";
import { toast } from "react-toastify";
import { getClientId } from "../../helpers/localStorages.helper";
import { getTruckListState } from "../initialState/get-truck-list-state";
import { addEditTruckState as initialState } from "../initialState/add-edit-truck-state";
import { IAddEditTruck, IGetTruckList, ITruck, ITruckList } from "../../models/response/ITruckResponse";
import { truckInitialFilterState } from "../initialState/initial-filter-states";
import { ITruckFilter, ITruckInitialFilterState } from "../../models/IFilter";

export class TruckStore {
    inProgress = false;
    error = '';
    truckList: IGetTruckList = getTruckListState;
    truckData: ITruck[] = [];

    initialStateValue: IObservableInitialState = {
        success: false,
        error: '',
        inProgress: false
    }

    addUpdateTruckState: IObservableInitialState = { ...this.initialStateValue };
    DeleteTruckState: IObservableInitialState = { ...this.initialStateValue };

    truckDetail: ITruck | undefined = undefined;
    truckDetailState: IObservableInitialState = { ...this.initialStateValue };
    filter: ITruckFilter = {
        ...truckInitialFilterState
    }
    constructor() {
        makeObservable(this, {
            inProgress: observable,
            error: observable,
            truckList: observable,
            addUpdateTruckState: observable,
            DeleteTruckState: observable,
            truckDetail: observable,
            truckDetailState: observable,
            truckData: observable,
            GetAllTruckService: action,
            GetTruckListService: action,
            GetTruckByIdService: action,
            AddTruckService: action,
            UpdateTruckService: action,
            DeleteTruckService: action,
            reset: action,
            resetStore: action,
            resetGetTruckDetail: action,
            resetAddUpdateTruckState: action,
            resetDeleteTruckState: action,
            getTruckDetail: computed,
            getTruckList: computed,
            availableTruckOptions: computed,
            availableTruckOptionsWithAll: computed,
        });
    }

    GetAllTruckService = () => {
        this.inProgress = true;
        const clientIdFromLocalStorage = getClientId() != null ? getClientId() : -1;
        const url = `${UrlConstants.GetTruckSummary}?clientId=${clientIdFromLocalStorage}`;
        return services.get(url)
            .then((response: IApiResponse<IApiSuccessResponse<any>>) => {
                this.truckData = response.data.Data;
            })
            .catch((err: string) => {
                toast.error(formatMessage(err));
                this.error = err;
            })
            .finally(action(() => { this.inProgress = false; }));
    }

    get availableTruckOptions(): ITruckOption[] {
        const options: ITruckOption[] = [];
        if (this.truckData && this.truckData?.length > 0)
            this.truckData.map((truck) => {
                options.push({
                    Id: truck.Name ?? "",
                    Name: truck.Name ?? "",
                })
            })
        return options;
    }

    get availableTruckOptionsWithAll(): ITruckOption[] {
        const options: ITruckOption[] = [];
        if (this.truckData && this.truckData?.length > 0) {
            options.push({
                Id: 'All',
                Name: "All",
            });
            this.truckData.map((truck) => {
                options.push({
                    Id: truck.Name ?? "",
                    Name: truck.Name ?? "",
                })
            })
        }
        return options;
    }

    GetTruckListService = (currentPage: number, pagerSize: number, orderBy: string, column: string, filter: ITruckInitialFilterState) => {
        this.inProgress = true;
        const clientIdFromLocalStorage = getClientId() != null ? getClientId() : -1;
        const url = UrlConstants.GetTruckList + '?clientId=' + clientIdFromLocalStorage + '&pageNo=' + currentPage + '&pageSize=' + pagerSize + '&column=' + column + '&orderBy=' + orderBy;
        // const url = `${UrlConstants.GetTruckList}?clientId=${clientIdFromLocalStorage}`;
        // this.truckList = demoTruck;
        // this.inProgress = false;
        const requestBody = { AssetId: filter.TruckId };
        return services.post(url, requestBody)
            .then((response: IApiResponse<IApiSuccessResponse<any>>) => {
                this.truckList = response.data.Data;
            })
            .catch((err: string) => {
                this.error = err;
            })
            .finally(action(() => { this.inProgress = false; }));
    }

    get getTruckList(): ITruckList[] {
        if (this.truckList?.Assets && this.truckList.Assets?.length > 0) {
            return this.truckList.Assets.map((truck: ITruck) => {
                let IsDelete: boolean = true;
                let IsEdit: boolean = true;
                return {
                    ...truck,
                    Compartment: truck.Compartment === 0 ? 1 : 2,
                    InventorySource: truck.InventorySource === 0 ? "Ticket" : "Level Sensor",
                    IsDeletable: IsDelete,
                    IsEditable: IsEdit,
                }
            })
        }
        return [];
    }

    GetTruckByIdService = (id: number) => {
        this.truckDetailState.inProgress = true;
        const clientIdFromLocalStorage = getClientId() != null ? getClientId() : -1;
        const url = `${UrlConstants.GetTruckById}?id=${id}&clientId=${clientIdFromLocalStorage}`;
        // const tempDetail = this.truckList.Trucks.filter((d:ITruck)=>d.TruckId === id);
        // this.truckDetail = tempDetail.length > 0 ? {...tempDetail[0]} : undefined;
        return services.get(url)
            .then((response: IApiResponse<IApiSuccessResponse<any>>) => {
                this.truckDetail = response.data.Data;
            })
            .catch((err: string) => {
                toast.error(formatMessage(err));
                this.truckDetailState.error = err;
            }).finally(action(() => { this.truckDetailState.inProgress = false; }));
    }

    get getTruckDetail(): IAddEditTruck {
        if (this.truckDetail) {
            return {
                ...this.truckDetail,
                TruckId: this.truckDetail.AssetId
            };
        }
        return initialState;
    }

    resetGetTruckDetail = () => {
        this.truckDetail = undefined;
        this.truckDetailState = { ...this.initialStateValue };
    }

    AddTruckService = (id: number, data: IAddEditTruck) => {
        this.addUpdateTruckState.inProgress = true;
        const clientIdFromLocalStorage = getClientId() != null ? getClientId() : -1;
        let url = `${UrlConstants.AddTruck}?clientId=${clientIdFromLocalStorage}`;
        let requestBody = {
            ...data,
            ClientId: Number(clientIdFromLocalStorage),
            AssetId: data.TruckId,
            AccountId: String(clientIdFromLocalStorage)
        }
        return services.post(url, { ...requestBody })
            .then(() => {
                this.addUpdateTruckState.success = true;
            })
            .catch((err: string) => {
                this.addUpdateTruckState.error = err;
            })
            .finally(action(() => { this.addUpdateTruckState.inProgress = false; }));
    }

    UpdateTruckService = (id: number, data: IAddEditTruck) => {
        this.addUpdateTruckState.inProgress = true;
        const clientIdFromLocalStorage = getClientId() != null ? getClientId() : -1;
        let url = `${UrlConstants.UpdateTruck}?id=${id}&clientId=${clientIdFromLocalStorage}`;
        // let url = `${UrlConstants.UpdateTruck}?clientId=${clientIdFromLocalStorage}`;
        let requestBody = {
            ...data,
            ClientId: Number(clientIdFromLocalStorage),
            AssetId: data.TruckId,
            AccountId: String(clientIdFromLocalStorage)
        }
        return services.put(url, { ...requestBody })
            .then(() => {
                this.addUpdateTruckState.success = true;
            })
            .catch((err: string) => {
                this.addUpdateTruckState.error = err;
            })
            .finally(action(() => { this.addUpdateTruckState.inProgress = false; }));
    }

    resetAddUpdateTruckState = () => {
        this.addUpdateTruckState = { ...this.initialStateValue }
    }

    DeleteTruckService = (id: number) => {
        this.DeleteTruckState.inProgress = true;
        const clientIdFromLocalStorage = getClientId() != null ? getClientId() : -1;
        const url = `${UrlConstants.DeleteTruck}?id=${id}&clientId=${clientIdFromLocalStorage}`;
        return services.deleteapi(url)
            .then(() => {
                this.DeleteTruckState.success = true;
            })
            .catch((err: string) => {
                this.DeleteTruckState.error = err;
            })
            .finally(action(() => { this.DeleteTruckState.inProgress = false; }));
    }

    resetDeleteTruckState = () => {
        this.DeleteTruckState = { ...this.initialStateValue }
    }

    reset = () => {
        this.error = '';
        this.inProgress = false;
    }

    resetError = () => {
        this.error = '';
    }

    /**
     * This function is used to reset all store observables to their initial values.
     * @returns
     */
    resetStore = () => {
        this.error = '';
        this.inProgress = false;
        this.truckList = getTruckListState;

        this.truckDetail = undefined;
        this.truckDetailState = { ...this.initialStateValue }

        this.addUpdateTruckState = { ...this.initialStateValue }

        this.DeleteTruckState = { ...this.initialStateValue }
    }

    /**
     * This function is used to convert truck list data to the corresponding CSV format
     * @param data : Truck List data
     * @returns csvData suitable for creating blob object
     */
    convertToCSV = (data: any[]): string => {
        const header = Object.keys(data[0]).join(",");
        const rows = data.map((obj) => Object.values(obj).join(","));
        return `${header}\n${rows.join("\n")}`;
    };

    /**
     * This function downloads the CSV by dynamically making an <a> tag. 
     * @param data : Truck List data
     */
    downloadTruckCSV = (data: ITruckList[]) => {
        const currentDate = new Date();
        if (data.length > 0) {
            const csvData = this.convertToCSV(this.exportTruckCSV(data));
            const blob = new Blob([csvData], { type: "text/csv" });
            const url = URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            a.download = moment(currentDate).format(DATE_TIME_FORMAT_FILENAME) + " Trucks.csv";
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        }
        else
            toast.warn(formatMessage("no_data_to_export"));
    };

    setFilterDetail = (filter: ITruckFilter) => {
        this.filter = { ...filter };
    }

    /**
     * This function is used to convert the truckDetailsList to the required data suitable for convertToCSV function.
     * @param truckDataList : Truck List
     * @returns CSV data suitable for convertToCSV function
     */
    exportTruckCSV(truckDataList: any): ITruckList[] {
        if (truckDataList && truckDataList?.length > 0)
            return truckDataList?.map((truck: ITruck) => {
                return {
                    Id: truck.Id,
                    DeviceId: truck.DeviceId,
                    AccountId: truck.AccountId,
                    TruckId: truck.TruckId,
                    Compartment: truck.Compartment,
                }
            })
        return [];
    }
}

export default new TruckStore();