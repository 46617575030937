/*    
<summary>
   This is the Alarm Component which shows the Alarms Data.
   Developer: Aashish Singh, Created Date:04-March-2024
</summary>
<param>No Parameter Passed</param>
<returns>Returns JSX</returns>
*/
import { observer } from "mobx-react";
import React, { useState, useCallback, useEffect } from "react";
import { toast } from "react-toastify";
import { ColumnType } from "../../constants/enums/gridColumnTypeEnum";
import { useStore } from "../../contexts/StoreProvider";
import useWindowDimensions, { getTableMaxHeight, } from "../../helpers/windowResize.helper";
import { ISorting } from "../../models/ISorting";
import Grid, { getColumns } from "../../shared-components/Grid/Grid";
import SearchField from "../../shared-components/SearchField/SearchField";
import { formatMessage } from "../../translations/formatMessage";
import Pagination from "../../shared-components/Grid/Pagination";
import IPageInfo from "../../models/IPageInfo";
import { IActionFormatter } from "../../models/IFormatter";
import Header from "../../shared-components/Header/Header";
import { PageLimitOptions, initialPageLimit, } from "../../constants/pageLimit.constant";
import { Col, Container, ListGroup, Row } from "react-bootstrap";
import { IAlarm, IAlarmList } from "../../models/response/IAlarmResponse";
import Footer from "../../shared-components/Footer/Footer";
import { withSize } from "react-sizeme";
import SubHeader from "../../shared-components/Header/SubHeader";
import "./alarm.scss";
import { FilterPopupHOC } from "../../shared-components/Filters/FilterPopupHOC";
import AlarmsFilterForm from "./forms/alarms-filter-form";
import { ModuleName } from "../../constants/moduleName.constant";
import { FormattedMessage } from "react-intl";
import NoData from "../../assets/Images/no_record.png";
import SpinnerLoader from "../../shared-components/Loader/SpinnerLoader";
import { filter } from "lodash";
import _ from "lodash";
import UrlConstants from "../../constants/url.constant";
import { getClientId } from "../../helpers/localStorages.helper";
import { IAlarmInitialFilterState } from "../../models/IFilter";
import { AlarmTypeEnum } from "../../constants/enums/alarm-type-enum";
import { alarmTypes } from "../../constants/common-constant";
import preferencesStore from "../../core/stores/preferences-store";
import moment from "moment";
import { alarmInitialFilterState } from "../../core/initialState/initial-filter-states";
import userPageLimitSettingEnum from "../../constants/enums/user-pagination-setting-enum";
interface IProps {
  size: {
    height: number;
  };
  showFilterPopup: boolean;
  filterToggleHandler: () => void;
}

const Alarm: React.FC<IProps> = (props: IProps) => {
  const {
    showFilterPopup,
    filterToggleHandler,
  } = props;

  const { alarmStore } = useStore();
  const { setUpdateTableView, CSVFileDownloadPostService, userPageLimitSetting, setUserPageLimitSetting } = preferencesStore;
  const {
    inProgress, error,
    alarmList, GetAlarmListService,
    getAlarmList, reset, resetError,
    filter, setFilterDetail
  } = alarmStore;

  const [selectedId, setSelectedId] = useState<number>(-1);
  const [changeDetails, setChangeDetails] = useState<boolean>(false);
  const [disablePagination, setDisablePagination] = useState<boolean>(false);
  const alarmListData: IAlarmList[] = getAlarmList;
  const [pageLimit, setPageLimit] = useState<number>(userPageLimitSetting.alarmLimitConfig);
  const [searchText, setSearchText] = useState("");
  const [sorting, setSorting] = useState<ISorting>({
    column: "alarm_timestamp",
    orderBy: "desc",
  });
  const [isApiCalled, setIsApiCalled] = useState<boolean>(false);

  const [pageInfo, setPageInfo] = useState<IPageInfo>({
    currentPage: 1,
    totalPages: 1,
  });

  const { height, width } = useWindowDimensions();

  const onPageChanged = useCallback((pageNumber: number) => {
    setPageInfo((pageInfo) => ({ ...pageInfo, currentPage: pageNumber }));
  }, []);

  const onPageLimitChangedForUser = (
    evt: React.ChangeEvent<HTMLSelectElement>
  ) => {
    let value = parseInt(evt.target.value);
    setPageLimit(value);
    setPageInfo({ ...pageInfo, currentPage: 1 })
    setUserPageLimitSetting(userPageLimitSettingEnum.AlarmLimitConfig, value)
  };

  const onSearchTextChanged = (text: string) => {
    setSearchText(text);
  };

  const openFilterHandler = () => {
    filterToggleHandler();
  };

  /**
   * This function calls the "GetAlarmListService" store funtion that get alarm list from API
   */
  const callGetAlarmList = useCallback(() => {
    let tempFilterDetails = { ...filter };
    tempFilterDetails = {
      ...tempFilterDetails,
      AlarmTimestamp: tempFilterDetails.StartDate,
      AlarmType:
        tempFilterDetails.AlarmType == -1 ? null : tempFilterDetails.AlarmType,
    };
    setDisablePagination(true);
    GetAlarmListService(
      pageInfo.currentPage,
      pageLimit,
      sorting.orderBy,
      sorting.column,
      searchText,
      tempFilterDetails
    );
    setIsApiCalled(true);
  }, [
    GetAlarmListService,
    pageInfo.currentPage,
    pageLimit,
    sorting.orderBy,
    sorting.column,
    searchText,
    filter
  ]);

  // useEffect(() => {
  //   callGetAlarmList();
  // }, [callGetAlarmList]);

  // useEffect(() => {
  //   if (isApiCalled) {
  //     // showUserDetailToggleHandler();
  //     setIsApiCalled(false);
  //   }
  // }, [isApiCalled]);

  useEffect(() => {
    if (filter.isFilterActive) {
      callGetAlarmList();
    }
    else
      callGetAlarmList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter, callGetAlarmList]);

  useEffect(() => {
    setPageInfo((pageInfo) => ({
      ...pageInfo,
      totalPages: alarmList?.TotalPages,
    }));
    setDisablePagination(false);
  }, [getAlarmList, alarmList.TotalPages]);

  useEffect(() => {
    if (error) {
      toast.error(formatMessage(error));
      resetError();
    }
  }, [error, resetError]);

  /**
   * This function shows the detail alarm panel
   */
  const showDetailsClickHandler = (row: { Id: number }) => {
    setSelectedId(row.Id);
    setChangeDetails(true);
    // !showUserDetail && showUserDetailToggleHandler();
    setIsApiCalled(false);
  };

  const handleSortingChange = (accessor: string) => {
    let sortOrder = sorting.orderBy;
    if (accessor === sorting.column) {
      sortOrder = sorting.orderBy === "asc" ? "desc" : "asc";
    }
    setSorting({ column: accessor, orderBy: sortOrder });
  };

  const addIcons: Array<IActionFormatter<string>> = [
    {
      handler: showDetailsClickHandler,
      icon: <svg
        enableBackground="new 0 0 519.643 519.643"
        width="14"
        height="14"
        viewBox="0 0 519.643 519.643"
      >
        <circle cx="259.823" cy="259.866" r="80" />
        <path d="m511.673 237.706c-61.494-74.31-154.579-145.84-251.85-145.84-97.29 0-190.397 71.58-251.85 145.84-10.63 12.84-10.63 31.48 0 44.32 15.45 18.67 47.84 54.71 91.1 86.2 108.949 79.312 212.311 79.487 321.5 0 43.26-31.49 75.65-67.53 91.1-86.2 10.599-12.815 10.654-31.438 0-44.32zm-251.85-89.84c61.76 0 112 50.24 112 112s-50.24 112-112 112-112-50.24-112-112 50.24-112 112-112z" />
      </svg>,
      key: "",
      title: "label_showdetails",
      className: "btn-secondary"
    }
  ];

  const columns = getColumns<IAlarm, number>(
    [
      {
        columnType: ColumnType.Hidden,
        accessorKey: "Id",
      },
      {
        columnType: ColumnType.TextHeader,
        accessorKey: "AlarmType",
        // sortingName: "alarm_type",
        displayName: "alarm_type",
      },
      // {
      //   columnType: ColumnType.ActionsFormatter,
      //   icons: addIcons,
      //   displayName: "label_action",
      // },
      {
        columnType: ColumnType.Sorting,
        accessorKey: "AlarmTimestamp",
        sortingName: "alarm_timestamp",
        displayName: "alarm_timestamp",
      },
      {
        columnType: ColumnType.TextHeader,
        accessorKey: "ActionTaken",
        // sortingName: "action_taken",
        displayName: "action_taken",
      },
      {
        columnType: ColumnType.TextHeader,
        accessorKey: "AlarmDetail",
        // sortingName: "alarm_detail",
        displayName: "alarm_detail",
      },
      {
        columnType: ColumnType.TextHeader,
        accessorKey: "RFID",
        // sortingName: "rfid",
        displayName: "rfid",
      },
    ],
    sorting,
    handleSortingChange
  );

  const exportFilteredLogsCSV = () => {
    let tempFilterDetails = { ..._.omit(filter, ["isFilterActive"]) };
    const clientIdFromLocalStorage = getClientId() != null ? getClientId() : -1;
    const url = `${UrlConstants.CSVExportAllAlarm}?clientId=${clientIdFromLocalStorage}`;
    tempFilterDetails = {
      ...tempFilterDetails,
      AlarmTimestamp: tempFilterDetails.StartDate,
      AlarmType:
        tempFilterDetails.AlarmType == -1 ? null : tempFilterDetails.AlarmType,
    };


    let startDateData = moment(tempFilterDetails.StartDate).format("YYYY-MM-DD");
    let endDateData = moment(tempFilterDetails.EndDate).format("YYYY-MM-DD");
    const tempStartDate = startDateData + " 00:00:00";
    const tempEndDate = endDateData + " 23:59:59";

    CSVFileDownloadPostService(url, "Alarm", {
      StartDate: tempStartDate,
      EndDate: tempEndDate,
      // AlarmTimestamp: tempFilterDetails.AlarmTimestamp,
      AlarmType: tempFilterDetails.AlarmType
    });
  };

  // useEffect(() => {
  //   if (filter.isFilterActive) {
  //     callGetVehicleList();
  //   }
  //   else
  //     callGetVehicleList();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [filter, callGetVehicleList]);


  const filterSubmitHandler = (values: any) => {
    setPageInfo({ ...pageInfo, currentPage: 1 });
    setFilterDetail({ ...values, isFilterActive: true });
    props.filterToggleHandler();
  };

  const resetFilterHandler = () => {
    setPageInfo({ ...pageInfo, currentPage: 1 });
    setFilterDetail({ ...alarmInitialFilterState });
  };

  const initialFilterValues: IAlarmInitialFilterState = _.omit(filter, ['isFilterActive']) as IAlarmInitialFilterState;

  return (
    <React.Fragment>

      {showFilterPopup && (
        <AlarmsFilterForm
          initialValues={initialFilterValues}
          submitHandler={filterSubmitHandler}
          onModalClosed={filterToggleHandler}
          actionInProgress={false}
        />
      )}
      <div className={`main-content user `}>
        <SubHeader
          moduleNameString={"alarms_title"} filterHandler={openFilterHandler}
          isFilterActive={filter.isFilterActive}
          resetFilterHandler={resetFilterHandler}
          exportCSVHandler={exportFilteredLogsCSV}
          moduleName={ModuleName.Alarms} isAdd={false} isFullView={false} isDownload={true} isRefresh={false} isFilter={true} isDateFilter={false} isImport={false} isMissing={false} />
      </div>
      <Container fluid className="p-0">
        {alarmListData.length !== 0 ? (
          <div className="alarmWrapper background-image">
            <div className="pt-3 px-3">
              <div className="Fuel-table">
                <div
                  className="table-responsive rounded shadow border"
                  style={getTableMaxHeight(height, width)}
                  data-testid="table"
                >
                  {inProgress && <SpinnerLoader />}
                  {alarmListData && (
                    <Grid
                      data={alarmListData}
                      columns={columns}
                      isInProgress={inProgress}
                      hiddenColumns={["Id"]}
                    />
                  )}
                </div>
              </div>
              <Container fluid>
                <Row className="tableFooter alarmFooter py-1">
                  <Col xs="3" className="d-flex">
                    <b className="text-white text-hidden-m mt-1 pt-1">
                      <FormattedMessage id="show" />:{" "}
                    </b>
                    <select
                      data-testid="pageSelect"
                      className="ms-2 from-control"
                      onChange={onPageLimitChangedForUser}
                      value={pageLimit}
                    >
                      {PageLimitOptions.map((op) => (
                        <option key={op} value={op}>
                          {op}
                        </option>
                      ))}
                    </select>
                  </Col>
                  <Col className="d-flex justify-content-end" xs="9">
                    <Pagination
                      totalPages={pageInfo.totalPages}
                      pageLimit={pageLimit}
                      onPageChanged={onPageChanged}
                      disablePagination={disablePagination}
                    />
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
        ) : (
          <div className="no_data_table background-image">
            <div className="no_data w-100 text-center">
              <img src={NoData} alt="NOData" className="NOData" />
              <h1 className="fs-2">
                <FormattedMessage id="message_no_datafound" />
              </h1>
            </div>
          </div>
        )}
      </Container>
      <Footer />

    </React.Fragment>
  );
};

export default withSize({ monitorHeight: true })(FilterPopupHOC(observer(Alarm)));

