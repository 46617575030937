/*    
<summary>
  This functional component "DashboardFilterForm" provides form to get data based on filter
  Developer:Aashish Singh, Created Date:04-April-2024
</summary>
<returns>Returns JSX</returns>
*/
import { Field, Form, Formik, useFormikContext } from "formik";
import React, { useEffect } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import NoDataFilter from "../../../assets/Images/no_filter-truck.png";
import { useStore } from "../../../contexts/StoreProvider";
import LoaderButton from "../../../shared-components/Button/LoaderButton";
import FormikFormSelectField from "../../../shared-components/FormikFormHandlers/FormikFormSelectField";
import Label from "../../../shared-components/label/label";
import { observer } from "mobx-react";
import { customerDashboardFilterValidateSchema } from "../../../shared-components/FormikFormHandlers/FormikValidationSchema";
import DraggableModal from "../../../shared-components/Draggable/DraggableModal";

interface IProps {
    submitHandler: (values: any) => void;
    onModalClosed: () => void;
    actionInProgress: boolean;
    title: string;
    filterCase: string;
    initialValues: { TruckIds: string, CustomerId2: string, CustomerId3: string }
}

const CustomerDashboardFilterForm = (props: IProps) => {
    const {
        submitHandler,
        onModalClosed,
        actionInProgress,
        title,
        filterCase,
        initialValues
    } = props;

    const { preferencesStore, customerDashboardStore } = useStore();
    const { GetDepartmentSummaryService, GetVehicleSummaryService, GetTruckSummaryService, getTruckCustomerOptions, getVehicleCustomerOptions, getDepartmentCustomerOptions } = preferencesStore;
    const { } = customerDashboardStore;
    const [truckOptions, setTruckOptions] = React.useState(getTruckCustomerOptions);
    const [vehicleOptions, setVehicleOptions] = React.useState(getVehicleCustomerOptions);
    const [departmentOptions, setDepartmentOptions] = React.useState(getDepartmentCustomerOptions);
    const FormObserver: React.FC = () => {
        const { values } = useFormikContext<{ TruckIds: string | string[] }>();
        return null;
    };

    const disabled = filterCase === "TankLevel" ? getTruckCustomerOptions.length > 0 : getVehicleCustomerOptions.length > 0 && getDepartmentCustomerOptions.length > 0

    useEffect(() => {
        if (filterCase === "TankLevel") {
            GetTruckSummaryService();

        }
        else {
            GetDepartmentSummaryService();
            GetVehicleSummaryService();
        }

    }, [GetDepartmentSummaryService, GetVehicleSummaryService, GetTruckSummaryService, filterCase])

    // useEffect(() => {
    //     if (filterCase === "TankLevel") {
    //         setTruckOptions(() => getTruckCustomerOptions);
    //     }
    //     else {
    //         setVehicleOptions(() => getVehicleCustomerOptions);
    //         setDepartmentOptions(() => getDepartmentCustomerOptions);
    //     }
    // }, [getTruckCustomerOptions, getVehicleCustomerOptions, getDepartmentCustomerOptions, filterCase])
    return (
        <>
            <Modal className="add-user-modal" show={true} centered dialogAs={DraggableModal}>
                <Modal.Header>
                    <Modal.Title>
                        <FormattedMessage id={title} />
                    </Modal.Title>
                    <div onClick={onModalClosed} className="icons8-close">
                        <span className="material-symbols-outlined fs-5 text-dark">
                            close
                        </span>
                    </div>
                </Modal.Header>
                <Formik
                    initialValues={initialValues}
                    enableReinitialize
                    validationSchema={customerDashboardFilterValidateSchema}
                    onSubmit={async (values) => {
                        submitHandler(values);
                    }}
                >
                    {({ values }) => (
                        <Form className="user-form p-3" noValidate>
                            <FormObserver />
                            {
                                !disabled ?
                                    <>
                                        <div className="No-data-filter text-center py-2">
                                            <img src={NoDataFilter} alt="NOData" className="NODataFilter" />
                                            <p className="h5 mt-3 pt-2 ">No Data Available</p>
                                        </div>
                                    </> :
                                    <Modal.Body>
                                        {filterCase === "TankLevel" && <Row className="mb-3 form-group d-flex align-items-center">
                                            <Col xs={3}>
                                                <Label
                                                    className="form-label"
                                                    label="truck_id"
                                                />
                                            </Col>
                                            <Col xs={9}>
                                                <Field
                                                    name="TruckIds"
                                                    className="form-control"
                                                    as={FormikFormSelectField}
                                                    options={getTruckCustomerOptions}
                                                    isName
                                                />
                                            </Col>

                                        </Row>}

                                        {(filterCase === "FuelStatus" || filterCase === "DailyFuelUsage") && <>
                                            <Row className="mb-3 form-group d-flex align-items-center">
                                                <Col xs={4}>
                                                    <Label
                                                        className="form-label"
                                                        label="department_id"
                                                    />
                                                </Col>
                                                <Col xs={8}>
                                                    <Field
                                                        name="CustomerId2"
                                                        className="form-control"
                                                        as={FormikFormSelectField}
                                                        options={getDepartmentCustomerOptions}
                                                        isName
                                                    />
                                                </Col>
                                            </Row>
                                            <Row className="mb-3 form-group d-flex align-items-center">
                                                <Col xs={4}>
                                                    <Label
                                                        className="form-label"
                                                        label="vehicle_id"
                                                    />
                                                </Col>
                                                <Col xs={8}>
                                                    <Field
                                                        name="CustomerId3"
                                                        className="form-control"
                                                        as={FormikFormSelectField}
                                                        options={getVehicleCustomerOptions}
                                                        isName
                                                    />
                                                </Col>
                                            </Row></>}
                                    </Modal.Body>
                            }
                            <div className="modal-footer  border-0">
                                <div className="m-auto">
                                    <LoaderButton
                                        type="submit"
                                        disabled={!disabled || actionInProgress}
                                        isLoading={actionInProgress}
                                        text="filter_title"
                                    />
                                    <Button
                                        variant="btn-secondary bg-light btn-light ms-2"
                                        onClick={onModalClosed}
                                    >
                                        <FormattedMessage id="button_cancel" />
                                    </Button>
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
            </Modal>
        </>
    );
};

export default observer(CustomerDashboardFilterForm);
