/*    
<summary>
  This functional component "ClientFilterForm" provides form to get data based on filter
  Developer:Aashish Singh, Created Date:20-March-2024
</summary>
<returns>Returns JSX</returns>
*/
import React, { useState } from "react";
import { Modal, Button, Row, Col } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { Form, Formik, Field, useFormikContext } from "formik";
import { IUser } from "../../../models/IUser";
import FormikFormInput from "../../../shared-components/FormikFormHandlers/FormikFormInput";
import LoaderButton from "../../../shared-components/Button/LoaderButton";
import Label from "../../../shared-components/label/label";
import DateRangeFilter from "../../Filter/Filter";
import FormikFormSelectField from "../../../shared-components/FormikFormHandlers/FormikFormSelectField";
import { ErrorMessage } from "../../../constants/error.constant";
import { alarmTypes } from "../../../constants/common-constant";
import { IAlarmInitialFilterState } from "../../../models/IFilter";
import _ from "lodash";
import { alarmInitialFilterState } from "../../../core/initialState/initial-filter-states";
import DraggableModal from "../../../shared-components/Draggable/DraggableModal";

interface IProps {
  submitHandler: (values: any) => void;
  onModalClosed: () => void;
  actionInProgress: boolean;
  initialValues: IAlarmInitialFilterState;
}

const AlarmsFilterForm = (props: IProps) => {
  const {
    submitHandler,
    onModalClosed,
    initialValues,
    actionInProgress,
  } = props;

  const [err, setErr] = useState<boolean>(false);

  const FormObserver: React.FC = () => {
    const { values } = useFormikContext<IUser>();
    return null;
  };

  const date = new Date();
  const startDate: Date = initialValues.StartDate;
  const endDate: Date = initialValues.EndDate;
  // const startDate: Date = new Date(date.getTime() - 7 * 24 * 60 * 60 * 1000);
  // const endDate: Date = date;
  const [dateFilter, setDateFilter] = useState<{ startDate: Date, endDate: Date }>({ startDate: startDate, endDate: endDate });
  const [isGlobalFilterChanged, setIsGlobalFilterChanged] = useState<boolean>(false);

  return (
    <>
      <Modal className="add-user-modal filter-vehicle" show={true} centered dialogAs={DraggableModal}>
        <Modal.Header>
          <Modal.Title>
            <FormattedMessage id='filter_title' />
          </Modal.Title>
          <div onClick={onModalClosed} className="icons8-close">
            <span className="material-symbols-outlined fs-5 text-dark">
              close
            </span>
          </div>
        </Modal.Header>
        <Formik
          initialValues={initialValues}
          enableReinitialize
          onSubmit={async (values) => {
            // if (JSON.stringify(values) === JSON.stringify(_.omit(alarmInitialFilterState, ["isFilterActive"]))) {
            //   // toast.warn(formatMessage("please_select_at_least_1_field"));
            //   setErr(true);
            //   setTimeout(() => { setErr(false) }, 3000);
            // }
            // else {
            let data = {
              AlarmTimestamp: dateFilter.startDate,
              StartDate: dateFilter.startDate,
              EndDate: dateFilter.endDate,
              isFilterActive: false,
              AlarmType: values.AlarmType
            }
            // setErr(false);
            props.submitHandler(data);
            // }
          }} >
          {({ values }) => (
            <Form className="user-form p-3" noValidate>
              <FormObserver />
              <Modal.Body>
                <Row>
                  <Col xs={12} className="alarm-datepicker">
                    <div className="mb-3">
                      <Label className="form-label"
                        label="Date Range"
                      />

                      <DateRangeFilter dateFilterHandler={setDateFilter}
                        setIsGlobalFilterChanged={setIsGlobalFilterChanged}
                        isGlobalFilterChanged={isGlobalFilterChanged} dateFilter={dateFilter} />
                    </div>
                  </Col>

                  <Col xs={12}>
                    <div className="mb-3 ">
                      <Label
                        className="form-label"
                        label="alarm_type"
                      />
                      <Field
                        name="AlarmType"
                        className="form-control"
                        type="number"
                        as={FormikFormSelectField}
                        placeholder="alarm_type"
                        typeofoptionid="number"
                        options={alarmTypes}
                      />
                    </div>
                  </Col>

                </Row>
                {/* {
                  err &&
                  <div className="error-text text-center w-100">
                    <span data-testid="testError" className="text-danger errorMessage">
                      <FormattedMessage id="please_select_at_least_1_field" />
                    </span>
                  </div>
                } */}

              </Modal.Body>
              <div className="modal-footer  border-0">
                <div className="m-auto">
                  <LoaderButton
                    type="submit"
                    isLoading={actionInProgress}
                    disabled={actionInProgress}
                    text="filter_title"
                  />
                  <Button
                    variant="btn-secondary bg-light btn-light ms-2"
                    onClick={onModalClosed}
                  >
                    <FormattedMessage id="button_cancel" />
                  </Button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};

export default AlarmsFilterForm;
