import { IGetAllTransactions, IGetMissingTickets } from "../../models/response/ITransactionsResponse";


export const getAllTransactionState: IGetAllTransactions = {
    TotalRecords: 30,
    TotalPages: 1,
    CurrentPage: 1,
    PageSize: 100,
    Tickets: [],
    TotalTransactionCount: 0,
    TotalVolumeDelivered: 0,
    TotalVat: 0,
    TotalAmountDue: 0
}

export const getAllMissingTransactionState: IGetMissingTickets = {
    EndTime: "",
    StartTicketNumber: 0,
    StartTime: "",
    MissingTickets: [],
    AssetId: "",
    EndTicketNumber: 0,
    PageSize: 0,
    PageNo: 0,
    TotalPages: 0
}