/*    
<summary>
  This functional component "AddUpdateCustomerForm" provides form to add new customer or update existing customer.
  Developer:Aashish Singh, Created Date:20-March-2024
</summary>
<returns>Returns JSX</returns>
*/
import { Field, Form, Formik, useFormikContext } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { calculateEffectivePrice, floatRegex, negativeFloatRegex } from "../../../constants/common-constant";
import { getAmountDecimal, getClientLimitSetting, getEnableLimitSetting, getUserType } from "../../../helpers/localStorages.helper";
import { IOption } from "../../../models/ICommon";
import { IAddEditCustomer } from "../../../models/response/ICustomerResponse";
import LoaderButton from "../../../shared-components/Button/LoaderButton";
import FormikFormInputForPhone from "../../../shared-components/FormikFormHandlers/formik-form-input-for-number";
import FormikFormInputForRegexCheck from "../../../shared-components/FormikFormHandlers/formik-form-input-for-regex-check";
import FormikFormInput from "../../../shared-components/FormikFormHandlers/FormikFormInput";
import FormikFormTextArea from "../../../shared-components/FormikFormHandlers/FormikFormTextArea";
import { addCustomerValidateSchema, editCustomerValidateSchema, } from "../../../shared-components/FormikFormHandlers/FormikValidationSchema";
import Label from "../../../shared-components/label/label";
import SpinnerLoader from "../../../shared-components/Loader/SpinnerLoader";
import UserType from "../../../constants/userType.constant";
import DraggableModal from "../../../shared-components/Draggable/DraggableModal";


interface IProps {
  id: number;
  submitHandler: (data: IAddEditCustomer) => void;
  onModalClosed: () => void;
  getDataInProgress: boolean;
  addUpdateInProgress: boolean;
  initialValues: IAddEditCustomer;
  customerIdList: IOption[];
  currencyOptions: IOption[];
}

const AddUpdateCustomerForm = (props: IProps) => {
  const {
    id,
    submitHandler,
    onModalClosed,
    getDataInProgress,
    addUpdateInProgress,
    initialValues,
    currencyOptions
  } = props;
  const [oldValues, setOldValues] = useState<IAddEditCustomer>();

  let decimalValue: number = getAmountDecimal();
  useEffect(() => {
    setOldValues({ ...props.initialValues })
  }, [props.initialValues])

  let clientSetting = getEnableLimitSetting();

  const FormObserver: React.FC = () => {
    const { values, setFieldValue } = useFormikContext<IAddEditCustomer>();

    useEffect(() => {
      if (id == -1) {
        if (oldValues?.PricePerL != values.PricePerL || oldValues?.PricePerGallon !== values.PricePerGallon
          || oldValues?.MaxAmountLimit != values.MaxAmountLimit) {
          if (values.PricePerL && Number(values.PricePerL) > 0) {
            let tempVolume = Number(values.MaxAmountLimit) / calculateEffectivePrice(Number(values.PricePerL), Number(values.Vat));
            setFieldValue("MaxVolumeLimit", Number(tempVolume).toFixed(3));
            setOldValues({ ...values });
          }
          else if (values.PricePerGallon && Number(values.PricePerGallon) > 0) {
            let tempVolume = Number(values.MaxAmountLimit) / calculateEffectivePrice((Number(values.PricePerGallon) / 4.54609), Number(values.Vat));
            setFieldValue("MaxVolumeLimit", Number(tempVolume).toFixed(3));
            setOldValues({ ...values });
          }
          else if ((values.PricePerGallon === "" || Number(values.PricePerGallon) === 0) && values.PricePerL === "" || Number(values.PricePerL) === 0) {
            setFieldValue("MaxVolumeLimit", 0);
          }
        }
      }
      else {
        let tempVolume = Number(values.PricePerL) > 0 ? Number(values.MaxAmountLimit) / calculateEffectivePrice(Number(values.PricePerL), Number(values.Vat)) : (Number(values.PricePerGallon) > 0 ? Number(values.MaxAmountLimit) / calculateEffectivePrice((Number(values.PricePerGallon) / 4.54609), Number(values.Vat)) : 0);
        if (props.initialValues?.MaxAmountLimit !== values.MaxAmountLimit || props.initialValues?.MaxVolumeLimit !== values.MaxVolumeLimit) {
          if ((values.MaxAmountLimit !== "" && Number(values.MaxAmountLimit) !== 0)) {
            setFieldValue("MaxVolumeLimit", Number(tempVolume).toFixed(3));
          }
          else {
            setFieldValue("MaxVolumeLimit", 0);
          }
        }
      }
    }, [values.MaxAmountLimit, values.PricePerL, values.PricePerGallon]);
    return null;
  };

  return (
    <>
      <Modal
        className="add-customer-modal add-user-modal"
        show={true}
        centered
        size="lg"
        dialogAs={DraggableModal}
      >
        <Modal.Header>
          <Modal.Title className="fs-5 ps-3">
            {id === -1 ? (
              <FormattedMessage id="add_customer" />
            ) : (
              <FormattedMessage id="update_customer" />
            )}
          </Modal.Title>
          <div onClick={onModalClosed} className="icons8-close">
            <span className="material-symbols-outlined fs-5 text-dark">
              close
            </span>
          </div>
          {getDataInProgress ? <SpinnerLoader /> : null}
        </Modal.Header>
        <Formik
          initialValues={initialValues}
          enableReinitialize
          validationSchema={id === -1 ? addCustomerValidateSchema(decimalValue) : editCustomerValidateSchema(decimalValue)}
          onSubmit={async (values) => {
            let tempValues = {
              Name: values.Name ? values.Name.trim() : values.Name,
              PhoneNo: values.PhoneNo ? values.PhoneNo.trim() : values.PhoneNo,
              ContactPersonName: values.ContactPersonName ? values.ContactPersonName.trim() : values.ContactPersonName,
              ContactPersonPhoneNo: values.ContactPersonPhoneNo ? values.ContactPersonPhoneNo.trim() : values.ContactPersonPhoneNo,
              ContactPersonEmail: values.ContactPersonEmail ? values.ContactPersonEmail.trim() : values.ContactPersonEmail,
              City: values.City ? values.City.trim() : values.City,
              State: values.State ? values.State.trim() : values.State,
              Country: values.Country ? values.Country.trim() : values.Country,
              Address: values.Address ? values.Address.trim() : values.Address,
              IsActive: values.IsActive,
              CustomerAdminName: values.CustomerAdminName ? values.CustomerAdminName.trim() : values.CustomerAdminName,
              Email: values.Email ? values.Email.trim() : values.Email,
              CurrencyName: values.CurrencyName,
              MaxAmountLimit: values.MaxAmountLimit,
              MaxVolumeLimit: values.MaxVolumeLimit,
              ThresholdVolumeLimit: values.ThresholdVolumeLimit,
              // AllocationPeriod: values.AllocationPeriod,
              PricePerL: Number(values.PricePerL),
              PricePerGallon: Number(values.PricePerGallon),
              Vat: Number(values.Vat),
              RemainingVolume: values.RemainingVolume,
              TaxRegistrationNo: values.TaxRegistrationNo ? values.TaxRegistrationNo.trim() : values.TaxRegistrationNo,
              EnableLimitSettings: values.EnableLimitSettings
            }

            submitHandler({
              ...tempValues
            });
          }}
        >
          {({ values, setFieldValue }) => (
            <Form className="customer-form p-3 user-form" noValidate>
              <FormObserver />
              <Modal.Body>
                <Row className="position-relative">
                  <Col lg={6} xs={6}>
                    <div className="form-group">
                      <Label
                        className="form-label mt-2"
                        required={true}
                        label="customer_name"
                      />

                      <Field
                        data-testid="testEmail"
                        className="form-control"
                        name="Name"
                        type="text"
                        // invalidcharacter={[" "]}
                        validcharacter={['@', '#', '$', '-', '_', '.']}
                        placeholder="customer_name"
                        disabled={id > 0 ? true : false}
                        as={FormikFormInput}
                      />
                    </div>
                  </Col>
                  <Col lg={6} xs={6}>
                    <div className="form-group">
                      <Label
                        className="form-label mt-2"
                        label="label_customer_trn"
                      />
                      <Field
                        data-testid="testCurrencyName"
                        className="form-control"
                        name="TaxRegistrationNo"
                        type="text"
                        placeholder="label_customer_trn"
                        as={FormikFormInput}
                      />
                    </div>
                  </Col>

                  {/* { (getUserType() !== UserType.CustomerAdmin)  &&
                    <Col xs={12}>
                    <div className="mb-3">
                      <Label
                        className="form-label"
                        required={true}
                        label="customer_id"
                      />
                      <Field
                        className="form-control form-select"
                        aria-label="Default select example"
                        name="CustomerId"
                        type="number"
                        as={FormikFormSelectField}
                        typeofoptionid="string"
                        options={customerIdList}
                      />
                    </div>
                  </Col>
                  } */}
                </Row>

                <Row className="border-box position-relative border mt-3">
                  <Col xs={12} className="pb-3">
                    <p className="h6 mt-3 fw-semi-bold heading-title">
                      <FormattedMessage id="contact_person_details" />
                    </p>
                  </Col>

                  <Col lg={4} md={4} xs={6}>
                    <div className="form-group">
                      <Label className="form-label" label="contact_person" />
                      <Field
                        data-testid="testContactPersonName"
                        className="form-control"
                        name="ContactPersonName"
                        validcharacter={['@', '#', '$', '-', '_', '.']}
                        type="text"
                        placeholder="contact_person"
                        as={FormikFormInput}
                      />
                    </div>
                  </Col>
                  <Col lg={4} md={4} xs={6}>
                    <div className="form-group">
                      <Label
                        className="form-label"
                        label="contact_person_phone_no"
                      />
                      {/* <InfoTooltip Info="phone_format_info" /> */}
                      <Field
                        data-testid="testContactPersonPhoneNo"
                        className="form-control"
                        name="ContactPersonPhoneNo"
                        type="tel"
                        validcharacter={["+", "-"]}
                        placeholder="contact_person_phone_no"
                        as={FormikFormInputForPhone}
                      />
                    </div>
                    {/* <div className="mb-3">
                      <Label
                        className="form-label"
                        label="contact_person_phone_no"
                      />
                      <Field
                        data-testid="testContactPersonPhoneNo"
                        className="form-control"
                        name="ContactPersonPhoneNo"
                        type="text"
                        placeholder="contact_person_phone_no"
                        regex={/^(?:\d+(\.\d*)?|)$/}
                        as={FormikFormInputForRegexCheck}
                      />
                    </div> */}
                  </Col>
                  <Col lg={4} md={4} xs={12}>
                    <div className="form-group">
                      <Label className="form-label" label="email" />
                      <Field
                        data-testid="testContactPersonEmail"
                        className="form-control"
                        name="ContactPersonEmail"
                        type="text"
                        placeholder="email"
                        invalidcharacter={[" "]}
                        validcharacter={[
                          "-",
                          "_",
                          "[",
                          "]",
                          "(",
                          ")",
                          ":",
                          "#",
                          "@",
                          ".",
                          "$"
                        ]}
                        tolowercase={+true}
                        as={FormikFormInput}
                      />
                    </div>
                  </Col>
                </Row>

                <Row className="border-box position-relative border">
                  <Col xs={12} className="pb-3">
                    <p className="h6 mt-3 fw-semi-bold heading-title">
                      <FormattedMessage id="customer_address_info" />
                    </p>
                  </Col>

                  <Col lg={3} xs={6}>
                    <div className="form-group">
                      <Label
                        className="form-label"
                        required={false}
                        label="city"
                      />
                      <Field
                        data-testid="testCity"
                        className="form-control"
                        name="City"
                        validcharacter={['@', '#', '$', '-', '_', '.']}
                        type="text"
                        placeholder="city"
                        as={FormikFormInput}
                      />
                    </div>
                  </Col>

                  <Col lg={3} xs={6}>
                    <div className="form-group">
                      <Label className="form-label" label="state" />
                      <Field
                        data-testid="testState"
                        className="form-control"
                        name="State"
                        type="text"
                        validcharacter={['@', '#', '$', '-', '_', '.']}
                        placeholder="state"
                        as={FormikFormInput}
                      />
                    </div>
                  </Col>
                  <Col lg={3} xs={6}>
                    <div className="form-group">
                      <Label
                        className="form-label"
                        required={false}
                        label="country"
                      />
                      <Field
                        data-testid="testCountry"
                        className="form-control"
                        name="Country"
                        validcharacter={['@', '#', '$', '-', '_', '.']}
                        type="text"
                        placeholder="country"
                        as={FormikFormInput}
                      />
                    </div>
                  </Col>
                  <Col lg={3} xs={6}>
                    <div className="form-group">
                      <Label className="form-label"
                        label="phone_no"
                        required={false}
                      />
                      {/* <InfoTooltip Info="phone_format_info" /> */}
                      <Field
                        data-testid="testPhoneNo"
                        className="form-control"
                        name="PhoneNo"
                        type="tel"
                        validcharacter={["+", "-"]}
                        placeholder="phone_no"
                        as={FormikFormInputForPhone}
                      />
                    </div>

                  </Col>
                  <Col xs={12}>
                    <div className="form-group">
                      <Label
                        className="form-label"
                        required={false}
                        label="address"
                      />
                      <Field
                        data-testid="testAddress"
                        className="form-control"
                        name="Address"
                        type="text"
                        validcharacter={['@', '#', '$', '-', '_', '.']}
                        placeholder="address"
                        as={FormikFormTextArea}
                      />
                    </div>
                  </Col>
                </Row>

                {id < 0 && (
                  <Row className="border-box position-relative border">
                    <Col xs={12} className="pb-3 ">
                      <p className="h6 mt-3 fw-semi-bold heading-title">
                        <FormattedMessage id="customer_admin_details" />
                      </p>
                    </Col>

                    <Col xs={6}>
                      <div className="form-group">
                        <Label
                          className="form-label"
                          required={true}
                          label="admin_name"
                        />
                        <Field
                          data-testid="testCustomerName"
                          className="form-control"
                          name="CustomerAdminName"
                          validcharacter={['@', '#', '$', '-', '_', '.']}
                          type="text"
                          placeholder="admin_name"
                          as={FormikFormInput}
                        />
                      </div>
                    </Col>
                    <Col xs={6}>
                      <div className="form-group">
                        <Label
                          className="form-label"
                          required={true}
                          label="label_email"
                        />
                        <Field
                          data-testid="testEmail"
                          className="form-control"
                          name="Email"
                          type="text"
                          invalidcharacter={[" "]}
                          validcharacter={[
                            "-",
                            "_",
                            "[",
                            "]",
                            "(",
                            ")",
                            ":",
                            "#",
                            "@",
                            ".",
                            "$"
                          ]}
                          placeholder="label_email"
                          tolowercase={+true}
                          as={FormikFormInput}
                        />
                      </div>
                    </Col>
                  </Row>
                )}
                <Row className="border-box position-relative border">
                  <Col xs={12} className="pb-3 ">
                    <p className="h6 mt-3 fw-semi-bold heading-title">
                      <FormattedMessage id="pricing_details" />
                    </p>
                  </Col>

                  {clientSetting && <Col lg={12} md={12} xs={12} className="mb-3">
                    <div className="form-check form-switch">
                      <Field
                        data-testid="testRememberMe"
                        className="form-check-input"
                        name="EnableLimitSettings"
                        type="checkbox"
                        id="EnableLimitSettings"
                      />
                      <label
                        htmlFor="EnableLimitSettings"
                        className="form-check-label mt-1"
                      >
                        <FormattedMessage id="limit_enabled" />
                      </label>
                    </div>
                  </Col>}

                  <Col lg={4} md={4} sm={6} xs={12}>
                    <div className="form-group">
                      <Label
                        className="form-label"
                        required={true}
                        label="transactions_label_perunitprice"
                      />
                      <Field
                        className="form-control"
                        aria-label="Default select example"
                        name="PricePerL"
                        disabled={id > 0}
                        placeholder="transactions_label_perunitprice"
                        regex={floatRegex}
                        as={FormikFormInputForRegexCheck}
                      // onChange={(event: any) => {
                      //   let value = event.target.value;
                      //   if (floatRegex.test(value)) {
                      //     setFieldValue('PricePerL', value);
                      //     if (values.MaxAmountLimit) {
                      //       setFieldValue('MaxVolumeLimit', value ? (Number(values.MaxAmountLimit) / Number(value)).toFixed(4) : "");
                      //     }
                      //   }
                      //   else {
                      //     event?.preventDefault()
                      //   }
                      // }}
                      // onBlur={(event: any) => {
                      //   let value = event.target.value;
                      //   if (floatRegex.test(value)) {
                      //     setFieldValue('PricePerL', value);
                      //     if (values.MaxAmountLimit) {
                      //       setFieldValue('MaxVolumeLimit', value ? (Number(values.MaxAmountLimit) / Number(value)).toFixed(4) : "");
                      //     }
                      //   }
                      //   else {
                      //     event?.preventDefault()
                      //   }
                      // }}
                      // isvolumelimit={+true}
                      />
                    </div>
                  </Col>
                  <Col lg={4} md={4} sm={6} xs={12}>
                    <div className="form-group">
                      <Label
                        className="form-label"
                        required={true}
                        label="transactions_label_perunitgallon"
                      />
                      <Field
                        className="form-control"
                        disabled={id > 0}
                        aria-label="Default select example"
                        name="PricePerGallon"
                        placeholder="transactions_label_perunitgallon"
                        regex={floatRegex}
                        as={FormikFormInputForRegexCheck}
                      />
                    </div>
                  </Col>
                  <Col lg={4} md={4} sm={6} xs={12}>
                    <div className="form-group">
                      <Label
                        className="form-label"
                        required={true}
                        label="transactions_label_vat_percent"
                      />
                      <Field
                        className="form-control"
                        disabled={id > 0}
                        aria-label="Default select example"
                        name="Vat"
                        placeholder="transactions_label_vat"
                        regex={floatRegex}
                        as={FormikFormInputForRegexCheck}
                      />
                    </div>
                  </Col>
                  <Col lg={4} md={4} sm={6} xs={12}>
                    <div className="form-group">
                      <Label
                        className="form-label"
                        required={true}
                        label="currency"
                      />
                      <Field
                        data-testid="testCurrencyName"
                        className="form-control"
                        name="CurrencyName"
                        type="text"
                        placeholder="currency"
                        invalidcharacter={[" "]}
                        as={FormikFormInput}
                      />
                    </div>
                  </Col>
                  {/* <Col lg={4} md={4} sm={6} xs={12}>
                    <div className="mb-3 form-group">
                      <Label
                        className="form-label"
                        label="allocation_period"
                      />
                      <Field
                        name="AllocationPeriod"
                        className="form-control"
                        type="number"
                        as={FormikFormSelectField}
                        typeofoptionid="number"
                        options={allocationPeriodCustomerTypes}
                      />
                    </div>
                  </Col> */}
                  {clientSetting && values.EnableLimitSettings && <>
                    <Col lg={4} md={4} sm={6} xs={12}>
                      <div className="form-group">
                        <Label
                          className="form-label"
                          // required={true}
                          label="max_amount_limit"
                        />
                        <Field
                          data-testid="testCurrencyName"
                          className="form-control"
                          name="MaxAmountLimit"
                          aria-label="Default select example"
                          type="text"
                          placeholder="max_amount_limit"
                          regex={floatRegex}
                          // onChange={(event: any) => {
                          //   let value = event.target.value;
                          //   if (floatRegex.test(value)) {
                          //     setFieldValue('MaxAmountLimit', value);
                          //     if (values.PricePerL) {
                          //       setFieldValue('MaxVolumeLimit', value ? (Number(value) / Number(values.PricePerL)).toFixed(4) : "");
                          //     }
                          //   }
                          //   else {
                          //     event?.preventDefault()
                          //   }
                          // }}
                          // onBlur={(event: any) => {
                          //   let value = event.target.value;
                          //   if (floatRegex.test(value)) {
                          //     setFieldValue('MaxAmountLimit', value);
                          //     if (values.PricePerL) {
                          //       setFieldValue('MaxVolumeLimit', value ? (Number(value) / Number(values.PricePerL)).toFixed(3) : "");
                          //     }
                          //   }
                          //   else {
                          //     event?.preventDefault()
                          //   }
                          // }}
                          as={FormikFormInputForRegexCheck}
                        // isvolumelimit={+true}
                        />
                      </div>
                    </Col>

                    <Col lg={4} md={4} sm={6} xs={12}>
                      <div className="form-group">
                        <Label
                          className="form-label"
                          label="max_volume_limit_unit"
                        />
                        <Field
                          data-testid="testCurrencyName"
                          className="form-control"
                          aria-label="Default select example"
                          name="MaxVolumeLimit"
                          type="text"
                          placeholder="max_volume_limit"
                          regex={floatRegex}
                          as={FormikFormInputForRegexCheck}
                        />
                      </div>
                    </Col>

                    <Col lg={4} md={4} sm={6} xs={12}>
                      <div className="form-group">
                        <Label
                          className="form-label"
                          label="threshold_volume_limit_unit"
                        />
                        <Field
                          data-testid="testCurrencyName"
                          className="form-control"
                          name="ThresholdVolumeLimit"
                          type="text"
                          aria-label="Default select example"
                          placeholder="threshold_volume_limit_unit"
                          regex={floatRegex}
                          as={FormikFormInputForRegexCheck}
                        />
                      </div>
                    </Col>
                    {id > 0 && <Col lg={4} md={4} sm={6} xs={12}>
                      <div className="form-group">
                        <Label
                          className="form-label"
                          required={true}
                          label="remaining_volume"
                        />
                        <Field
                          className="form-control"
                          aria-label="Default select example"
                          name="RemainingVolume"
                          disabled={getUserType() !== UserType.SuperAdmin}
                          placeholder="remaining_volume"
                          regex={negativeFloatRegex}
                          as={FormikFormInputForRegexCheck}
                        // onChange={(event: any) => {
                        //   let value = event.target.value;
                        //   if (floatRegex.test(value)) {
                        //     setFieldValue('PricePerL', value);
                        //     if (values.MaxAmountLimit) {
                        //       setFieldValue('MaxVolumeLimit', value ? (Number(values.MaxAmountLimit) / Number(value)).toFixed(4) : "");
                        //     }
                        //   }
                        //   else {
                        //     event?.preventDefault()
                        //   }
                        // }}
                        // onBlur={(event: any) => {
                        //   let value = event.target.value;
                        //   if (floatRegex.test(value)) {
                        //     setFieldValue('PricePerL', value);
                        //     if (values.MaxAmountLimit) {
                        //       setFieldValue('MaxVolumeLimit', value ? (Number(values.MaxAmountLimit) / Number(value)).toFixed(4) : "");
                        //     }
                        //   }
                        //   else {
                        //     event?.preventDefault()
                        //   }
                        // }}
                        // isvolumelimit={+true}
                        />
                      </div>
                    </Col>}
                  </>}

                </Row>
                <Row>
                  <Col lg={4} xs={12}>
                    <div className="form-check mt-2">
                      <Field
                        data-testid="testRememberMe"
                        className="form-check-input"
                        name="IsActive"
                        type="checkbox"
                        id="IsActive"
                      />
                      <label htmlFor="IsActive" className="form-check-label mt-1">
                        <FormattedMessage id="is_active" />
                      </label>
                    </div>
                  </Col>
                </Row>
              </Modal.Body>
              <div className="modal-footer  border-0">
                <div className="m-auto">
                  <LoaderButton
                    type="submit"
                    isLoading={addUpdateInProgress}
                    disabled={addUpdateInProgress}
                    text={id === -1 ? "button_save" : "button_edit"}
                  />
                  <Button
                    variant="btn-secondary bg-light btn-light ms-2"
                    onClick={onModalClosed}
                  >
                    <FormattedMessage id="button_cancel" />
                  </Button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </Modal >
    </>
  );
};

export default AddUpdateCustomerForm;
