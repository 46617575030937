import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Field, Form, Formik } from "formik";
import { observer } from "mobx-react";
import { Button, Modal } from "react-bootstrap";
import { useStore } from "../../../contexts/StoreProvider";
import { toast } from "react-toastify";
import { formatMessage } from "../../../translations/formatMessage";
import Label from "../../../shared-components/label/label";
import FormikFormSelectField from "../../../shared-components/FormikFormHandlers/FormikFormSelectField";
import FormikFormFileInput from "../../../shared-components/FormikFormHandlers/formik-form-file-input";
import LoaderButton from "../../../shared-components/Button/LoaderButton";
import { importCsvValidateSchema, importVehicleCsvValidateSchema } from "../../../shared-components/FormikFormHandlers/FormikValidationSchema";
import { IOption } from "../../../models/ICommon";
import DraggableModal from "../../../shared-components/Draggable/DraggableModal";

interface IProps {
    initialValues: any;
    submitHandler: (data: any) => void;
    isLoading: boolean;
    modalClosed: () => void;
    downloadcsvHandler?: () => void;
    isDownload: boolean
}


const AddDriverCSVForm = (props: IProps) => {
    const { driverStore, preferencesStore } = useStore();
    const {
        resetAddDriverCSVState,
        addDriverCSVState,
    } = driverStore;

    let totalDeviceToBeAdded: number = 0;
    const { allAvailableClientOptions } = preferencesStore;
    // const { allAvailableClientOptions } = clientStore;
    const [targetData, setTargetData] = useState("");
    const [csvFile, setCSVFile] = useState<File | null>(null);
    const { initialValues, submitHandler, isLoading, modalClosed, isDownload } = props;

    const getHeaderArray = (csvRecordsArr: any): string[] => {
        let headerArray = [];
        let headers = csvRecordsArr[0].split(",");
        for (let i = 0; i < headers.length; i++) {
            headerArray.push(headers[i]);
        }
        return headerArray;
    };

    useEffect(() => {
        if (addDriverCSVState.success) {
            onModalClosed();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [addDriverCSVState.success]);

    const onModalClosed = () => {
        setCSVFile(null);
        modalClosed();
    }

    const headerInvalidError = (headersRow: any) => {
        var isInvalid: boolean = false;
        if (headersRow.length > 3) {
            isInvalid = true;
            return isInvalid;
        } else if (
            headersRow[0].trim() != "Name" ||
            headersRow[1].trim() != "Iccid" ||
            headersRow[2].trim() != "Tags"
        ) {
            isInvalid = true;
            return isInvalid;
        }
        return isInvalid;
    };

    const handleFileUpload = (event: any) => {
        var files = event?.target?.result;
        setTargetData(files.toString());
    };

    const startFileDecode = () => {
        if (targetData) {
            let csvData: string = targetData;
            let csvRecordsArray = csvData?.split(/\r\n|\n/);
            let headersRow = getHeaderArray(csvRecordsArray);
            if (csvRecordsArray.length > 0) {
                totalDeviceToBeAdded = csvRecordsArray.length - 2;
            }
            if (csvRecordsArray[1] != "") {
                let csv = CSVDataConditions(headersRow, csvRecordsArray);
                if (csv && csv?.length > 0) {
                    return csv;
                }
            } else {
                toast.error(formatMessage("empty_csv"));
                return [];
            }
        } else {
            toast.error(formatMessage("invalid_csv_format"));
        }
    };

    const CSVDataConditions = (headersRow: any, csvRecordsArray: any) => {
        let dataManipulated = getDataRecordsArrayFromCSVFile(
            csvRecordsArray,
            headersRow.length
        );
        return dataManipulated;
    };

    const getDataRecordsArrayFromCSVFile = (
        csvRecordsArray: any,
        headerLength: number
    ) => {
        var ImportDataArr = [];
        var totalColumns = 23;
        csvRecordsArray.splice(csvRecordsArray.length - 1, 1);
        for (let i = 1; i < csvRecordsArray?.length; i++) {
            let data = csvRecordsArray[i]?.split(",");
            if (data.length > totalColumns) {
                data.splice(totalColumns);
            }
            if (data.length == totalColumns) {
                var CSVdata = {
                    TicketNubmer: data[0]?.trim(),
                    StartTime: data[1],
                    EndTime: data[2],
                    TruckId: data[3]?.trim(),
                    DeviceId: data[4]?.trim(),
                    ProductId: data[5]?.trim(),
                    GrossDelivered: data[6],
                    EndGross: data[7],
                    FlowAverage: data[8],
                    FlowMax: data[9],
                    Latitude: data[10],
                    Longitude: data[11],
                    OperatorRFID: data[12],
                    SendTime: data[13],
                    PerUnitPrice: data[14]?.trim(),
                    Vat: data[15]?.trim(),
                    AmountDue: data[16]?.trim(),
                    CustomerId1: data[17]?.trim(),
                    CustomerId2: data[18]?.trim(),
                    CustomerId3: data[19]?.trim(),
                    CustomerId4: data[20]?.trim(),
                    CustomerId5: data[21]?.trim(),
                    CustomerId6: data[22]?.trim(),
                };
                ImportDataArr.push(CSVdata);
            }
        }
        return ImportDataArr;
    };

    // useEffect(() => {
    //   if (getAddBulkCount > 0) {
    //     modalClosed();
    //     resetAddDeviceCSV();
    //   }
    // }, [getAddBulkCount]);

    return (
        <>
            <Modal className="add-user-modal" show={true} centered dialogAs={DraggableModal}>
                <Modal.Header>
                    <Modal.Title className="fs-5 ps-3">
                        <FormattedMessage id="import_csv" />
                    </Modal.Title>
                    <div onClick={onModalClosed} className="icons8-close">
                        <span className="material-symbols-outlined fs-5 text-dark">
                            close
                        </span>
                    </div>
                </Modal.Header>
                <Formik
                    initialValues={initialValues}
                    validationSchema={importVehicleCsvValidateSchema}
                    enableReinitialize
                    validateOnBlur={false}
                    onSubmit={async (values: any) => {
                        // let data = startFileDecode();
                        values.Transactions = targetData;
                        // if (data && data.length > 0) {
                        // submitHandler(values);
                        // }
                        let tempValues = { ...values };
                        let tampClient = allAvailableClientOptions.filter(
                            (item: IOption) => item.Id == values.ClientId
                        );
                        if (tampClient.length != 0) {
                            tempValues.ClientName = tampClient[0].Name?.toLowerCase();
                            tempValues.CSVFile = csvFile;
                            submitHandler(tempValues);
                        }
                    }}
                    render={({ values }) => {
                        return (
                            <Form id="formBody" className="p-3 user-form">
                                <Modal.Body>
                                    <div className="row  ">
                                        {/* <div className="col-12">
                                            <div className="form-group formSet mb-3">
                                                <Label
                                                    className="form-label "
                                                    required={true}
                                                    label="transactions_label_clientid"
                                                />
                                                <Field
                                                    className="form-control form-select"
                                                    aria-label="Default select example"
                                                    name="ClientId"
                                                    options={allAvailableClientOptions}
                                                    as={FormikFormSelectField}
                                                // isName={true}
                                                />
                                            </div>
                                        </div> */}
                                        <div className="col-12">
                                            <Label
                                                className="form-label mt-2"
                                                required={true}
                                                label="import_csv_file"
                                            />
                                            {isDownload && <a href="javascript:;"
                                                className="download_icon"
                                                onClick={props.downloadcsvHandler}
                                                title={formatMessage("demo_transaction_file")}>
                                                <svg
                                                    version="1.1"
                                                    x="0px"
                                                    y="0px"
                                                    fill="currentColor"
                                                    height="13"
                                                    width="13"
                                                    viewBox="0 0 512 512"
                                                >
                                                    <g>
                                                        <g>
                                                            <path
                                                                d="M382.56,233.376C379.968,227.648,374.272,224,368,224h-64V16c0-8.832-7.168-16-16-16h-64c-8.832,0-16,7.168-16,16v208h-64
c-6.272,0-11.968,3.68-14.56,9.376c-2.624,5.728-1.6,12.416,2.528,17.152l112,128c3.04,3.488,7.424,5.472,12.032,5.472
c4.608,0,8.992-2.016,12.032-5.472l112-128C384.192,245.824,385.152,239.104,382.56,233.376z"
                                                            />
                                                        </g>
                                                    </g>
                                                    <g>
                                                        <g>
                                                            <path d="M432,352v96H80v-96H16v128c0,17.696,14.336,32,32,32h416c17.696,0,32-14.304,32-32V352H432z" />
                                                        </g>
                                                    </g>
                                                </svg>
                                            </a>}
                                            <div className="input-group custom-file-button">
                                                <label className="input-group-text text-white upload-label mb-0" htmlFor="inputFile">
                                                    <span className="material-symbols-outlined">
                                                        upload
                                                    </span>    <FormattedMessage id="file_upload" />
                                                </label>
                                                <Field
                                                    className="form-control"
                                                    id="inputFile"
                                                    name="CSVFile"
                                                    type="file"
                                                    setCSVFile={setCSVFile}
                                                    handleFileUpload={handleFileUpload}
                                                    as={FormikFormFileInput}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </Modal.Body>
                                <div className="modal-footer border-0 mt-3">
                                    <div className="m-auto">
                                        <LoaderButton
                                            type="submit"
                                            isLoading={isLoading}
                                            disabled={isLoading}
                                            text="submit"
                                        />
                                        <Button
                                            variant="btn-secondary bg-light btn-light ms-2"
                                            onClick={onModalClosed}
                                        >
                                            <FormattedMessage id="button_cancel" />
                                        </Button>
                                    </div>
                                </div>
                            </Form>
                        );
                    }}
                ></Formik>
            </Modal>
        </>
    );
};

export default observer(AddDriverCSVForm);
