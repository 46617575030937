/*    
<summary>
  This functional component "AddUpdateCustomerSettingForm" provides form to add new customer or update existing customer.
  Developer:Aashish Singh, Created Date:20-March-2024
</summary>
<returns>Returns JSX</returns>
*/
import { Field, Form, Formik, useFormikContext } from "formik";
import React from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import {
  ticketTitleTypes
} from "../../../constants/common-constant";
import { IUser } from "../../../models/IUser";
import {
  IPDFSetting
} from "../../../models/response/ICustomerResponse";
import LoaderButton from "../../../shared-components/Button/LoaderButton";
import DraggableModal from "../../../shared-components/Draggable/DraggableModal";
import FormikFormImagePickerLogo from "../../../shared-components/FormikFormHandlers/FormikFormImagePickerLogo";
import FormikFormInput from "../../../shared-components/FormikFormHandlers/FormikFormInput";
import FormikFormSelectField from "../../../shared-components/FormikFormHandlers/FormikFormSelectField";
import { pdfSettingValidateSchema } from "../../../shared-components/FormikFormHandlers/FormikValidationSchema";
import Label from "../../../shared-components/label/label";
import SpinnerLoader from "../../../shared-components/Loader/SpinnerLoader";

interface IProps {
  submitHandler: (data: IPDFSetting) => void;
  onModalClosed: () => void;
  getDataInProgress: boolean;
  addUpdateInProgress: boolean;
  initialValues: IPDFSetting;
}

const AddUpdateCustomerSettingForm = (props: IProps) => {
  const {
    submitHandler,
    onModalClosed,
    getDataInProgress,
    addUpdateInProgress,
    initialValues,
  } = props;

  const FormObserver: React.FC = () => {
    const { values } = useFormikContext<IUser>();
    return null;
  };

  return (
    <>
      <Modal
        className="add-customer-modal add-user-modal pdf-modal"
        show={true}
        centered
        dialogAs={DraggableModal}
      >
        <Modal.Header>
          <Modal.Title className="fs-5 ps-3">
            <FormattedMessage id="update_customer_setting" />
          </Modal.Title>
          <div onClick={onModalClosed} className="icons8-close">
            <span className="material-symbols-outlined fs-5 text-dark">
              close
            </span>
          </div>
          {getDataInProgress ? <SpinnerLoader /> : null}
        </Modal.Header>
        <Formik
          initialValues={initialValues}
          enableReinitialize
          validationSchema={pdfSettingValidateSchema}
          onSubmit={async (values) => {
            let tempValues = {
              TicketTitle: values.TicketTitle ? values.TicketTitle.trim() : values.TicketTitle,
              Header1: values.Header1 ? values.Header1.trim() : values.Header1,
              Header2: values.Header2 ? values.Header2.trim() : values.Header2,
              Header3: values.Header3 ? values.Header3.trim() : values.Header3,
              Header4: values.Header4 ? values.Header4.trim() : values.Header4,
              Header5: values.Header5 ? values.Header5.trim() : values.Header5,
              Header6: values.Header6 ? values.Header6.trim() : values.Header6,
              CustomerId1Title: values.CustomerId1Title ? values.CustomerId1Title.trim() : values.CustomerId1Title,
              CustomerId2Title: values.CustomerId2Title ? values.CustomerId2Title.trim() : values.CustomerId2Title,
              CustomerId3Title: values.CustomerId3Title ? values.CustomerId3Title.trim() : values.CustomerId3Title,
              CustomerId4Title: values.CustomerId4Title ? values.CustomerId4Title.trim() : values.CustomerId4Title,
              CustomerId5Title: values.CustomerId5Title ? values.CustomerId5Title.trim() : values.CustomerId5Title,
              CustomerId6Title: values.CustomerId6Title ? values.CustomerId6Title.trim() : values.CustomerId6Title,
              VatInfo: values.VatInfo ? values.VatInfo.trim() : values.VatInfo,
              Footer1: values.Footer1 ? values.Footer1.trim() : values.Footer1,
              Footer2: values.Footer2 ? values.Footer2.trim() : values.Footer2,
              Logo: values.Logo ? values.Logo.trim() : values.Logo,
            };
            submitHandler({ ...tempValues });
          }}
        >
          {({ values }) => (
            <Form className="customer-form p-3 user-form" noValidate>
              <FormObserver />
              <Modal.Body>
                <div className="form-group mb-0 form-group-m-zero">
                  <Row className="pdf-logo">
                  {/* <div className="col-lg-1 col-md-2 col-sm-2 pt-3">
                      <Label
                        className="form-label mt-3 fs-4 fw-semi-bold"
                        label="Logo"
                      />
                    </div> */}
                    <div className="col-lg-2 col-md-2 col-sm-3 col-xs-3 logo_pd_file">
                      <Field
                        name="Logo"
                        type="text"
                        as={FormikFormImagePickerLogo}
                      />
                    </div>
                    <div className="col-lg-2 col-md-2 col-sm-3 col-xs-3 mt-3">
                      <Label
                        className="form-label mt-3"
                        required={true}
                        label="ticket_title"
                      />
                    </div>
                    <div  className="col-lg-8 col-md-8 col-sm-6 col-xs-9 mt-3">
                      <Field
                        className="form-control"
                        name="TicketTitle"
                        placeholder="ticket_title"
                        isName={true}
                        as={FormikFormSelectField}
                        options={ticketTitleTypes}
                      />
                    </div>

                  </Row>
                </div>
                <div className="border-box position-relative border">
                  <Row>
                    <Col xs={12} className="pb-3 ">
                      <p className="h6 mt-3 fw-semi-bold heading-title">
                        <FormattedMessage id="header_details" />
                      </p>
                    </Col>
                    <Col lg={4} md={6} sm="6" xs={12}>
                      <div className="form-group">
                        <Label
                          className="form-label"
                          // required={true}
                          label="header_1"
                        />
                        <Field
                          className="form-control"
                          name="Header1"
                          placeholder="header_1"
                          validcharacter={["@", "#", "$", "-", "_", "."]}
                          as={FormikFormInput}
                        />
                      </div>
                    </Col>
                    <Col lg={4} md={6} sm="6" xs={12}>
                      <div className="form-group">
                        <Label
                          className="form-label"
                          // required={true}
                          label="header_2"
                        />
                        <Field
                          className="form-control"
                          name="Header2"
                          placeholder="header_2"
                          validcharacter={["@", "#", "$", "-", "_", "."]}
                          as={FormikFormInput}
                        />
                      </div>
                    </Col>
                    <Col lg={4} md={6} sm="6" xs={12}>
                      <div className="form-group">
                        <Label
                          className="form-label"
                          // required={true}
                          label="header_3"
                        />
                        <Field
                          className="form-control"
                          name="Header3"
                          placeholder="header_3"
                          validcharacter={["@", "#", "$", "-", "_", "."]}
                          as={FormikFormInput}
                        />
                      </div>
                    </Col>
                    <Col lg={4} md={6} sm="6" xs={12}>
                      <div className="form-group">
                        <Label
                          className="form-label"
                          // required={true}
                          label="header_4"
                        />
                        <Field
                          className="form-control"
                          name="Header4"
                          placeholder="header_4"
                          validcharacter={["@", "#", "$", "-", "_", "."]}
                          as={FormikFormInput}
                        />
                      </div>
                    </Col>
                    <Col lg={4} md={6} sm="6" xs={12}>
                      <div className="form-group">
                        <Label
                          className="form-label"
                          // required={true}
                          label="header_5"
                        />
                        <Field
                          className="form-control"
                          name="Header5"
                          placeholder="header_5"
                          validcharacter={["@", "#", "$", "-", "_", "."]}
                          as={FormikFormInput}
                        />
                      </div>
                    </Col>
                    <Col lg={4} md={6} sm="6" xs={12}>
                      <div className="form-group">
                        <Label
                          className="form-label"
                          // required={true}
                          label="header_6"
                        />
                        <Field
                          className="form-control"
                          name="Header6"
                          placeholder="header_6"
                          validcharacter={["@", "#", "$", "-", "_", "."]}
                          as={FormikFormInput}
                        />
                      </div>
                    </Col>
                  </Row>
                </div>
                <div className="border-box position-relative border">
                  <Row>
                    <Col xs={12} className="pb-3 ">
                      <p className="h6 mt-3 fw-semi-bold heading-title">
                        <FormattedMessage id="label_details" />
                      </p>
                    </Col>
                    <Col lg={4} md={6} sm="6" xs={12}>
                      <div className="form-group">
                        <Label
                          className="form-label"
                          label="transactions_label_customer_id_1"
                        />
                        <Field
                          className="form-control"
                          aria-label="Default select example"
                          name="CustomerId1Title"
                          type="text"
                          placeholder="transactions_label_customer_id_1"
                          validcharacter={["@", "#", "$", "-", "_", "."]}
                          as={FormikFormInput}
                        />
                      </div>
                    </Col>
                    <Col lg={4} md={6} sm="6" xs={12}>
                      <div className="form-group">
                        <Label
                          className="form-label"
                          label="transactions_label_customer_id_2"
                        />
                        <Field
                          className="form-control"
                          aria-label="Default select example"
                          name="CustomerId2Title"
                          type="text"
                          placeholder="transactions_label_customer_id_2"
                          validcharacter={["@", "#", "$", "-", "_", "."]}
                          as={FormikFormInput}
                        />
                      </div>
                    </Col>
                    <Col lg={4} md={6} sm="6" xs={12}>
                      <div className="form-group">
                        <Label
                          className="form-label"
                          label="transactions_label_customer_id_3"
                        />
                        <Field
                          className="form-control"
                          aria-label="Default select example"
                          name="CustomerId3Title"
                          type="text"
                          placeholder="transactions_label_customer_id_3"
                          validcharacter={["@", "#", "$", "-", "_", "."]}
                          as={FormikFormInput}
                        />
                      </div>
                    </Col>
                    <Col lg={4} md={6} sm="6" xs={12}>
                      <div className="form-group">
                        <Label
                          className="form-label"
                          label="transactions_label_customer_id_4"
                        />
                        <Field
                          className="form-control"
                          aria-label="Default select example"
                          name="CustomerId4Title"
                          type="text"
                          placeholder="transactions_label_customer_id_4"
                          validcharacter={["@", "#", "$", "-", "_", "."]}
                          as={FormikFormInput}
                        />
                      </div>
                    </Col>
                    <Col lg={4} md={6} sm="6" xs={12}>
                      <div className="form-group">
                        <Label
                          className="form-label"
                          label="transactions_label_customer_id_5"
                        />
                        <Field
                          className="form-control"
                          aria-label="Default select example"
                          name="CustomerId5Title"
                          type="text"
                          placeholder="transactions_label_customer_id_5"
                          validcharacter={["@", "#", "$", "-", "_", "."]}
                          as={FormikFormInput}
                        />
                      </div>
                    </Col>
                    <Col lg={4} md={6} sm="6" xs={12}>
                      <div className="form-group">
                        <Label
                          className="form-label"
                          label="transactions_label_customer_id_6"
                        />
                        <Field
                          className="form-control"
                          aria-label="Default select example"
                          name="CustomerId6Title"
                          type="text"
                          placeholder="transactions_label_customer_id_6"
                          validcharacter={["@", "#", "$", "-", "_", "."]}
                          as={FormikFormInput}
                        />
                      </div>
                    </Col>
                  </Row>
                </div>
                <div className="m-0 pt-3 form-group">
                  <Label
                    className="form-label"
                    label="vat_info"
                  />
                  <Field
                    className="form-control"
                    aria-label="Default select example"
                    name="VatInfo"
                    type="text"
                    placeholder="vat_info"
                    validcharacter={["%", "@", "#", "$", "-", "_", "."]}
                    // invalidcharacter={["%"]}
                    as={FormikFormInput}
                  />
                </div>
                <div className="border-box position-relative border">
                  <Row>
                    <Col xs={12} className="pb-3 ">
                      <p className="h6 mt-3 fw-semi-bold heading-title">
                        <FormattedMessage id="footer_details" />
                      </p>
                    </Col>
                    <Col lg={6} md={6} sm={6} xs={12}>
                      <div className="form-group">
                        <Label className="form-label" label="footer1" />
                        <Field
                          className="form-control"
                          aria-label="Default select example"
                          name="Footer1"
                          type="text"
                          placeholder="footer1"
                          validcharacter={["@", "#", "$", "-", "_", "."]}
                          as={FormikFormInput}
                        />
                      </div>
                    </Col>
                    <Col lg={6} md={6} sm={6} xs={12}>
                      <div className="form-group">
                        <Label className="form-label" label="footer2" />
                        <Field
                          className="form-control"
                          name="Footer2"
                          type="text"
                          placeholder="footer2"
                          validcharacter={["@", "#", "$", "-", "_", "."]}
                          as={FormikFormInput}
                        />
                      </div>
                    </Col>
                  </Row>
                </div>
              </Modal.Body>
              <div className="modal-footer  border-0">
                <div className="m-auto">
                  <LoaderButton
                    type="submit"
                    isLoading={addUpdateInProgress}
                    disabled={addUpdateInProgress}
                    text="button_edit"
                  />
                  <Button
                    variant="btn-secondary bg-light btn-light ms-2"
                    onClick={onModalClosed}
                  >
                    <FormattedMessage id="button_cancel" />
                  </Button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};

export default AddUpdateCustomerSettingForm;
