/*    
<summary>
  This functional component "DashboardFilterForm" provides form to get data based on filter
  Developer:Aashish Singh, Created Date:04-April-2024
</summary>
<returns>Returns JSX</returns>
*/
import React, { useEffect } from "react";
import { Modal, Button, Row, Col } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { Form, Formik, Field, useFormikContext } from "formik";
import FormikFormInput from "../../../shared-components/FormikFormHandlers/FormikFormInput";
import LoaderButton from "../../../shared-components/Button/LoaderButton";
import Label from "../../../shared-components/label/label";
import FormikFormDropdownTable from "../../../shared-components/FormikFormHandlers/FormikFormDropDownTable";
import { ErrorMessage } from "../../../constants/error.constant";
import {
  customerIdList,
  userTypeList,
} from "../../../constants/common-constant";
import { IOption, ITruckOption } from "../../../models/ICommon";
import NoDataFilter from "../../../assets/Images/no_filter-truck.png";
import FormikFormSelectField from "../../../shared-components/FormikFormHandlers/FormikFormSelectField";
import { dashboardFilterValidateSchema } from "../../../shared-components/FormikFormHandlers/FormikValidationSchema";
import DraggableModal from "../../../shared-components/Draggable/DraggableModal";
import { useStore } from "../../../contexts/StoreProvider";
import { observer } from "mobx-react";

interface IProps {
  submitHandler: (values: any) => void;
  onModalClosed: () => void;
  actionInProgress: boolean;
  initialValues: { TruckIds?: string | string[]; CustomerId?: string; CustomerId2?: string; CustomerId3?: string; };
  truckOptions: ITruckOption[];
  title: string;
  singleSelector: boolean;
  disabled?: boolean;
  selectAllColumns: any[];
}

const DashboardFilterForm = (props: IProps) => {
  const {
    submitHandler,
    onModalClosed,
    initialValues,
    actionInProgress,
    truckOptions,
    title,
    singleSelector,
    selectAllColumns,
  } = props;
  const { truckStore, preferencesStore, dashboardStore } = useStore();
  const { getCustomerOptionsAll, getCustomerOptions, getAllVehicleOptions, getAllDepartmentOptions, GetAllDeptAndVehiclesSummaryService } = preferencesStore;
  const { availableTruckOptions } = truckStore;
  const { filterCase } = dashboardStore;

  const isDisabled = () => {
    if (filterCase === "DailyFuelUsage") {
      return !(getCustomerOptionsAll.length > 1 || getAllVehicleOptions.length > 1 || getAllDepartmentOptions.length > 1)
      // return !(getCustomerOptionsAll.length > 1 && getAllVehicleOptions.length > 1 && getAllDepartmentOptions.length > 1)
    }
    else {
      return (availableTruckOptions.length === 0)
    }
  }
  // const FormObserver: React.FC = () => {
  //   const { values } = useFormikContext<{ TruckIds?: string | string[]; CustomerId?: string; CustomerId2?: string; CustomerId3?: string; }>();

  //   let customerId = getCustomerOptions.filter((customer: IOption) => customer.Name === values.CustomerId).map((customer: IOption) => customer.Id)[0];
  //   if (values.CustomerId && values.CustomerId !== "All") {
  //     // GetAllDeptAndVehiclesSummaryService(customerId);
  //   }

  //   return null;
  // };

  const changeHandler = (value: any) => {
    console.log("Value -->", value)
    let customerId = getCustomerOptions.filter((customer: IOption) => customer.Name === value).map((customer: IOption) => customer.Id)[0] ?? -1;
    console.log("customerId -->", customerId)
    if (value && customerId) {
      GetAllDeptAndVehiclesSummaryService(customerId);
    }
  }

  return (
    <>
      <Modal className="add-user-modal" show={true} centered dialogAs={DraggableModal}>
        <Modal.Header>
          <Modal.Title>
            <FormattedMessage id={title} />
          </Modal.Title>
          <div onClick={onModalClosed} className="icons8-close">
            <span className="material-symbols-outlined fs-5 text-dark">
              close
            </span>
          </div>
        </Modal.Header>
        <Formik
          initialValues={initialValues}
          enableReinitialize
          // validationSchema={dashboardFilterValidateSchema}
          onSubmit={async (values) => {
            const tempTruckValue = truckOptions.filter((truck: ITruckOption) => truck.Id === values.TruckIds);
            const tempDepartmentValue = getAllDepartmentOptions.filter((truck: ITruckOption) => truck.Id === values.CustomerId2);
            const tempVehicleValue = getAllVehicleOptions.filter((truck: ITruckOption) => truck.Id === values.CustomerId3);
            const tempCustomerValue = getCustomerOptionsAll.filter((truck: ITruckOption) => truck.Id === values.CustomerId);

            if (title !== "Daily Fuel Usage") {
              if (tempTruckValue)
                submitHandler(tempTruckValue[0]);
            }
            else {
              submitHandler({ CustomerId: tempCustomerValue[0] ? tempCustomerValue[0] : { Id: "All", Name: "All" }, CustomerId2: tempDepartmentValue[0] ? tempDepartmentValue[0] : { Id: "All", Name: "All" }, CustomerId3: tempVehicleValue[0] ? tempVehicleValue[0] : { Id: "All", Name: "All" }, });
            }

          }}
        >
          {({ values }) => (
            <Form className="user-form p-3" noValidate>

              {/* <FormObserver /> */}
              {
                isDisabled() ?
                  <>
                    <div className="No-data-filter text-center py-2">
                      <img src={NoDataFilter} alt="NOData" className="NODataFilter" />
                      <p className="h5 mt-3 pt-2 ">No Data Available</p>
                    </div>
                  </> :
                  <Modal.Body>
                    {title !== "Daily Fuel Usage" ?
                      <Row className="mb-3 form-group d-flex align-items-center">
                        <Col xs={3}>
                          <Label
                            className="form-label"
                            label="truck_id"
                          />
                        </Col>
                        <Col xs={9}>
                          {singleSelector ?
                            <Field
                              name="TruckIds"
                              className="form-control"
                              as={FormikFormSelectField}
                              options={truckOptions}
                            />
                            : <Field
                              as={FormikFormDropdownTable}
                              data-testid="testTemplateId"
                              name='TruckIds'
                              data={truckOptions}
                              columns={selectAllColumns}
                              hiddenColumns={['Id']}
                              keyField='Id'
                              searchEnabled={true}
                              className="form-control"
                            />}
                        </Col>
                      </Row>
                      : <>
                        <Row className="mb-3 form-group d-flex align-items-center">
                          <Col xs={4}>
                            <Label
                              className="form-label"
                              label="customer_id"
                            />
                          </Col>
                          <Col xs={8}>
                            <Field
                              name="CustomerId"
                              className="form-control"
                              as={FormikFormSelectField}
                              options={getCustomerOptionsAll}
                              changeHandler={changeHandler}
                            />
                          </Col>
                        </Row>
                        <Row className="mb-3 form-group d-flex align-items-center">
                          <Col xs={4}>
                            <Label
                              className="form-label"
                              label="department_id"
                            />
                          </Col>
                          <Col xs={8}>
                            <Field
                              name="CustomerId2"
                              className="form-control"
                              as={FormikFormSelectField}
                              options={getAllDepartmentOptions}
                            />
                          </Col>
                        </Row>
                        <Row className="mb-3 form-group d-flex align-items-center">
                          <Col xs={4}>
                            <Label
                              className="form-label"
                              label="vehicle_id"
                            />
                          </Col>
                          <Col xs={8}>
                            <Field
                              name="CustomerId3"
                              className="form-control"
                              as={FormikFormSelectField}
                              options={getAllVehicleOptions}
                            />
                          </Col>
                        </Row>

                      </>}

                  </Modal.Body>
              }
              <div className="modal-footer  border-0">
                <div className="m-auto">
                  <LoaderButton
                    type="submit"
                    disabled={isDisabled() || actionInProgress}
                    isLoading={actionInProgress}
                    text="filter_title"
                  />
                  <Button
                    variant="btn-secondary bg-light btn-light ms-2"
                    onClick={onModalClosed}
                  >
                    <FormattedMessage id="button_cancel" />
                  </Button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};

export default observer(DashboardFilterForm);
