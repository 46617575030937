/*    
<summary>
   This is the Vehicle Component which shows the Vehicles Data.
   Developer: Aashish Singh, Created Date:04-March-2024
</summary>
<param>No Parameter Passed</param>
<returns>Returns JSX</returns>
*/
import { observer } from "mobx-react";
import React, { useState, useCallback, useEffect } from "react";
import { toast } from "react-toastify";
import { ColumnType } from "../../constants/enums/gridColumnTypeEnum";
import { useStore } from "../../contexts/StoreProvider";
import useWindowDimensions, { getTableMaxHeight, } from "../../helpers/windowResize.helper";
import { ISorting } from "../../models/ISorting";
import Grid, { getColumns } from "../../shared-components/Grid/Grid";
import { formatMessage } from "../../translations/formatMessage";
import Pagination from "../../shared-components/Grid/Pagination";
import IPageInfo from "../../models/IPageInfo";
import { IActionFormatter } from "../../models/IFormatter";
import { PageLimitOptions, initialPageLimit, } from "../../constants/pageLimit.constant";
import { Button, Container, Dropdown } from "react-bootstrap";
import DeletePopup from "../../shared-components/Popup/DeletePopup";
import DeletePopupHOC from "../../shared-components/Popup/Hoc/DeletePopupHoc";
import AddPopupHOC from "../../shared-components/Popup/Hoc/AddPopupHoc";
import EditPopupHOC from "../../shared-components/Popup/Hoc/EditPopupHoc";
import { Col, ListGroup, Row } from "react-bootstrap";
import AddUpdateVehicle from "./components/add-edit-vehicle";
import { IVehicle, IVehicleCSVImportForm, IVehicleList } from "../../models/response/IVehicleResponse";
import Footer from "../../shared-components/Footer/Footer";
import SubHeader from "../../shared-components/Header/SubHeader";
import VehicleDetail from "./components/vehicle-detail";
import SlidingPanel from "react-sliding-side-panel";
import "./vehicle.scss";
import { FormattedMessage } from "react-intl";
import { formatDateToText } from "../../helpers/common-helper-components";
import { allocationPeriodColorSchemeVehicle, emptyValue, notAvailable } from "../../constants/common-constant";
import { accessPermissionPermitted, isOperationPermitted, showIcons, } from "../../helpers/routesFunctions.helper";
import { getClientId, getTableView } from "../../helpers/localStorages.helper";
import { ModuleName } from "../../constants/moduleName.constant";
import SpinnerLoader from "../../shared-components/Loader/SpinnerLoader";
import { FilterPopupHOC } from "../../shared-components/Filters/FilterPopupHOC";
import VehicleFilterForm from "./forms/vehicle-filter-form";
import NoData from "../../assets/Images/no_record.png";
import UrlConstants from "../../constants/url.constant";
import _ from "lodash";
import { driverInitialFilterState, vehicleInitialFilterState } from "../../core/initialState/initial-filter-states";
import userPageLimitSettingEnum from "../../constants/enums/user-pagination-setting-enum";
import ImportPopUpHoc from "../../shared-components/Popup/Hoc/ImportPopupHoc";
import AddVehicleCSVForm from "./forms/import-vehicle-csv-form";


interface IProps {
  size: {
    height: number;
  };
  showDeletePopup: boolean;
  deleteToggleHandler: () => void;
  showAddPopup: boolean;
  addToggleHandler: () => void;
  showEditPopup: boolean;
  editToggleHandler: () => void;
  showFilterPopup: boolean;
  filterToggleHandler: () => void;
  showImportPopup: boolean;
  importToggleHandler: () => void;
}

const Vehicle: React.FC<IProps> = (props: IProps) => {
  const { vehicleStore, preferencesStore, customerStore } = useStore();
  const {
    showDeletePopup,
    deleteToggleHandler,
    showAddPopup,
    addToggleHandler,
    showFilterPopup,
    filterToggleHandler,
  } = props;
  const {
    inProgress,
    error,
    vehicleList,
    GetVehicleListService,
    getVehicleList,
    reset,
    resetError,
    DeleteVehicleService,
    DeleteVehicleState,
    resetDeleteVehicleState,
    addUpdateVehicleState,
    resetGetVehicleDetail,
    filter,
    setFilterDetail,
    addVehicleCSVState,
    addVehicleCSVService,
    resetAddVehicleCSVState
  } = vehicleStore;
  const { setUpdateTableView, CSVFileDownloadPostService, userPageLimitSetting, setUserPageLimitSetting, CSVFileDownloadService } = preferencesStore;
  const { GetAllCustomerService } = customerStore;
  const [tableView, setTableView] = useState<boolean>(
    getTableView() ? (getTableView() == "false" ? false : true) : false
  );
  const [selectedId, setSelectedId] = useState<number>(-1);
  const [selectedRow, setSelectedRow] = useState<IVehicleList | undefined>(
    undefined
  );
  const [openPanel, setOpenPanel] = useState(false);
  const [changeDetails, setChangeDetails] = useState<boolean>(false);
  const [disablePagination, setDisablePagination] = useState<boolean>(false);
  const vehicleListData: Array<IVehicleList> = getVehicleList;
  const [pageLimit, setPageLimit] = useState<number>(userPageLimitSetting.vehicleLimitConfig);
  const [searchText, setSearchText] = useState("");
  const [sorting, setSorting] = useState<ISorting>({
    column: "registration_expiry_date",
    orderBy: "asc",
  });
  const [isApiCalled, setIsApiCalled] = useState<boolean>(false);

  const [pageInfo, setPageInfo] = useState<IPageInfo>({
    currentPage: 1,
    totalPages: 1,
  });

  const { height, width } = useWindowDimensions();

  const onPageChanged = useCallback((pageNumber: number) => {
    setPageInfo((pageInfo) => ({ ...pageInfo, currentPage: pageNumber }));
  }, []);

  const onPageLimitChangedForUser = (
    evt: React.ChangeEvent<HTMLSelectElement>
  ) => {
    let value = parseInt(evt.target.value);
    setPageLimit(value);
    setPageInfo({ ...pageInfo, currentPage: 1 });
    setUserPageLimitSetting(userPageLimitSettingEnum.VehicleLimitConfig, value);
  };

  const onSearchTextChanged = (text: string) => {
    setSearchText(text);
  };

  const noDataView = () => {
    return (
      <>
        {vehicleListData.length == 0 && (
          <div className="no_data_height">

          </div>
        )}
      </>
    )
  };

  const tableViewToggleHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    let tableView =
      localStorage.getItem("secure_fuel_tableView") == "false"
        ? "true"
        : "false";
    setUpdateTableView(tableView);
    if (tableView == "false") {
      setTableView(false);
    } else {
      setTableView(true);
    }
  };

  const exportFilteredLogsCSV = () => {
    let tempFilterDetails = { ..._.omit(filter, ["isFilterActive"]) };
    tempFilterDetails = { ...tempFilterDetails, RegistrationExpiryDateInDays: tempFilterDetails.RegistrationExpiryDateInDays == -1 ? null : tempFilterDetails.RegistrationExpiryDateInDays };
    const clientIdFromLocalStorage = getClientId() != null ? getClientId() : -1;
    const url = `${UrlConstants.VehicleCSVDownload}?clientId=${clientIdFromLocalStorage}`;
    CSVFileDownloadPostService(url, "Vehicle", tempFilterDetails);
  };

  /**
   * This function calls the "GetVehicleListService" store funtion that get vehicle list from API
   */
  const callGetVehicleList = useCallback(() => {
    setDisablePagination(true);
    GetVehicleListService(
      pageInfo.currentPage,
      pageLimit,
      sorting.orderBy,
      sorting.column,
      _.omit(filter, ["isFilterActive"])
    );
    setIsApiCalled(true);
  }, [
    GetVehicleListService,
    pageInfo.currentPage,
    pageLimit,
    sorting.orderBy,
    sorting.column,
    filter,
  ]);

  // useEffect(() => {
  //   return()=>{
  //     resetFilterHandler();
  //   }
  // }, []);

  // useEffect(() => {
  //   callGetVehicleList();
  // }, [callGetVehicleList]);

  // useEffect(() => {
  //   if (isApiCalled) {
  //     // showUserDetailToggleHandler();
  //     setIsApiCalled(false);
  //   }
  // }, [isApiCalled]);

  /**
  * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
  * dependency "filter" of the useEffect changed.
  */
  useEffect(() => {
    if (filter.isFilterActive) {
      callGetVehicleList();
    }
    else
      callGetVehicleList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter, callGetVehicleList]);


  useEffect(() => {
    setPageInfo((pageInfo) => ({
      ...pageInfo,
      totalPages: vehicleList?.TotalPages,
    }));
    setDisablePagination(false);
  }, [getVehicleList, vehicleList.TotalPages]);

  useEffect(() => {
    if (addUpdateVehicleState.success) {
      callGetVehicleList();
    }
  }, [addUpdateVehicleState.success, callGetVehicleList]);

  useEffect(() => {
    if (addVehicleCSVState.success) {
      callGetVehicleList();
      resetAddVehicleCSVState();
    }
  }, [addVehicleCSVState.success, callGetVehicleList, resetAddVehicleCSVState]);

  useEffect(() => {
    if (error) {
      toast.error(formatMessage(error));
      resetError();
    }
  }, [error, resetError]);

  useEffect(() => {
    if (DeleteVehicleState.success) {
      toast.success(formatMessage("deleted_success"));
      resetDeleteVehicleState();
      deleteToggleHandler();
      callGetVehicleList();
    }
  }, [DeleteVehicleState.success, reset, callGetVehicleList]);

  useEffect(() => {
    if (DeleteVehicleState.error) {
      toast.error(formatMessage(DeleteVehicleState.error));
      resetDeleteVehicleState();
    }
  }, [DeleteVehicleState.error, resetDeleteVehicleState]);

  /**
   * This function shows the add vehicle pop up from where we can add new vehicle and update existing vehicle. we use id to identify
   * that we are adding or updating vehicle.
   * "Id == -1" : we are adding new vehicle
   * "Id != -1" : means we are updating existing vehicle
   */
  const openAddUpdateHandler = (row: { Id: number }) => {
    GetAllCustomerService();
    closePanelHandler();
    setSelectedId(row.Id);
    setChangeDetails(false);
    addToggleHandler();
  };

  /**
   * This function shows the delete vehicle confirmation popup component and sets the seleted vehicle Id
   */
  const deleteClickHandler = (row: { Id: number }) => {
    closePanelHandler();
    setSelectedId(row.Id);
    setChangeDetails(false);
    deleteToggleHandler();
  };

  /**
   * This function deletes the vehicle by providing Id to the Api
   */
  const deleteHandler = () => {
    DeleteVehicleService(selectedId);
  };

  /**
   * This function shows the detail vehicle panel
   */
  const showDetailsClickHandler = (row: { Id: number }) => {
    setSelectedId(row.Id);
    setChangeDetails(true);
    // !showUserDetail && showUserDetailToggleHandler();
    setIsApiCalled(false);
  };

  const rowEvents = (row: any) => {
    setSelectedCard(row.VehicleId)
    setSelectedRow(row);
    setOpenPanel(true);
  };

  const closePanelHandler = () => {
    setSelectedRow(undefined);
    setSelectedCard(-1)
    setOpenPanel(false);
    resetGetVehicleDetail();
  };

  const openFilterHandler = () => {
    closePanelHandler();
    filterToggleHandler();
  };

  const filterSubmitHandler = (values: any) => {
    closePanelHandler();
    setPageInfo({ ...pageInfo, currentPage: 1 });
    setFilterDetail({ ...values, isFilterActive: true });
    props.filterToggleHandler();
  };

  const resetFilterHandler = () => {
    setPageInfo({ ...pageInfo, currentPage: 1 });
    setFilterDetail({ ...vehicleInitialFilterState });
  };

  const handleSortingChange = (accessor: string) => {
    let sortOrder = sorting.orderBy;
    if (accessor === sorting.column) {
      sortOrder = sorting.orderBy === "asc" ? "desc" : "asc";
    }
    setSorting({ column: accessor, orderBy: sortOrder });
  };

  const addIcons: Array<IActionFormatter<string>> = [
    {
      handler: rowEvents,
      icon: (
        <svg
          enableBackground="new 0 0 519.643 519.643"
          width="14"
          height="14"
          viewBox="0 0 519.643 519.643"
        >
          <circle cx="259.823" cy="259.866" r="80" />
          <path d="m511.673 237.706c-61.494-74.31-154.579-145.84-251.85-145.84-97.29 0-190.397 71.58-251.85 145.84-10.63 12.84-10.63 31.48 0 44.32 15.45 18.67 47.84 54.71 91.1 86.2 108.949 79.312 212.311 79.487 321.5 0 43.26-31.49 75.65-67.53 91.1-86.2 10.599-12.815 10.654-31.438 0-44.32zm-251.85-89.84c61.76 0 112 50.24 112 112s-50.24 112-112 112-112-50.24-112-112 50.24-112 112-112z" />
        </svg>
      ),
      key: "",
      title: "label_showdetails",
      className: "btn-secondary",
    },
    {
      handler: openAddUpdateHandler,
      icon: (
        <svg height="14" viewBox="0 0 492.49284 492" width="14">
          <path d="m304.140625 82.472656-270.976563 270.996094c-1.363281 1.367188-2.347656 3.09375-2.816406 4.949219l-30.035156 120.554687c-.898438 3.628906.167969 7.488282 2.816406 10.136719 2.003906 2.003906 4.734375 3.113281 7.527344 3.113281.855469 0 1.730469-.105468 2.582031-.320312l120.554688-30.039063c1.878906-.46875 3.585937-1.449219 4.949219-2.8125l271-270.976562zm0 0" />
          <path d="m476.875 45.523438-30.164062-30.164063c-20.160157-20.160156-55.296876-20.140625-75.433594 0l-36.949219 36.949219 105.597656 105.597656 36.949219-36.949219c10.070312-10.066406 15.617188-23.464843 15.617188-37.714843s-5.546876-27.648438-15.617188-37.71875zm0 0" />
        </svg>
      ),
      // key: "IsEditable",
      key: "",
      title: "label_edit",
      className: "btn-warning",
    },
    {
      handler: deleteClickHandler,
      icon: (
        <svg
          version="1.1"
          x="0px"
          y="0px"
          width="14"
          height="14"
          viewBox="0 0 512 512"
        >
          <g>
            <g>
              <path
                d="M62.205,150l26.569,320.735C90.678,493.865,110.38,512,133.598,512h244.805c23.218,0,42.92-18.135,44.824-41.265
L449.795,150H62.205z M180.986,452c-7.852,0-14.458-6.108-14.956-14.063l-15-242c-0.513-8.276,5.771-15.395,14.033-15.908
c8.569-0.601,15.381,5.757,15.908,14.033l15,242C196.502,444.632,189.721,452,180.986,452z M271,437c0,8.291-6.709,15-15,15
c-8.291,0-15-6.709-15-15V195c0-8.291,6.709-15,15-15s15,6.709,15,15V437z M360.97,195.938l-15,242
c-0.493,7.874-7.056,14.436-15.908,14.033c-8.262-0.513-14.546-7.632-14.033-15.908l15-242
c0.513-8.276,7.764-14.297,15.908-14.033C355.199,180.543,361.483,187.662,360.97,195.938z"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                d="M451,60h-90V45c0-24.814-20.186-45-45-45H196c-24.814,0-45,20.186-45,45v15H61c-16.569,0-30,13.431-30,30
c0,16.567,13.431,30,30,30c137.966,0,252.039,0,390,0c16.569,0,30-13.433,30-30C481,73.431,467.569,60,451,60z M331,60H181V45
c0-8.276,6.724-15,15-15h120c8.276,0,15,6.724,15,15V60z"
              />
            </g>
          </g>
        </svg>
      ),
      // key: "IsDeletable",
      key: "",
      title: "label_delete",
      className: "btn-danger",
    },

  ];

  const columns = getColumns<IVehicle, number>(
    [
      {
        columnType: ColumnType.Hidden,
        accessorKey: "VehicleId",
      },
      {
        columnType: ColumnType.Sorting,
        accessorKey: "RFID",
        sortingName: "rfid",
        displayName: "rfid",
      },
      {
        columnType: ColumnType.ActionsFormatter,
        icons: showIcons(addIcons, ModuleName.VehicleManagement),
        displayName: "label_action",
      },
      {
        columnType: ColumnType.Sorting,
        accessorKey: "Description",
        sortingName: "description",
        displayName: "description",
      },
      {
        columnType: ColumnType.TextHeader,
        accessorKey: "CustomerName",
        // sortingName: "customer_id",
        displayName: "customer_name",
      },
      {
        columnType: ColumnType.Sorting,
        accessorKey: "CustId2",
        sortingName: "cust_id2",
        displayName: "cust_id_2",
      },
      {
        columnType: ColumnType.Sorting,
        accessorKey: "CustId4",
        sortingName: "cust_id4",
        displayName: "cust_id_4",
      },
      {
        columnType: ColumnType.Sorting,
        accessorKey: "CustId5",
        sortingName: "cust_id5",
        displayName: "cust_id_5",
      },
      {
        columnType: ColumnType.Sorting,
        accessorKey: "CustId6",
        sortingName: "cust_id6",
        displayName: "cust_id_6",
      },
      // {
      //   columnType: ColumnType.TextHeader,
      //   accessorKey: "BelongToCustomer",
      //   // sortingName: "belong_to_customer",
      //   displayName: "belongs_to_customer",
      // },
      {
        columnType: ColumnType.Sorting,
        accessorKey: "RegistrationNo",
        sortingName: "registration_no",
        displayName: "registration_no",
      },
      {
        columnType: ColumnType.Sorting,
        accessorKey: "RegistrationExpiryDate",
        sortingName: "registration_expiry_date",
        displayName: "registration_expiry_date",
      },
      {
        columnType: ColumnType.Sorting,
        accessorKey: "TruckAllocation1",
        sortingName: "truck_allocation_1",
        displayName: "truck_allocation",
      },
      {
        columnType: ColumnType.Sorting,
        accessorKey: "FuelDeliveryEnabled",
        sortingName: "fuel_delivery_enabled",
        displayName: "fuel_delivery_enabled",
      },
      {
        columnType: ColumnType.Sorting,
        accessorKey: "AllocationPerFill",
        sortingName: "allocation_per_fill",
        displayName: "allocation_per_fill",
      },
      {
        columnType: ColumnType.Sorting,
        accessorKey: "AllocationPeriod",
        sortingName: "allocation_period",
        displayName: "allocation_period",
      },
      {
        columnType: ColumnType.Sorting,
        accessorKey: "AllocationVolume",
        sortingName: "allocation_volume",
        displayName: "allocation_volume",
      },
      {
        columnType: ColumnType.Sorting,
        accessorKey: "RemainingVolume",
        sortingName: "remaining_volume",
        displayName: "remaining_volume",
      },
      {
        columnType: ColumnType.Sorting,
        accessorKey: "LowerConsumptionLimit",
        sortingName: "lower_consumption_limit",
        displayName: "lower_consumption_limit",
      },
      {
        columnType: ColumnType.Sorting,
        accessorKey: "HigherConsumptionLimit",
        sortingName: "higher_consumption_limit",
        displayName: "higher_consumption_limit",
      },
    ],
    sorting,
    handleSortingChange
  );

  const openAddCSVHandler = () => {
    props.importToggleHandler();
  };

  const switchButton = () => {
    return (
      <div className="form-check tableAccordian-switch p-0 m-0">
        <input
          className="ml-1 form-check-input"
          type="checkbox"
          role="switch"
          data-testid="SwitchConfigurationView"
          checked={tableView}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            closePanelHandler();
            tableViewToggleHandler(e);
          }}
          id="flexSwitchCheckDefault"
        />
        <label htmlFor="flexSwitchCheckDefault">
          <svg width="17" height="17" version="1.1" viewBox="0 0 32 32">
            <path
              fill="currentColor"
              d="M-0.4-0.4v32.7h32.7V-0.4H-0.4z M2.6,2.6h7.4v7.4H2.6V2.6z M13,2.6H19v7.4H13V2.6z M21.9,2.6h7.4v7.4h-7.4V2.6z M2.6,13h7.4
V19H2.6V13z M13,13H19V19H13V13z M21.9,13h7.4V19h-7.4V13z M2.6,21.9h7.4v7.4H2.6V21.9z M13,21.9H19v7.4H13V21.9z M21.9,21.9h7.4
v7.4h-7.4V21.9z"
            />
          </svg>

          <svg width="17" height="17" version="1.1" viewBox="0 0 17 17">
            <path
              d="M4 0v9h13v-9h-13zM16 8h-11v-7h11v7zM0 3h3v-3h-3v3zM1 1h1v1h-1v-1zM4 13h13v-3h-13v3zM5 11h11v1h-11v-1zM0 13h3v-3h-3v3zM1 11h1v1h-1v-1zM4 17h13v-3h-13v3zM5 15h11v1h-11v-1zM0 17h3v-3h-3v3zM1 15h1v1h-1v-1z"
              fill="currentColor"
            />
          </svg>
        </label>
      </div>
    );
  };

  const [selectedCard, setSelectedCard] = useState<number>(-1);
  const csvFormInitailValues: IVehicleCSVImportForm = {
    ClientId: -1,
    CSVFile: "",
  };

  const downloadcsvHandler = () => {
    const clientIdFromLocalStorage = getClientId() != null ? getClientId() : -1;
    CSVFileDownloadService(`${UrlConstants.ExportEmptyVehicleCsv}?clientId=${clientIdFromLocalStorage}`, "AddVehicles");
  };

  const csvSubmitHandler = (values: IVehicleCSVImportForm) => {
    addVehicleCSVService(values);
  };
  const onCSVModalClosed = () => {
    props.importToggleHandler();
  };

  return (
    <React.Fragment>
      {props.showImportPopup && (
        <AddVehicleCSVForm
          initialValues={csvFormInitailValues}
          isLoading={addVehicleCSVState.inProgress}
          downloadcsvHandler={downloadcsvHandler}
          isDownload={true}
          submitHandler={csvSubmitHandler}
          modalClosed={onCSVModalClosed}
        />
      )}
      {showAddPopup && (
        <AddUpdateVehicle id={selectedId} modalClosed={addToggleHandler} />
      )}

      {showFilterPopup && (
        <VehicleFilterForm
          initialValues={_.omit(filter, ["isFilterActive"])}
          submitHandler={filterSubmitHandler}
          onModalClosed={filterToggleHandler}
          actionInProgress={false}
        />
      )}

      {showDeletePopup && (
        <DeletePopup
          modalSubmit={deleteHandler}
          modalClosed={deleteToggleHandler}
          isLoading={DeleteVehicleState.inProgress}
          message="delete_vehicle_confirm"
          submitBtnText="submit_button"
          cancelBtnText="button_cancel"
          modalTitle="delete_vehicle"
        />
      )}

      {/* <div className={`main-content user ${showUserDetail ? 'table-container' : ''} `}> */}
      <div className={`main-content user`}>
        <SubHeader
          moduleNameString={"vehicle_title"}
          moduleName={ModuleName.VehicleManagement}
          addHandler={openAddUpdateHandler}
          isAdd={true}
          filterHandler={openFilterHandler}
          isFilterActive={filter.isFilterActive}
          exportCSVHandler={exportFilteredLogsCSV}
          importCSVHandler={openAddCSVHandler}
          resetFilterHandler={resetFilterHandler}
          addButtonTitle="add_vehicle"
          switchButton={switchButton}
          isFullView={false}
          isDownload={true}
          isRefresh={false}
          isFilter={true}
          isDateFilter={false}
          isImport={true} isMissing={false} />
      </div>
      {/* {vehicleListData.length > 0 && (
          <li>
            <div className="form-check tableAccordian-switch p-0 m-0">
              <input
                className="ml-1 form-check-input"
                type="checkbox"
                role="switch"
                data-testid="SwitchConfigurationView"
                checked={tableView}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  closePanelHandler();
                  tableViewToggleHandler(e);
                }}
                id="flexSwitchCheckDefault"
              />
              <label htmlFor="flexSwitchCheckDefault">
                <svg width="17" height="17" version="1.1" viewBox="0 0 32 32">
                  <path
                    fill="currentColor"
                    d="M-0.4-0.4v32.7h32.7V-0.4H-0.4z M2.6,2.6h7.4v7.4H2.6V2.6z M13,2.6H19v7.4H13V2.6z M21.9,2.6h7.4v7.4h-7.4V2.6z M2.6,13h7.4
  V19H2.6V13z M13,13H19V19H13V13z M21.9,13h7.4V19h-7.4V13z M2.6,21.9h7.4v7.4H2.6V21.9z M13,21.9H19v7.4H13V21.9z M21.9,21.9h7.4
  v7.4h-7.4V21.9z"
                  />
                </svg>

                <svg width="17" height="17" version="1.1" viewBox="0 0 17 17">
                  <path
                    d="M4 0v9h13v-9h-13zM16 8h-11v-7h11v7zM0 3h3v-3h-3v3zM1 1h1v1h-1v-1zM4 13h13v-3h-13v3zM5 11h11v1h-11v-1zM0 13h3v-3h-3v3zM1 11h1v1h-1v-1zM4 17h13v-3h-13v3zM5 15h11v1h-11v-1zM0 17h3v-3h-3v3zM1 15h1v1h-1v-1z"
                    fill="currentColor"
                  />
                </svg>
              </label>
            </div>
          </li>
        )} */}
      {inProgress && <SpinnerLoader />}

      <Container fluid className="p-0">
        {vehicleListData.length !== 0 ? (
          <div className="vehicleWrapper" id="moveContent">

            <div className={`${openPanel ? "paneIsOpen" : ""}`}>
              <div className="pt-2 px-3">
                <div className="Total-users d-flex justify-content-end mb-2">
                  <FormattedMessage id="Total Vehicles" /> :{" "}
                  <b className="ps-1"> {vehicleList.TotalFilteredVehicles}</b>
                </div>
                {!tableView && noDataView()}
                {!tableView ? (
                  <div
                    className={`row g-3 mt-1 ${vehicleListData.length > 0 ? "card_height" : ""
                      }`}
                  >
                    {vehicleListData.length > 0 &&
                      vehicleListData.map((vehicle: IVehicleList) => {
                        const registrationExpiryDate =
                          vehicle.RegistrationExpiryDate
                            ? new Date(vehicle.RegistrationExpiryDate)
                            : new Date();
                        const volumePercentage =
                          (vehicle.RemainingVolume * 100) /
                          vehicle.AllocationVolume;
                        return (
                          <div
                            className={`${vehicleListData.length > 2
                              ? "vehicle-100"
                              : "vehicle-two-card"
                              } col-12 col-xxl-6 col-xl-6 col-lg-12 col-xs-6`}
                            key={vehicle.VehicleId}
                          >
                            {/* //  <div className="col-12 col-xxl-6 col-xl-6 col-lg-12 col-xs-6" key={vehicle.VehicleId}> */}
                            <div
                              className={
                                selectedCard === vehicle.VehicleId
                                  ? "card card-body rounded shadow vehicle_card active-card"
                                  : "card  card-body rounded shadow vehicle_card"
                              }
                            >
                              <div className="row g-0">
                                <div className="col-xxl-5 col-lg-5 col-md-5 col-sm-12  active-mute-text">
                                  <p className="text-muted mb-2">
                                    <i
                                      className={`fa fa-circle ${vehicle.FuelDeliveryEnabled
                                        ? "text-success"
                                        : "text-danger"
                                        }`}
                                    ></i>
                                    &nbsp;
                                    <FormattedMessage
                                      id={
                                        vehicle.FuelDeliveryEnabled
                                          ? "fuel_delivery_enabled"
                                          : "fuel_delivery_disabled"
                                      }
                                    />
                                  </p>
                                  <h4 className="m-0">
                                    {vehicle.RFID !== emptyValue
                                      ? vehicle.RFID
                                      : formatMessage("notAvailable_rfid")}
                                  </h4>
                                  <p>{vehicle.Description}</p>

                                  <div className="mt-3 row">
                                    <div className="col-12">
                                      <small className="text-muted">
                                        <FormattedMessage id="reg_no_expiry" />
                                      </small>
                                      <p className="h5">
                                        {vehicle.RegistrationNo}{" "}
                                        <small className="h6 text-muted">
                                          [
                                          {formatDateToText(
                                            registrationExpiryDate
                                          )}
                                          ]
                                        </small>
                                      </p>
                                    </div>
                                  </div>
                                  <div className="mt-3 row">
                                    <div className="col-6">
                                      <small className="text-muted">
                                        <FormattedMessage id="truck_allocation" />
                                      </small>
                                      <p>{vehicle.TruckAllocation1}</p>
                                    </div>
                                    {vehicle.CustomerName != emptyValue && (
                                      <div className="col-6">
                                        <small className="text-muted">
                                          <FormattedMessage id="customer_name" />
                                        </small>
                                        <p>{vehicle.CustomerName}</p>
                                      </div>
                                    )}
                                  </div>
                                </div>
                                <div className="col-xxl-7 col-lg-7 col-md-7 col-sm-12 p-3 bg-light rounded">
                                  <div className="row g-0">
                                    <div className="col-6 col-xs-6">
                                      <small className="text-muted pe-2">
                                        <FormattedMessage id="allocation_period" />
                                      </small>
                                      <span
                                        className={`px-2 rounded fw-normal allocation-period ${allocationPeriodColorSchemeVehicle(
                                          vehicle.AllocationPeriod
                                        )}`}
                                      >
                                        {" "}
                                        {vehicle.AllocationPeriod}{" "}
                                      </span>
                                      <br />
                                      <small className="text-muted pe-2">
                                        <FormattedMessage id="allocation_per_fill" />
                                      </small>
                                      : {vehicle.AllocationPerFill}{" "}
                                    </div>
                                    <div className="col-5 col-xs-5 text-end">
                                      <small className="text-muted">
                                        <FormattedMessage id="volume" />
                                      </small>
                                      <h6>
                                        <span
                                          className={`fw-bold ${volumePercentage > 25
                                            ? volumePercentage < 75
                                              ? "text-warning"
                                              : "text-success"
                                            : "text-danger"
                                            }`}
                                        >
                                          {vehicle.RemainingVolume}
                                        </span>
                                        <small className="h6 text-muted">
                                          /{vehicle.AllocationVolume}
                                        </small>
                                      </h6>
                                    </div>
                                    <div className="col-1 col-xs-1 text-end">
                                      <div className="d-flex justify-content-end edit-popup">
                                        <Dropdown className="border-0 enable-caret editButton">
                                          <Dropdown.Toggle
                                            variant=""
                                            id="dropdown-basic"
                                            className="shadow-none pt-1 p-0"
                                          >
                                            <span>
                                              {" "}
                                              <i className="fas fa-ellipsis-v"></i>
                                            </span>
                                          </Dropdown.Toggle>

                                          <Dropdown.Menu className="dropdown-menu-start p-0">
                                            {accessPermissionPermitted(
                                              ModuleName.VehicleManagement, "edit"
                                            ) && (
                                                <Dropdown.Item
                                                  className="d-flex align-items-center pb-0"
                                                  onClick={() => {
                                                    openAddUpdateHandler({
                                                      Id: vehicle.Id,
                                                    });
                                                  }}
                                                  title="Edit"
                                                >
                                                  <div className="">
                                                    <svg
                                                      height="14"
                                                      viewBox="0 0 492.49284 492"
                                                      width="14"
                                                      fill="#fff"
                                                    >
                                                      <path d="m304.140625 82.472656-270.976563 270.996094c-1.363281 1.367188-2.347656 3.09375-2.816406 4.949219l-30.035156 120.554687c-.898438 3.628906.167969 7.488282 2.816406 10.136719 2.003906 2.003906 4.734375 3.113281 7.527344 3.113281.855469 0 1.730469-.105468 2.582031-.320312l120.554688-30.039063c1.878906-.46875 3.585937-1.449219 4.949219-2.8125l271-270.976562zm0 0" />
                                                      <path d="m476.875 45.523438-30.164062-30.164063c-20.160157-20.160156-55.296876-20.140625-75.433594 0l-36.949219 36.949219 105.597656 105.597656 36.949219-36.949219c10.070312-10.066406 15.617188-23.464843 15.617188-37.714843s-5.546876-27.648438-15.617188-37.71875zm0 0" />
                                                    </svg>
                                                  </div>
                                                  <div className="ps-2 fs-6 text-white">
                                                    <a
                                                      className="dropdown-item  p-0 pt-1"
                                                      href="javascript:;"
                                                    >
                                                      <FormattedMessage id="label_edit" />
                                                    </a>
                                                  </div>
                                                </Dropdown.Item>
                                              )}
                                            {accessPermissionPermitted(
                                              ModuleName.VehicleManagement, "delete"
                                            ) && (
                                                <Dropdown.Item
                                                  className="d-flex align-items-center pb-0"
                                                  onClick={() => {
                                                    deleteClickHandler({
                                                      Id: vehicle.Id,
                                                    });
                                                  }}
                                                  title="Delete"
                                                >
                                                  <div className="">
                                                    <svg
                                                      version="1.1"
                                                      x="0px"
                                                      y="0px"
                                                      width="13"
                                                      height="13"
                                                      fill="#fff"
                                                      viewBox="0 0 512 512"
                                                    >
                                                      <g>
                                                        <g>
                                                          <path
                                                            d="M62.205,150l26.569,320.735C90.678,493.865,110.38,512,133.598,512h244.805c23.218,0,42.92-18.135,44.824-41.265
      L449.795,150H62.205z M180.986,452c-7.852,0-14.458-6.108-14.956-14.063l-15-242c-0.513-8.276,5.771-15.395,14.033-15.908
      c8.569-0.601,15.381,5.757,15.908,14.033l15,242C196.502,444.632,189.721,452,180.986,452z M271,437c0,8.291-6.709,15-15,15
      c-8.291,0-15-6.709-15-15V195c0-8.291,6.709-15,15-15s15,6.709,15,15V437z M360.97,195.938l-15,242
      c-0.493,7.874-7.056,14.436-15.908,14.033c-8.262-0.513-14.546-7.632-14.033-15.908l15-242
      c0.513-8.276,7.764-14.297,15.908-14.033C355.199,180.543,361.483,187.662,360.97,195.938z"
                                                          />
                                                        </g>
                                                      </g>
                                                      <g>
                                                        <g>
                                                          <path
                                                            d="M451,60h-90V45c0-24.814-20.186-45-45-45H196c-24.814,0-45,20.186-45,45v15H61c-16.569,0-30,13.431-30,30
      c0,16.567,13.431,30,30,30c137.966,0,252.039,0,390,0c16.569,0,30-13.433,30-30C481,73.431,467.569,60,451,60z M331,60H181V45
      c0-8.276,6.724-15,15-15h120c8.276,0,15,6.724,15,15V60z"
                                                          />
                                                        </g>
                                                      </g>
                                                      <g></g>
                                                      <g></g>
                                                      <g></g>
                                                      <g></g>
                                                      <g></g>
                                                      <g></g>
                                                      <g></g>
                                                      <g></g>
                                                      <g></g>
                                                      <g></g>
                                                      <g></g>
                                                      <g></g>
                                                      <g></g>
                                                      <g></g>
                                                      <g></g>
                                                    </svg>
                                                  </div>
                                                  <div className="ps-2 fs-6 text-white">
                                                    <a
                                                      className="dropdown-item  p-0 pt-1"
                                                      href="javascript:;"
                                                    >
                                                      <FormattedMessage id="label_delete" />
                                                    </a>
                                                  </div>
                                                </Dropdown.Item>
                                              )}

                                            <Dropdown.Item
                                              className="d-flex align-items-center"
                                              onClick={() => {
                                                rowEvents(vehicle);
                                              }}
                                              title="Show Details"
                                            >
                                              <div className="">
                                                <svg
                                                  enableBackground="new 0 0 519.643 519.643"
                                                  width="14"
                                                  height="14"
                                                  viewBox="0 0 519.643 519.643"
                                                  fill="#fff"
                                                >
                                                  <circle
                                                    cx="259.823"
                                                    cy="259.866"
                                                    r="80"
                                                  />
                                                  <path d="m511.673 237.706c-61.494-74.31-154.579-145.84-251.85-145.84-97.29 0-190.397 71.58-251.85 145.84-10.63 12.84-10.63 31.48 0 44.32 15.45 18.67 47.84 54.71 91.1 86.2 108.949 79.312 212.311 79.487 321.5 0 43.26-31.49 75.65-67.53 91.1-86.2 10.599-12.815 10.654-31.438 0-44.32zm-251.85-89.84c61.76 0 112 50.24 112 112s-50.24 112-112 112-112-50.24-112-112 50.24-112 112-112z" />
                                                </svg>
                                              </div>
                                              <div className="ps-2 fs-6 text-white">
                                                <a
                                                  className="dropdown-item  p-0 pt-1"
                                                  href="javascript:;"
                                                >
                                                  <FormattedMessage id="label_showdetails" />
                                                </a>
                                              </div>
                                            </Dropdown.Item>
                                          </Dropdown.Menu>
                                        </Dropdown>
                                      </div>
                                      {/* <div className="dropdown">
                                      <a
                                        className="d-block pt-1 text-dark"
                                        href="#"
                                        role="button"
                                        id="dropdownMenuLink"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                      >
                                        <i className="fas fa-ellipsis-v"></i>
                                      </a>
                                      <ul
                                        className="dropdown-menu"
                                        aria-labelledby="dropdownMenuLink"
                                      >
                                        {isOperationPermitted(
                                          ModuleName.VehicleManagement
                                        ) && (
                                          <>
                                            <li>
                                              <a
                                                className="dropdown-item"
                                                href="javascript:;"
                                                onClick={() => {
                                                  openAddUpdateHandler({
                                                    Id: vehicle.Id,
                                                  });
                                                }}
                                              >
                                                <FormattedMessage id="label_edit" />
                                              </a>
                                            </li>
                                            <li>
                                              <a
                                                className="dropdown-item"
                                                href="javascript:;"
                                                onClick={() => {
                                                  deleteClickHandler({
                                                    Id: vehicle.Id,
                                                  });
                                                }}
                                              >
                                                <FormattedMessage id="label_delete" />
                                              </a>
                                            </li>
                                          </>
                                        )}
                                        <li>
                                          <a
                                            className="dropdown-item"
                                            href="javascript:;"
                                            onClick={() => {
                                              rowEvents(vehicle);
                                            }}
                                          >
                                            <FormattedMessage id="label_showdetails" />
                                          </a>
                                        </li>
                                      </ul>
                                    </div> */}
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-xxl-5 col-xl-4 col-lg-6 col-md-6 col-5">
                                      <div className="timeline">
                                        <div className="timeline-item">
                                          <small className="text-muted">
                                            <FormattedMessage id="lower_limit" />
                                            {/* Lower Limit */}
                                          </small>
                                          <h5 className="mb-0">
                                            {vehicle.LowerConsumptionLimit}{" "}
                                          </h5>
                                          <small className="h6">L/100km</small>
                                        </div>
                                        <div className="timeline-item">
                                          <small className="text-muted">
                                            <FormattedMessage id="higher_limit" />
                                            {/* Higher Limit */}
                                          </small>
                                          <h5 className="mb-0">
                                            {vehicle.HigherConsumptionLimit}{" "}
                                          </h5>
                                          <small className="h6">L/100km</small>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-xxl-7 col-xl-8 col-lg-6 col-md-6 col-7">
                                      <div className="progress_parent">
                                        <div className="progress">
                                          <div
                                            role="progressbar"
                                            className={`progress-bar ${volumePercentage > 25
                                              ? volumePercentage < 75
                                                ? "bg-warning"
                                                : "bg-success"
                                              : "bg-danger"
                                              }`}
                                            style={{
                                              width: `${volumePercentage}%`,
                                            }}
                                          ></div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                ) : (
                  <div className="rounded shadow">
                    <div className="Fuel-table">
                      <div
                        className="table-responsive"
                        style={getTableMaxHeight(height, width)}
                        data-testid="table"
                      >
                        {vehicleListData && (
                          <Grid
                            data={vehicleListData}
                            columns={columns}
                            isInProgress={inProgress}
                            hiddenColumns={["VehicleId"]}
                          />
                        )}
                        {noDataView()}
                      </div>
                    </div>
                  </div>
                )}

                <Container fluid>
                  <Row className="tableFooter py-1 mt-0">
                    <Col xs="4" className="d-flex">
                      <b className="text-white text-hidden-m mt-1 pt-1">
                        {" "}
                        <FormattedMessage id="show" />:{" "}
                      </b>
                      <select
                        data-testid="pageSelect"
                        className="ms-2 from-control"
                        onChange={onPageLimitChangedForUser}
                        value={pageLimit}
                      >
                        {PageLimitOptions.map((op) => (
                          <option key={op} value={op}>
                            {op}
                          </option>
                        ))}
                      </select>
                    </Col>
                    <Col className="d-flex justify-content-end" xs="8">
                      <Pagination
                        totalPages={pageInfo.totalPages}
                        pageLimit={pageLimit}
                        onPageChanged={onPageChanged}
                        disablePagination={disablePagination}
                      />
                    </Col>
                  </Row>
                </Container>
              </div>
            </div>
            <SlidingPanel
              type={"right"}
              isOpen={openPanel}
              noBackdrop={false}
              size={100}
            >
              <div>
                <div className="row g-0 border-bottom pt-3 pb-1 ps-3">
                  <Col lg={10} md={6} xs={6}>
                    <h2 className="fs-4 fw-bolder text-break">
                      {" "}
                      {selectedRow ? selectedRow.RFID : ""}
                    </h2>
                  </Col>
                  <Col className="text-end" lg={2} md={6} xs={6}>
                    <svg
                      onClick={closePanelHandler}
                      role="button"
                      xlinkTitle="close"
                      xmlns="http://www.w3.org/2000/svg"
                      width="30"
                      height="30"
                      fill="currentColor"
                      className="bi bi-x"
                      viewBox="0 0 16 16"
                    >
                      <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                    </svg>
                  </Col>
                </div>
                <div className="group_detailsWrapper">
                  <VehicleDetail selectedData={selectedRow} />
                </div>
              </div>
            </SlidingPanel>
          </div>
        ) : (
          <div className="no_data_table background-image ">
            <div className="no_data w-100 text-center">
              <img src={NoData} alt="NOData" className="NOData" />
              <h1 className="fs-2">
                <FormattedMessage id="message_no_datafound" />
              </h1>
            </div>
          </div>
        )}
      </Container>
      <Footer />
    </React.Fragment>
  );
};

export default FilterPopupHOC(
  ImportPopUpHoc(EditPopupHOC(AddPopupHOC(DeletePopupHOC(observer(Vehicle)))))
);
