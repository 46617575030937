import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { FormattedMessage } from "react-intl";
import RoutesConstants from "../../../constants/routes.constant";
import { useNavigate } from "react-router-dom";
import { useStore } from "../../../contexts/StoreProvider";
import DraggableModal from "../../../shared-components/Draggable/DraggableModal";

interface IProps {
  modalClosed: () => void;
}

const LogoutPopup = (props: IProps) => {
  const navigate = useNavigate();
  const { authStore } = useStore();
  const { logout } = authStore;

  const logoutCaller = () => {
    logout();
    //    navigate(RoutesConstants.Login);
  };

  return (
    <Modal
      show={true}
      onHide={() => { }}
      centered
      dialogClassName="logout-modal add-user-modal"
      dialogAs={DraggableModal}
    >
      <Modal.Header>
        <Modal.Title className="fs-5 ps-3">
          <FormattedMessage id="logout" />
        </Modal.Title>
        <div onClick={props.modalClosed} className="icons8-close">
          <span className="material-symbols-outlined fs-5 text-darks">
            close
          </span>
        </div>
      </Modal.Header>
      <Modal.Body className="text-center p-5">
        <svg
          viewBox="0 0 512 512"
          xmlns="http://www.w3.org/2000/svg"
          height={80}
          width={80}
          fill="#AA000D"
        >
          <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_2_copy_10" data-name="Layer 2 copy 10">
              <g id="_97" data-name="97">
                <path d="m256 0c-141.38 0-256 114.62-256 256s114.62 256 256 256 256-114.62 256-256-114.62-256-256-256zm2.67 387.89c0 16.53-15.75 28.15-30.88 22.79l-66.5-23.54c-22.42-7.94-37.49-29.72-37.49-54.21v-206.93c0-17.82 17-30.34 33.28-24.57l85.82 30.37a24.06 24.06 0 0 1 15.77 22.8zm79-51.9a39.11 39.11 0 0 1 -39.1 39.11h-29.9v-220.5a34.07 34.07 0 0 0 -22.43-32.22l-63.24-22.38h115.55a39.11 39.11 0 0 1 39.1 39.1v91.06h-30.79a25.84 25.84 0 1 0 0 51.68h30.79zm79.64-70.32-49.42 36.12a12 12 0 0 1 -19-9.66v-20.29h-42a15.84 15.84 0 1 1 0-31.68h42v-20.29a12 12 0 0 1 19-9.66l49.42 36.12a12 12 0 0 1 -.02 19.34z" />
              </g>
            </g>
          </g>
        </svg>
        <p className="h5 mt-3 pt-2">
          {" "}
          <FormattedMessage id="logout_message_logout_confirm" />{" "}
        </p>
      </Modal.Body>
      <Modal.Footer className="border-0 text-center d-flex m-auto pt-0">
        <Button variant="primary" onClick={logoutCaller}>
          <FormattedMessage id="button_confirm" />
        </Button>
        <Button variant="btn-secondary btn-light" onClick={props.modalClosed}>
          <FormattedMessage id="button_cancel" />
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default LogoutPopup;
