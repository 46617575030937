import config from "../helpers/config.helper";
import { IUrlConstant } from "../models/IUrlConstants";

const appConfig = config();

const ApiUrl = `${appConfig.API_URL}/api/v1`;
const AuthController = `${ApiUrl}/auth`;
const ClientAdminController = `${ApiUrl}/clientAdmin`;
const DriverController = `${ApiUrl}/driver`;
const VehicleController = `${ApiUrl}/vehicle`;
const UserController = `${ApiUrl}/clientUser`;
const TransactionController = `${ApiUrl}/tickets`;
const ResetPasswordController = `${ApiUrl}/reset`;
const CustomerController = `${ApiUrl}/customerAdmin`;
const CustomerRateController = `${ApiUrl}/customerRate`;
const RoleController = `${ApiUrl}/role`;
const ProfileController = `${ApiUrl}/profile`;
const DashboardController = `${ApiUrl}/dashboard`;
const TruckController = `${ApiUrl}/truck`;
const SuperAdminController = `${ApiUrl}/superAdminDashboard`;
const Monitor = `${ApiUrl}/monitor`;
const AlarmController = `${ApiUrl}/alarm`;
const ReportController = `${ApiUrl}/report`;
const PDFController = `${ApiUrl}/pdfSettings`;
const CurrencyController = `${ApiUrl}/currency`;
const CustomerPaymentsController = `${ApiUrl}/customerPayment`;
const SupplierController = `${ApiUrl}/supplier`;
const AssetsController = `${ApiUrl}/assets`;

const UrlConstants: IUrlConstant = {
    Authenticate: `${AuthController}/signin`,
    SignOut: `${AuthController}/signout`,
    RefreshToken: `${AuthController}/refreshtoken`,

    GetUserProfile: `${ProfileController}/getProfile`,
    UpdateLoggedInUserPassword: `${ProfileController}/updatePassword`,

    GetVerion: `${DashboardController}/getVersion`,
    GetFuelStatus: `${DashboardController}/getFuelStatus`,
    GetTankLevel: `${DashboardController}/getVersion`,
    GetDashboardMetrices: `${DashboardController}/getDashboardMetrics`,
    GetDailyFuelUsage: `${DashboardController}/getDailyFuelUsage`,
    GetVolumeDelivered: `${DashboardController}/getVolumeDelivered`,
    GetVolumeConsumed: `${DashboardController}/getVolumeConsumed`,
    GetTruckStatus: `${DashboardController}/getTruckStatus`,
    GetCompartmentFuel: `${DashboardController}/getCompartmentFuel`,

    GetClientList: `${ClientAdminController}/getClientList`,
    GetAllClient: `${ClientAdminController}/getAllClient`,
    GetAllClientAdminNames: `${ClientAdminController}/getAllClientAdminNames`,
    GetClientById: `${ClientAdminController}/getClientById`,
    AddClient: `${ClientAdminController}/addClient`,
    UpdateClientById: `${ClientAdminController}/updateClient`,
    DeleteClientById: `${ClientAdminController}/deleteClient`,
    ClientCSVDownload: `${ClientAdminController}/clientCSVDownload`,
    GetAllCustomerAndSupplierAdminsNames: `${CustomerController}/getAllCustomerAndSupplierAdminsNames`,

    GetCustomerList: `${CustomerController}/getAllCustomerAdmins`,
    GetAllCustomer: `${CustomerController}/getAllCustomerAdmins`,
    GetCustomerById: `${CustomerController}/getCustomerAdminById`,
    AddCustomer: `${CustomerController}/addCustomerAdmin`,
    UpdateCustomer: `${CustomerController}/updateCustomerAdmin`,
    DeleteCustomer: `${CustomerController}/deleteCustomerAdmin`,
    CustomerCSVDownload: `${CustomerController}/customerCSVDownload`,
    GetAllCustomerRate: `${CustomerRateController}/getAllRateForCustomer`,
    AddCustomerRate: `${CustomerRateController}/addRateForCustomer`,
    DeleteCustomerRate: `${CustomerRateController}/deleteRateForCustomer`,

    GetAllTruck: `${TruckController}/getAllTanks`,
    GetTruckList: `${AssetsController}/getAllAsset`,
    GetTruckById: `${AssetsController}/getAssetById`,
    AddTruck: `${AssetsController}/addAsset`,
    UpdateTruck: `${AssetsController}/updateAssetById`,
    DeleteTruck: `${AssetsController}/deleteAssetById`,
    TruckCSVDownload: `${AssetsController}/downloadAssetCSV`,

    GetAllUser: `${UserController}/getAllUsersByClientAdmin`,
    GetClientUserById: `${UserController}/getClientUserById`,
    AddClientUser: `${UserController}/addClientUser`,
    UpdateClientUser: `${UserController}/updateClientUser`,
    DeleteClientUser: `${UserController}/deleteClientUser`,
    UserCSVDownload: `${UserController}/userCSVDownload`,

    GetDriverList: `${DriverController}/getDriverList`,
    GetDriverById: `${DriverController}/getDriverById`,
    AddDriver: `${DriverController}/addDriver`,
    UpdateDriver: `${DriverController}/updateDriverById`,
    DeleteDriver: `${DriverController}/deleteDriverById`,
    DriverCSVDownload: `${DriverController}/driverCSVDownload`,
    ImportDriverCsv: `${DriverController}/importDriverCsv`,
    ExportEmptyDriverCsv: `${DriverController}/exportEmptyDriverCsv`,

    GetVehicleList: `${VehicleController}/getAllVehicle`,
    GetVehicleById: `${VehicleController}/getVehicleById`,
    AddVehicle: `${VehicleController}/addVehicle`,
    UpdateVehicle: `${VehicleController}/updateVehicleById`,
    DeleteVehicle: `${VehicleController}/deleteVehicleById`,
    VehicleCSVDownload: `${VehicleController}/vehicleCSVDownload`,
    ImportVehicleCsv: `${VehicleController}/importVehicleCsv`,
    ExportEmptyVehicleCsv: `${VehicleController}/exportEmptyVehicleCsv`,

    GetAllRole: `${RoleController}/getAllRole`,
    GetRoleList: `${RoleController}/getAllRole`,
    GetRoleById: `${RoleController}/getRoleById`,
    AddRole: `${RoleController}/addRole`,
    UpdateRole: `${RoleController}/updateRole`,
    DeleteRole: `${RoleController}/deleteRole`,
    CSVExportAllRole: `${RoleController}/csvExportAllRole`,

    GetAllAlarm: `${AlarmController}/getAllAlarms`,
    GetAlarmList: `${AlarmController}/getAlarmList`,
    GetAlarmById: `${AlarmController}/getAlarmById`,
    AddAlarm: `${AlarmController}/addAlarm`,
    UpdateAlarm: `${AlarmController}/updateAlarm`,
    DeleteAlarm: `${AlarmController}/deleteAlarm`,
    CSVExportAllAlarm: `${AlarmController}/alarmCsvDownload`,

    GetAllTransactions: `${TransactionController}/getAllTransactionsBasicList`,
    GetAllMissingTransactions: `${TransactionController}/getMissingTransactionsCsv`,
    GetAllMissingViewTransactions: `${TransactionController}/getMissingTransactionsList`,
    GetTransactionById: `${TransactionController}/getTicket`,
    AddTransaction: `${TransactionController}/addTicket`,
    UpdateTransaction: `${TransactionController}/updateTicket`,
    DeleteTransaction: `${TransactionController}/deleteTicketById`,
    UpdateCustomerIdsInTransaction: `${TransactionController}/updateCustomerIdsInTicket`,
    ExportTransactionReport: `${TransactionController}/exportReport`,
    TransactionCSVDownload: `${TransactionController}/ticketCSVDownload`,
    ImportTicketCsv: `${TransactionController}/importTicketCsv`,
    ExportEmptyTicketCsv: `${TransactionController}/exportEmptyTicketCsv`,

    GetReportList: `${ReportController}/getAllTransactionReport`,
    GenerateReportViewData: `${ReportController}/generateViewDataForTransactionReport`,
    GenerateTransactionReport: `${ReportController}/generateTransactionReport`,
    DownloadTransactionReportPdf: `${ReportController}/downloadTransactionReportPdf`,

    VerfiyEmail: `${ResetPasswordController}/verifyEmail`,
    VerfiyOtp: `${ResetPasswordController}/verifyOtp`,
    ChangePassword: `${ResetPasswordController}/changedPassword`,
    User: "", // only for reference


    // GetAllUser: `${UserController}/getAllClientUser`,
    GetClientSummary: `${SuperAdminController}/getClientSummaryInformation`,
    GetClientDetailInformation: `${SuperAdminController}/getClientDetailInformation`,

    GetAllMonitoringData: `${Monitor}/getAllMonitoringData`,

    EmailVerfiyOtpResend: `${ApiUrl}/otp/resendOtp`,
    EmailVerfiyOtp: `${ApiUrl}/otp/otpVerify`,

    GetRecentAlarms: `${AlarmController}/getRecentAlarms`,

    GetCustomerSummary: `${CustomerController}/getAllCustomerAdminsNames`,
    GetVehicleSummary: `${TransactionController}/getAllVehiclesName`,
    GetTruckSummary: `${AssetsController}/getAllAssetId`,
    GetDepartmentSummary: `${TransactionController}/getAllDepartmentNames`,
    GetAllDeptAndVehiclesName: `${VehicleController}/getAllDeptAndVehiclesName`,

    GetReportCount: `${ReportController}/getReportCount`,

    GetPdfSettingById: `${PDFController}/getPdfSettingById`,
    AddPdfSettings: `${PDFController}/addPdfSettings`,
    DeleteReportPdf: `${ReportController}/deleteTransactionReportPdf`,

    CurrencyList: `${CurrencyController}/getAllCurrency`,

    GetPaymentList: `${CustomerPaymentsController}/getAllPaymentslist`,
    AddPaymentData: `${CustomerPaymentsController}/addPayment`,
    DeletePayment: `${CustomerPaymentsController}/deletePaymentById`,
    GeneratePaymentReport: `${CustomerPaymentsController}/generatePaymentReport`,
    ViewPaymentReport: `${CustomerPaymentsController}/generateViewDataForPaymentReport`,

    SupplierCSVDownload: `${SupplierController}/downloadSupplierCSV`,
    GetSupplierList: `${SupplierController}/getAllSupplierPaginationList`,
    GetSupplierById: `${SupplierController}/getSupplierById`,
    AddSupplier: `${SupplierController}/addSupplier`,
    UpdateSupplier: `${SupplierController}/updateSupplierById`,
    DeleteSupplier: `${SupplierController}/deleteSupplierById`,

    GetDailyFuelUsageCustomer: `${DashboardController}/getDailyFuelUsageForCustomer`,
    GetFuelStatusForCustomer: `${DashboardController}/getFuelStatusFilterForCustomer`,
    GetRecentAlarmsForCustomer: `${AlarmController}/getRecentAlarmsForCustomer`,
    GetDashboardMetricsForCustomer: `${DashboardController}/getDashboardMetricsForCustomer`,
    GetPricingForCustomer: `${DashboardController}/getPricingByCustomerId`,
    GetCompartmentFuelForCustomer: `${DashboardController}/getCompartmentFuelForCustomer`,


}
export default UrlConstants;