/*    
<summary>
  This functional component "AddUpdateRole" defines the props like initial values and functions for the child form component.
  Developer:Aashish Singh, Created Date:04-March-2024
</summary>
<returns>Returns JSX</returns>
*/

import { observer } from "mobx-react";
import React, { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useStore } from "../../../contexts/StoreProvider";
import { ICSVImportForm } from "../../../models/ITransaction";
import { formatMessage } from "../../../translations/formatMessage";
import { Field, Form, Formik, useFormikContext } from "formik";
import { Row, Container } from "react-bootstrap";
import LoaderButton from "../../../shared-components/Button/LoaderButton";
import FormikFormInput from "../../../shared-components/FormikFormHandlers/FormikFormInput";
import Label from "../../../shared-components/label/label";
import { ModuleName } from "../../../constants/moduleName.constant";
import Footer from "../../../shared-components/Footer/Footer";
import SubHeader from "../../../shared-components/Header/SubHeader";
import SpinnerLoader from "../../../shared-components/Loader/SpinnerLoader";
import UserType from "../../../constants/userType.constant";
import { getAmountDecimal, getUserType } from "../../../helpers/localStorages.helper";
import FormikFormSelectField from "../../../shared-components/FormikFormHandlers/FormikFormSelectField";
import FormikFormDateTimePicker from "../../../shared-components/FormikFormHandlers/FormikFormDateTimePicker";
import FormikFormInputForRegexCheck from "../../../shared-components/FormikFormHandlers/formik-form-input-for-regex-check";
import { addTransactionValidateSchema } from "../../../shared-components/FormikFormHandlers/FormikValidationSchema";
import AddTransactionCSVForm from "../forms/add-transaction-csv-form";
import AddPopupHOC from "../../../shared-components/Popup/Hoc/AddPopupHoc";
import UrlConstants from "../../../constants/url.constant";
import moment from "moment";
import { floatRegex, getCurrentDateTime, integerRegex, productTypeList, unitTypeList } from "../../../constants/common-constant";
interface IProps {
  showAddPopup: boolean;
  addToggleHandler: () => void;
}

const AddTransaction = (props: IProps) => {
  const { transactionsStore, preferencesStore } = useStore();
  let resetPresForm = () => { }; // This function dtores the resetForm function
  const {
    getAddTransactionDetail,
    addTransactionService,
    resetAddUpdateTransactionState,
    addUpdateTransactionState,
    addTransactionCSVService,
    resetAddTransactionCSVState,
    addTransactionCSVState,
  } = transactionsStore;
  const { allAvailableClientOptions, GetAllClientsService, CSVFileDownloadService, setAmountDecimal } = preferencesStore;
  // const [initialValues, setInitialValues] = useState<any>(getAddTransactionDetail)
  const csvFormInitailValues: ICSVImportForm = {
    ClientId: -1,
    CSVFile: "",
  };

  /**
   * This functional component stores the reset form function in the resetPresentForm variable
   */
  const ResettingForm = () => {
    const { resetForm } = useFormikContext();
    resetPresForm = resetForm; // Store the value of resetForm in this variable
    return null;
  };

  useEffect(() => {
    if (getUserType() == UserType.SuperAdmin) GetAllClientsService();
  }, []);

  useEffect(() => {
    if (addUpdateTransactionState.success) {
      resetAddUpdateTransactionState();
      resetPresForm();
      // let currentDateTime = getCurrentDateTime();
      // setInitialValues({...getAddTransactionDetail, TsStart: currentDateTime, TsFinish: currentDateTime, TsGen: currentDateTime, TsSend: currentDateTime})
      toast.success(formatMessage("added_success"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addUpdateTransactionState.success]);

  useEffect(() => {
    if (addTransactionCSVState.success) {
      resetAddTransactionCSVState();
      props.addToggleHandler();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addTransactionCSVState.success]);

  const openAddCSVHandler = () => {
    props.addToggleHandler();
  };

  const csvSubmitHandler = (values: ICSVImportForm) => {
    addTransactionCSVService(values);
  };

  const downloadcsvHandler = () => {
    CSVFileDownloadService(UrlConstants.ExportEmptyTicketCsv, "AddTransaction");
  };

  const onCSVModalClosed = () => {
    props.addToggleHandler();
  };

  let decimalValue: number = getAmountDecimal();

  return (
    <React.Fragment>
      {props.showAddPopup && (
        <AddTransactionCSVForm
          initialValues={csvFormInitailValues}
          isLoading={addTransactionCSVState.inProgress}
          downloadcsvHandler={downloadcsvHandler}
          submitHandler={csvSubmitHandler}
          modalClosed={onCSVModalClosed}
        />
      )}
      <div className={`main-content user`}>
        <SubHeader
          moduleNameString={"add_transactions_title"}
          moduleName={ModuleName.AddTransaction}
          addHandler={openAddCSVHandler}
          // filterHandler={openFilterHandler}
          // isFilterActive={filter.isFilterActive}
          // exportCSVHandler={exportFilteredLogsCSV}
          // resetFilterHandler={resetFilterHandler}
          isAdd={true}
          addButtonTitle="add_csv"
          isFullView={false}
          isDownload={false}
          isRefresh={false}
          isFilter={false}
          isDateFilter={false}
          isImport={false} isMissing={false} />
      </div>

      {addUpdateTransactionState.inProgress && <SpinnerLoader />}

      <Container fluid className="p-0">
        <div className="add-transaction blank_height" id="moveContent">
          <Formik
            initialValues={getAddTransactionDetail}
            enableReinitialize
            validateOnBlur={false}
            validationSchema={addTransactionValidateSchema(decimalValue)}
            onSubmit={async (values: any) => {
              let tempValues = {
                ...values,
                EndGross: values.EndGross ? values.EndGross : "",
                FlowMax: values.FlowMax ? values.FlowMax : "",
                FlowAvg: values.FlowAvg ? values.FlowAvg : "",
                GrossDelivered: values.GrossDelivered ? values.GrossDelivered : "",
                PricePerL: values.PricePerL ? values.PricePerL : "",
                Vat: values.Vat ? values.Vat : "",
                AmountDue: values.AmountDue ? values.AmountDue : "",
                Lat: values.Lat ? values.Lat : "",
                Lon: values.Lon ? values.Lon : "",
                MeterId: values.MeterId ? values.MeterId : "",
                NetDelivered: values.NetDelivered ? values.NetDelivered : "",
              };
              let tempDateValues = {
                TsStart: moment(tempValues.TsStart.$d ? tempValues.TsStart.$d : tempValues.TsStart).utc().toISOString(),
                TsFinish: moment(tempValues.TsFinish.$d ? tempValues.TsFinish.$d : tempValues.TsFinish).utc().toISOString(),
                TsGen: tempValues.TsGen ? moment(tempValues.TsGen.$d ? tempValues.TsGen.$d : tempValues.TsGen).utc().toISOString() : "",
                TsSend: tempValues.TsSend ? moment(tempValues.TsSend.$d ? tempValues.TsSend.$d : tempValues.TsSend).utc().toISOString() : "",
              };
              tempValues.ClientId = tempValues.ClientId.toLowerCase();
              tempValues.Unit = tempValues.Unit === "liter_unit" ? "L" : "UKG";
              tempValues.InvUnit1 = tempValues.InvUnit1 === "liter_unit" ? "L" : "UKG";
              tempValues.InvUnit2 = tempValues.InvUnit2 === "liter_unit" ? "L" : "UKG";
              addTransactionService({ ...tempValues, ...tempDateValues });
            }}
          >
            {({ values }) => {
              return (
                <div className="pt-3 px-3">
                  <Form className="user-form customer-modal p-3" noValidate>
                    <Row>
                      <div className="col-lg-2 col-md-4 col-sm-4 col-xs-6">
                        <div className="mb-3 form-group">
                          <Label
                            className="form-label"
                            required={true}
                            label="transactions_label_meterid"
                          />
                          <Field
                            className="form-control"
                            aria-label="Default select example"
                            name="DeviceId"
                            type="text"
                            placeholder="transactions_label_meterid"
                            validcharacter={['@', '#', '$', '-', '_', '.']}
                            invalidcharacter={[" "]}
                            as={FormikFormInput}
                          />
                        </div>
                      </div>
                      <div className="col-lg-2 col-md-4 col-sm-4 col-xs-6">
                        <div className="mb-3 form-group">
                          <Label
                            className="form-label"
                            required={true}
                            label="transactions_label_clientid"
                          />
                          <Field
                            className="form-control"
                            aria-label="Default select example"
                            name="ClientId"
                            placeholder="transactions_label_clientid"
                            options={allAvailableClientOptions}
                            as={FormikFormSelectField}
                            isName={+true}
                            isaccount={+true}
                            isdecimal={+true}
                            decimalChangeHandler={setAmountDecimal}
                          />
                        </div>
                      </div>
                      <div className="col-lg-2 col-md-4 col-sm-4 col-xs-6">
                        <div className="mb-3 form-group">
                          <Label
                            className="form-label"
                            required={true}
                            label="transactions_label_truckid"
                          />
                          <Field
                            className="form-control"
                            aria-label="Default select example"
                            name="TruckId"
                            type="text"
                            placeholder="transactions_label_truckid"
                            validcharacter={['@', '#', '$', '-', '_', '.']}
                            invalidcharacter={[" "]}
                            as={FormikFormInput}
                          />
                        </div>
                      </div>
                      <div className="col-lg-2 col-md-4 col-sm-4 col-xs-6">
                        <div className="mb-3 form-group">
                          <Label
                            className="form-label"
                            required={true}
                            label="transactions_label_order_unit"
                          />
                          <Field
                            className="form-control"
                            name="Unit"
                            as={FormikFormSelectField}
                            options={unitTypeList}
                            isName={true}
                          />
                        </div>
                      </div>
                      <div className="col-lg-2 col-md-4 col-sm-4 col-xs-6">
                        <div className="mb-3 form-group">
                          <Label
                            className="form-label"
                            required={true}
                            label="transactions_label_ticketnumber"
                          />
                          <Field
                            className="form-control"
                            aria-label="Default select example"
                            name="TicketNumber"
                            type="text"
                            placeholder="transactions_label_ticketnumber"
                            validcharacter={['@', '#', '$', '-', '_', '.']}
                            invalidcharacter={[" "]}
                            as={FormikFormInput}
                          />
                        </div>
                      </div>
                      <div className="col-lg-2 col-md-4 col-sm-4 col-xs-6">
                        <div className="mb-3 form-group">
                          <Label
                            className="form-label"
                            required={true}
                            label="transactions_label_productid"
                          />
                          <Field
                            className="form-control"
                            name="ProductId"
                            type="text"
                            placeholder="transactions_label_productid"
                            validcharacter={['_', " "]}
                            // invalidcharacter={[" "]}
                            as={FormikFormInput}
                          />
                        </div>
                      </div>
                      <div className="col-lg-2 col-md-4 col-sm-4 col-xs-6">
                        <div className="mb-3 form-group datepicker-transaction">
                          <Label
                            className="form-label"
                            required={true}
                            label="transactions_label_starttime"
                          />
                          {/* <Field
                            className="form-control"
                            aria-label="Default select example"
                            name="TsStart"
                            // as={FormikFormDateTimePicker}
                            as={FormikFormDateTimePicker}
                          /> */}
                          <Field
                            name="TsStart"
                            as={FormikFormDateTimePicker}
                            // label="From"
                            ampm="false"
                            max={getCurrentDateTime()}
                          />
                        </div>
                      </div>
                      <div className="col-lg-2 col-md-4 col-sm-4 col-xs-6">
                        <div className="mb-3 form-group datepicker-transaction">
                          <Label
                            className="form-label"
                            required={true}
                            label="transactions_label_endtime"
                          />
                          <Field
                            name="TsFinish"
                            as={FormikFormDateTimePicker}
                            // label="From"
                            max={getCurrentDateTime()}
                            ampm="false"
                          />
                        </div>
                      </div>
                      <div className="col-lg-2 col-md-4 col-sm-4 col-xs-6">
                        <div className="mb-3 form-group">
                          <Label
                            className="form-label"
                            // required={true}
                            label="meter_id"
                          />
                          <Field
                            className="form-control"
                            aria-label="Default select example"
                            name="MeterId"
                            placeholder="meter_id"
                            regex={integerRegex}
                            as={FormikFormInputForRegexCheck}
                          />
                        </div>
                      </div>
                      <div className="col-lg-2 col-md-4 col-sm-4 col-xs-6">
                        <div className="mb-3 form-group">
                          <Label
                            className="form-label"
                            // required={true}
                            label="net_delivered"
                          />
                          <Field
                            className="form-control"
                            aria-label="Default select example"
                            name="NetDelivered"
                            placeholder="net_delivered"
                            regex={floatRegex}
                            as={FormikFormInputForRegexCheck}
                          />
                        </div>
                      </div>
                      <div className="col-lg-2 col-md-4 col-sm-4 col-xs-6">
                        <div className="mb-3 form-group">
                          <Label
                            className="form-label"
                            // required={true}
                            label="transactions_label_destlat"
                          />
                          <Field
                            className="form-control"
                            aria-label="Default select example"
                            name="Lat"
                            placeholder="transactions_label_destlat"
                            regex={floatRegex}
                            as={FormikFormInputForRegexCheck}
                          />
                        </div>
                      </div>
                      <div className="col-lg-2 col-md-4 col-sm-4 col-xs-6">
                        <div className="mb-3 form-group">
                          <Label
                            className="form-label"
                            // required={true}
                            label="transactions_label_destlon"
                          />
                          <Field
                            className="form-control"
                            aria-label="Default select example"
                            name="Lon"
                            placeholder="transactions_label_destlon"
                            regex={floatRegex}
                            as={FormikFormInputForRegexCheck}
                          />
                        </div>
                      </div>
                      <div className="col-lg-2 col-md-4 col-sm-4 col-xs-6">
                        <div className="mb-3 form-group">
                          <Label
                            className="form-label"
                            required={true}
                            label="transactions_label_endgross"
                          />
                          <Field
                            className="form-control"
                            aria-label="Default select example"
                            name="EndGross"
                            placeholder="transactions_label_endgross"
                            regex={floatRegex}
                            as={FormikFormInputForRegexCheck}
                          />
                        </div>
                      </div>
                      <div className="col-lg-2 col-md-4 col-sm-4 col-xs-6">
                        <div className="mb-3 form-group">
                          <Label
                            className="form-label"
                            // required={true}
                            label="transactions_label_flowaverage"
                          />
                          <Field
                            className="form-control"
                            aria-label="Default select example"
                            name="FlowAvg"
                            placeholder="transactions_label_flowaverage"
                            regex={floatRegex}
                            as={FormikFormInputForRegexCheck}
                          />
                        </div>
                      </div>
                      <div className="col-lg-2 col-md-4 col-sm-4 col-xs-6">
                        <div className="mb-3 form-group">
                          <Label
                            className="form-label"
                            // required={true}
                            label="transactions_label_flowmax"
                          />
                          <Field
                            className="form-control"
                            aria-label="Default select example"
                            name="FlowMax"
                            placeholder="transactions_label_flowmax"
                            regex={floatRegex}
                            as={FormikFormInputForRegexCheck}
                          />
                        </div>
                      </div>
                      <div className="col-lg-2 col-md-4 col-sm-4 col-xs-6">
                        <div className="mb-3 form-group">
                          <Label
                            className="form-label"
                            required={true}
                            label="transactions_label_grossdelivered"
                          />
                          <Field
                            className="form-control"
                            aria-label="Default select example"
                            name="GrossDelivered"
                            placeholder="transactions_label_grossdelivered"
                            regex={floatRegex}
                            as={FormikFormInputForRegexCheck}
                          />
                        </div>
                      </div>
                      {/* <div className="col-lg-2 col-md-4 col-sm-4 col-xs-6">
                      <div className="mb-3 form-group">
                          <Label
                            className="form-label"
                            // required={true}
                            label="transactions_label_perunitprice"
                            />
                          <Field
                            className="form-control"
                            aria-label="Default select example"
                            name="PricePerL"
                            placeholder="transactions_label_perunitprice"
                            regex={floatRegex}
                            as={FormikFormInputForRegexCheck}
                          />
                        </div>
                      </div> */}
                      {/* <div className="col-lg-2 col-md-4 col-sm-4 col-xs-6">
                      <div className="mb-3 form-group">
                          <Label
                            className="form-label"
                            // required={true}
                            label="transactions_label_vat_percent"
                            />
                          <Field
                            className="form-control"
                            aria-label="Default select example"
                            name="Vat"
                            placeholder="transactions_label_vat"
                            regex={floatRegex}
                            as={FormikFormInputForRegexCheck}
                          />
                        </div>
                      </div> */}
                      {/* <div className="col-lg-2 col-md-4 col-sm-4 col-xs-6">
                      <div className="mb-3 form-group">
                          <Label
                            className="form-label"
                            // required={true}
                            label="transactions_label_amountdue"
                            />
                          <Field
                            className="form-control"
                            aria-label="Default select example"
                            name="AmountDue"
                            placeholder="transactions_label_amountdue"
                            regex={floatRegex}
                            as={FormikFormInputForRegexCheck}
                          />
                        </div>
                      </div> */}
                      <div className="col-lg-2 col-md-4 col-sm-4 col-xs-6">
                        <div className="mb-3 form-group datepicker-transaction">
                          <Label
                            className="form-label"
                            // required={true}
                            label="transactions_label_gentimestamp"
                          />
                          {/* <Field
                            className="form-control"
                            aria-label="Default select example"
                            name="TsGen"
                            as={FormikFormDateTimePicker}
                          /> */}
                          <Field
                            name="TsGen"
                            as={FormikFormDateTimePicker}
                            // label="From"
                            ampm="false"
                            max={getCurrentDateTime()}
                          />
                        </div>
                      </div>
                      <div className="col-lg-2 col-md-4 col-sm-4 col-xs-6">
                        <div className="mb-3 form-group datepicker-transaction">
                          <Label
                            className="form-label"
                            // required={true}
                            label="transactions_label_sendtimestamp"
                          />
                          {/* <Field
                            className="form-control"
                            aria-label="Default select example"
                            name="TsSend"
                            as={FormikFormDateTimePicker}
                          /> */}
                          <Field
                            name="TsSend"
                            as={FormikFormDateTimePicker}
                            // label="From"
                            ampm="false"
                            max={getCurrentDateTime()}
                          />
                        </div>
                      </div>
                      <div className="col-lg-2 col-md-4 col-sm-4 col-xs-6">
                        <div className="mb-3 position-relative">
                          <Label
                            className="form-label"
                            // required={true}
                            label="transactions_label_vehicleRfid"
                          />
                          <Field
                            className="form-control"
                            aria-label="Default select example"
                            name="VehicleRfid"
                            type="text"
                            placeholder="transactions_label_vehicleRfid"
                            validcharacter={['@', '#', '$', '-', '_', '.']}
                            invalidcharacter={[" "]}
                            as={FormikFormInput}
                          />
                        </div>
                      </div>
                      <div className="col-lg-2 col-md-4 col-sm-4 col-xs-6">
                        <div className="mb-3 position-relative">
                          <Label
                            className="form-label"
                            // required={true}
                            label="transactions_label_driverRfid"
                          />
                          <Field
                            className="form-control"
                            aria-label="Default select example"
                            name="DriverRfid"
                            type="text"
                            placeholder="transactions_label_driverRfid"
                            validcharacter={['@', '#', '$', '-', '_', '.']}
                            invalidcharacter={[" "]}
                            as={FormikFormInput}
                          />
                        </div>
                      </div>
                      <div className="col-lg-2 col-md-4 col-sm-4 col-xs-6">
                        <div className="mb-3 position-relative">
                          <Label
                            className="form-label"
                            // required={true}
                            label="transactions_label_operatorRfid"
                          />
                          <Field
                            className="form-control"
                            aria-label="Default select example"
                            name="OperatorRfid"
                            type="text"
                            placeholder="transactions_label_operatorRfid"
                            validcharacter={['@', '#', '$', '-', '_', '.']}
                            invalidcharacter={[" "]}
                            as={FormikFormInput}
                          />
                        </div>
                      </div>

                      <div className="col-lg-2 col-md-4 col-sm-4 col-xs-6">
                        <div className="mb-3 form-group">
                          <Label
                            className="form-label"
                            required={false}
                            label="transactions_label_customer_id_1"
                          />
                          <Field
                            className="form-control"
                            aria-label="Default select example"
                            name="CustomerId1"
                            type="text"
                            placeholder="transactions_label_customer_id_1"
                            validcharacter={['@', '#', '$', '-', '_', '.']}
                            as={FormikFormInput}
                          />
                        </div>
                      </div>
                      <div className="col-lg-2 col-md-4 col-sm-4 col-xs-6">
                        <div className="mb-3 form-group">
                          <Label
                            className="form-label"
                            // required={true}
                            label="transactions_label_customer_id_2"
                          />
                          <Field
                            className="form-control"
                            aria-label="Default select example"
                            name="CustomerId2"
                            type="text"
                            placeholder="transactions_label_customer_id_2"
                            validcharacter={['@', '#', '$', '-', '_', '.']}
                            as={FormikFormInput}
                          />
                        </div>
                      </div>
                      <div className="col-lg-2 col-md-4 col-sm-4 col-xs-6">
                        <div className="mb-3 form-group">
                          <Label
                            className="form-label"
                            // required={true}
                            label="transactions_label_customer_id_3"
                          />
                          <Field
                            className="form-control"
                            aria-label="Default select example"
                            name="CustomerId3"
                            type="text"
                            placeholder="transactions_label_customer_id_3"
                            validcharacter={['@', '#', '$', '-', '_', '.']}
                            as={FormikFormInput}
                          />
                        </div>
                      </div>
                      <div className="col-lg-2 col-md-4 col-sm-4 col-xs-6">
                        <div className="mb-3 form-group">
                          <Label
                            className="form-label"
                            // required={true}
                            label="transactions_label_customer_id_4"
                          />
                          <Field
                            className="form-control"
                            aria-label="Default select example"
                            name="CustomerId4"
                            type="text"
                            placeholder="transactions_label_customer_id_4"
                            validcharacter={['@', '#', '$', '-', '_', '.']}
                            as={FormikFormInput}
                          />
                        </div>
                      </div>
                      <div className="col-lg-2 col-md-4 col-sm-4 col-xs-6">
                        <div className="mb-3 form-group">
                          <Label
                            className="form-label"
                            // required={true}
                            label="transactions_label_customer_id_5"
                          />
                          <Field
                            className="form-control"
                            aria-label="Default select example"
                            name="CustomerId5"
                            type="text"
                            placeholder="transactions_label_customer_id_5"
                            validcharacter={['@', '#', '$', '-', '_', '.']}
                            as={FormikFormInput}
                          />
                        </div>
                      </div>
                      <div className="col-lg-2 col-md-4 col-sm-4 col-xs-6">
                        <div className="mb-3 form-group">
                          <Label
                            className="form-label"
                            // required={true}
                            label="transactions_label_customer_id_6"
                          />
                          <Field
                            className="form-control"
                            aria-label="Default select example"
                            name="CustomerId6"
                            type="text"
                            placeholder="transactions_label_customer_id_6"
                            validcharacter={['@', '#', '$', '-', '_', '.']}
                            as={FormikFormInput}
                          />
                        </div>
                      </div>
                    </Row>
                    <Row>
                      <div className="col-lg-12">
                        <p className='tanks-heading fs-5 mt-3'>Tank Details</p>
                      </div>
                      <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6">
                        <div className="mb-3 position-relative">
                          <Label
                            className="form-label"
                            // required={true}
                            label="transactions_label_inv_name1"
                          />
                          <Field
                            className="form-control"
                            aria-label="Default select example"
                            name="InvName1"
                            placeholder="transactions_label_inv_name1"
                            validcharacter={['@', '#', '$', '-', '_', '.']}
                            as={FormikFormInput}
                          />
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6">
                        <div className="mb-3 position-relative">
                          <Label
                            className="form-label"
                            // required={true}
                            label="transactions_label_inv_unit1"
                          />
                          <Field
                            className="form-control"
                            name="InvUnit1"
                            as={FormikFormSelectField}
                            options={unitTypeList}
                            isName={true}
                          />
                          {/* <Field
                            className="form-control"
                            aria-label="Default select example"
                            name="InvUnit1"
                            type="text"
                            placeholder="transactions_label_inv_unit1"
                            validcharacter={['@', '#', '$', '-', '_', '.']}
                            invalidcharacter={[" "]}
                            as={FormikFormInput}
                          /> */}
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6">
                        <div className="mb-3 position-relative">
                          <Label
                            className="form-label"
                            // required={true}
                            label="transactions_label_inv_value1"
                          />
                          <Field
                            className="form-control"
                            aria-label="Default select example"
                            name="InvValue1"
                            placeholder="transactions_label_inv_value1"
                            regex={floatRegex}
                            as={FormikFormInputForRegexCheck}
                          />
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6">
                        <div className="mb-3 position-relative">
                          <Label
                            className="form-label"
                            // required={true}
                            label="transactions_label_inv_name2"
                          />
                          <Field
                            className="form-control"
                            aria-label="Default select example"
                            name="InvName2"
                            type="text"
                            placeholder="transactions_label_inv_name2"
                            validcharacter={['@', '#', '$', '-', '_', '.']}
                            as={FormikFormInput}
                          />
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6">
                        <div className="mb-3 position-relative">
                          <Label
                            className="form-label"
                            // required={true}
                            label="transactions_label_inv_unit2"
                          />
                          <Field
                            className="form-control"
                            name="InvUnit2"
                            as={FormikFormSelectField}
                            options={unitTypeList}
                            isName={true}
                          />
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6">
                        <div className="mb-3 position-relative">
                          <Label
                            className="form-label"
                            // required={true}
                            label="transactions_label_inv_value2"
                          />
                          <Field
                            className="form-control"
                            aria-label="Default select example"
                            name="InvValue2"
                            type="text"
                            placeholder="transactions_label_inv_value2"
                            regex={floatRegex}
                            as={FormikFormInputForRegexCheck}
                          />
                        </div>
                      </div>
                    </Row>
                    <div className="modal-footer  border-0">
                      <div className="m-auto">
                        <LoaderButton className="text-uppercase"
                          type="submit"
                          isLoading={addUpdateTransactionState.inProgress}
                          disabled={addUpdateTransactionState.inProgress}
                          text="button_save"
                        />
                      </div>
                    </div>
                  </Form>
                  <ResettingForm />
                </div>
              );
            }}
          </Formik>
        </div>
      </Container>
      <Footer />
    </React.Fragment>
  );
};

export default AddPopupHOC(observer(AddTransaction));
