/*    
<summary>
   This class component is all about Managing driver data functionality.
   Developer:Aashish Singh, Created Date:04-March-2024
</summary>
<param>No Parameter Passed</param>
<returns>Returns class instance</returns>
*/

import { makeObservable, observable, action, computed } from "mobx";
import IApiResponse, { IApiSuccessResponse } from "../../models/response/IApiResponse";
import { getDriverListState } from "../initialState/get-driver-list-state";
import { addEditDriverState as initialState } from "../initialState/add-edit-driver-state";
import UrlConstants from "../../constants/url.constant";
import * as services from '../service/base-service';
import { IObservableInitialState } from "../../models/ICommon";
import { IAddEditDriver, IDriver, IDriverList, IGetDriverList } from "../../models/response/IDriverResponse";
import moment from "moment";
import { DATE_FORMAT_BACKEND, DATE_TIME_FORMAT_FILENAME, DATE_TIME_FORMAT_TO_BACKEND, emptyValue } from "../../constants/common-constant";
import { AllocationPeriodEnum } from "../../constants/enums/allocation-period-enum";
import { getClientId, getClientLimitSetting, getClientSettings, getEnableLimitSetting } from "../../helpers/localStorages.helper";
import { IDriverState } from "../../models/state/IDriverState";
import { IDriverFilter, IDriverInitialFilterState } from "../../models/IFilter";
import { driverInitialFilterState } from "../initialState/initial-filter-states";
import { toast } from "react-toastify";
import { formatMessage } from "../../translations/formatMessage";
import { IVehicleCSVImportForm } from "../../models/response/IVehicleResponse";
import { ErrorMessage } from "../../constants/error.constant";

export class DriverStore implements IDriverState {
    inProgress = false;
    error = '';
    driverList: IGetDriverList = getDriverListState;

    initialStateValue: IObservableInitialState = {
        success: false,
        error: '',
        inProgress: false
    }

    addUpdateDriverState: IObservableInitialState = { ...this.initialStateValue };
    DeleteDriverState: IObservableInitialState = { ...this.initialStateValue };

    driverDetail: IDriver | undefined = undefined;
    driverDetailState: IObservableInitialState = { ...this.initialStateValue };

    filter: IDriverFilter = {
        ...driverInitialFilterState
    }

    addDriverCSVState = { ...this.initialStateValue }

    constructor() {
        makeObservable(this, {
            inProgress: observable,
            error: observable,
            driverList: observable,
            addUpdateDriverState: observable,
            DeleteDriverState: observable,
            driverDetail: observable,
            driverDetailState: observable,
            filter: observable,
            addDriverCSVState: observable,
            GetDriverListService: action,
            GetDriverByIdService: action,
            AddDriverService: action,
            UpdateDriverService: action,
            DeleteDriverService: action,
            addDriverCSVService: action,
            reset: action,
            resetStore: action,
            resetGetDriverDetail: action,
            resetAddUpdateDriverState: action,
            resetDeleteDriverState: action,
            setFilterDetail: action,
            resetAddDriverCSVState: action,
            getDriverDetail: computed,
            getDriverList: computed,
        });
    }

    GetDriverListService = (currentPage: number, pagerSize: number, orderBy: string, column: string, filter: IDriverInitialFilterState) => {
        this.inProgress = true;
        const clientIdFromLocalStorage = getClientId() != null ? getClientId() : -1;
        const url = `${UrlConstants.GetDriverList}?clientId=${clientIdFromLocalStorage}` + "&pageNo=" + currentPage + "&pageSize=" + pagerSize + "&orderBy=" + orderBy + "&column=" + column;
        const requestBody = { ...filter, DLExpiryInDays: filter.DLExpiryInDays == -1 ? null : filter.DLExpiryInDays };
        return services.post(url, { ...requestBody })
            // return services.get(url)
            .then((response: IApiResponse<IApiSuccessResponse<IGetDriverList>>) => {
                this.driverList = response.data.Data;
            })
            .catch((err: string) => {
                this.error = err;
            })
            .finally(action(() => { this.inProgress = false; }));
    }

    get getDriverList(): IDriverList[] {
        if (this.driverList?.Drivers && this.driverList.Drivers?.length > 0) {
            return this.driverList.Drivers.map((driver: IDriver) => {
                let IsDelete: boolean = true;
                let IsEdit: boolean = true;
                return {
                    ...driver,
                    RFID: driver.RFID ? driver.RFID : emptyValue,
                    Id: driver.DriverId,
                    DLExpiryDate: moment(driver.DLExpiryDate).format(DATE_FORMAT_BACKEND),
                    AllocationPeriod: AllocationPeriodEnum[driver.AllocationPeriod],
                    CustomerName: driver.CustomerName ? driver.CustomerName : emptyValue,
                    BelongToCustomer: driver?.BelongToCustomer ? "true" : "false",
                    IsDeletable: IsDelete,
                    IsEditable: IsEdit,
                }
            })
        }
        return [];
    }

    GetDriverByIdService = (id: number) => {
        this.driverDetailState.inProgress = true;
        const clientIdFromLocalStorage = getClientId() != null ? getClientId() : -1;
        const url = `${UrlConstants.GetDriverById}?id=${id}&clientId=${clientIdFromLocalStorage}`;
        return services.get(url)
            .then((response: IApiResponse<IApiSuccessResponse<IDriver>>) => {
                this.driverDetail = response.data.Data;
            })
            .catch((err: string) => {
                toast.error(formatMessage(err));
                // this.driverDetailState.error = err;
            }).finally(action(() => { this.driverDetailState.inProgress = false; }));
    }

    get getDriverDetail(): IAddEditDriver {
        let clientSetting = getEnableLimitSetting();
        let defaultValues = {
            RemainingVolume: "0",
            AllocationVolume: "0",
            AllocationPerFill: "0",
            AllocationPeriod: 0,
        }
        let tempValues = { ...initialState, DLExpiryDate: moment(new Date()).format(DATE_FORMAT_BACKEND) };
        if (this.driverDetail) {
            tempValues = {
                ...this.driverDetail,
                DLExpiryDate: this.driverDetail?.DLExpiryDate ? moment(this.driverDetail?.DLExpiryDate).format(DATE_FORMAT_BACKEND) : moment(new Date()).format(DATE_FORMAT_BACKEND),
                RemainingVolume: this.driverDetail?.RemainingVolume != null ? this.driverDetail.RemainingVolume.toString() : "",
                AllocationVolume: this.driverDetail?.AllocationVolume != null ? this.driverDetail.AllocationVolume.toString() : "",
                TruckAllocation1: this.driverDetail?.TruckAllocation1 != null ? this.driverDetail.TruckAllocation1 : "",
                AllocationPerFill: this.driverDetail?.AllocationPerFill != null ? this.driverDetail.AllocationPerFill.toString() : "",
                EmployeeId: this.driverDetail?.EmployeeId ? this.driverDetail?.EmployeeId : "",
                UpdatedAt: this.driverDetail?.UpdatedAt ? moment(this.driverDetail?.UpdatedAt).format(DATE_TIME_FORMAT_TO_BACKEND) : "",
            };
        }
        if (!clientSetting)
            tempValues = { ...tempValues, ...defaultValues };
        return tempValues;
    }

    resetGetDriverDetail = () => {
        this.driverDetail = undefined;
        this.driverDetailState = { ...this.initialStateValue };
    }

    AddDriverService = (id: number, data: IAddEditDriver) => {
        this.addUpdateDriverState.inProgress = true;
        const clientIdFromLocalStorage = getClientId() != null ? getClientId() : -1;
        let requestPayload: any = {
            ...data, ClientId: clientIdFromLocalStorage,
            DLExpiryDate: moment(data.DLExpiryDate).format(DATE_FORMAT_BACKEND) + "T23:59:59.000Z",
            RemainingVolume: data.AllocationVolume
        }
        let url = `${UrlConstants.AddDriver}?clientId=${clientIdFromLocalStorage}`;
        const clientId = getClientId();
        return services.post(url, requestPayload)
            .then(() => {
                this.addUpdateDriverState.success = true;
            })
            .catch((err: string) => {
                this.addUpdateDriverState.error = err;
            })
            .finally(action(() => { this.addUpdateDriverState.inProgress = false; }));
    }

    UpdateDriverService = (id: number, data: IAddEditDriver) => {
        this.addUpdateDriverState.inProgress = true;
        const clientIdFromLocalStorage = getClientId() != null ? getClientId() : -1;
        let url = `${UrlConstants.UpdateDriver}?id=${id}&clientId=${clientIdFromLocalStorage}`;
        delete data.CustomerName;
        let requestPayload: any = {
            ...data, ClientId: clientIdFromLocalStorage,
            DLExpiryDate: moment(data.DLExpiryDate).format(DATE_FORMAT_BACKEND) + "T23:59:59.000Z"
        }
        return services.put(url, { ...requestPayload })
            .then(() => {
                this.addUpdateDriverState.success = true;
            })
            .catch((err: string) => {
                this.addUpdateDriverState.error = err;
            })
            .finally(action(() => { this.addUpdateDriverState.inProgress = false; }));
    }

    resetAddUpdateDriverState = () => {
        this.addUpdateDriverState = { ...this.initialStateValue }
    }

    DeleteDriverService = (id: number) => {
        this.DeleteDriverState.inProgress = true;
        const clientIdFromLocalStorage = getClientId() != null ? getClientId() : -1;
        const url = `${UrlConstants.DeleteDriver}?id=${id}&clientId=${clientIdFromLocalStorage}`;
        return services.deleteapi(url)
            .then(() => {
                this.DeleteDriverState.success = true;
            })
            .catch((err: string) => {
                this.DeleteDriverState.error = err;
            })
            .finally(action(() => { this.DeleteDriverState.inProgress = false; }));
    }

    addDriverCSVService = (data: IVehicleCSVImportForm) => {
        const clientIdFromLocalStorage = getClientId() != null ? getClientId() : -1;
        this.addDriverCSVState.inProgress = true;
        const formData = new FormData();
        if (data.CSVFile)
            formData.append('file', data.CSVFile); // Append the file to FormData
        return services.postMultipart(UrlConstants.ImportDriverCsv + "?clientId=" + clientIdFromLocalStorage, formData)
            .then((response: IApiResponse<IApiSuccessResponse<boolean>>) => {
                this.addDriverCSVState.success = true;
                toast.success(formatMessage("DriverCsvFileUploaded"));
                this.downloadLog(response.data);
            })
            .catch((err: any) => {
                if (err.message && err.data) {
                    switch (err.message) {
                        case ErrorMessage.AllRecordsDuplicate:
                            toast.error(formatMessage(err.message ?? err));
                            this.downloadLog({ Data: err.data });
                            break
                        case ErrorMessage.SomeRecordsDuplicate:
                            toast.info(formatMessage(err.message ?? err));
                            this.downloadLog({ Data: err.data });
                            this.addDriverCSVState.success = true;
                            break
                        case ErrorMessage.ErrorLog:
                            toast.error(formatMessage(err.message ?? err));
                            this.downloadErrorLog(err.data);
                            break
                        default:
                            break
                    }
                }
                else {
                    toast.error(formatMessage(err));
                }
            })
            .finally(action(() => { this.addDriverCSVState.inProgress = false; }));
    }

    downloadErrorLog = (data: any) => {
        const currentDate = new Date();
        const splittedData = data.split("Row");
        const logContent = `${splittedData.map((item: any, index: number) => `${index > 0 ? `Row ${item}\n` : `${item}\n`}`).join("")}`;
        const blob = new Blob([logContent], { type: "text/plain" });
        const a = document.createElement("a");
        a.href = URL.createObjectURL(blob);
        a.download = moment(currentDate).format(DATE_TIME_FORMAT_FILENAME) + "driver log.txt";
        a.click();
        URL.revokeObjectURL(a.href);
    };

    downloadLog = (data: any) => {
        const currentDate = new Date();
        const logContent = this.generateLogContent(data.Data);
        const blob = new Blob([logContent], { type: "text/plain" });
        const a = document.createElement("a");
        a.href = URL.createObjectURL(blob);
        a.download = moment(currentDate).format(DATE_TIME_FORMAT_FILENAME) + "driver log.txt";
        a.click();
        URL.revokeObjectURL(a.href);
    };

    generateLogContent = (data: any) => {
        const currentDate = new Date();

        const generateErrorList = (values: string[] | number, itemsPerLine: number = 5) => {
            if (!Array.isArray(values) || values.length === 0) {
                return 'None';
            }

            const formattedList = values.map((item, index) => {
                const separator = (index + 1) % itemsPerLine === 0 ? '\n' : ', ';
                return `${item}${separator}`;
            });

            return formattedList.join('').trim();
        };

        const logContent = `
    Log generated on: ${moment(currentDate).format(DATE_TIME_FORMAT_TO_BACKEND)}
    Total Drivers Count (To be added): ${data.TotalDriversCount}
    Drivers Added: ${data.TotalDriversAdded}
    Drivers Failed: ${data.TotalFailedRecord}
    
    **Errors**
    **Records with Duplicate Driver Name: (Count: ${data.TotalDuplicateDriverName}) ** :
     ${data?.DuplicateRegNo?.length > 0 ? generateErrorList(data.DuplicateDriverName) : 0}
     **Records with Duplicate RFID: (Count: ${data.TotalDuplicateRFID}) ** :
     ${data?.DuplicateRFIDs?.length > 0 ? generateErrorList(data.DuplicateRFIDs) : 0}
    `;


        return logContent;
    };

    resetAddDriverCSVState = () => {
        this.addDriverCSVState = { ...this.initialStateValue }
    }

    resetDeleteDriverState = () => {
        this.DeleteDriverState = { ...this.initialStateValue }
    }

    reset = () => {
        this.error = '';
        this.inProgress = false;
    }

    resetError = () => {
        this.error = '';
    }

    /*
    This function is used to set the filter details to the Filter observable.  
    */
    setFilterDetail = (filter: IDriverFilter) => {
        this.filter = { ...filter };
    }

    /**
     * This function is used to reset all store observables to their initial values.
     * @returns
     */
    resetStore = () => {
        this.error = '';
        this.inProgress = false;
        this.driverList = getDriverListState;

        this.driverDetail = undefined;
        this.driverDetailState = { ...this.initialStateValue }

        this.addUpdateDriverState = { ...this.initialStateValue }

        this.DeleteDriverState = { ...this.initialStateValue }
    }
}

export default new DriverStore();