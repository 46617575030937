import { observer } from "mobx-react";
import React, { useCallback, useEffect, useState } from "react";
import { Col, Container, Modal, Row } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";
import NoData from "../../assets/Images/no_record.png";
import { ColumnType } from "../../constants/enums/gridColumnTypeEnum";
import { useStore } from "../../contexts/StoreProvider";
import { getAmountDecimal } from "../../helpers/localStorages.helper";
import useWindowDimensions, {
    getTableMaxHeight,
} from "../../helpers/windowResize.helper";
import IPageInfo from "../../models/IPageInfo";
import { ISorting } from "../../models/ISorting";
import DraggableModal from "../../shared-components/Draggable/DraggableModal";
import Grid, { getColumns } from "../../shared-components/Grid/Grid";
import SpinnerLoader from "../../shared-components/Loader/SpinnerLoader";
import "./missing-transaction-preview.scss";
import Pagination from "../../shared-components/Grid/Pagination";
import { PageLimitOptions } from "../../constants/pageLimit.constant";
import { emptyValue } from "../../constants/common-constant";

interface IProps {
    data: any;
    onModalClosed: () => void;
}

const MissingTransactionPreview: React.FC<IProps> = (props: IProps) => {
    const navigate = useNavigate();
    const getToken: any = localStorage.getItem("secure_fuel_token");
    const { transactionsStore, preferencesStore } = useStore();
    const { GetAllMissingTransactionViewService, getMissingTransactionViewData, missingTransactionViewState } = transactionsStore;
    const { data, onModalClosed } = props;
    const { userPageLimitSetting, setUserPageLimitSetting } = preferencesStore;
    const [disablePagination, setDisablePagination] = useState<boolean>(false);
    const allMissingTicketssData: any = getMissingTransactionViewData;
    const [sortingForTransactions, setSortingForTransactions] =
        useState<ISorting>({
            column: "ticket_number",
            orderBy: "desc",
        });
    let decimalValue = getAmountDecimal();
    const handleSortingChange = (accessor: string) => {
        const sortOrder = sortingForTransactions.orderBy === "asc" ? "desc" : "asc";
        setSortingForTransactions({ column: accessor, orderBy: sortOrder });
    };

    const { height, width } = useWindowDimensions();

    const [pageLimit, setPageLimit] = useState<number>(userPageLimitSetting.trasnactionLimitConfig);


    const [sorting, setSorting] = useState<ISorting>({
        column: "userName",
        orderBy: "asc",
    });

    const [pageInfo, setPageInfo] = useState<IPageInfo>({
        currentPage: 1,
        totalPages: 1,
    });

    const onPageChanged = useCallback((pageNumber: number) => {
        setPageInfo((pageInfo) => ({ ...pageInfo, currentPage: pageNumber }));
    }, []);

    const onPageLimitChanged = (
        evt: React.ChangeEvent<HTMLSelectElement>
    ) => {
        let value = parseInt(evt.target.value);
        setPageLimit(value);
        setPageInfo({ ...pageInfo, currentPage: 1 });
    };
    // const callGetAllTransactionsService = useCallback(() => {
    //   setDisablePagination(true);
    //   GetAllReportViewDataService(data);
    // }, [
    //   GetAllReportViewDataService,
    //   data
    // ]);

    useEffect(() => {
        setPageInfo((pageInfo) => ({
            ...pageInfo,
            totalPages: getMissingTransactionViewData.TotalPages,
        }));
        setDisablePagination(false);
    }, [getMissingTransactionViewData.TotalPages]);

    const callGetAllTransactionsService = useCallback(() => {
        // setDisablePagination(true);
        GetAllMissingTransactionViewService(
            "missing_tickets",
            data,
            pageInfo.currentPage,
            pageLimit,
        );
    }, [GetAllMissingTransactionViewService, data, pageInfo.currentPage, pageLimit]);

    useEffect(() => {
        if (data !== null) callGetAllTransactionsService();
    }, [callGetAllTransactionsService, data]);

    // useEffect(() => {
    //     setPageInfo((pageInfo) => ({
    //         ...pageInfo,
    //         totalPages: paymentReportViewData?.TotalPages,
    //     }));
    //     setDisablePagination(false);
    // }, [paymentReportViewData.TotalPages]);

    // useEffect(() => {
    //     setPageInfo((pageInfo) => ({
    //         ...pageInfo,
    //         totalPages: reportViewData?.TotalPages,
    //     }));
    //     setDisablePagination(false);
    // }, [reportViewData, reportViewData.TotalPages]);

    const resetFilterHandler = () => {
        setPageInfo({ ...pageInfo, currentPage: 1 });
        // setFilterDetail({ ...reportViewDataFilterState });
    };
    const columns = getColumns<any, number>([
        {
            columnType: ColumnType.TextHeader,
            accessorKey: "TicketNumber",
            displayName: `Missing Tickets`,
        },
        // {
        //     columnType: ColumnType.TextHeader,
        //     accessorKey: "PaymentDate",
        //     displayName: `Start Time: ${getMissingTransactionViewData.StartTime}`,
        // },
        // {
        //     columnType: ColumnType.TextHeader,
        //     accessorKey: "PaymentDate1",
        //     displayName: `End Time : ${getMissingTransactionViewData.EndTime}`,
        // }
    ]);

    console.log(allMissingTicketssData)

    return (
        <>
            <Modal
                className="report-preview-modal add-user-modal"
                show={true}
                centered
                size="xl"
                dialogAs={DraggableModal}
            >
                <Modal.Header>
                    <Modal.Title className="fs-5">
                        <FormattedMessage id="Missing Tickets View" />
                    </Modal.Title>
                    <div onClick={onModalClosed} className="icons8-close">
                        <span className="material-symbols-outlined fs-5 text-dark">
                            close
                        </span>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <React.Fragment>
                        {missingTransactionViewState.inProgress && <SpinnerLoader />}
                        <Container fluid className="p-0">
                            {allMissingTicketssData.MissingTickets.length > 0 ? (
                                <div className="roleWrapper background-image ">
                                    <div className="rounded shadow">

                                        <div className="Fuel-table">
                                            <div className="table-responsive"
                                                style={getTableMaxHeight(height, width)}
                                                data-testid="table"
                                            >
                                                <Grid
                                                    data={
                                                        allMissingTicketssData.MissingTickets
                                                    }
                                                    columns={
                                                        columns
                                                    }
                                                    isInProgress={missingTransactionViewState.inProgress}
                                                    hiddenColumns={["Id"]}
                                                />
                                            </div>
                                        </div>
                                        <Container fluid>
                                            <Row className="tableFooter py-1 mt-0">
                                                <Col xs="2" className="d-flex">
                                                    <b className="text-white text-hidden-m mt-1 pt-1">
                                                        <FormattedMessage id="show" />:{" "}
                                                    </b>
                                                    <select
                                                        data-testid="pageSelect"
                                                        className="ms-2 from-control"
                                                        onChange={onPageLimitChanged}
                                                        value={pageLimit}
                                                    >
                                                        {PageLimitOptions.map((op) => (
                                                            <option key={op} value={op}>
                                                                {op}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </Col>

                                                <Col className="d-flex justify-content-end" xs="10">

                                                    <Pagination
                                                        totalPages={pageInfo.totalPages}
                                                        pageLimit={pageLimit}
                                                        onPageChanged={onPageChanged}
                                                        disablePagination={disablePagination}
                                                    />
                                                </Col>
                                                <Col className="d-flex justify-content-center missingReport" xs="12">
                                                    <div className="total-report-review">
                                                        <>
                                                            <ul className="list-group list-group-horizontal">
                                                                <li className="list-group-item bg-transparent border-0 text-white d-flex">
                                                                    <FormattedMessage id="Start Time" />:{" "}
                                                                    {getMissingTransactionViewData.StartTime !== null
                                                                        ? getMissingTransactionViewData.StartTime
                                                                        : emptyValue}
                                                                </li>
                                                                <li className="list-group-item bg-transparent border-0 text-white d-flex">
                                                                    <FormattedMessage id="End Time" />:{" "}
                                                                    {getMissingTransactionViewData.EndTime !== null
                                                                        ? getMissingTransactionViewData.EndTime
                                                                        : emptyValue}
                                                                </li>
                                                                <li className="list-group-item bg-transparent border-0 text-white d-flex">
                                                                    <FormattedMessage id="Asset Id" />:{" "}
                                                                    {getMissingTransactionViewData.AssetId !== null
                                                                        ? getMissingTransactionViewData.AssetId
                                                                        : emptyValue}
                                                                </li>
                                                                <li className="list-group-item bg-transparent border-0 text-white d-flex">
                                                                    <FormattedMessage id="Start Ticket Number" />:{" "}
                                                                    {getMissingTransactionViewData.StartTicketNumber !== null
                                                                        ? getMissingTransactionViewData.StartTicketNumber
                                                                        : emptyValue}
                                                                </li>
                                                                <li className="list-group-item bg-transparent border-0 text-white d-flex">
                                                                    <FormattedMessage id="End Ticket Number" />:{" "}
                                                                    {getMissingTransactionViewData.EndTicketNumber !== null
                                                                        ? getMissingTransactionViewData.EndTicketNumber
                                                                        : emptyValue}
                                                                </li>

                                                            </ul>

                                                        </>

                                                    </div>
                                                </Col>
                                            </Row>
                                        </Container>
                                    </div>
                                </div>
                            ) : (
                                <div className="no_data_height">
                                    <div className="no_data w-100 text-capitalize text-center">
                                        <img src={NoData} alt="NOData" className="NOData" />
                                        <h1 className="text-capitalize fs-2">
                                            <FormattedMessage id="message_no_datafound" />
                                        </h1>
                                    </div>
                                </div>
                            )}
                        </Container>
                    </React.Fragment>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default observer(MissingTransactionPreview)