/*    
<summary>
   This class component is all about Managing dashboard data functionality.
   Developer:Aashish Singh, Created Date:04-April-2024
</summary>
<param>No Parameter Passed</param>
<returns>Returns class instance</returns>
*/

import { makeObservable, observable, action, computed } from "mobx";
import { IObservableInitialState, ITruckOption } from "../../models/ICommon";
import UrlConstants from "../../constants/url.constant";
import * as services from '../service/base-service';
import { getClientId } from "../../helpers/localStorages.helper";
import IApiResponse, { IApiSuccessResponse } from "../../models/response/IApiResponse";
import { IDailyFuelUsage, IDashboardMetrics, IFuelStatus, IFuelStatusDetail, IRecentAlarms, ITankLevelDetail, ITruckStatus, IVolumeConsumed, IVolumeConsumedDetail, IVolumeDelivered, IVolumeDeliveredDetail } from "../../models/response/IDashboardResponse";
import { toast } from "react-toastify";
import { formatMessage } from "../../translations/formatMessage";
import { dashboardMetricsInitialState, dashboardRecentAlarmsInitialState, fuelStatusInitialDetail, tankLevelInitialDetail, volumeConsumedInitialDetail, volumeDeliveredInitialDetail } from "../initialState/add-edit-dashboard-state";
import { IDashboardState } from "../../models/state/IDashboradState";
import moment from "moment";
import { IDataPoint } from "../../models/IChartData";
import { IDashboardFilter } from "../../models/IFilter";
import { initialDashboardFilter } from "../initialState/initial-filter-states";
import { allOption, allOptionDailyFuelUsage, DATE_TIME_FORMAT_TO_BACKEND } from "../../constants/common-constant";

export class DashboardStore implements IDashboardState {
    inProgress = false;
    error = '';

    initialStateValue: IObservableInitialState = {
        success: false,
        error: '',
        inProgress: false
    }

    fuelStatusDetail: IFuelStatus | undefined = undefined;
    fuelStatusDetailState: IObservableInitialState = { ...this.initialStateValue };

    dailyFuelUsageDetail: IDailyFuelUsage[] = [];
    dailyFuelUsageDetailState: IObservableInitialState = { ...this.initialStateValue };

    dashboardMetricsDetail: IDashboardMetrics = dashboardMetricsInitialState;
    dashboardMetricsDetailState: IObservableInitialState = { ...this.initialStateValue };

    dashboardRecentAlarmsDetail: IRecentAlarms[] = dashboardRecentAlarmsInitialState;
    dashboardRecentAlarmsState: IObservableInitialState = { ...this.initialStateValue };

    tankLevelDetail: any = undefined;
    tankLevelDetailState: IObservableInitialState = { ...this.initialStateValue };

    VolumeDeliveredDetail: IVolumeDelivered | undefined = undefined;
    VolumeDeliveredDetailState: IObservableInitialState = { ...this.initialStateValue };

    VolumeConsumedDetail: IVolumeConsumed | undefined = undefined;
    VolumeConsumedDetailState: IObservableInitialState = { ...this.initialStateValue };
    date = new Date();

    filterSetting: any = {
        ...initialDashboardFilter
    };

    filterCase: string = "";

    truckStatusDetail: ITruckStatus[] = [];
    truckStatusDetailState: IObservableInitialState = { ...this.initialStateValue };

    constructor() {
        makeObservable(this, {
            inProgress: observable,
            error: observable,
            fuelStatusDetail: observable,
            fuelStatusDetailState: observable,
            tankLevelDetail: observable,
            tankLevelDetailState: observable,
            dailyFuelUsageDetail: observable,
            dailyFuelUsageDetailState: observable,
            dashboardMetricsDetail: observable,
            dashboardMetricsDetailState: observable,
            VolumeDeliveredDetail: observable,
            VolumeDeliveredDetailState: observable,
            VolumeConsumedDetail: observable,
            VolumeConsumedDetailState: observable,
            dashboardRecentAlarmsState: observable,
            filterSetting: observable,
            truckStatusDetail: observable,
            truckStatusDetailState: observable,
            filterCase: observable,
            GetFuelStatusService: action,
            GetDailyFuelUsageService: action,
            GetDashboardMetricsService: action,
            GetTankLevelService: action,
            GetVolumeDeliveredService: action,
            GetVolumeConsumedService: action,
            resetDashboardDataSuccessState: action,
            GetTruckStatusService: action,
            resetTruckStatusState: action,
            setFilterSetting: action,
            setFilterDateSetting: action,
            setFilterCase: action,
            GetRecentAlarmsService: action,
            getFilterSettingInfo: computed,
            getFuelStatusDetail: computed,
            getVolumeDeliveredDetail: computed,
            getVolumeConsumedDetail: computed,
            getDailyFuelUsageChartData: computed,
            getDashboardDataSuccessState: computed,
            getTruckStatusData: computed,
        });
    }

    GetFuelStatusService = (truckId: string[] | string, startDate: Date, endDate: Date) => {
        this.fuelStatusDetailState.inProgress = true;
        let startDateData = moment(startDate).format("YYYY-MM-DD");
        let endDateData = moment(endDate).format("YYYY-MM-DD");
        const tempStartDate = startDateData + " 00:00:00";
        const tempEndDate = endDateData + " 23:59:59";
        const url = UrlConstants.GetFuelStatus + '?startTime=' + tempStartDate + '&endTime=' + tempEndDate + '&truckIds=' + (this.filterSetting.FuelStatus.Id === "All" ? "" : this.filterSetting.FuelStatus.Id);
        return services.get(url)
            .then((response: IApiResponse<IApiSuccessResponse<IFuelStatus>>) => {
                this.fuelStatusDetail = response.data.Data;
                this.fuelStatusDetailState.success = true;
            })
            .catch((err: string) => {
                // this.error = err;
                toast.error(formatMessage(err));
            })
            .finally(action(() => { this.fuelStatusDetailState.inProgress = false; }));
    }

    get getFuelStatusDetail(): IFuelStatusDetail {
        if (this.fuelStatusDetail) {
            return {
                ...this.fuelStatusDetail,
                TotalFuelDelivered: Number(this.fuelStatusDetail.TotalFuelDelivered).toFixed(2),
                TotalFuelConsumed: Number(this.fuelStatusDetail.TotalFuelConsumed).toFixed(2),
                TotalFuelInflow: (this.fuelStatusDetail.TotalFuelInflow !== null && this.fuelStatusDetail.TotalFuelInflow !== undefined) ? Number(this.fuelStatusDetail.TotalFuelInflow).toFixed(2) : Number(fuelStatusInitialDetail.TotalFuelInflow).toFixed(2),
            };
        }
        return fuelStatusInitialDetail;
    }

    GetTankLevelService = (truckId: string[] | string, startDate: Date, endDate: Date) => {
        this.tankLevelDetailState.inProgress = true;
        let startDateData = moment(startDate).format("YYYY-MM-DD");
        let endDateData = moment(endDate).format("YYYY-MM-DD");
        const tempStartDate = startDateData + " 00:00:00";
        const tempEndDate = endDateData + " 23:59:59";
        const url = UrlConstants.GetCompartmentFuel + '?&startTime=' + tempStartDate + '&endTime=' + tempEndDate + '&truckIds=' + this.filterSetting.TankLevel.Name;
        return services.get(url)
            .then((response: IApiResponse<IApiSuccessResponse<ITankLevelDetail>>) => {
                this.tankLevelDetail = response.data.Data;
                this.tankLevelDetailState.success = true;
            })
            .catch((err: string) => {
                // this.error = err;
                toast.error(formatMessage(err));
            })
            .finally(action(() => { this.tankLevelDetailState.inProgress = false; }));
    }

    get getTankLevelDetail(): ITankLevelDetail {
        if (this.tankLevelDetail) {
            return {
                ...this.tankLevelDetail,
                Compartment: this.tankLevelDetail.CompartmentType === 0 ? 1 : 2,
            };
        }
        return tankLevelInitialDetail;
    }

    GetDailyFuelUsageService = (truckId: any, startDate: Date, endDate: Date) => {
        this.dailyFuelUsageDetailState.inProgress = true;
        let startDateData = moment(startDate).format("YYYY-MM-DD");
        let endDateData = moment(endDate).format("YYYY-MM-DD");
        const tempStartDate = startDateData + " 00:00:00";
        const tempEndDate = endDateData + " 23:59:59";
        // const url = UrlConstants.GetDailyFuelUsage + '?startTime=' + tempStartDate + '&endTime=' + tempEndDate + '&truckIds=' + (this.filterSetting.DailyFuelUsage.Id == "All" ? [] : this.filterSetting.DailyFuelUsage.Id);
        const url = UrlConstants.GetDailyFuelUsage + '?startTime=' + tempStartDate + '&endTime=' + tempEndDate + '&customerId=' + (this.filterSetting.DailyFuelUsage.CustomerId.Name === "All" ? "" : this.filterSetting.DailyFuelUsage.CustomerId.Name) + '&customerId2=' + (this.filterSetting.DailyFuelUsage.CustomerId2.Name === "All" ? "" : this.filterSetting.DailyFuelUsage.CustomerId2.Name) + '&customerId3=' + (this.filterSetting.DailyFuelUsage.CustomerId3.Name === "All" ? "" : this.filterSetting.DailyFuelUsage.CustomerId3.Name);
        return services.get(url)
            .then((response: IApiResponse<IApiSuccessResponse<IDailyFuelUsage[]>>) => {
                this.dailyFuelUsageDetail = response.data.Data;
                this.dailyFuelUsageDetailState.success = true;
            })
            .catch((err: string) => {
                // this.error = err;
                toast.error(formatMessage(err));
            })
            .finally(action(() => { this.dailyFuelUsageDetailState.inProgress = false; }));
    }

    get getDailyFuelUsageChartData(): IDataPoint[] {
        if (this.dailyFuelUsageDetail && this.dailyFuelUsageDetail.length > 0) {
            return this.dailyFuelUsageDetail.map((item: IDailyFuelUsage) => {
                return { x: new Date(item.Day), y: item.Usage }
            })
        }
        return [];
    }

    GetVolumeDeliveredService = (startDate: Date, endDate: Date) => {
        this.VolumeDeliveredDetailState.inProgress = true;
        let startDateData = moment(startDate).format("YYYY-MM-DD");
        let endDateData = moment(endDate).format("YYYY-MM-DD");
        const tempStartDate = startDateData + " 00:00:00";
        const tempEndDate = endDateData + " 23:59:59";
        let requestBody = {
            StartTime: tempStartDate,
            EndTime: tempEndDate
        }
        const url = UrlConstants.GetVolumeDelivered;// + '?startTime=' + tempStartDate + '&endTime=' + tempEndDate;
        return services.post(url, requestBody)
            .then((response: IApiResponse<IApiSuccessResponse<IVolumeDelivered>>) => {
                this.VolumeDeliveredDetail = response.data.Data;
                this.VolumeDeliveredDetailState.success = true;
            })
            .catch((err: string) => {
                // this.error = err;
                toast.error(formatMessage(err));
            })
            .finally(action(() => { this.VolumeDeliveredDetailState.inProgress = false; }));
    }

    get getVolumeDeliveredDetail(): IVolumeDeliveredDetail {
        if (this.VolumeDeliveredDetail) {
            return {
                MinDelivered: Number(this.VolumeDeliveredDetail.MinDelivered).toFixed(2),
                AvgDelivered: Number(this.VolumeDeliveredDetail.AvgDelivered).toFixed(2),
                MaxDelivered: Number(this.VolumeDeliveredDetail.MaxDelivered).toFixed(2),
            };
        }
        return volumeDeliveredInitialDetail;
    }

    GetVolumeConsumedService = (startDate: Date, endDate: Date) => {
        this.VolumeConsumedDetailState.inProgress = true;
        let startDateData = moment(startDate).format("YYYY-MM-DD");
        let endDateData = moment(endDate).format("YYYY-MM-DD");
        const tempStartDate = startDateData + " 00:00:00";
        const tempEndDate = endDateData + " 23:59:59";
        let requestBody = {
            StartTime: tempStartDate,
            EndTime: tempEndDate
        }
        const url = UrlConstants.GetVolumeConsumed// + '?startTime=' + tempStartDate + '&endTime=' + tempEndDate;
        return services.post(url, requestBody)
            .then((response: IApiResponse<IApiSuccessResponse<IVolumeConsumed>>) => {
                this.VolumeConsumedDetail = response.data.Data;
                this.VolumeConsumedDetailState.success = true;
            })
            .catch((err: string) => {
                // this.error = err;
                toast.error(formatMessage(err));
            })
            .finally(action(() => { this.VolumeConsumedDetailState.inProgress = false; }));
    }

    get getVolumeConsumedDetail(): IVolumeConsumedDetail {
        if (this.VolumeConsumedDetail) {
            return {
                MinConsumed: Number(this.VolumeConsumedDetail.MinConsumed).toFixed(2),
                AvgConsumed: Number(this.VolumeConsumedDetail.AvgConsumed).toFixed(2),
                MaxConsumed: Number(this.VolumeConsumedDetail.MaxConsumed).toFixed(2),
            };
        }
        return volumeConsumedInitialDetail;
    }

    GetDashboardMetricsService = () => {
        this.dashboardMetricsDetailState.inProgress = true;
        const url = UrlConstants.GetDashboardMetrices;
        return services.get(url)
            .then((response: IApiResponse<IApiSuccessResponse<IDashboardMetrics>>) => {
                this.dashboardMetricsDetail = response.data.Data;
                this.dashboardMetricsDetailState.success = true;
            })
            .catch((err: string) => {
                // this.error = err;
                toast.error(formatMessage(err));
            })
            .finally(action(() => { this.dashboardMetricsDetailState.inProgress = false; }));
    }

    GetRecentAlarmsService = () => {
        this.dashboardRecentAlarmsState.inProgress = true;
        const url = UrlConstants.GetRecentAlarms;
        return services.get(url)
            .then((response: IApiResponse<IApiSuccessResponse<any>>) => {
                this.dashboardRecentAlarmsDetail = response.data.Data;
                this.dashboardRecentAlarmsState.success = true;
            })
            .catch((err: string) => {
                // this.error = err;
                toast.error(formatMessage(err));
            })
            .finally(action(() => { this.dashboardRecentAlarmsState.inProgress = false; }));
    }

    GetTruckStatusService = () => {
        this.truckStatusDetailState.inProgress = true;
        const url = `${UrlConstants.GetTruckStatus}?truckCount=${4}`;
        return services.get(url)
            .then((response: IApiResponse<IApiSuccessResponse<ITruckStatus[]>>) => {
                this.truckStatusDetail = response.data.Data;
                this.truckStatusDetailState.success = true;
            })
            .catch((err: string) => {
                // this.error = err;
                toast.error(formatMessage(err));
            })
            .finally(action(() => { this.truckStatusDetailState.inProgress = false; }));
    }

    get getTruckStatusData(): ITruckStatus[] {
        return this.truckStatusDetail.map((truckDetail) => {
            return {
                TruckStatus: truckDetail.TruckStatus,
                TruckId: truckDetail.TruckId,
                TruckStatusUpdatedTime: moment(truckDetail.TruckStatusUpdatedTime).format(DATE_TIME_FORMAT_TO_BACKEND)
            }
        });
    }

    resetTruckStatusState = () => {
        this.truckStatusDetailState = { ...this.initialStateValue };
    }

    resetDashboardDataSuccessState = () => {
        this.fuelStatusDetailState = { ...this.initialStateValue };
        this.dailyFuelUsageDetailState = { ...this.initialStateValue };
        this.dashboardMetricsDetailState = { ...this.initialStateValue };
        this.VolumeConsumedDetailState = { ...this.initialStateValue };
        this.VolumeDeliveredDetailState = { ...this.initialStateValue };
        this.truckStatusDetailState = { ...this.initialStateValue };
        this.tankLevelDetailState = { ...this.initialStateValue };
    }

    get getDashboardDataSuccessState(): boolean {
        let success = this.fuelStatusDetailState.success && this.dailyFuelUsageDetailState.success &&
            this.dashboardMetricsDetailState.success && this.VolumeConsumedDetailState.success && this.VolumeDeliveredDetailState.success
        return success;
    }

    get getDashboardRecentAlarmsDetail(): IRecentAlarms[] {
        if (this.dashboardRecentAlarmsDetail) {
            return this.dashboardRecentAlarmsDetail
        }
        else {
            return [];
        }
    }

    setFilterSetting = (key: string, truck: any, initial: boolean = false, setfromLocalStorage: boolean = false) => {
        if (setfromLocalStorage) {
            let tempFilterDetails = localStorage.getItem("secure_fuel_dashboard_filter");
            if (tempFilterDetails != null)
                this.filterSetting = JSON.parse(tempFilterDetails);
        }
        else if (initial) {
            this.filterSetting = {
                ...this.filterSetting,
                FuelStatus: allOption,
                DailyFuelUsage: allOptionDailyFuelUsage,
                TankLevel: truck,
            }
        }
        else {
            this.filterSetting[key] = truck;
        }
        localStorage.setItem("secure_fuel_dashboard_filter", JSON.stringify(this.filterSetting));
    }

    setFilterDateSetting = (startDate: Date, endDate: Date) => {
        this.filterSetting.StartDate = startDate;
        this.filterSetting.EndDate = endDate;
        localStorage.setItem("secure_fuel_dashboard_filter", JSON.stringify(this.filterSetting));
    }

    setFilterCase = (filtercase: string) => {
        this.filterCase = filtercase;
    }

    get getFilterSettingInfo(): any {
        let tempFilterDetails = localStorage.getItem("secure_fuel_dashboard_filter");
        if (tempFilterDetails != null && JSON.stringify(this.filterSetting) != JSON.stringify(tempFilterDetails)) {
            let tempFilterDetails = localStorage.getItem("secure_fuel_dashboard_filter");
            if (tempFilterDetails != null) {
                const filterDetails = JSON.parse(tempFilterDetails);
                return filterDetails;
            }
            else
                return this.filterSetting;
        }
        else
            return this.filterSetting;
    }

}

export default new DashboardStore();