import { Field, Form, Formik, useFormikContext } from "formik";
import { ITruckInitialFilterState } from "../../../models/IFilter";
import { Modal, Row, Col, Button } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import LoaderButton from "../../../shared-components/Button/LoaderButton";
import FormikFormInput from "../../../shared-components/FormikFormHandlers/FormikFormInput";
import Label from "../../../shared-components/label/label";
import { truckFilterValidateSchema } from "../../../shared-components/FormikFormHandlers/FormikValidationSchema";
import DraggableModal from "../../../shared-components/Draggable/DraggableModal";

interface IProps {
  submitHandler: (values: any) => void;
  onModalClosed: () => void;
  actionInProgress: boolean;
  initialValues: ITruckInitialFilterState;
}

const TruckFilterForm = (props: IProps) => {
  const { submitHandler, onModalClosed, initialValues, actionInProgress } =
    props;

  // const [err, setErr] = useState<boolean>(false)

  const FormObserver: React.FC = () => {
    const { values } = useFormikContext<any>();
    return null;
  };


  return (
    <>
      <Modal className="add-user-modal " show={true} centered dialogAs={DraggableModal}>
        <Modal.Header>
          <Modal.Title>
            <FormattedMessage id="filter_title" />
          </Modal.Title>
          <div onClick={onModalClosed} className="icons8-close">
            <span className="material-symbols-outlined fs-5 text-dark">
              close
            </span>
          </div>
        </Modal.Header>
        <Formik
          validationSchema={truckFilterValidateSchema}
          initialValues={initialValues}
          enableReinitialize
          onSubmit={async (values) => {
            let tempValue = {
              TruckId: values.TruckId
            }
            props.submitHandler(tempValue);
            // }
          }}
        >
          {({ values }) => (
            <Form className="user-form p-3" noValidate>
              <FormObserver />
              <Modal.Body>
                <Row className="mb-3 form-group d-flex align-items-center">
                  <Col xs={3}>
                    <Label className="form-label" label="truck_id" />
                  </Col>
                  <Col xs={9}>

                    <Field
                      data-testid="testUserName"
                      className="form-control"
                      name="TruckId"
                      type="text"
                      invalidcharacter={[" "]}
                      validcharacter={["_"]}
                      placeholder="truck_id"
                      as={FormikFormInput}
                    />
                  </Col>
                </Row>
                {/* {
                  err &&
                  <span data-testid="testError" className= "text-danger errorMessage">
                    <FormattedMessage id="please_select_at_least_1_field" />
                  </span>
                } */}
              </Modal.Body>
              <div className="modal-footer  border-0">
                <div className="m-auto">
                  <LoaderButton
                    type="submit"
                    isLoading={actionInProgress}
                    disabled={actionInProgress}
                    text="filter_title"
                  />
                  <Button
                    variant="btn-secondary bg-light btn-light ms-2"
                    onClick={onModalClosed}
                  >
                    <FormattedMessage id="button_cancel" />
                  </Button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};

export default TruckFilterForm;
