import moment from "moment";
import { DateFormatForBackend } from "../constants/datePicker.constant";
import { TimeFormatWithSeconds } from "../constants/timePicker.constant";

export const getFormattedTime = (time: string) => {
    return moment(time, TimeFormatWithSeconds).format(TimeFormatWithSeconds);
}

export const getFormattedDateForBackend = (date: Date) => {
    return moment(date).format(DateFormatForBackend);
}

export function getDaysBetweenDateTimes(startTime: string, endTime: string): number {
    // Convert the ISO date strings to Date objects
    const start = new Date(startTime);
    const end = new Date(endTime);

    // Check if parsing was successful
    if (isNaN(start.getTime()) || isNaN(end.getTime())) {
        throw new Error("Invalid date format");
    }

    // Calculate the time difference in milliseconds
    const differenceInTime = end.getTime() - start.getTime();

    // Convert the difference from milliseconds to days
    const differenceInDays = differenceInTime / (1000 * 3600 * 24);

    return Math.floor(differenceInDays); // Round down to full days
}
